/**
 * @generated SignedSource<<c027183ab286b5e6c4b8cde986da9ce5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LeaderboardType = "ALLTIME" | "MONTH" | "WEEK" | "%future added value";
export type FetchUserLeaderboardQuery$variables = {
  scope?: LeaderboardType | null;
  tenantId: string;
};
export type FetchUserLeaderboardQuery$data = {
  readonly leaderboardUserRecord: {
    readonly avatar: string | null;
    readonly level: number | null;
    readonly rank: number | null;
    readonly username: string | null;
    readonly xp: number;
  } | null;
};
export type FetchUserLeaderboardQuery = {
  response: FetchUserLeaderboardQuery$data;
  variables: FetchUserLeaderboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "scope",
        "variableName": "scope"
      },
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "LeaderboardUserRecord",
    "kind": "LinkedField",
    "name": "leaderboardUserRecord",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "xp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rank",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "level",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchUserLeaderboardQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FetchUserLeaderboardQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9359c0b5cb350cd9f4bd546b5547d1b2",
    "id": null,
    "metadata": {},
    "name": "FetchUserLeaderboardQuery",
    "operationKind": "query",
    "text": "query FetchUserLeaderboardQuery(\n  $tenantId: String!\n  $scope: LeaderboardType\n) {\n  leaderboardUserRecord(tenantId: $tenantId, scope: $scope) {\n    xp\n    rank\n    username\n    avatar\n    level\n  }\n}\n"
  }
};
})();

(node as any).hash = "92dc75183d032715e302003bd957f8c4";

export default node;
