/* ----------------- React --------------- */
import { useCallback, useState } from "react";

/* ----------------- Types --------------- */
import type { Distribution } from "@memorang/types/src/content";
import type {
	CreateSessionType,
	EndSessionTypes,
	Mode,
} from "@memorang/types/src/session/common";

import { useExamContext } from "@features/exam/contexts/ExamContext";
/* ----------------- Hooks --------------- */
import useSessionStore from "./useSessionStore";

/* ----------------- Functions --------------- */
import createSession from "../relay/CreateSession";

/* ----------------- Analytics --------------- */
import { trackCustomEvent } from "analytics";

/* ----------------- Constants --------------- */
import { events } from "@constants/tracking";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
/* ----------------- Router --------------- */
import { useRouter } from "expo-router";
import { endSession } from "../relay/EndSession";

export enum LearningModes {
	flashcards = "flashcards",
	questions = "question",
	quiz = "quiz",
}
const sessionTypeMap: Record<LearningModes, CreateSessionType> = {
	question: "PRACTICE",
	flashcards: "FLASHCARD",
	quiz: "QUIZ",
};

const sessionModeMap: Record<LearningModes, Mode> = {
	question: "QUESTION",
	flashcards: "FLIP",
	quiz: "QUIZ",
};
export const useSession = () => {
	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();
	const router = useRouter();

	const { viewerId: userId } = useAuthContext();

	const [creatingSession, setCreatingSession] = useState(false);
	const [showErrorDialog, setShowErrorDialog] = useState(false);
	const resetSessionStore = useSessionStore((state) => state.resetSessionStore);
	const updateSession = useSessionStore((state) => state.updateSession);

	const queryClient = useQueryClient();
	const updateEndingSessionInProgress = useSessionStore(
		(state) => state.updateEndingSessionInProgress,
	);

	const toggleShowErrorDialog = () => {
		setShowErrorDialog((prev) => !prev);
	};
	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const handleCreateFormativeSession = useCallback(
		async ({
			dist,
			learningMode,
			tags,
			handleClose,
			isRecommendedTask,
		}: {
			dist: Distribution;
			learningMode: LearningModes;
			tags?: string[];
			handleClose?: () => void;
			isRecommendedTask?: boolean;
		}) => {
			resetSessionStore();
			try {
				setCreatingSession(true);
				const createSessionType = sessionTypeMap[learningMode];
				const numItemsRequested = Object.values(dist).reduce(
					(acc, curr) => acc + curr,
					0,
				);
				const result = await createSession({
					action: "FORMATIVE_SESSION",
					distribution: dist,
					createSessionType,
					learningOrder: "SHUFFLED",
					tags: tags ? [tags] : [],
					context: {
						contextType: "SELECTION",
						contents: [
							{
								contentId: studyPackProductId,
								contentType: "QUESTION_SET",
							},
						],
					},
				});
				trackCustomEvent({
					eventName: events.sessionStarted,
					learningMode,
					numItems: numItemsRequested,
					dist: JSON.stringify(dist),
					tags: JSON.stringify(tags || []),
					contentId: studyPackProductId,
					type: "FORMATIVE_SESSION",
				});
				updateSession({
					sessionResponse: result,
					mode: sessionModeMap[learningMode],
					numItemsRequested,
					isRecommendedTask,
				});
				if (handleClose) {
					handleClose();
					router.push({
						pathname: "/(protected)/session/[id]",
						params: {
							id: result.id,
						},
					});
				} else {
					router.replace({
						pathname: "/(protected)/session/[id]",
						params: {
							id: result.id,
						},
					});
				}
			} catch (_error) {
				toggleShowErrorDialog();
			} finally {
				setCreatingSession(false);
			}
		},
		[resetSessionStore, router, studyPackProductId, updateSession],
	);

	const { mutate: handleEndSession } = useMutation({
		mutationFn: async ({
			id,
			sessionType,
		}: {
			id: string;
			sessionType: EndSessionTypes;
		}) => {
			updateEndingSessionInProgress(true);
			await endSession({
				sessionId: id!,
				userId: userId!,
				sessionType,
			});
		},
		onSuccess: () => {
			updateEndingSessionInProgress(false);
			queryClient.invalidateQueries();
		},
	});
	return {
		creatingSession,
		handleCreateFormativeSession,
		showErrorDialog,
		toggleShowErrorDialog,
		handleEndSession,
	};
};
