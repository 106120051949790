/* -----------------Globals--------------- */
import { useCallback, useEffect, useMemo, useState } from "react";

/* -----------------Helpers & Hooks--------------- */
import { useHotkeys } from "@memorang/helpers/src/hooks/useHotkeys";
import { useSearchContext } from "../contexts/SearchContext";
import { useMessageActions } from "./useMessageActions";

import type { MessageData } from "@memorang/ui/src/components/ChatUI/types";
import { v4 as uuidv4 } from "uuid";
/* -----------------Types--------------- */
import type { FeedbackParams as FeedbackParamsType } from "../../feedback/types";
import type { HandleTracking } from "../../types";
import type { EpubParams } from "../types";

/* -----------------Constants--------------- */
import { events, searchContexts } from "../constants";

type Props = {
	feedbackDialogParams?: FeedbackParamsType;
	handleTracking: HandleTracking;
	handleShowConversationMode?: () => void;
	setConstructEpubParams?: React.Dispatch<
		React.SetStateAction<EpubParams | undefined>
	>;
	filterSourceIds?: number[];
	searchContext?: string;
	handleSelectSummarizeChip?: () => void;
	handleResetChat?: () => void;
	epubParams?: EpubParams;
};

export const useSearchView = ({
	feedbackDialogParams,
	handleTracking,
	handleShowConversationMode,
	setConstructEpubParams,
	filterSourceIds,
	searchContext,
	handleSelectSummarizeChip,
	handleResetChat,
	epubParams,
}: Props) => {
	const {
		defaultQuery = "",
		vectorStoreParams,
		context,
		searchQuery,
		handleSetSearchQuery,
		loading,
		streaming,
		setMessages,
		setSummaryResponse,
		resetSearch,
		isThreadLoading,
		thread,
		abortSearch,
		addMessage,
		initialTitle,
		followUps,
	} = useSearchContext();
	// Local state management
	const { messageFeedbackComponents, messageActions, appBarFeedbackAction } =
		useMessageActions({
			handleTracking,
			searchQuery: searchQuery.value,
			feedbackDialogParams,
		});
	const [previousDefaultQuery, setPreviousDefaultQuery] = useState<
		string | null
	>(null);

	// Helper functions
	const handleSelectQuery = useCallback(
		// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
		(query: string) => {
			handleTracking(events.copilotFollowupSelected, { query });
			setSummaryResponse?.("");
			//TODO:This is a hack, need to be done in a better way
			if (query === "Summarize this page" && handleSelectSummarizeChip) {
				handleSelectSummarizeChip();
				return;
			}
			if (query) {
				const defaultQuery = query
					? query
					: `Ask anything related to: ${context}`;

				if (defaultQuery) {
					addMessage({
						content: defaultQuery,
						type: "human",
						id: uuidv4(),
					});
				}
				addMessage({
					content: "",
					type: "assistant",
					loading: true,
					streaming: true,
					id: uuidv4(),
				});
			}

			const newEpubParams: EpubParams = {
				...epubParams,
				isSummarize: false,
				scope: epubParams?.scope || "global",
			};

			if (searchContext && searchContext !== searchContexts.currentView) {
				newEpubParams.scope = "global";
			}

			if (filterSourceIds) {
				newEpubParams.filterSourceIds = [...filterSourceIds];
			}

			setConstructEpubParams?.(newEpubParams);
			handleShowConversationMode?.();
			handleSetSearchQuery(query);
		},
		[
			epubParams,
			searchContext,
			filterSourceIds,
			setConstructEpubParams,
			handleShowConversationMode,
			handleSetSearchQuery,
			context,
			addMessage,
			handleSelectSummarizeChip,
			setSummaryResponse,
			handleTracking,
		],
	);

	const handleHotkeyAction = useCallback(
		(index: number) => {
			if (followUps[index]) {
				handleSelectQuery(followUps[index]);
			}
		},
		[handleSelectQuery, followUps],
	);

	useHotkeys(
		[
			["mod+1", () => handleHotkeyAction(0)],
			["mod+2", () => handleHotkeyAction(1)],
			["mod+3", () => handleHotkeyAction(2)],
			[
				"mod+4",
				() =>
					handleSelectSummarizeChip
						? handleSelectQuery("Summarize this page")
						: null,
			],
		],
		[],
	);

	useEffect(() => {
		if (searchQuery) {
			handleTracking(events.copilotTextSubmitted, { query: searchQuery.value });
		}
	}, [handleTracking, searchQuery]);

	const showRecommendedChipsLoading = useMemo(() => {
		return followUps.length === 0 || loading || streaming;
	}, [followUps.length, loading, streaming]);

	const addNewMessages = (query: string) => {
		const newMessage: MessageData = {
			content: query,
			type: "human",
			id: uuidv4(),
		};
		const newAiMessage: MessageData = {
			content: "",
			type: "assistant",
			loading: true,
			streaming: true,
			id: uuidv4(),
		};
		setMessages((prevMessages) => [...prevMessages, newMessage, newAiMessage]);
	};

	const interceptSearchQuery = (
		query: string,
		isSummarize = false,
		scope: "aila" | "global" = "aila",
	) => {
		if (loading || streaming) {
			return;
		}
		setSummaryResponse(undefined);
		addNewMessages(query);
		handleSetSearchQuery(query);

		if (searchContext && searchContext !== searchContexts.currentView) {
			// biome-ignore lint/style/noParameterAssign: // TODO fix me later
			scope = "global";
		}

		const newEpubParams: EpubParams = {
			...epubParams,
			isSummarize,
			scope,
		};
		if (filterSourceIds) {
			newEpubParams.filterSourceIds = [...filterSourceIds];
		}

		setConstructEpubParams?.(newEpubParams);
	};

	const handleResetChatState = () => {
		handleSetSearchQuery("");
		handleResetChat?.();
		resetSearch(true);
	};

	if (defaultQuery !== previousDefaultQuery && thread && !isThreadLoading) {
		if (defaultQuery) {
			addNewMessages(defaultQuery);
		}
		setPreviousDefaultQuery(defaultQuery);
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		abortSearch();
	}, [vectorStoreParams.itemId]);

	return {
		showRecommendedChipsLoading,
		localRecommendedQueries: followUps,
		handleSelectQuery,
		messageFeedbackComponents,
		messageActions,
		appBarFeedbackAction,
		isRecommendedQuestionsFetching: loading || streaming || !followUps.length,
		handleResetChatState,
		interceptSearchQuery,
		initialTitle,
	};
};
