/* -----------------Globals--------------- */
import { NodeViewWrapper } from "@tiptap/react";

/* -----------------Child components--------------- */
import { ZoomImage } from "@memorang/ui/src/components/MarkdownRenderer/ZoomImage";
import type { Node } from "@tiptap/pm/model";

export const ImageNodeViewWrapper = ({ node }: { node: Node }) => {
	return (
		<NodeViewWrapper>
			<ZoomImage allImages={[{ url: node.attrs.src }]} url={node.attrs.src} />
		</NodeViewWrapper>
	);
};
