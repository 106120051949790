/* -----------------Globals--------------- */

import { pageViewTypes } from "@constants/tracking";
/* -----------------Child components--------------- */
import StudyPackContainer from "@features/dashboard/screens/StudyPackContainer";
import useAnalytics from "@hooks/useAnalytics";
import DocumentTitle from "components/DocumentTitle";

const StudyPackRoute = () => {
	useAnalytics({
		type: pageViewTypes.studyPack,
	});
	return (
		<>
			<DocumentTitle title="Study-Pack" />
			<StudyPackContainer />
		</>
	);
};

export default StudyPackRoute;
