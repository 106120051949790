/* -----------------Globals--------------- */
import { create } from "zustand";

/* -----------------Types--------------- */
import type { OriginalQuestionData } from "@memorang/features/src/qa-search/types/question";

/* -----------------Types--------------- */
type State = {
	itemId?: string;
	userPrompt: string;
	selectedQuery: string;
	showDrawer: boolean;
	isItemAnswered: boolean;
	originalQuestionData?: OriginalQuestionData;
	updateItemId: (itemId: string) => void;
	updateUserPrompt: (prompt: string) => void;
	updateSelectedQuery: (query: string) => void;
	updateShowDrawer: (show: boolean) => void;
	updateIsItemAnswered: (isDisabled: boolean) => void;
	updateOriginalQuestionData: (data: OriginalQuestionData | undefined) => void;
};

/* -----------------Store--------------- */
export const useAilaStore = create<State>()((set) => ({
	itemId: undefined,
	userPrompt: "",
	selectedQuery: "",
	showDrawer: false,
	isItemAnswered: false,
	originalQuestionData: undefined,
	updateItemId: (itemId: string) => set({ itemId }),
	updateUserPrompt: (userPrompt: string) => set({ userPrompt }),
	updateSelectedQuery: (selectedQuery: string) => set({ selectedQuery }),
	updateShowDrawer: (showDrawer: boolean) => set({ showDrawer }),
	updateIsItemAnswered: (isItemAnswered) => set({ isItemAnswered }),
	updateOriginalQuestionData: (originalQuestionData) =>
		set({ originalQuestionData }),
}));
