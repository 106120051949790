/* ----------------- Hooks --------------- */
import { useHint } from "../hooks/useHint";

/* ----------------- Components --------------- */
import { HintAccordion } from "../components/HintAccordion";

/* ----------------- Types --------------- */
import type { SessionChildItem } from "@memorang/types/src/session/common";

const HintContainer = ({ item }: { item: SessionChildItem }) => {
	const { hints, loading, streaming, getHint } = useHint({
		item,
	});

	return (
		<HintAccordion
			hints={hints}
			loading={loading}
			streaming={streaming}
			itemId={item.id}
			getHint={getHint}
		/>
	);
};

export default HintContainer;
