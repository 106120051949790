/* ----------------- UI Components --------------- */
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";
import { Button, Dialog, Text } from "react-native-paper";

/* ----------------- Navigation --------------- */
import { router } from "expo-router";

export const OLXExamEligibilityDialog = ({
	visible,
	handleClose,
	title,
	message,
}: {
	visible: boolean;
	handleClose: () => void;
	title: string;
	message: string;
}) => {
	return (
		<DialogWrapper visible={visible} handleClose={handleClose} maxWidth={"xs"}>
			<Dialog.Icon icon={"information-outline"} />
			<Dialog.Title style={{ textAlign: "center" }}>{title}</Dialog.Title>
			<Dialog.Content>
				<Text variant="bodyMedium">{message}</Text>
			</Dialog.Content>
			<Dialog.Actions>
				<Button
					mode="contained"
					contentStyle={{
						paddingHorizontal: 8,
					}}
					onPress={() => {
						router.push("/practice-tests");
						handleClose();
					}}
				>
					Go to Practice tests
				</Button>
			</Dialog.Actions>
		</DialogWrapper>
	);
};
