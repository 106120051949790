import { useAppTheme } from "@memorang/ui/src/useAppTheme";
import { ActivityIndicator, Chip } from "react-native-paper";
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../../analytics";

type Props = {
	title: string;
	message: string;
	handlePress: (message: string) => void;
	partial: boolean;
};

const SuggestedAction = ({ title, message, handlePress, partial }: Props) => {
	const theme = useAppTheme();
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	const handleSuggestionPress = (message: string) => {
		trackRedeemEvent(rcEvents.suggestionClicked, {
			interactionType: "manual",
			interactionElementType: "suggestion",
		});

		handlePress(message);
	};

	return (
		<Chip
			mode="outlined"
			disabled={partial}
			onPress={() => handleSuggestionPress(message)}
			icon={
				partial
					? () => <ActivityIndicator size="small" />
					: "arrow-right-bottom"
			}
			style={{
				borderColor: theme.colors.outlineVariant,
			}}
			textStyle={{
				color: theme.colors.onSurface,
				fontSize: 12,
			}}
		>
			{title}
		</Chip>
	);
};

export default SuggestedAction;
