import { useCopilotAction } from "@copilotkit/react-core";
import {
	type ActionComponent,
	actionComponents,
} from "../components/ActionRenderer";
import type { SupportDetailsProps } from "../components/SupportDetails";
import { CONTACT_SUPPORT_INSTRUCTIONS } from "../constants";
import type { ActionHandlerReturn } from "../types";

/* --------------- Analytics --------------- */
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../analytics";

type Props = SupportDetailsProps & {
	supportHandler: () => void;
};

type Result = ActionHandlerReturn<
	ActionComponent["contactSupport"]["resultType"]
>;

export const useContactSupportActionTrigger = ({
	supportHandler,
	...rest
}: Props) => {
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	useCopilotAction({
		name: actionComponents.contactSupport.name,
		description:
			"Escalate user issue to support team by preparing support email template.",
		handler: (): Result => {
			trackRedeemEvent(rcEvents.supportContactInitiated, {
				interactionType: "auto",
				interactionElementType: "action",
				success: true,
			});

			supportHandler();
			return {
				success: true,
				instructions: CONTACT_SUPPORT_INSTRUCTIONS,
				resultData: { details: rest },
			};
		},
	});
};
