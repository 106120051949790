import type { Choice } from "@memorang/types/src/session/common";
import concat from "lodash-es/concat";

const getSortedChoices = (choiceList: Choice[]) => {
	const correctChoices = choiceList.filter(({ isCorrect }) => isCorrect);
	const incorrectChoices = choiceList.filter(({ isCorrect }) => !isCorrect);
	return concat(correctChoices, incorrectChoices);
};

export { getSortedChoices };
