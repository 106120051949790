/* -----------------Globals--------------- */
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

/* -----------------Helpers & Hooks--------------- */
import { useSearch } from "../hooks/useSearch";

/* -----------------Types--------------- */
import type { FeedbackParams } from "@memorang/features/src/feedback/types";
import type {
	AiAssistantInfo,
	MessageData,
} from "@memorang/ui/src/components/ChatUI/types";
import type {
	EpubParams,
	Thread,
	VectorDocument,
	VectorStoreParams,
} from "../types";
import type { OriginalQuestionData } from "../types/question";

type SearchContextState = {
	loading?: boolean;
	summaryResponse?: string;
	references: VectorDocument[];
	streaming?: boolean;
	messages: MessageData[];
	setMessages: React.Dispatch<React.SetStateAction<MessageData[]>>;
	resetSearch: (shouldResetMessages?: boolean) => void;
	showReference?: boolean;
	searchQuery: {
		value: string;
	};
	handleSetSearchQuery: (query: string) => void;
	userId: string;
	defaultQuery?: string;
	vectorStoreParams: VectorStoreParams;
	feedbackDialogParams?: FeedbackParams;
	sessionId?: string;
	epubParams?: EpubParams;
	context: string;
	setSummaryResponse: React.Dispatch<React.SetStateAction<string | undefined>>;
	abortSearch: () => void;
	assistantInfo: AiAssistantInfo;
	isThreadLoading: boolean;
	activityType: "review" | "reader";
	thread: Thread | undefined;
	originalQuestionData?: OriginalQuestionData;
	addMessage: (message: MessageData) => void;
	initialTitle?: string;
	followUps: string[];
};

const SearchContext = createContext<SearchContextState | null>(null);

export const SearchContextProvider = ({
	children,
	assistantInfo,
	vectorStoreParams,
	sessionId,
	userId,
	epubParams,
	defaultQuery,
	context,
	activityType = "review",
	originalQuestionData,
	action,
	userPrompt,
	defaultInitialTitle,
	initialFollowUps,
}: {
	children: React.ReactNode;
	assistantInfo: AiAssistantInfo;
	defaultQuery?: string;
	vectorStoreParams: VectorStoreParams;
	sessionId: string;
	userId: string;
	epubParams?: EpubParams;
	context: string;
	activityType?: "review" | "reader";
	originalQuestionData?: OriginalQuestionData;
	action?: "new";
	userPrompt?: string;
	defaultInitialTitle?: string;
	initialFollowUps?: string[];
}) => {
	const [searchQuery, setSearchQuery] = useState<{
		value: string;
	}>({
		value: defaultQuery || "",
	});

	// when user clicks a recommended chip when the drawer is still open, this gets triggered
	useEffect(() => {
		setSearchQuery({
			value: defaultQuery || "",
		});
	}, [defaultQuery]);

	const {
		loading,
		summaryResponse,
		references,
		streaming,
		messages,
		setMessages,
		resetSearch,
		showReference,
		setSummaryResponse,
		abortSearch,
		isThreadLoading,
		thread,
		addMessage,
		initialTitle,
		followUps,
	} = useSearch({
		searchQuery,
		vectorStoreParams,
		sessionId,
		userId,
		epubParams,
		activityType,
		originalQuestionData,
		action,
		userPrompt,
		defaultInitialTitle,
		context,
		initialFollowUps,
		assistantInfo,
	});
	const handleSetSearchQuery = useCallback(
		(query: string) => {
			setSummaryResponse("");
			setSearchQuery({
				value: query,
			});
		},
		[setSummaryResponse],
	);

	const value = useMemo<SearchContextState>(() => {
		return {
			loading,
			summaryResponse,
			references,
			streaming,
			messages,
			setMessages,
			resetSearch,
			showReference,
			handleSetSearchQuery,
			searchQuery,
			userId,
			defaultQuery,
			vectorStoreParams,
			epubParams,
			sessionId,
			context,
			setSummaryResponse,
			abortSearch,
			assistantInfo,
			isThreadLoading,
			activityType,
			thread,
			originalQuestionData,
			addMessage,
			initialTitle,
			followUps,
		} satisfies SearchContextState;
	}, [
		handleSetSearchQuery,
		loading,
		messages,
		references,
		resetSearch,
		searchQuery,
		setMessages,
		showReference,
		streaming,
		summaryResponse,
		userId,
		vectorStoreParams,
		epubParams,
		defaultQuery,
		sessionId,
		context,
		setSummaryResponse,
		abortSearch,
		assistantInfo,
		isThreadLoading,
		activityType,
		thread,
		originalQuestionData,
		addMessage,
		initialTitle,
		followUps,
	]);

	return (
		<SearchContext.Provider value={value}>{children}</SearchContext.Provider>
	);
};
export const useSearchContext = () => {
	const context = useContext(SearchContext);
	if (!context) {
		throw new Error(
			"useSearchContext must be used within a SearchContextProvider",
		);
	}
	return context;
};
