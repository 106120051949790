/* ----------------- Globals --------------- */
import { events } from "@constants/tracking";
import useAnalytics from "@hooks/useAnalytics";
import { useAppTheme } from "@hooks/useAppTheme";
import { useMemo, useState } from "react";

import { TextSkeleton } from "@memorang/ui/src/components/ChatUI/TextSkeleton";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
/* ----------------- Components --------------- */
import { List, Text } from "react-native-paper";

/* ----------------- Types --------------- */
import type { UseHintReturnType } from "../hooks/useHint";

type Props = UseHintReturnType & {
	itemId: string;
};

export const HintAccordion = ({
	itemId,
	getHint,
	hints,
	loading,
	streaming,
}: Props) => {
	const [showHint, setShowHint] = useState(false);
	const theme = useAppTheme();

	const lastHint = useMemo(() => {
		return hints[hints.length - 1]?.text;
	}, [hints.length, hints[hints.length - 1]?.text]);

	useAnalytics(
		{
			eventName: events.sessionItemHintGiven,
			itemId,
			hint: lastHint ?? "",
		},
		showHint && !loading && !streaming,
	);

	const handleToggle = () => {
		setShowHint((prev) => !prev);
		getHint();
	};

	const leftIcon = showHint ? "lightbulb-on-outline" : "lightbulb-outline";
	const color = showHint
		? theme.colors.onPrimaryContainer
		: theme.colors.primary;

	const title = useMemo(() => {
		if (!showHint) {
			return (
				<Text
					variant="bodyMedium"
					style={{
						fontWeight: "bold",
						color,
					}}
				>
					Show Hint
				</Text>
			);
		}

		if (loading || !lastHint) {
			return <TextSkeleton height={14} numLines={1} />;
		}

		return (
			<MarkdownRenderer
				text={lastHint}
				variant="answer"
				customStyles={{
					text: {
						fontSize: 16,
						lineHeight: 24,
						color,
					},
				}}
			/>
		);
	}, [lastHint, loading, showHint, color]);

	return (
		<List.Item
			left={(props) => (
				<List.Icon
					{...props}
					style={{
						...props.style,
						alignSelf: "flex-start",
					}}
					icon={leftIcon}
					color={color}
				/>
			)}
			style={{
				borderRadius: 8,
				...(showHint
					? {
							backgroundColor: theme.colors.secondaryContainer,
							cursor: "auto",
						}
					: {}),
			}}
			title={() => title}
			onPress={showHint ? undefined : handleToggle}
		/>
	);
};
