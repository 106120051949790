/* -----------------UI--------------- */
import { List, type ListItemProps, Text } from "react-native-paper";

/* -----------------Child components--------------- */
import CustomChip from "@memorang/ui/src/components/CustomChip";
import { OverlineText } from "@memorang/ui/src/components/OverlineText";

type Props = {
	rightText?: string;
	icon?: string;
	iconColor?: string;
} & ListItemProps;

const StatListItem = (props: Props) => {
	const { icon, iconColor, title, description, rightText } = props;

	return (
		<List.Item
			style={{
				paddingRight: 0,
			}}
			left={() => <List.Icon icon={icon!} color={iconColor} />}
			title={<OverlineText>{description as string}</OverlineText>}
			description={<Text>{title as string}</Text>}
			right={(p) =>
				rightText ? <CustomChip {...p}>{rightText}</CustomChip> : null
			}
		/>
	);
};

export default StatListItem;
