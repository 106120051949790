/* -----------------Child components--------------- */
import DocumentTitle from "components/DocumentTitle";

import { pageViewTypes } from "@constants/tracking";
/* -----------------Types--------------- */
import ReportContainer from "@features/report/screens/ReportContainer";
import useAnalytics from "@hooks/useAnalytics";

const ReportRoute = () => {
	useAnalytics({
		type: pageViewTypes.report,
	});
	return (
		<>
			<DocumentTitle title={"Report"} />
			<ReportContainer />
		</>
	);
};

export default ReportRoute;
