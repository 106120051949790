/**
 * @generated SignedSource<<dda9239088a50c5d4298c93396e465ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SessionConfigInput = {
  allotedTime?: number | null;
  section?: string | null;
  startTime?: any | null;
  status?: string | null;
};
export type UpdateSessionConfigMutation$variables = {
  config?: ReadonlyArray<SessionConfigInput | null> | null;
  sessionId?: string | null;
};
export type UpdateSessionConfigMutation$data = {
  readonly updateSessionConfig: boolean | null;
};
export type UpdateSessionConfigMutation = {
  response: UpdateSessionConfigMutation$data;
  variables: UpdateSessionConfigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "config"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sessionId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "config",
        "variableName": "config"
      },
      {
        "kind": "Variable",
        "name": "sessionId",
        "variableName": "sessionId"
      }
    ],
    "kind": "ScalarField",
    "name": "updateSessionConfig",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSessionConfigMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSessionConfigMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2c9aab0834719b1f0864a37feee4980",
    "id": null,
    "metadata": {},
    "name": "UpdateSessionConfigMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSessionConfigMutation(\n  $config: [SessionConfigInput]\n  $sessionId: ID\n) {\n  updateSessionConfig(config: $config, sessionId: $sessionId)\n}\n"
  }
};
})();

(node as any).hash = "0c12b3ef2a3b16a361e24ca184899d29";

export default node;
