import { Stack } from "expo-router";
import { StatusBar } from "expo-status-bar";
import type { ThemeProp } from "react-native-paper/lib/typescript/types";

/* -----------------UI--------------- */
import { PaperProvider } from "react-native-paper";

import theme from "@configs/theme";
import { useThemeContext } from "@contexts/ThemeContext";
import { ThemeProvider } from "@react-navigation/native";
/* -----------------Types--------------- */
import { RelayEnvironmentProvider } from "react-relay";
import relayEnv from "relay/relayenv";
/* -----------------Helpers & Hooks--------------- */
import useCachedResources from "../src/hooks/useCachedResources";

/* -----------------Child components--------------- */
import { ThemeContextProvider } from "@contexts/ThemeContext";
import { AuthProvider } from "@features/auth/contexts/AuthContext";
import { ExamContextProvider } from "@features/exam/contexts/ExamContext";
import { buildType } from "@helpers/expo-extras";
import { identifyMixpanelUser, initializeMixpanel } from "analytics";
import { GestureHandlerRootHOC } from "components/GestureHandlerRootHOC";

import { isWeb } from "@helpers/platform";
import { applyGlobalPolyfills } from "@memorang/helpers/src/applyGlobalPolyfills";
import { CommandBarProviderWrapper } from "components/CommandBarProviderWrapper";
import Head from "expo-router/head";

import ReactQueryProvider from "@contexts/ReactQueryContext";
import { ToastProvider } from "@contexts/ToastContext";
import { initSentry } from "@helpers/lib/sentry";
import * as SentryNative from "@sentry/react-native";

applyGlobalPolyfills();
initSentry();
initializeMixpanel();

function App() {
	const { isLoadingComplete, isDarkMode } = useCachedResources();

	const RenderRoot = () => {
		const { isDarkMode } = useThemeContext();

		const finalTheme = theme(isDarkMode);

		return (
			<PaperProvider theme={finalTheme as unknown as ThemeProp}>
				<ThemeProvider
					value={{
						...finalTheme,
						// TODO: remove if react native paper gets a major version bump
						fonts: {
							...finalTheme.typography.fonts,
							heavy: finalTheme.typography.fonts.extraBold,
							// biome-ignore lint/correctness/noUndeclaredVariables: available in global namespace
						} as ReactNavigation.Theme["fonts"],
					}}
				>
					<StatusBar
						key={isDarkMode ? "dark" : "light"}
						style={isDarkMode ? "light" : "dark"}
					/>
					<ToastProvider>
						<CommandBarProviderWrapper>
							<Stack
								screenOptions={{
									headerShown: false,
								}}
							/>
						</CommandBarProviderWrapper>
					</ToastProvider>
				</ThemeProvider>
			</PaperProvider>
		);
	};

	if (!isLoadingComplete) {
		return null;
	}

	return (
		//@ts-ignore
		<RelayEnvironmentProvider environment={relayEnv}>
			{isWeb && (
				<Head>
					<link
						rel="shortcut icon"
						href={`https://icon.memorang.com/app-resources/${buildType}/ios/icon.png`}
					/>
				</Head>
			)}
			<GestureHandlerRootHOC>
				<ThemeContextProvider initIsDarkMode={isDarkMode}>
					<ReactQueryProvider>
						<AuthProvider
							app={buildType}
							identifyMixpanelUser={identifyMixpanelUser}
						>
							<ExamContextProvider>
								<RenderRoot />
							</ExamContextProvider>
						</AuthProvider>
					</ReactQueryProvider>
				</ThemeContextProvider>
			</GestureHandlerRootHOC>
		</RelayEnvironmentProvider>
	);
}
export default isWeb ? App : SentryNative.wrap(App);
