/* -----------------Globals--------------- */
import { useEffect, useMemo, useRef } from "react";

import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import InputAdornment from "@mui/material/InputAdornment";
/* -----------------UI--------------- */
import TextField from "@mui/material/TextField";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../../useAppTheme";

/* -----------------Child components--------------- */
import Box from "../../Box";

type MessageInputBarProps = {
	placeholder: string;
	label: string;
	localSearchQuery: string;
	handleAbortSearch: () => void;
	handleOnChangeInput: (text: string) => void;
	handleClearInput?: () => void;
	handleSubmitQuery: (query: string) => void;
	disabled?: boolean;
	icon?: keyof typeof MaterialCommunityIcons.glyphMap;
	showSubmitButton?: boolean;
	showAdornments?: boolean;
};

export const MessageInputBar = (props: MessageInputBarProps) => {
	const theme = useAppTheme();
	const inputRef = useRef<HTMLDivElement>(null);
	const {
		placeholder,
		label,
		localSearchQuery,
		handleOnChangeInput,
		handleSubmitQuery,
		handleAbortSearch,
		handleClearInput,
		disabled,
		icon = "magnify",
		showSubmitButton = false,
		showAdornments,
	} = props;

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		const { key: keyValue, shiftKey } = event;

		if (keyValue === "Enter" && !shiftKey) {
			event.preventDefault();
			handleSubmitQuery(localSearchQuery);
		}
		if (keyValue === "Escape") {
			if (localSearchQuery.length > 0) {
				handleClearInput?.();
			} else {
				inputRef?.current?.blur();
			}
		}
	};

	useEffect(() => {
		if (!disabled) {
			inputRef.current?.focus();
		}
	}, [disabled]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const rightIcon = useMemo(() => {
		if (!(showSubmitButton && showAdornments)) {
			return null;
		}

		if (disabled) {
			return (
				<MaterialCommunityIcons
					color={theme.colors.primary}
					size={24}
					name="stop-circle-outline"
					onPress={handleAbortSearch}
				/>
			);
		}

		if (localSearchQuery.length > 0) {
			return (
				<MaterialCommunityIcons
					color={theme.colors.primary}
					size={24}
					name="send-outline"
					onPress={() => handleSubmitQuery(localSearchQuery)}
				/>
			);
		}

		return null;
	}, [
		showSubmitButton,
		localSearchQuery,
		disabled,
		handleSubmitQuery,
		theme.colors.primary,
		handleAbortSearch,
	]);

	return (
		<Box>
			<TextField
				placeholder={placeholder}
				inputRef={inputRef}
				variant="outlined"
				label={label}
				disabled={disabled}
				value={localSearchQuery}
				size="medium"
				multiline
				maxRows={3}
				autoFocus
				onChange={(e) => handleOnChangeInput(e.target.value)}
				sx={{
					"& .MuiOutlinedInput-root": {
						paddingBlock: 1.5,
						color: theme.colors.text,
						"& fieldset": {
							borderColor: theme.colors.onSurfaceVariant,
						},
						"&:hover fieldset": {
							borderColor: theme.colors.onSurface,
						},
						"&.Mui-focused fieldset": {
							borderColor: theme.colors.primary,
						},
						"&.Mui-disabled fieldset": {
							borderColor: theme.colors.divider,
						},
					},
					"& .MuiOutlinedInput-input": {
						"&.Mui-disabled": {
							color: theme.colors.text,
							"-webkit-text-fill-color": theme.colors.text,
						},
					},
				}}
				InputProps={{
					sx: {
						borderRadius: 6,
						paddingRight: disabled ? 6 : "auto",
					},
					startAdornment: (
						<InputAdornment position="start">
							<MaterialCommunityIcons
								name={icon}
								size={24}
								color={theme.colors.onSurfaceVariant}
							/>
						</InputAdornment>
					),
					endAdornment: rightIcon ? (
						<InputAdornment position="end">{rightIcon}</InputAdornment>
					) : null,
				}}
				onKeyDown={handleKeyDown}
			/>
		</Box>
	);
};
