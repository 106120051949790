/* -----------------Globals--------------- */
import { useCallback, useEffect, useState } from "react";
import { BackHandler } from "react-native";
import { Button, Surface } from "react-native-paper";

import DialogSession from "./SessionDialog";
import SessionEndDialog from "./SessionEndDialog";
/* -----------------Child components--------------- */
import StudySessionAppBar from "./StudySessionAppBar";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
/* -----------------Helpers & Hooks--------------- */
import { router, useFocusEffect, useLocalSearchParams } from "expo-router";
import { useAilaStore } from "../hooks/useAilaStore";
import useEditorStore from "../hooks/useEditorStore";
import useSessionStore from "../hooks/useSessionStore";
import useCountDown from "../hooks/useTimer";

/* -----------------Constants & Types--------------- */
import { events } from "@constants/tracking";
import { QuestionVariant } from "@memorang/types/src/session/question";
import type { ActionType } from "./StudySessionAppBar/StudySessionActionsFab";

import { isAndroid, isWeb } from "@helpers/platform";
/* -----------------Utils--------------- */
import { trackCustomEvent } from "analytics";
import { recordSummativeTestEvents } from "../relay/RecordSummativeTestEvents";

/* -----------------UI Components--------------- */
import Box from "@memorang/ui/src/components/Box";
import { getSessionConfig } from "../containers/StudySessionBottombarContainer/helpers";
import { useSession } from "../hooks/useSession";

const TIMEOUT_DURATION_AUTO_REDIRECT = 2000;
// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
export const StudySessionAppBarContainer = () => {
	const {
		numericId: sessionNumericId,
		id: sessionId,
		sessionTime,
		sessionItems,
		currentBlockIndex,
		inReviewMode,
		isSummativeTest,
		totalTime,
		updateTimeElapsed,
		timeElapsed,
		blockStates,
		examName,
		answers,
		updateMarkedItemsForReview,
		startTime,
		serverExamBreak,
	} = useSessionStore((state) => state);

	const { handleEndSession } = useSession();

	const [showTimeExpiredDialog, setShowTimeExpiredDialog] = useState(false);

	const toggleShowTimeExpiredDialog = useCallback(() => {
		setShowTimeExpiredDialog((prev) => !prev);
	}, []);

	const isOlxSession = examName?.includes("OLX");

	const { viewerId: userId } = useAuthContext();

	const editorRef = useEditorStore((store) => store.editorRef);
	const [hasFocus, setHasFocus] = useState(false);

	const breaks = useSessionStore((state) => state.breaks);

	const lastBreak = breaks?.length
		? breaks[breaks.length - 1]
		: getSessionConfig(serverExamBreak);

	const breakTimeTakenInSeconds = lastBreak?.totalBreakTimeTakenInSeconds;

	const { isMobile } = useDevice();
	const hasAnsweredAtLeastOneItem = answers ? answers.size > 0 : false;
	const isBreakOngoing = useSessionStore((state) => state.isBreakOngoing);

	const calculateTotalTimeRemaining = () => {
		if (isOlxSession && startTime && !isBreakOngoing) {
			const currentTime = Date.now();
			let timeElapsed = Math.floor(
				(currentTime - new Date(startTime).getTime()) / 1000,
			);
			if (breakTimeTakenInSeconds) {
				timeElapsed -= breakTimeTakenInSeconds;
			}

			return Math.max(0, totalTime - timeElapsed);
		}
		return totalTime - (sessionTime || 0);
	};

	const totalTimeRemaining = calculateTotalTimeRemaining();

	const isTimeExpired = totalTimeRemaining <= 0;

	useEffect(() => {
		let timeoutId: NodeJS.Timeout | null = null;
		if (isOlxSession && isTimeExpired) {
			toggleShowTimeExpiredDialog();
			timeoutId = setTimeout(() => {
				toggleShowTimeExpiredDialog();
				handleEndSession({
					id: sessionId,
					sessionType: "SUMMATIVE",
				});
				router.replace({
					pathname: "/(protected)/session/[id]/report",
					params: {
						id: sessionNumericId,
					},
				});
			}, TIMEOUT_DURATION_AUTO_REDIRECT);
		}
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [
		handleEndSession,
		isOlxSession,
		isTimeExpired,
		sessionId,
		sessionNumericId,
		toggleShowTimeExpiredDialog,
	]);

	useFocusEffect(
		useCallback(() => {
			setHasFocus(true);
			return () => {
				setHasFocus(false);
			};
		}, []),
	);

	const [showEndSessionDialog, setShowEndSessionDialog] = useState(false);
	const [showSummativeEndSessionDialog, setShowSummativeEndSessionDialog] =
		useState(false);
	const updateShowDrawer = useAilaStore((state) => state.updateShowDrawer);

	const toggleShowSummativeEndSessionDialog = () => {
		setShowSummativeEndSessionDialog((prev) => !prev);
	};

	const toggeShowEndSessionDialog = useCallback(() => {
		if (inReviewMode) {
			router.back();
			return;
		}
		setShowEndSessionDialog((prev) => !prev);
		return true;
	}, [inReviewMode]);

	useEffect(() => {
		if (isAndroid && hasFocus) {
			BackHandler.addEventListener(
				"hardwareBackPress",
				toggeShowEndSessionDialog,
			);

			return () =>
				BackHandler.removeEventListener(
					"hardwareBackPress",
					toggeShowEndSessionDialog,
				);
		}
	}, [toggeShowEndSessionDialog, hasFocus]);

	useEffect(() => {
		if (!isSummativeTest && isWeb) {
			window.onbeforeunload = () => {
				return "";
			};

			return () => {
				window.onbeforeunload = null;
			};
		}
	}, [isSummativeTest]);

	const { index } = useLocalSearchParams<{
		index: string;
	}>();

	const currentItemIndex = index ? Number.parseInt(index) - 1 : 0;
	const currentItem =
		sessionItems[currentBlockIndex]?.children[currentItemIndex];
	const currentItemId = currentItem?.id;
	const currentBlockState = blockStates.get(sessionItems[currentBlockIndex].id);
	const currentBlockProgress = currentBlockState?.currentProgress || 0;
	const markedItems = currentBlockState?.markedItemsForReview;
	const highlightedItems = currentBlockState?.highlightedItems;
	const isMarked = currentItem?.marked || markedItems?.includes(currentItemId);
	const highlightedHtml =
		currentItem?.highlight || highlightedItems?.get(currentItemId);

	const { isNegative, hours, minutes, seconds, pause, resume } = useCountDown(
		(totalTimeRemaining || 0) * 1000,
		isSummativeTest && !inReviewMode && !isBreakOngoing,
	);

	useEffect(() => {
		if (isBreakOngoing) {
			pause();
		} else {
			resume();
		}
	}, [isBreakOngoing, pause, resume]);

	const formattedTime = `${
		isNegative ? "-" : ""
	}${hours}:${minutes}:${seconds}`;

	const handleUpdateItemStateForTime = useCallback(async () => {
		trackCustomEvent({
			eventName: events.sessionPaused,
		});
		const itemState = {
			sessionId,
			sessionTime: timeElapsed + (sessionTime || 0),
			itemId: currentItemId,
			userId: userId!,
			variant: QuestionVariant.mcq,
			progress: currentBlockProgress,
			marked: isMarked,
			highlight: highlightedHtml,
		};
		await recordSummativeTestEvents(itemState);
		router.replace("/practice-tests");
	}, [
		currentBlockProgress,
		currentItemId,
		highlightedHtml,
		isMarked,
		sessionId,
		sessionTime,
		timeElapsed,
		userId,
	]);

	useEffect(() => {
		let interval: NodeJS.Timeout | null = null;
		interval = setInterval(() => {
			if (!inReviewMode && isSummativeTest && !isBreakOngoing) {
				updateTimeElapsed(timeElapsed + 1);
			}
		}, 1000);
		if (isBreakOngoing) {
			clearInterval(interval);
		}
		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [
		inReviewMode,
		isSummativeTest,
		timeElapsed,
		updateTimeElapsed,
		isBreakOngoing,
	]);

	const handleNavigation = (type: "positive" | "negative") => {
		toggeShowEndSessionDialog();

		if (type === "positive") {
			updateShowDrawer(false);
			if (hasAnsweredAtLeastOneItem) {
				handleEndSession({
					id: sessionId,
					sessionType: "FORMATIVE",
				});
				router.replace({
					pathname: "/(protected)/session/[id]/report",
					params: {
						id: sessionId,
					},
				});
			} else {
				router.back();
			}
		}
	};
	const handleClickRightCta = () => {
		if (isSummativeTest) {
			if (inReviewMode) {
				updateShowDrawer(false);
				router.back();
			} else {
				toggleShowSummativeEndSessionDialog();
			}
		} else {
			toggeShowEndSessionDialog();
		}
	};

	const handleClickButton = (type: string) => {
		if (type === "confirm") {
			handleUpdateItemStateForTime();
		}
		toggleShowSummativeEndSessionDialog();
	};

	const toggleMark = () => {
		if (!inReviewMode) {
			updateMarkedItemsForReview(currentItemId);
		}
	};
	const handleAction = (type: ActionType) => {
		switch (type) {
			case "pause":
				handleClickRightCta();
				break;
			case "mark":
				toggleMark();
				break;
			default:
				break;
		}
	};

	const theme = useAppTheme();

	const markActionIcon = isMarked ? "flag" : "flag-variant-outline";
	const markActionMode = isMarked ? "contained" : "text";
	const markActionLabel = isMarked ? "Marked" : "Mark";
	const markActionColor = isMarked
		? theme.palette.error.main
		: theme.colors.white;

	const showToolbar = inReviewMode ? isMarked : !isMobile && isSummativeTest;

	const handleClickMenuItem = (type: "pause") => {
		switch (type) {
			case "pause":
				toggleShowSummativeEndSessionDialog();
				break;
			default:
				break;
		}
	};

	const handleHighlight = () => {
		if (editorRef) {
			if (editorRef.isActive("highlight")) {
				editorRef.commands.unsetHighlight();
				return;
			}
			editorRef.commands.setHighlight();
		}
	};
	return (
		<>
			<Box>
				<StudySessionAppBar
					numericId={sessionNumericId}
					examName={examName}
					timeRemaining={`${formattedTime}`}
					isTimerExpired={isNegative}
					handleClickRightCta={handleClickRightCta}
					variant={currentItem?.variant}
					showPause={!isOlxSession}
					handleClickMenuItem={handleClickMenuItem}
				/>
				{showToolbar && (
					<Surface
						style={{
							backgroundColor: theme.colors.info.main,
						}}
					>
						<Box
							justifyContent="space-between"
							flexDirection="row"
							padding={16}
						>
							{inReviewMode ? (
								<Box />
							) : (
								<Button
									textColor={theme.colors.white}
									icon={"marker"}
									onPress={handleHighlight}
								>
									Highlight
								</Button>
							)}
							<Button
								onPress={() => {
									if (!inReviewMode) {
										handleAction("mark");
									}
								}}
								icon={markActionIcon}
								mode={markActionMode}
								textColor={markActionColor}
								style={{
									backgroundColor: isMarked ? theme.colors.white : undefined,
								}}
							>
								{markActionLabel}
							</Button>
						</Box>
					</Surface>
				)}
			</Box>
			{showEndSessionDialog && (
				<SessionEndDialog
					visible={showEndSessionDialog}
					onDismiss={toggeShowEndSessionDialog}
					handleAction={handleNavigation}
					hasAnsweredAtLeastOneItem={hasAnsweredAtLeastOneItem}
				/>
			)}
			{showSummativeEndSessionDialog && (
				<DialogSession
					type="pause"
					open={showSummativeEndSessionDialog}
					handleClose={toggleShowSummativeEndSessionDialog}
					handleClickButton={handleClickButton}
				/>
			)}
			{showTimeExpiredDialog && (
				<DialogSession
					type="timerExpired"
					maxWidth={"xs"}
					open={showTimeExpiredDialog}
					showCloseButton={false}
					handleClose={toggleShowTimeExpiredDialog}
				/>
			)}
		</>
	);
};
