import {
	type ResultMessage,
	Role,
	type TextMessage,
} from "@copilotkit/runtime-client-gql";
import Box from "@memorang/ui/src/components/Box";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import { useAppTheme } from "@memorang/ui/src/useAppTheme";

import { ScrollView, View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

/* --------------- Child Components --------------- */
import { ActionRendererContainer } from "../ActionRendererContainer";
import SelectSuggestion from "./Suggestions";

/* --------------- Hooks --------------- */
import { useCopilotChat } from "@copilotkit/react-core";
import { useEffect, useRef, useState } from "react";

/* --------------- Analytics --------------- */
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../../analytics";

type MessageType = TextMessage | ResultMessage;

const isTextMessage = (message: MessageType): message is TextMessage => {
	return (message as TextMessage).role !== undefined;
};

const isResultMessage = (message: MessageType): message is ResultMessage => {
	return (message as ResultMessage).result !== undefined;
};

const MessageContainer = ({
	message,
	loading,
}: {
	message: TextMessage;
	loading?: boolean;
}) => {
	const theme = useAppTheme();

	const alignment = message.role === Role.Assistant ? "flex-start" : "flex-end";
	const color =
		message.role === Role.Assistant
			? theme.colors.onSurfaceVariant
			: theme.colors.onPrimary;
	const bgColor =
		message.role === Role.Assistant
			? theme.colors.surfaceVariant
			: theme.colors.primary;

	return (
		<Box flexDirection="row" justifyContent={alignment}>
			<Box
				maxWidth="80%"
				backgroundColor={bgColor}
				padding={16}
				borderRadius={theme.roundness}
			>
				{loading && <ActivityIndicator size="small" />}
				<MarkdownRenderer
					text={message.content}
					fontSize={16}
					customStyles={{
						text: {
							lineHeight: 24,
							color,
						},
						code_inline: {
							borderRadius: 4,
							textWrap: "nowrap",
							padding: 4,
						},
					}}
					overrideRules={{
						// biome-ignore lint/suspicious/noExplicitAny: Valid use case
						strong: (node: any, children: any) => (
							<Text key={node.key} variant="titleMedium">
								{children}
							</Text>
						),
					}}
				/>
			</Box>
		</Box>
	);
};

const Messages = ({
	suggestionsInstructions,
}: { suggestionsInstructions?: string }) => {
	const theme = useAppTheme();
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();
	const { visibleMessages, isLoading } = useCopilotChat();

	const [skipSuggestions, setSkipSuggestions] = useState(false);

	const messageStartTimeRef = useRef<number>(Date.now());
	const lastMessageIdRef = useRef<string | null>(null);

	const chatMessages = (visibleMessages as MessageType[]).filter((message) => {
		if (isTextMessage(message)) {
			return message.role !== Role.System;
		}
		return true;
	});

	const scrollViewRef = useRef<ScrollView | null>(null);
	const endRef = useRef<View | null>(null);

	const shouldRenderSuggestions = !(isLoading || skipSuggestions);

	// biome-ignore lint/correctness/useExhaustiveDependencies: This is a valid useEffect
	useEffect(() => {
		if (scrollViewRef.current) {
			scrollViewRef.current.scrollToEnd({ animated: false });
		}

		const lastMessage = chatMessages[chatMessages.length - 1];

		const shouldTrackAssistantMessage =
			!isLoading &&
			lastMessage &&
			isTextMessage(lastMessage) &&
			lastMessage.role === Role.Assistant &&
			lastMessageIdRef.current !== lastMessage.id;

		if (shouldTrackAssistantMessage) {
			trackRedeemEvent(rcEvents.assistantResponded, {
				interactionType: "auto",
				interactionElementType: "text",
				timeToComplete: Date.now() - messageStartTimeRef.current,
			});

			if (shouldRenderSuggestions) {
				trackRedeemEvent(rcEvents.assistantSuggestedAction, {
					interactionType: "auto",
					interactionElementType: "action",
				});
			}

			messageStartTimeRef.current = Date.now();
			lastMessageIdRef.current = lastMessage.id;
		}
	}, [chatMessages]);

	return (
		<ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false}>
			<Box flex={1} backgroundColor={theme.colors.surface} gap={16} padding={8}>
				{chatMessages.map((message, index) => {
					const loading = isLoading && index === chatMessages.length - 1;

					if (isTextMessage(message)) {
						const msg = message as TextMessage;
						const textLoading = loading && !msg.content;
						return (
							<MessageContainer
								key={msg.id}
								message={msg}
								loading={textLoading}
							/>
						);
					}

					if (isResultMessage(message)) {
						const msg = message as ResultMessage;
						return (
							<ActionRendererContainer
								key={msg.id}
								message={msg}
								loading={loading}
							/>
						);
					}
				})}

				{!!suggestionsInstructions && (
					<View
						ref={endRef}
						style={{
							display: shouldRenderSuggestions ? "flex" : "none",
							alignSelf: "flex-end",
							width: "70%",
						}}
					>
						<SelectSuggestion
							instructions={suggestionsInstructions}
							minSuggestions={0}
							maxSuggestions={4}
							setSkipSuggestions={setSkipSuggestions}
						/>
					</View>
				)}

				<View style={{ height: 16 }} ref={endRef} />
			</Box>
		</ScrollView>
	);
};

export default Messages;
