/* -----------------Globals--------------- */
import { isWeb } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
import { Fragment, useState } from "react";

/* -----------------UI--------------- */
import { Divider, List } from "react-native-paper";

type Props = {
	accordions: {
		title: string;
		children: React.ReactNode;
	}[];
};

/* -----------------Component--------------- */

const AccordionListItem = ({
	title,
	children,
}: {
	title: React.ReactNode;
	children: React.ReactNode;
}) => {
	const [expanded, setExpanded] = useState(true);
	const theme = useAppTheme();
	return (
		<List.Accordion
			expanded={expanded}
			onPress={() => setExpanded(!expanded)}
			style={{
				paddingRight: 16,
				paddingLeft: 0,
				backgroundColor: isWeb ? theme.colors.surface : theme.colors.background,
			}}
			title={
				<CustomListSubheaderContainer enableGutters={false}>
					{title}
				</CustomListSubheaderContainer>
			}
		>
			{children}
		</List.Accordion>
	);
};
const ListAccordion = ({ accordions }: Props) => {
	return (
		<>
			{accordions.map(({ title, children }, index) => {
				const isLast = index === accordions.length - 1;
				return (
					<Fragment key={title}>
						<AccordionListItem title={title}>{children}</AccordionListItem>
						{!isLast && <Divider />}
					</Fragment>
				);
			})}
		</>
	);
};

export default ListAccordion;
