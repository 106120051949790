import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { Redirect, Stack } from "expo-router";

export default function Layout() {
	const { loading, isAuthenticated } = useAuthContext();

	if (loading) {
		return null;
	}

	if (isAuthenticated) {
		return <Redirect href={"/(protected)/(tabs)/home"} />;
	}
	return <Stack />;
}
