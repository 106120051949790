import { zodResolver } from "@hookform/resolvers/zod";
/* ----------------- React Hook Form --------------- */
import { Controller, useForm } from "react-hook-form";

/* ----------------- React Native Paper --------------- */
import { Button, Dialog, HelperText, TextInput } from "react-native-paper";

/* ----------------- Memorang UI --------------- */
import Box from "@memorang/ui/src/components/Box";
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";

/* ----------------- Auth & API Client --------------- */
import { useAuthContext } from "@features/auth/contexts/AuthContext";
import updateUserInfo from "relay/user/UpdateUserInfo";

/* ----------------- Hooks & Schema --------------- */
import { useAppStore } from "@hooks/useAppStore";
import { type UserDetails, userDetailsSchema } from "./schema";

type Props = {
	visible: boolean;
	handleClose: () => void;
};
const CollectUserDetailsDialog = ({ visible, handleClose }: Props) => {
	const { userDetails } = useAuthContext();

	const email = userDetails?.email;
	const appId = useAppStore((store) => store.app.id);

	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<UserDetails>({
		resolver: zodResolver(userDetailsSchema),
		mode: "onChange",
	});

	const onSubmit = (data: UserDetails) => {
		try {
			if (email && appId) {
				updateUserInfo(email, appId, data);
			}
		} finally {
			handleClose();
		}
	};

	return (
		<DialogWrapper visible={visible} maxWidth={"xs"}>
			<Dialog.Icon icon="account" />
			<Dialog.Title style={{ textAlign: "center" }}>
				Enter your details
			</Dialog.Title>

			<Dialog.Content
				style={{
					gap: 16,
				}}
			>
				<Controller
					control={control}
					render={({
						field: { onChange, onBlur, value },
						fieldState: { error },
					}) => (
						<Box>
							<TextInput
								label="First Name"
								onBlur={onBlur}
								onChangeText={onChange}
								value={value}
								autoFocus
								error={!!error}
								placeholder="Enter your first name"
								mode="outlined"
								contentStyle={{
									backgroundColor: "transparent",
								}}
							/>
							<HelperText type="error" visible={!!error}>
								{error?.message}
							</HelperText>
						</Box>
					)}
					name="firstName"
				/>
				<Controller
					control={control}
					render={({
						field: { onChange, onBlur, value },
						fieldState: { error },
					}) => (
						<Box>
							<TextInput
								label="Last Name"
								onBlur={onBlur}
								onChangeText={onChange}
								value={value}
								error={!!error}
								mode="outlined"
								placeholder="Enter your last name"
							/>
							<HelperText type="error" visible={!!error}>
								{error?.message}
							</HelperText>
						</Box>
					)}
					name="lastName"
				/>
			</Dialog.Content>
			<Dialog.Actions>
				<Button
					mode="contained"
					disabled={!isValid}
					style={{
						paddingHorizontal: 16,
					}}
					onPress={handleSubmit(onSubmit)}
				>
					Submit
				</Button>
			</Dialog.Actions>
		</DialogWrapper>
	);
};

export default CollectUserDetailsDialog;
