/* -----------------UI--------------- */
import theme from "@configs/theme";
import type { Stat } from "components/RowStats";

import { getParentMetadata } from "@helpers/content/metadata";
import { hasBundleAccess } from "@helpers/content/tag";
/* -----------------Helpers & Hooks--------------- */
import { formatNumber } from "@memorang/helpers/src/common";
import useItemsCount from "./useItemsCount";

/* -----------------Types--------------- */
import type { Distribution } from "@memorang/types/src/content";

/* -----------------Child components--------------- */
import { useExamContext } from "@features/exam/contexts/ExamContext";

import { cacheKeys } from "@constants/cache-keys";
import { isScreenshotMode } from "@constants/common";
import { mockedHomePageHeaderStats } from "@constants/mocked-data";
import useBundleStore from "@features/store/hooks/useBundleStore";
import { randomizeDistribution } from "@helpers/mocked-helpers";
import { useAppTheme } from "@hooks/useAppTheme";
import { useQuery } from "@tanstack/react-query";
/* -----------------API Calls--------------- */
import fetchCumulativeContentsDist from "relay/content/FetchCumulativeContentsDist";
import { getQuickActionsChips, getWeakTagsIds } from "../helpers/common";
import { getExamStatus } from "../helpers/stats";
import type { HomeSection } from "../types";
import { useMasteryKeyTopicsSectionData } from "./useMasteryKeyTopicsSectionData";
import { useProductSections } from "./useProductSections";
import { useRecommendationSection } from "./useRecommendationSection";

const getHeaderData = (
	distribution: Distribution,
	examIcon?: string,
	examName?: string,
	examDate?: string,
	isDarkMode?: boolean,
) => {
	if (!distribution) {
		return undefined;
	}
	const examStatusText = getExamStatus(examDate);

	const { high, low, medium } = distribution;
	const answered = high + low + medium;
	const masteryPercentage = answered ? Math.round((high / answered) * 100) : 0;
	const accuracyPercentage = answered
		? Math.round(((high + medium) / answered) * 100)
		: 0;

	const headerStats: Stat[] = [
		{
			icon: "brain",
			value: `${masteryPercentage}%`,
			label: "Mastery",
			color: theme(isDarkMode).palette.purple.light,
		},

		{
			icon: "check-circle-outline",
			value: `${answered} items`,
			label: "Answered",
			color: theme(isDarkMode).palette.success.light,
		},
		{
			icon: "bullseye-arrow",
			value: `${accuracyPercentage}%`,
			label: "Accuracy",
			color: theme(isDarkMode).palette.error.light,
		},
	];
	return {
		distribution: isScreenshotMode
			? randomizeDistribution(distribution)
			: distribution,
		headerStats: isScreenshotMode ? mockedHomePageHeaderStats : headerStats,
		masteryPercentage,
		examIcon,
		examName,
		examStatusText,
		examDate,
	};
};

const homeMenus = [
	{
		type: "store" as const,
		label: "Store",
		icon: "cart-outline",
	},
	{
		type: "settings" as const,
		label: "Settings",
		icon: "cog-outline",
	},
];

export const useHome = () => {
	const {
		numItemsInStudyPack,
		numPracticeTests,
		numQuiz,
		numTopicsInStudyPack,
		isFetchingItemsCount,
	} = useItemsCount();

	const {
		currentExamContext: {
			examId,
			examIcon,
			studyPackProductId,
			examName,
			examDate,
		},
	} = useExamContext();

	const isDarkMode = useAppTheme().dark;
	const { data } = useQuery({
		queryKey: [cacheKeys.home, examId],
		queryFn: () => fetchCumulativeContentsDist(examId),
		enabled: !!examId,
	});

	const mixedItemDist = data?.getMixedItemDistribution;

	const currentlySelectedBundleDetails = useBundleStore(
		(store) => store.currentlySelectedBundleDetails,
	);

	const hasAccess = hasBundleAccess(currentlySelectedBundleDetails!);

	const studyPackSubtitle = `Includes ${formatNumber(
		numItemsInStudyPack,
	)} items across ${formatNumber(numTopicsInStudyPack)} topics`;

	const practiceTestSubtitle = `${numPracticeTests ? formatNumber(numPracticeTests) : ""} practice tests${numQuiz ? ` • ${formatNumber(numQuiz)} diagnostics` : ""}`;

	const contents = mixedItemDist?.contents || [];
	const numItems = contents.reduce((acc, curr) => acc + curr.totalCount, 0);

	const { distribution } = getParentMetadata(contents, numItems);

	const headerData = mixedItemDist
		? getHeaderData(distribution, examIcon, examName, examDate, isDarkMode)
		: undefined;

	const { examSectionData } = useProductSections({
		isFetchingItemsCount,
		practiceTestSubtitle,
		studyPackSubtitle,
	});

	const masteryKeyTopicsSectionData = useMasteryKeyTopicsSectionData(
		mixedItemDist,
		hasAccess,
	);

	const weakTagsIds = mixedItemDist ? getWeakTagsIds(mixedItemDist) : [];
	const quickActionsChips = getQuickActionsChips(studyPackProductId);

	const recommendationSectionHookData = useRecommendationSection(
		mixedItemDist ? distribution : undefined,
	);

	const tasksSection = recommendationSectionHookData?.tasksSection;
	const showPurchaseDialog = recommendationSectionHookData?.showPurchaseDialog;
	const toggleShowPurchaseDialog =
		recommendationSectionHookData?.toggleShowPurchaseDialog;

	const showCollectUserDetailsDialog =
		recommendationSectionHookData?.showCollectUserDetailsDialog;
	const toggleShowCollectUserDetailsDialog =
		recommendationSectionHookData?.toggleShowCollectUserDetailsDialog;

	const olxError = recommendationSectionHookData?.olxError;
	const setOlxError = recommendationSectionHookData?.setOlxError;
	const sections: HomeSection[] = [
		...(tasksSection ? [tasksSection] : []),
		...(examSectionData ? [examSectionData] : []),
		...(masteryKeyTopicsSectionData ? [masteryKeyTopicsSectionData] : []),
	].map((item) => {
		return {
			title: item.sectionTitle,
			type: item.type,
			loading: item.loading,
			onPress: item.onPress,
			data: [
				{
					type: item.type,
					items: item.data,
				},
			],
		};
	});

	return {
		headerData,
		weakTagsIds,
		quickActionsChips,
		sections,
		homeMenus,
		studyPackSubtitle,
		showPurchaseDialog,
		toggleShowPurchaseDialog,
		olxError,
		setOlxError,
		showCollectUserDetailsDialog,
		toggleShowCollectUserDetailsDialog,
	};
};
