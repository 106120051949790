/* -----------------Globals--------------- */
import { SectionList } from "react-native";

/* -----------------UI--------------- */
import { AlertStandard } from "@memorang/ui/src/components/AlertStandard";
import Box from "@memorang/ui/src/components/Box";

/* -----------------Helpers & Hooks--------------- */
import { statusMeta } from "../constants";

/* -----------------Types--------------- */
import type { PracticeTestsViewSection } from "../helpers/practice-tests-data";

import { useExamContext } from "@features/exam/contexts/ExamContext";
import { getIsOlxExam, isOlxExamWithinDateRange } from "@helpers/exams";
import { useAppTheme } from "@hooks/useAppTheme";
import { useGlobalStore } from "@hooks/useGlobalStore";
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
import type { EXAM_STATUS } from "types/tag";
import { getSummativeTestAlertConfig } from "../helpers/common";
/* -----------------Child components--------------- */
import { PracticeTestListItem } from "./PracticeTestListItem";
import { PremiumChip } from "./Progress/PremiumChip";

export const PracticeTestsView = ({
	sections,
	selectedExamId,
	handleNavigation,
	hasProductAccess,
}: {
	sections: PracticeTestsViewSection[];
	selectedExamId?: string;
	disableCard?: boolean;
	hasProductAccess?: boolean;
	handleNavigation: (
		id: string,
		status: EXAM_STATUS,
		numericId: number | undefined,
		isQuiz?: boolean,
		hasExamAccess?: boolean,
		canStartSession?: boolean,
	) => void;
}) => {
	const hasShownPracticeTestAlert = useGlobalStore(
		(state) => state.hasShownPracticeTestAlert,
	);
	const hasShownQuizAlert = useGlobalStore((state) => state.hasShownQuizAlert);
	const setHasShownPracticeTestAlert = useGlobalStore(
		(state) => state.setHasShownPracticeTestAlert,
	);
	const setHasShownQuizAlert = useGlobalStore(
		(state) => state.setHasShownQuizAlert,
	);
	const theme = useAppTheme();
	const {
		currentExamContext: { examName },
	} = useExamContext();

	const renderSectionHeader = ({
		section: { title, rightChip, type },
	}: {
		section: PracticeTestsViewSection;
	}) => {
		const config = getSummativeTestAlertConfig(examName)[type];
		const showAlert =
			type === "practice-tests"
				? !hasShownPracticeTestAlert
				: !hasShownQuizAlert;

		const handleCloseAlert = () => {
			if (type === "practice-tests") {
				setHasShownPracticeTestAlert(true);
			} else {
				setHasShownQuizAlert(true);
			}
		};
		return (
			<Box
				gap={16}
				backgroundColor={theme.colors.background}
				paddingHorizontal={16}
			>
				<Box
					justifyContent="space-between"
					flexDirection="row"
					alignItems="center"
				>
					<CustomListSubheaderContainer enableGutters={false}>
						{title}
					</CustomListSubheaderContainer>
					{rightChip && <PremiumChip {...rightChip} />}
				</Box>
				{showAlert && config && (
					<AlertStandard
						description={config.description}
						severity={config.severity}
						callback={handleCloseAlert}
					/>
				)}
			</Box>
		);
	};

	const renderItem = ({
		item,
	}: {
		item: PracticeTestsViewSection["data"][0];
	}) => {
		return (
			<Box gap={16}>
				{item.list[0].map((practiceTest) => {
					const {
						title,
						description,
						status,
						cta,
						numericId,
						id,
						score,
						hasExamAccess,
						beginStartDate,
						endStartDate,
					} = practiceTest;
					const { text, icon } = statusMeta[status];
					const isFreeExam = !title.includes("Test");

					const isOlxExam = getIsOlxExam(id);

					const getCanStartSession = () => {
						if (isOlxExam) {
							if (status === "ACTIVE" || status === "COMPLETE") {
								return true;
							}
							return isOlxExamWithinDateRange(beginStartDate, endStartDate);
						}
						return true;
					};

					const canStartSession = getCanStartSession();

					return (
						<PracticeTestListItem
							key={id}
							label={title}
							description={description}
							text={text}
							isFreeExam={isFreeExam}
							hasAccess={hasExamAccess && hasProductAccess}
							icon={
								(hasExamAccess && hasProductAccess) || isFreeExam
									? icon!
									: "lock-outline"
							}
							status={status}
							cta={cta}
							loading={selectedExamId === id}
							score={score}
							handleNavigation={() =>
								handleNavigation(
									id,
									status,
									numericId,
									isFreeExam,
									hasExamAccess,
									canStartSession,
								)
							}
						/>
					);
				})}
			</Box>
		);
	};

	return (
		<SectionList
			sections={sections}
			showsVerticalScrollIndicator={false}
			keyExtractor={(_, index) => index.toString()}
			renderItem={renderItem}
			renderSectionHeader={renderSectionHeader}
			SectionSeparatorComponent={() => <Box height={16} />}
			contentContainerStyle={{
				paddingBottom: 124,
				paddingHorizontal: 2,
			}}
		/>
	);
};
