import { emptyStates } from "@features/dashboard/constants";
/* ----------------- Globals --------------- */
import { getSummativeTestAlertConfig } from "@features/dashboard/helpers/common";
import { useGlobalStore } from "@hooks/useGlobalStore";
import useDetailedListTopics from "../../hooks/useDetailedListTopics";

/* ----------------- UI Components --------------- */
import { AlertStandard } from "@memorang/ui/src/components/AlertStandard";
import Box from "@memorang/ui/src/components/Box";
import EmptyState from "@memorang/ui/src/components/EmptyView";
import Loader from "@memorang/ui/src/components/Loader";
import ScrollContainer from "@memorang/ui/src/components/ScrollContainer";
import CustomAppbar from "components/CustomAppbar";
import DetailedListTopicsTable from "components/DetailedListTopicsTable";

/* ----------------- Router --------------- */
import { useLocalSearchParams, useRouter } from "expo-router";

import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
/* ----------------- Assets --------------- */
import { ProgressEmptySvg } from "../../../../../assets";

const DetailedListTopicsContainer = () => {
	const { type, id, examName } = useLocalSearchParams<{
		type: string;
		id: string;
		examName: string;
	}>();

	const description = `Your predicted mastery per ${type!.toLocaleLowerCase()} based upon your progress so far`;

	const alertConfig = getSummativeTestAlertConfig(examName!);

	const { detailMastertyScreenData, ascendingMap, updateRows } =
		useDetailedListTopics({
			id,
			type: type!,
			examName,
		});

	const hasShownMasteryTopicsAlert = useGlobalStore(
		(state) => state.hasShownMasteryTopicsAlert,
	);

	const router = useRouter();

	const showAlert = !hasShownMasteryTopicsAlert;

	const setHasShownMasteryTopicsAlert = useGlobalStore(
		(state) => state.setHasShownMasteryTopicsAlert,
	);

	const CustomLoader = () => {
		return (
			<Box gap={16} flex={1}>
				<CustomAppbar
					back
					options={{
						headerTitle: type,
						subtitle: description,
						headerRight: () => null,
					}}
				/>
				<Box flexGrow={1}>
					<Loader />
				</Box>
			</Box>
		);
	};
	if (!detailMastertyScreenData) {
		return <CustomLoader />;
	}

	const { rows, columns, breadcrumbs, subheader, title, footerData } =
		detailMastertyScreenData;
	const handleCloseAlert = () => {
		setHasShownMasteryTopicsAlert(true);
	};

	const showEmptyState = rows.length === 0;

	return (
		<>
			<CustomAppbar
				back
				breadcrumbs={breadcrumbs}
				options={{
					headerTitle: title,
					subtitle: description,
					headerRight: () => null,
				}}
			/>
			<ScrollContainer maxWidth="md">
				{showEmptyState ? (
					<EmptyState
						{...emptyStates.progress}
						cta={"Take Practice Test"}
						image={ProgressEmptySvg}
						handleAction={() => {
							router.push("/practice-tests");
						}}
					/>
				) : (
					<>
						<CustomListSubheaderContainer>
							{subheader}
						</CustomListSubheaderContainer>
						<Box paddingHorizontal={16} gap={16}>
							{showAlert ? (
								<AlertStandard
									{...alertConfig.topicMastery}
									callback={handleCloseAlert}
								/>
							) : null}
							<DetailedListTopicsTable
								columns={columns}
								rows={rows}
								footerData={footerData}
								ascendingMap={ascendingMap}
								updateRows={updateRows}
							/>
						</Box>
					</>
				)}
			</ScrollContainer>
		</>
	);
};

export default DetailedListTopicsContainer;
