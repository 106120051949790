import fetchQueryWrapper from "@helpers/relay-fetch-query-wrapper";
import type { Bundle } from "@memorang/types/src/bundle";
import { graphql } from "react-relay";

type AccessCodes = {
	bundle: Bundle;
	codes: string[];
}[];

type Response = {
	getActiveCodes: AccessCodes;
};

const query = graphql`
  query FetchAccessCodesQuery(
    $input: String!
    $productId: String
    $tenantId: String!
  ) {
    getActiveCodes(input: $input, productId: $productId, tenantId: $tenantId) {
      bundle {
        bundleType
        categories
        categoryIds
        created
        description
        icon {
          url
        }
        examId
        examName
        externalId
        features
        isMobileOnly
        isPracticeTest
        isPublished
        numTrialDays
        numericId
        plans {
          alias
          amount
          currency
          duration
          externalId
          name
          productName
        }
        purchasable
        tags {
          accessType
          count
          label
          level
          type
        }
      }
      codes
    }
  }
`;

export const fetchAccessCodes = async ({
	input,
	productId,
	tenantId,
}: {
	input: string;
	productId?: string | null;
	tenantId: string;
}): Promise<AccessCodes> => {
	const variables = {
		input,
		productId,
		tenantId,
	};

	const { getActiveCodes } = (await fetchQueryWrapper(
		query,
		variables,
	)) as Response;

	return getActiveCodes;
};
