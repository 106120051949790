/* -----------------Globals--------------- */
import type { Bundle } from "@memorang/types/src/bundle";

/* -----------------UI--------------- */
import { List } from "react-native-paper";

import Box from "@memorang/ui/src/components/Box";
/* -----------------Child components--------------- */
import ProductIcon from "components/ProductIcon";
import { getItemsCountDescription } from "../helpers";
import ProductStatusChip from "./ProductStatusChip";

/* -----------------Props--------------- */
type Props = {
	statusText?: string;
	expired?: boolean;
	size?: number;
	bundle: Bundle;
	handleOpenProductModal: () => void;
	showRightView?: boolean;
	rightComp?: React.ReactElement;
	showIcon?: boolean;
	enableBoxShadow?: boolean;
};

/* -----------------Component--------------- */
const ProductListItem = (props: Props) => {
	const {
		statusText,
		expired,
		size,
		rightComp,
		showRightView = true,
		showIcon = true,
		bundle,
		handleOpenProductModal,
		enableBoxShadow = true,
	} = props;
	const { name: title, icon, contentsItemsCount } = bundle;

	const itemsCountDescription = getItemsCountDescription(contentsItemsCount);

	const renderRight = () => (
		<Box alignSelf="center">
			{rightComp || (
				<Box>
					{statusText && (
						<ProductStatusChip label={statusText} expired={expired} />
					)}
				</Box>
			)}
		</Box>
	);

	return (
		<List.Item
			style={{
				paddingVertical: 12,
				paddingHorizontal: 16,
			}}
			title={title}
			titleStyle={{ fontWeight: "500", paddingRight: 16 }}
			titleNumberOfLines={2}
			description={itemsCountDescription}
			descriptionStyle={{ fontSize: 12 }}
			onPress={handleOpenProductModal}
			left={() =>
				icon &&
				showIcon && (
					<ProductIcon
						enabledBoxShadow={enableBoxShadow}
						image={icon.url}
						size={size!}
					/>
				)
			}
			right={() => showRightView && renderRight()}
		/>
	);
};

export default ProductListItem;
