import { useAppTheme } from "@hooks/useAppTheme";
/* -----------------Globals--------------- */
import { Image } from "expo-image";

type Props = {
	image: string;
	size: number;
	enabledBoxShadow?: boolean;
};

const ProductIcon = (props: Props) => {
	const { size, image } = props;
	const theme = useAppTheme();

	return (
		<Image
			source={{ uri: image }}
			style={{
				width: size,
				height: size,
				borderRadius: size * 0.22,
				borderWidth: size * 0.02,
				borderColor: theme.colors.onPrimaryContainer,
			}}
			cachePolicy={"memory"}
		/>
	);
};

export default ProductIcon;
