/* -----------------UI--------------- */
import { Divider, List, Text } from "react-native-paper";

import { useAppTheme } from "@hooks/useAppTheme";
import { formatRank } from "@memorang/helpers/src/common";
/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import CustomAvatar from "@memorang/ui/src/components/CustomAvatar";
import CustomChip from "@memorang/ui/src/components/CustomChip";
import type { TextStyle, ViewStyle } from "react-native";

type Props = {
	title: string;
	rank: number;
	xp: number;
	showDivider?: boolean;
	image: string;
	textStyle?: TextStyle;
};
const LeaderboardListItem = (props: Props) => {
	const theme = useAppTheme();
	const { rank, title, xp, showDivider, image, textStyle } = props;

	const renderRight = (p: {
		color: string;
		style?: ViewStyle;
	}) => <CustomChip {...p}>{`${xp.toLocaleString()} XP`}</CustomChip>;

	const renderLeft = (p: { color: string; style: ViewStyle }) => {
		const rankLabel = formatRank(rank);

		return (
			<Box flexDirection="row" paddingLeft={8} alignItems="center">
				{rankLabel ? <Text variant="bodySmall">{rankLabel}</Text> : null}
				{image ? (
					<List.Icon
						{...p}
						icon={() => <CustomAvatar size={40} avatar={image} />}
					/>
				) : null}
			</Box>
		);
	};

	return (
		<>
			<List.Item
				title={title}
				titleStyle={{
					...theme.typography.fonts.regular,
					...(textStyle || {}),
				}}
				right={renderRight}
				left={renderLeft}
			/>
			{showDivider ? <Divider /> : null}
		</>
	);
};

export default LeaderboardListItem;
