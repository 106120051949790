import { useAppTheme } from "@memorang/ui/src/useAppTheme";
import { Appbar as Bar } from "react-native-paper";

type Props = {
	title?: string;
	showClose?: boolean;
	handleClose?: () => void;
};

const Appbar = ({ title = "Chat", showClose = true, handleClose }: Props) => {
	const theme = useAppTheme();

	return (
		<Bar.Header style={{ backgroundColor: theme.colors.primary }}>
			<Bar.Content title={title} color={theme.colors.onPrimary} />
			{showClose && handleClose && (
				<Bar.Action
					icon="close"
					color={theme.colors.onPrimary}
					onPress={handleClose}
				/>
			)}
		</Bar.Header>
	);
};

export default Appbar;
