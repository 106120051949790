/* -----------------UI--------------- */
import Dialog from "@mui/material/Dialog";

/* -----------------Child components--------------- */
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { IconButton } from "react-native-paper";

type Props = {
	isVisible: boolean;
	onCancel: () => void;
	onConfirm: (date: Date) => void;
	initialDate?: string;
};
const DateTimePicker = ({
	isVisible,
	onCancel,
	onConfirm,
	initialDate,
}: Props) => {
	let finalMinDate: DateTime = DateTime.now().plus({ months: 1 });

	if (initialDate) {
		finalMinDate = DateTime.fromFormat(initialDate, "MMMM dd, yyyy");
	}

	return (
		<Dialog
			open={isVisible}
			onClose={onCancel}
			maxWidth="xs"
			sx={{
				"& .MuiDialog-paper": {
					maxWidth: 334,
				},
			}}
		>
			<IconButton
				icon={"close"}
				onPress={onCancel}
				style={{
					position: "absolute",
					right: 8,
					top: 6,
					zIndex: 1000,
				}}
			/>
			<LocalizationProvider dateAdapter={AdapterLuxon}>
				<StaticDatePicker
					displayStaticWrapperAs="mobile"
					defaultValue={finalMinDate}
					minDate={DateTime.now()}
					slotProps={{
						actionBar: {
							actions: ["accept"],
						},
					}}
					localeText={{
						toolbarTitle: "Select exam date",
						okButtonLabel: "Set exam date",
					}}
					onAccept={(date) => {
						if (date) {
							onConfirm(date.toJSDate());
						}
					}}
					onClose={onCancel}
				/>
			</LocalizationProvider>
		</Dialog>
	);
};

export default DateTimePicker;
