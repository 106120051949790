/* -----------------Child components--------------- */
import CustomAppbar from "components/CustomAppbar";

import { pageViewTypes } from "@constants/tracking";
import MoreRouteContainer from "@features/more/containers/MoreRouteContainer";
import { isWeb } from "@helpers/platform";
import useAnalytics from "@hooks/useAnalytics";
/* -----------------Types--------------- */
import { useDevice } from "@hooks/useDevice";
import DocumentTitle from "components/DocumentTitle";

const MoreRoute = () => {
	const { isMobile } = useDevice();

	useAnalytics({
		type: pageViewTypes.more,
	});
	return (
		<>
			<CustomAppbar
				showCommandBar={isWeb}
				showHambugerMenu
				options={{
					headerTitle: "More Settings",
					subtitle: isMobile ? "" : "Explore additional settings here",
				}}
			/>
			<DocumentTitle title="More Settings" />
			<MoreRouteContainer />
		</>
	);
};

export default MoreRoute;
