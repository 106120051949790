import ExamsFilterRouteView from "@features/exam/components/ExamsFilterRouteView";
/* -----------------Others--------------- */
import { Stack } from "expo-router";

const ExamsRoute = () => {
	return (
		<>
			<Stack.Screen
				options={{
					headerShown: false,
				}}
			/>
			<ExamsFilterRouteView />
		</>
	);
};

export default ExamsRoute;
