/* -----------------Globals--------------- */
import { isScreenshotMode } from "@constants/common";

/* -----------------Helpers & Hooks--------------- */
import { getIsOlxExam, getMappedExams } from "@helpers/exams";
import { formatCount } from "@memorang/helpers/src/common";

/* -----------------Types--------------- */
import type { PracticeTestExam } from "@memorang/types/src/tag";
import type { MixedItemDistResponse } from "@memorang/types/src/tag";
import type { ChipProps } from "react-native-paper";
import type { EXAM_STATUS } from "types/tag";

import { formatExamDate } from "@helpers/date";

const mockedPracticeTestsData = (
	examName: string,
	numItems: number,
	freeQuizzes: PracticeTestExam[],
) => [
	{
		title: "Diagnostic Assessments",
		type: "quiz" as const,
		data: [
			{
				type: "quiz",
				list: [
					freeQuizzes.map((item) => ({
						title: item.title,
						description: "20 items • 35:00",
						status: "NOT_STARTED" as const,
						numericId: 15500,
						cta: "Start",
						id: "D",
						score: 0,
						legacyReport: null,
						migratedSessionReport: null,
					})),
				],
			},
		],
	},
	{
		type: "practice-tests" as const,
		title: "Practice Tests",
		data: [
			{
				list: [
					[
						{
							title: `${examName}: Test #A`,
							description: `${numItems} items • 1:30:00`,
							status: "NOT_STARTED" as const,
							numericId: 15919,
							cta: "Start",
							id: "A",
							score: 0,
							legacyReport: null,
							migratedSessionReport: null,
						},
						{
							title: `${examName}: Test #B`,
							description: `35 / ${numItems} items • 50:50 Remaining`,
							status: "ACTIVE" as const,
							numericId: 15277,
							cta: "Resume",
							id: "B",
							score: 2,
							legacyReport: null,
							migratedSessionReport: null,
						},
						{
							title: `${examName}: Test #C`,
							description: `${numItems} items • May 16, 2023 • 1:10:00`,
							status: "COMPLETE" as const,
							numericId: 15388,
							cta: "View Report",
							id: "C",
							score: 95,
							legacyReport: null,
							migratedSessionReport: null,
						},
					],
				],
			},
		],
	},
];
export type PracticeTestsViewSection = {
	title: string;
	data: Array<{
		list: {
			title: string;
			description: string;
			status: EXAM_STATUS;
			numericId: number;
			cta: string;
			id: string;
			score: number;
			hasExamAccess?: boolean;
			beginStartDate?: string;
			endStartDate?: string;
		}[][];
	}>;
	rightChip?: {
		text: string;
		icon: string;
		tooltip: string;
		mode: ChipProps["mode"];
	};
	type: "practice-tests" | "quiz";
};

const getStats = (
	exams: PracticeTestExam[],
	numQuiz: number,
	numPracticeTests: number,
	examDate?: string,
) => {
	const practiceTests = exams.filter((exam) =>
		getIsOlxExam(exam.id) ? exam.hasAccess : exam.title.includes("Test"),
	);
	const practiceTestQuizzes = exams
		.filter((exam) => !exam.title.includes("Test"))
		.slice(0, 2);
	const numTotalItems = [...practiceTests, ...practiceTestQuizzes].reduce(
		(acc, exam) => acc + exam.numItems,
		0,
	);
	const stats = [
		{
			icon: "clipboard-check-multiple-outline" as const,
			label: "practice tests",
			value: numPracticeTests,
		},
		...(numQuiz
			? [
					{
						label: `${formatCount("diagnostic assessment", numQuiz)}`,
						value: numQuiz,
						icon: "format-list-checks" as const,
					},
				]
			: []),
		{
			icon: "text-box-multiple-outline" as const,
			label: "items",
			value: numTotalItems,
		},
		...(examDate
			? [
					{
						label: formatExamDate(examDate),
						icon: "calendar-outline" as const,
						value: "Exam Date:",
					},
				]
			: []),
	];
	return stats;
};

export const getPracticeTestsQuizzesAndExams = (exams: PracticeTestExam[]) => {
	const practiceTests = exams
		.filter((exam) => {
			return getIsOlxExam(exam.id)
				? exam.hasAccess
				: exam.type === "PRACTICE_TEST";
		})
		.sort((a, b) => {
			const aIsOlx = getIsOlxExam(a.id);
			const bIsOlx = getIsOlxExam(b.id);
			if (aIsOlx && !bIsOlx) {
				return -1;
			}
			if (!aIsOlx && bIsOlx) {
				return 1;
			}
			return 0;
		});
	const practiceTestQuizzes = exams
		.filter((exam) => exam.type === "QUIZ")
		.slice(0, 2);
	return {
		practiceTests,
		practiceTestQuizzes,
	};
};

export const getExamStatusIcon = (status: EXAM_STATUS) => {
	switch (status) {
		case "COMPLETE":
			return "check";
		case "ACTIVE":
			return "timer-sand";
		case "LEGACYREPORT":
		case "MIGRATEDREPORT":
			return "file-chart-outline";
		default:
			return "";
	}
};
export const getPracticeTestsData = (
	response: MixedItemDistResponse,
	examName: string,
	examDate?: string,
) => {
	const {
		getMixedItemDistribution: { exams = [], hasAccess },
	} = response;
	const { practiceTests, practiceTestQuizzes } =
		getPracticeTestsQuizzesAndExams(exams);
	const mappedExams = getMappedExams(practiceTests);
	const stats = getStats(
		exams,
		practiceTestQuizzes?.length,
		mappedExams?.length,
		examDate,
	);
	const mappedQuizzes = getMappedExams(practiceTestQuizzes);

	const sections: PracticeTestsViewSection[] = [
		{
			title: "Practice Tests",
			data: [
				{
					list: [mappedExams],
				},
			],
			...(hasAccess
				? {}
				: {
						rightChip: {
							text: "Premium",
							icon: "crown-outline",
							mode: "flat" as const,
							tooltip: `Full-length, premium practice tests for the ${examName} exam`,
						},
					}),
			type: "practice-tests" as const,
		},
	];
	const freeQuizzes = mappedQuizzes.length
		? {
				title: "Diagnostic Assessments",
				data: [
					{
						type: "quiz",
						list: [mappedQuizzes],
					},
				],
				rightChip: {
					text: "Free",
					icon: "gift-outline",
					mode: "outlined" as const,
					tooltip: `Free diagnostic assessments for the ${examName} exam`,
				},
				type: "quiz" as const,
			}
		: null;

	const sectionsWithRecommendedItems = freeQuizzes
		? [freeQuizzes, ...sections]
		: sections;
	return {
		stats,
		hasAccess: isScreenshotMode ? true : hasAccess,
		sections: isScreenshotMode
			? mockedPracticeTestsData(examName, exams.length, practiceTestQuizzes)
			: sectionsWithRecommendedItems,
		numExams: exams.length,
		mappedQuizzes,
	};
};
