/* -----------------Globals--------------- */

import Box from "@memorang/ui/src/components/Box";
import EmptyState from "@memorang/ui/src/components/EmptyView";
import Loader from "@memorang/ui/src/components/Loader";
import ScrollContainer from "@memorang/ui/src/components/ScrollContainer";
import { useDevice } from "@memorang/ui/src/useDevice";
/* -----------------UI--------------- */
import { Card } from "react-native-paper";

import { useAppStore } from "@hooks/useAppStore";
import UndrawWinnersImage from "../../../../assets/no-search-results.svg";
/* -----------------Helpers & Hooks--------------- */
import useLeaderboard from "../hooks/useLeaderboard";
import useUserLeaderboard from "../hooks/useUserLeaderboard";

/* -----------------Child components--------------- */
import LeaderboardView from "../components/Leaderboard/LeaderboardView";

const LeaderboardContainer = () => {
	const {
		leaderboards,
		podiumData,
		selectedChip,
		handleSelectChip,
		fetchingLeaderboard,
	} = useLeaderboard();

	const { isMobile } = useDevice();
	const tenantId = useAppStore((store) => store.tenant.id);
	const { userLeaderboardResponse } = useUserLeaderboard(
		tenantId,
		selectedChip,
	);
	const Wrapper = isMobile ? Box : Card;

	if (fetchingLeaderboard) {
		return <Loader />;
	}

	if (leaderboards?.length < 3) {
		return (
			<Box flexGrow={1} display="flex" justifyContent="center">
				<EmptyState
					title="Collecting Leaderboard Data..."
					subtitle="Come back later to check your rank!"
					image={UndrawWinnersImage}
				/>
			</Box>
		);
	}
	return (
		<ScrollContainer
			contentContainerStyle={{
				paddingVertical: 16,
				paddingHorizontal: isMobile ? 0 : 16,
			}}
		>
			<Wrapper>
				<LeaderboardView
					userLeaderboardItem={userLeaderboardResponse}
					leaderboards={leaderboards}
					topRankers={podiumData}
					selectedChip={selectedChip}
					handleSelectChip={handleSelectChip}
				/>
			</Wrapper>
		</ScrollContainer>
	);
};

export default LeaderboardContainer;
