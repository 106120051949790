/* ----------------- React --------------- */
import { useEffect, useState, useTransition } from "react";

/* ----------------- Constants --------------- */
import { ENDPOINT_HINT } from "@constants/urls";

/* ----------------- Features --------------- */
import { useExamContext } from "@features/exam/contexts/ExamContext";
import useSessionStore from "./useSessionStore";

/* ----------------- Auth --------------- */
import { useAuthContext } from "@features/auth/contexts/AuthContext";

/* ----------------- Helpers --------------- */
import { getPlatform } from "@memorang/helpers/src/common";
import { v4 as uuidv4 } from "uuid";

import type { Hint } from "@memorang/types/src/session/common";
import type { SessionChildItem } from "@memorang/types/src/session/common";
/* ----------------- React Query --------------- */
import { useMutation } from "@tanstack/react-query";
import { fetch as expoFetch } from "expo/fetch";

export const useHint = ({
	item: { id: itemId, children, tags, stem },
}: {
	item: SessionChildItem;
}) => {
	const topics = tags?.map((tag) => tag.label!);

	const [hints, setHints] = useState<Hint[]>([]);
	const correctChoice = children?.find((child) => child.isCorrect);
	const explanation = correctChoice ? correctChoice.explanation : "";
	const incorrectChoices = children
		?.filter((child) => !child.isCorrect)
		.map((child) => child.stem);

	const correctChoiceText = correctChoice?.stem;
	const { viewerId: userId } = useAuthContext();
	const {
		currentExamContext: { examId },
	} = useExamContext();

	const platform = getPlatform();
	const [loading, setLoading] = useState(false);
	const [streaming, setStreaming] = useState(false);

	const [pending, startTransition] = useTransition();
	const updateHintsForItem = useSessionStore(
		(state) => state.updateHintsForItem,
	);

	const { mutate: getHint } = useMutation({
		mutationKey: ["hint", itemId],
		mutationFn: async () => {
			const params = {
				platform,
				itemId,
				examId,
				originalQuestion: {
					topics,
					explanation,
					stem,
					incorrectChoices,
					correctChoice: correctChoiceText,
				},
				userId,
			};
			setLoading(true);
			const response = await expoFetch(ENDPOINT_HINT, {
				method: "POST",
				headers: {
					Accept: "text/event-stream",
				},
				body: JSON.stringify(params),
			});
			const reader = response.body?.getReader();
			return reader;
		},
		onSuccess: (reader) => {
			setLoading(false);
			setStreaming(true);
			setHints((prevHints) => [
				...prevHints,
				{
					id: uuidv4(),
					text: "",
				},
			]);
			const decoder = new TextDecoder("utf-8");
			let result = "";

			async function read() {
				if (reader) {
					const { done, value } = await reader.read();
					if (done) {
						setStreaming(false);
						return;
					}
					result += decoder.decode(value);
					startTransition(() => {
						setHints((prevHint) => {
							prevHint[prevHint.length - 1].text = result;
							return prevHint;
						});
					});
					await read();
				}
			}
			read();
		},
	});

	useEffect(() => {
		if (hints.length > 0 && !loading && !streaming && !pending) {
			updateHintsForItem(itemId, hints);
		}
	}, [hints, loading, streaming, itemId, pending, updateHintsForItem]);

	return {
		hints,
		loading,
		streaming,
		getHint,
	};
};

export type UseHintReturnType = ReturnType<typeof useHint>;
