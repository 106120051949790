import ChooseDateSvg from "./image_choose_date.svg";
import ProgressEmptySvg from "./progress-empty-state.svg";
import HighlightEmptyIcon from "./undraw_bookmarks_re_mq1u.svg";

const opensSansFonts = {
	"opensans-bold": require("./fonts/opensans/opensans-bold.ttf"),
	"opensans-bolditalic": require("./fonts/opensans/opensans-bolditalic.ttf"),
	"opensans-extrabold": require("./fonts/opensans/opensans-extrabold.ttf"),
	"opensans-extrabolditalic": require("./fonts/opensans/opensans-extrabolditalic.ttf"),
	"opensans-italic": require("./fonts/opensans/opensans-italic.ttf"),
	"opensans-light": require("./fonts/opensans/opensans-light.ttf"),
	"opensans-lightitalic": require("./fonts/opensans/opensans-lightitalic.ttf"),
	"opensans-regular": require("./fonts/opensans/opensans-regular.ttf"),
	"opensans-semibold": require("./fonts/opensans/opensans-semibold.ttf"),
	"opensans-semibolditalic": require("./fonts/opensans/opensans-semibolditalic.ttf"),
};

const beausiteFonts = {
	"beausite-regular": require("./fonts/beausite/beausite-regular.ttf"),
	"beausite-bold": require("./fonts/beausite/beausite-bold.ttf"),
	"beausite-bolditalic": require("./fonts/beausite/beausite-bolditalic.ttf"),
	"beausite-semibold": require("./fonts/beausite/beausite-semibold.ttf"),
	"beausite-semibolditalic": require("./fonts/beausite/beausite-semibolditalic.ttf"),
};

const customFonts = {
	memorangicons: require("./fonts/memorangicons.ttf"),
	...beausiteFonts,
	...opensSansFonts,
};

export { customFonts, HighlightEmptyIcon, ProgressEmptySvg, ChooseDateSvg };
