/* ----------------- Hooks --------------- */
import { useAppTheme } from "../../../useAppTheme";
import { useDevice } from "../../../useDevice";

/* ----------------- Components --------------- */
import Box from "../../Box";

/* ----------------- External Libraries --------------- */
import ContentLoader from "react-native-easy-content-loader";

export const StatsSkeleton = ({ numRows = 3 }: { numRows?: number }) => {
	const { isMobile } = useDevice();

	const theme = useAppTheme();
	return (
		<Box
			flexDirection="row"
			gap={8}
			alignItems="center"
			flexGrow={1}
			justifyContent={"space-around"}
			maxWidth={isMobile ? "100%" : 350}
		>
			{[...new Array(numRows).keys()].map((item) => {
				return (
					<Box
						gap={4}
						key={item}
						alignItems="center"
						borderWidth={1}
						borderColor={theme.colors.outlineVariant}
						borderRadius={12}
						padding={8}
						flexGrow={1}
					>
						{/*@ts-ignore */}
						<ContentLoader
							pRows={numRows}
							title={false}
							pWidth={[22, 24, 50]}
							pHeight={[20, 11, 11]}
							key={item}
							paragraphStyles={{
								alignSelf: "center",
							}}
							titleStyles={{
								height: 16,
								width: 50,
								alignSelf: "center",
							}}
						/>
					</Box>
				);
			})}
		</Box>
	);
};
