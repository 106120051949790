import { useEffect, useState } from "react";
import DialogSession from "../../components/SessionDialog";
import { getBreakExpiredDialogConfig } from "../../constants/dialog-configs";
import useSessionStore from "../../hooks/useSessionStore";
import { updateSessionConfig } from "../../relay/UpdateSessionConfigMutation";
import { getBreakTimeTakenInSeconds } from "./helpers";

const ExpiredBreakDialogContainer = ({
	setShowBreakDialog,
	breakTime,
	sectionId,
	sessionId,
}: {
	setShowBreakDialog: (showBreakDialog: boolean) => void;
	breakTime: number;
	sectionId: string;
	sessionId: string;
}) => {
	const [showExpiredBreakDialog, setShowExpiredBreakDialog] = useState(false);

	const breaks = useSessionStore((state) => state.breaks);

	const firstBreakStartTime = breaks?.length ? breaks[0].startTime : "";

	const toggleBreakOngoing = useSessionStore(
		(state) => state.toggleBreakOngoing,
	);

	const breakTimeElapsed = useSessionStore((state) => state.breakTimeElapsed);

	const updateBreak = useSessionStore((state) => state.updateBreak);

	const isBreakOngoing = useSessionStore((state) => state.isBreakOngoing);

	const isBreakExpired = breakTimeElapsed >= breakTime;

	useEffect(() => {
		if (isBreakExpired && isBreakOngoing) {
			setShowExpiredBreakDialog(true);
			toggleBreakOngoing(false);
			setShowBreakDialog(false);
			const breakTimeTakenInSeconds =
				getBreakTimeTakenInSeconds(firstBreakStartTime);
			updateBreak({
				startTime: firstBreakStartTime,
				allotedTime: breakTime,
				status: "EXPIRED",
				totalBreakTimeTakenInSeconds: breakTimeTakenInSeconds,
			});
			updateSessionConfig(
				{
					allotedTime: breakTime,
					section: sectionId,
					status: "EXPIRED",
					startTime: firstBreakStartTime,
					totalBreakTimeTakenInSeconds: breakTimeTakenInSeconds,
				},
				sessionId,
			);
		}
	}, [
		isBreakExpired,
		isBreakOngoing,
		toggleBreakOngoing,
		setShowBreakDialog,
		updateBreak,
		breakTime,
		sectionId,
		firstBreakStartTime,
		sessionId,
	]);

	return (
		showExpiredBreakDialog && (
			<DialogSession
				customConfig={getBreakExpiredDialogConfig()}
				open={showExpiredBreakDialog}
				handleClickButton={() => setShowExpiredBreakDialog(false)}
				handleClose={() => {
					setShowExpiredBreakDialog(false);
				}}
			/>
		)
	);
};

export default ExpiredBreakDialogContainer;
