/* ======== Types ========= */
import { events } from "@constants/tracking";
import { useAppStore } from "@hooks/useAppStore";
import { useAppTheme } from "@hooks/useAppTheme";
import { getExpirationDateText } from "@memorang/helpers/src/bundle";
import type { Plan } from "@memorang/types/src/bundle";

/* ======== Components ========= */
import Box from "@memorang/ui/src/components/Box";
import { Text, TouchableRipple } from "react-native-paper";

export type PlansSectionProps = {
	sortedPlans: Plan[];
	selectedPlan?: Plan;
	setSelectedPlan: (plan: Plan) => void;
	expiryDate?: string;
	handleTracking: (eventName: string, params?: Record<string, string>) => void;
};

type PlanBoxProps = {
	isSelected: boolean;
	planAmount: number;
	alias: string;
	expirationText: string;
};
const PlansSection = ({
	handleTracking,
	sortedPlans,
	selectedPlan,
	setSelectedPlan,
}: PlansSectionProps) => {
	const theme = useAppTheme();

	const appFont = useAppStore((store) => store.app.font) || "opensans";

	const PlanBox = ({ isSelected, planAmount, alias }: PlanBoxProps) => {
		return (
			<Box alignItems="center" gap={8}>
				<Text
					variant="titleMedium"
					style={{
						color: isSelected ? theme.colors.primary : theme.colors.text,
						textAlign: "center",
						fontFamily: `${appFont}-semibold`,
					}}
				>
					{`${alias}`}
				</Text>
				<Text
					variant="titleMedium"
					style={{
						fontFamily: `${appFont}-semibold`,
					}}
				>{`$${planAmount}`}</Text>
			</Box>
		);
	};
	return (
		<Box flexDirection="row" gap={8} justifyContent="center">
			{sortedPlans.map((plan) => {
				const { amount: planAmount, id, alias } = plan;

				const expirationText = getExpirationDateText(plan);

				const isSelected = selectedPlan?.id === id;

				return (
					<TouchableRipple
						key={id}
						rippleColor="transparent"
						onPress={() => {
							setSelectedPlan(plan);
							handleTracking(events.planSelected, {
								plan_id: id,
								plan_name: alias,
								plan_amount: `${planAmount}`,
								plan_duration: expirationText,
								plan_interval: plan.interval,
								plan_currency: plan.currency,
							});
						}}
						style={{
							borderColor: isSelected
								? theme.colors.primary
								: theme.colors.primaryContainer,
							borderWidth: 2,
							borderRadius: 8,
							alignItems: "center",
							paddingVertical: 16,
							justifyContent: "center",
							paddingHorizontal: 8,
							flexGrow: 1,
							width: 100,
						}}
					>
						<PlanBox
							isSelected={isSelected}
							planAmount={planAmount}
							alias={alias}
							expirationText={expirationText}
						/>
					</TouchableRipple>
				);
			})}
		</Box>
	);
};

export default PlansSection;
