import { fetchData } from "@memorang/helpers/src/fetchData";
import { graphql } from "react-relay";
/* -----------------Globals--------------- */
import relayEnv from "../relayenv";

type Response = {
	viewer: {
		name: string;
		email: string;
		iconUrl?: string;
		purchased: {
			folder: {
				contents: {
					edges: {
						node: {
							id: string;
						};
					}[];
				};
			};
		};
	};
};
const query = graphql`
  query FetchUserDetailsQuery {
    viewer {
      name
      email
      iconUrl
      purchased {
        folder {
          contents {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

const fetchUserDetails = async (): Promise<{
	name: string;
	email: string;
	iconUrl?: string;
	isPremiumUser: boolean;
}> => {
	const { viewer } = (await fetchData(relayEnv, query)) as Response;

	return {
		...viewer,
		isPremiumUser: viewer.purchased.folder.contents.edges.length > 0,
	};
};

export default fetchUserDetails;
