/* -----------------Globals--------------- */
import { composeMessage } from "@memorang/helpers/src/email";
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";
import { Platform } from "react-native";

/* -----------------UI--------------- */
import { Button, Dialog, Paragraph } from "react-native-paper";

/* -----------------Types--------------- */
type Props = {
	visible: boolean;
	handleDismiss: () => void;
	companyName: string;
	supportEmail: string;
	buildType: string;
	currentAppVersion: string;
};
const body =
	"Please get in touch with our customer support if you have trouble logging in or try again";
/* -----------------Component--------------- */
const DialogContactSupport = (props: Props) => {
	const {
		visible,
		handleDismiss,
		companyName,
		supportEmail,
		buildType,
		currentAppVersion,
	} = props;

	const finalTitle = "Trouble logging in?";
	const cta = "Email Us";

	const handleComposeMessage = () => {
		const subject = `[${companyName}] Trouble logging in`;
		const emailBody = `Hi, I have the ${Platform.OS} version of the ${buildType} app version ${currentAppVersion} and I’m having trouble logging in with my ${buildType} account`;
		composeMessage(subject, emailBody, supportEmail);
	};
	return (
		<DialogWrapper
			visible={visible}
			handleClose={handleDismiss}
			maxWidth={"xs"}
		>
			<Dialog.Title>{finalTitle}</Dialog.Title>
			<Dialog.Content>
				<Paragraph>{body}</Paragraph>
			</Dialog.Content>
			<Dialog.Actions>
				<Button onPress={handleComposeMessage}>{cta}</Button>
			</Dialog.Actions>
		</DialogWrapper>
	);
};
export default DialogContactSupport;
