import Constants from "expo-constants";
import { Platform } from "react-native";

const { expoConfig } = Constants;

export const isDev = process.env.NODE_ENV === "development";
// export const isDev = false;

// biome-ignore lint/correctness/noConstantCondition: <explanation>
export const baseNextjsUrl = false
	? Platform.OS === "android"
		? `http://${expoConfig!.hostUri?.split(":").shift()}:3000`
		: "http://localhost:3000"
	: "https://hub.memorang.com";

export const baseDevUrl =
	Platform.OS === "android"
		? `http://${expoConfig!.hostUri?.split(":").shift()}:8081`
		: "http://localhost:8081";

export const ENDPOINT_GET_WAVEFORM_DATA = `${baseNextjsUrl}/api/ai/get-audio-waveform`;

export const ENDPOINT_TTS = `${baseNextjsUrl}/api/ai/tts`;
