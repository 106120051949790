import type { AccessCodeResult } from "@features/auth/relay/applyAccessCode";
import { getOlxPostRedeemDialogConfig } from "../helpers";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import useSessionStore from "@features/session/hooks/useSessionStore";
import createSession from "@features/session/relay/CreateSession";
import { ConfirmDialog } from "components/ConfirmDialog";
import { useRouter } from "expo-router";
import { type Dispatch, type SetStateAction, useState } from "react";

type OlxDates = {
	beginStartDate: string;
	endStartDate: string;
	tempAccessCodeResponse?: AccessCodeResult;
} | null;

type Props = {
	olxDates: OlxDates;
	setOlxDates: Dispatch<SetStateAction<OlxDates>>;
	handleNext: (result: AccessCodeResult) => void;
	toggleShowCollectUserDialog: () => void;
};

const PostRedeemOlxDialog = ({
	olxDates,
	setOlxDates,
	handleNext,
	toggleShowCollectUserDialog,
}: Props) => {
	const [creatingSession, setCreatingSession] = useState(false);

	const router = useRouter();

	const updateSession = useSessionStore((state) => state.updateSession);

	const {
		currentExamContext: { practiceTestProductId },
	} = useExamContext();

	const { viewerId } = useAuthContext();

	const handleStartPracticeTestSession = async (examId: string) => {
		try {
			setCreatingSession(true);
			const result = await createSession({
				action: "SUMMATIVE_SESSION",
				createSessionType: "PRACTICE",
				learningOrder: "SHUFFLED",
				examId,
				bundleId: practiceTestProductId,
				userId: viewerId!,
			});
			updateSession({
				sessionResponse: result,
				isSummativeTest: true,
			});
		} catch (_error) {
			handleNext(olxDates?.tempAccessCodeResponse!);
		} finally {
			setCreatingSession(false);
		}
	};

	const showOlxDialog = olxDates != null;
	if (showOlxDialog) {
		const config = getOlxPostRedeemDialogConfig(
			olxDates.beginStartDate,
			olxDates.endStartDate,
		);

		return (
			<ConfirmDialog
				visible={showOlxDialog}
				title={config.title}
				body={config.body}
				confirmCta={config.confirmCta}
				dismissCta={config.cancelCta}
				icon="information-outline"
				confirmCtaLoading={creatingSession}
				confirmCallback={async () => {
					const olxAssessmentId =
						olxDates?.tempAccessCodeResponse?.data.content;
					if (olxAssessmentId) {
						await handleStartPracticeTestSession(olxAssessmentId);
						toggleShowCollectUserDialog();
					} else {
						router.push("/practice-tests");
					}
					setOlxDates(null);
				}}
				dismissCallback={() => {
					setOlxDates(null);
					router.push("/practice-tests");
				}}
			/>
		);
	}
	return null;
};

export default PostRedeemOlxDialog;
