import { formatCount } from "@memorang/helpers/src/common";
import { DateTime } from "luxon";

type Params = {
	masteryPercentage: number;
	daysLeft?: number;
	xpEarned?: number;
};
const getDashboardStats = ({
	masteryPercentage,
	daysLeft,
	xpEarned,
}: Params) => {
	const data = [
		{
			label: "Mastered",
			icon: "brain",
			value: `${masteryPercentage}%`,
		},
		{
			label: "Until Exam",
			icon: "calendar-outline",
			value: daysLeft && daysLeft > 0 ? `${daysLeft} days` : "Past Due",
		},
		...(xpEarned
			? [
					{
						label: "xp earned",
						icon: "medal-outline",
						value: xpEarned.toLocaleString(),
					},
				]
			: []),
	];
	return data;
};
const getExamDaysLeft = (examDateString?: string) => {
	if (!examDateString) {
		return undefined;
	}
	const examDate = DateTime.fromFormat(examDateString, "MMMM d, yyyy").startOf(
		"day",
	);
	const today = DateTime.local().startOf("day");
	const differenceMs = examDate.diff(today, "days").days;
	return Math.round(differenceMs);
};

const getExamStatus = (examDateString?: string, showExamDate?: boolean) => {
	const daysLeft = getExamDaysLeft(examDateString);

	if (!daysLeft) {
		return "";
	}
	if (daysLeft < 0) {
		return `${Math.abs(daysLeft)} days ago`;
	}
	if (daysLeft > 0) {
		return `${
			showExamDate ? `${examDateString} • ` : ""
		}${daysLeft} ${formatCount("day", daysLeft)} until exam`;
	}
	return "Exam is today!";
};

export { getDashboardStats, getExamDaysLeft, getExamStatus };
