import type { SessionMeta } from "@memorang/types/src/session";
import type { Question } from "@memorang/types/src/session/question";
import {
	calcTimeFactor,
	confidenceRanges,
	getExpectedTimeQuestion,
	getExpectedTimeQuiz,
	getNewScoreMultiChoice,
	getScorePenalty,
	normalizeScore,
} from "./functions";

// Calculates the new score for the quiz or practice question
const calcScore = (
	confidence: number,
	correct: boolean,
	timeTaken: number,
	question: Question,
	sessionMeta: SessionMeta,
	mode: "quiz" | "practice",
) => {
	// Gets the "fastest" expected time to answer the item
	const isQuiz = mode === "quiz";
	const timeExpected = isQuiz
		? getExpectedTimeQuiz(question)
		: getExpectedTimeQuestion(question);

	// Provides a factor (0-1) whether the user's answer was fast or slow
	// Speed is another way to estimate how confident a user is
	const timeFactor = calcTimeFactor(timeTaken, timeExpected / 1000);

	const currentScore = question.currentScore ? question.currentScore.value : 0;
	const newScore = getNewScoreMultiChoice({
		correct,
		confidence,
		currentScore,
		timeFactor,
	});

	const itemsMeta = sessionMeta[question.id];
	const scorePenalty = itemsMeta
		? getScorePenalty(
				correct,
				itemsMeta.numSuccessAttempts,
				itemsMeta.numTotalAttempts,
				(Date.now() - new Date(itemsMeta.lastTime).valueOf()) / 1000,
			)
		: 0;

	const updatedScore = normalizeScore(newScore - scorePenalty);

	// Makes sure that both correct and incorrect answers cannot exist with the same score
	const getFinalScore = () => {
		if (correct) {
			// Min score if correct
			const minScore = confidenceRanges[50][0];
			return Math.max(updatedScore, minScore);
		}
		// Max score if incorrect
		const maxScore = confidenceRanges[0][1];
		return Math.min(updatedScore, maxScore);
	};

	return {
		score: getFinalScore(),
		timeFactor,
	};
};

export { calcScore };
