/* -----------------Globals--------------- */
import {
	type LayoutChangeEvent,
	Platform,
	View,
	type ViewStyle,
} from "react-native";

export type AlignItems =
	| "stretch"
	| "center"
	| "flex-start"
	| "flex-end"
	| "baseline";
/* -----------------Props--------------- */
export type BoxProps = {
	children?: React.ReactNode;
	zIndex?: number;
	borderColor?: string;
	borderWidth?: number;
	borderStyle?: "solid" | "dotted" | "dashed";
	borderRadius?: number | string;
	minWidth?: number;
	maxWidth?: string | number;
	borderRoundness?: number;
	display?: "none" | "flex";
	height?: number | string;
	width?: ViewStyle["width"];
	position?: "sticky" | "relative" | "absolute";
	flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
	justifyContent?:
		| "center"
		| "flex-start"
		| "flex-end"
		| "space-between"
		| "space-around"
		| "space-evenly";
	alignItems?: AlignItems;
	alignSelf?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
	padding?: number;
	paddingVertical?: number;
	paddingHorizontal?: number;
	paddingTop?: number;
	paddingRight?: number;
	paddingBottom?: number;
	paddingLeft?: number;
	margin?: number;
	overflow?: string;
	marginVertical?: number;
	marginHorizontal?: number;
	marginTop?: number;
	marginRight?: number;
	marginBottom?: number;
	marginLeft?: number;
	color?: string;
	fontColor?: string;
	backgroundColor?: string;
	flex?: number;
	flexWrap?: "wrap" | "nowrap" | "wrap-reverse";
	flexGrow?: number;
	alignContent?: "center";
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	style?: any;
	gap?: number | string;
	top?: number;
	elevation?: number;
	borderTopLeftRadius?: number;
	borderTopRightRadius?: number;
	pointerEvents?: "none" | "auto";
	onLayout?: (e: LayoutChangeEvent) => void;
	left?: number;
	bottom?: number;
	right?: number;
	cursor?: "pointer" | "default";
	columnGap?: number;
	rowGap?: number;
	onMouseEnter?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	onContextMenu?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	id?: string;
};

/* -----------------Component--------------- */
const Box = (props: BoxProps) => {
	const {
		children,
		onLayout,
		style,
		onMouseEnter,
		onMouseLeave,
		onContextMenu,
		id,
		...rest
	} = props;
	const handleOnLayout = (e: LayoutChangeEvent) => {
		if (onLayout) {
			onLayout(e);
		}
	};
	return (
		<View
			onLayout={handleOnLayout}
			style={[style, { ...rest, display: "flex" }]}
			{...(Platform.OS === "web"
				? { onMouseEnter, onMouseLeave, onContextMenu, id }
				: {})}
		>
			{children}
		</View>
	);
};

export default Box;
