import { useState } from "react";
import { Banner, Icon, Text } from "react-native-paper";
import MarkdownRenderer from "../MarkdownRenderer";

const WarningBanner = ({
	message,
	actionLabel = "Got it!",
	icon = "alert",
	iconSize = 24,
	iconColor,
	handlePressCallback,
}: {
	message: string;
	actionLabel?: string;
	icon?: string;
	iconSize?: number;
	iconColor?: string;
	handlePressCallback?: () => void;
}) => {
	const [visible, setVisible] = useState(true);

	return (
		<Banner
			visible={visible}
			actions={[
				{
					label: actionLabel,
					onPress: () => {
						handlePressCallback?.();
						setVisible(false);
					},
				},
			]}
			icon={({ size, color }) => (
				<Icon
					source={icon}
					size={iconSize || size}
					color={iconColor || color}
				/>
			)}
		>
			<MarkdownRenderer
				text={message}
				overrideRules={{
					// biome-ignore lint/suspicious/noExplicitAny: Valid use case
					strong: (node: any, children: any) => (
						<Text key={node.key} variant="titleSmall">
							{children}
						</Text>
					),
				}}
			/>
		</Banner>
	);
};

export default WarningBanner;
