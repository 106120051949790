/* ----------------- Helpers --------------- */
import { formatRank } from "@memorang/helpers/src/common";

/* ----------------- UI --------------- */
import { Text } from "react-native-paper";
import Box from "../Box";
import CustomAvatar from "../CustomAvatar";
import CustomChip from "../CustomChip";

/* ----------------- Constants --------------- */
import { rankStyles } from "./constants";

type Props = {
	rank: number;
	xp: number;
	title: string;
	image: string;
};
const LeaderboardPodium = (props: Props) => {
	const { rank, image, title, xp } = props;
	const rankLabel = formatRank(rank);
	const { podiumBg, textColor, height, marginTop, avatarSize } =
		rankStyles[rank - 1];

	return (
		<Box
			flex={1}
			paddingHorizontal={4}
			display="flex"
			maxWidth={200}
			flexDirection="column"
			justifyContent="flex-end"
		>
			<CustomAvatar size={avatarSize} caption={title} avatar={image} />
			<Box
				width="100%"
				backgroundColor={podiumBg}
				borderTopLeftRadius={12}
				borderTopRightRadius={12}
				height={height}
				alignItems="center"
				justifyContent="center"
				marginTop={marginTop}
				gap={8}
			>
				<Text
					variant="headlineMedium"
					style={{
						color: textColor,
					}}
				>
					{rankLabel}
				</Text>
				<CustomChip
					elevated
					elevation={4}
					textStyle={{
						color: textColor,
					}}
					style={{
						backgroundColor: podiumBg,
					}}
				>
					{`${xp.toLocaleString()} XP`}
				</CustomChip>
			</Box>
		</Box>
	);
};

export default LeaderboardPodium;
