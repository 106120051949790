import useBreakpoints from "@memorang/ui/src/useBreakpoints";

export const useDevice = () => {
	const { smDown, mdDown, smUp, lgUp } = useBreakpoints();
	const isMediumWindowSize = smUp && mdDown;

	const isLargeWindowSize = lgUp;
	return {
		isMobile: smDown,
		isMediumWindowSize,
		isLargeWindowSize,
	};
};
