/* -----------------UI--------------- */
import { Drawer, Text } from "react-native-paper";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------Helpers & Hooks--------------- */
import { type Href, Link, router, usePathname } from "expo-router";

import { useAppTheme } from "@hooks/useAppTheme";
import useBreakpoints from "@hooks/useBreakpoints";
/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import { useDevice } from "@memorang/ui/src/useDevice";
import MemorangIcons from "components/MemorangIcons";
import type { IconType } from "components/types/icon";
import { Image } from "expo-image";
import { type ImageSourcePropType, Platform } from "react-native";
import type { IconSource } from "react-native-paper/lib/typescript/components/Icon";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import StoresDownloadBadge, {
	type StoreDownloadBadgeProps,
} from "./StoresDownloadBadge";

type FooterProps = {
	userAvatar?: string;
	userName?: string;
	storeDownloadBadge: StoreDownloadBadgeProps;
	isCollapsed?: boolean;
};
type AppLogo = {
	default: {
		light: ImageSourcePropType;
		dark: ImageSourcePropType;
	};
	stacked: {
		light: ImageSourcePropType;
		dark: ImageSourcePropType;
	};
};

type SectionItem = {
	label: string;
	focusedIcon?: IconSource;
	unfocusedIcon?: IconSource;
	right?: IconType;
	route: Href;
	subtitle?: string;
	custom?: boolean;
	isCollapsed?: boolean;
};
export type Section = {
	title: string;
	items: SectionItem[];
	customHeader?: () => React.ReactNode;
} & {
	isCollapsed?: boolean;
};

const isActiveRoute = (route: Href, pathname: string) => {
	return pathname === route;
};

const LeftDrawerListItem = ({
	label,
	focusedIcon,
	unfocusedIcon,
	right,
	route,
	subtitle,
	isCollapsed,
}: SectionItem) => {
	const theme = useAppTheme();

	const isProfile = route === "/profile";
	const isActive = isActiveRoute(route, usePathname());
	return isCollapsed ? (
		<Drawer.CollapsedItem
			label={label}
			active={isActive}
			focusedIcon={focusedIcon}
			unfocusedIcon={unfocusedIcon}
			onPress={() => router.push(route)}
			theme={{
				colors: { error: theme.colors.onPrimaryContainer },
			}}
		/>
	) : (
		<Drawer.Item
			//@ts-ignore
			label={
				<Box>
					<Text variant="bodyMedium">{label}</Text>
					{subtitle && <Text variant="labelSmall">{subtitle}</Text>}
				</Box>
			}
			active={isActive}
			icon={isActive && !isProfile ? focusedIcon : unfocusedIcon}
			onPress={() => {
				router.push(route);
			}}
			right={() =>
				right && (
					<Icon
						name={right}
						onPress={() => router.push("/account/settings")}
						size={24}
						color={theme.colors.onSurfaceVariant}
					/>
				)
			}
		/>
	);
};

const Section = ({ title, items, customHeader, isCollapsed }: Section) => {
	const drawerSectionTitle = isCollapsed ? "" : title;
	const theme = useAppTheme();
	return (
		<Drawer.Section title={drawerSectionTitle} showDivider={false}>
			{customHeader?.()}
			{items.map(
				({
					label,
					focusedIcon,
					unfocusedIcon,
					right,
					route,
					subtitle,
					custom,
				}) => {
					return (
						<LeftDrawerListItem
							key={label}
							label={label}
							isCollapsed={isCollapsed}
							focusedIcon={
								custom
									? () => (
											<MemorangIcons
												name={focusedIcon}
												size={24}
												color={theme.colors.onSurfaceVariant}
											/>
										)
									: focusedIcon
							}
							unfocusedIcon={
								custom
									? () => (
											<MemorangIcons
												name={unfocusedIcon}
												size={24}
												color={theme.colors.onSurfaceVariant}
											/>
										)
									: unfocusedIcon
							}
							right={right}
							route={route}
							custom={custom}
							subtitle={subtitle}
						/>
					);
				},
			)}
		</Drawer.Section>
	);
};

const Footer = ({ storeDownloadBadge, isCollapsed }: FooterProps) => {
	const { isMobile } = useDevice();
	const isWeb = Platform.OS === "web";
	return (
		<Box
			style={{
				paddingBottom: 16,
			}}
			gap={24}
		>
			{!isMobile && isWeb && !isCollapsed && (
				<Box
					style={{
						paddingHorizontal: 16,
						width: "100%",
						flexDirection: "column",
						gap: 8,
					}}
				>
					<StoresDownloadBadge {...storeDownloadBadge} />
				</Box>
			)}
		</Box>
	);
};

type Props = {
	sections: Section[];
	isCollapsed?: boolean;
	drawerHeader: {
		appLogo: AppLogo;
		title?: string;
		subtitle?: string;
		renderFab?: () => React.ReactNode;
	};
	footer: FooterProps;
};

const DrawerHeader = ({
	appLogo,
	title,
	subtitle,
	renderFab,
	isCollapsed,
}: {
	appLogo: AppLogo;
	title?: string;
	subtitle?: string;
	renderFab?: () => React.ReactNode;
	isCollapsed?: boolean;
}) => {
	const theme = useAppTheme();
	const finalAppLogo = theme.dark
		? isCollapsed
			? appLogo.stacked.dark
			: appLogo.default.dark
		: isCollapsed
			? appLogo.stacked.light
			: appLogo.default.light;
	const { smUp, mdDown } = useBreakpoints();
	const showCollapsed = smUp && mdDown;

	const insets = useSafeAreaInsets();
	return (
		<Box
			gap={16}
			paddingHorizontal={showCollapsed ? 12 : 24}
			paddingBottom={16}
			paddingTop={insets.top + 16}
		>
			<Link href={"/home" as never}>
				<Box style={{ width: showCollapsed ? 300 : 200, height: 56 }}>
					<Image
						contentFit="contain"
						source={finalAppLogo}
						transition={400}
						style={{
							width: showCollapsed ? 50 : "100%",
							height: showCollapsed ? 60 : "100%",
							alignSelf: showCollapsed ? "flex-start" : "center",
						}}
					/>
				</Box>
			</Link>
			{(title || subtitle) && (
				<Box>
					<Text variant="titleMedium">{title}</Text>
					<Text variant="bodySmall">{subtitle}</Text>
				</Box>
			)}
			{renderFab?.()}
		</Box>
	);
};
export const DrawerContents = ({
	sections,
	drawerHeader: { appLogo, title, subtitle, renderFab },
	footer,
	isCollapsed,
}: Props) => {
	const theme = useAppTheme();
	return (
		<Box
			style={{
				flexGrow: 1,
				justifyContent: "space-between",
			}}
			backgroundColor={theme.colors.surface}
		>
			<Box>
				<DrawerHeader
					title={title}
					subtitle={subtitle}
					appLogo={appLogo}
					renderFab={renderFab}
					isCollapsed={isCollapsed}
				/>
				{sections.map((section) => (
					<Section
						key={section.title}
						title={section.title}
						items={section.items}
						customHeader={section.customHeader}
						isCollapsed={isCollapsed}
					/>
				))}
			</Box>
			<Footer {...footer} isCollapsed={isCollapsed} />
		</Box>
	);
};
