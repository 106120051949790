import { fetchData } from "@memorang/helpers/src/fetchData";
import type { MixedItemDistResponse } from "@memorang/types/src/tag";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";
import relayEnv from "relay/relayenv";

const query = graphql`
  query FetchPracticeTestsContentsQuery(
    $bundleId: ID!
    $dashboardAction: DashboardAction
  ) {
    getMixedItemDistribution(
      bundleId: $bundleId
      dashboardAction: $dashboardAction
    ) {
      hasAccess
      description
      exams {
        id
        title
        hasAccess
        type
        beginStartDate
        endStartDate
        legacyReport {
          numericId
          sessionDate
          numItems
          score
          id
        }
        migratedSessionReport {
          numericId
          sessionDate
          numItems
          score
          id
        }
        numItems
        numericId
        numCompleted
        status
        time
        timeElapsed
        totalTime
        attempted {
          low
          medium
          high
        }
        dateCompleted
        distribution {
          low
          medium
          high
        }
      }
    }
  }
`;

const fetchPracticeTestContents = async (bundleId: string) => {
	const variables = {
		bundleId,
		dashboardAction: "GETEXAMS",
	};

	const { getMixedItemDistribution } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as MixedItemDistResponse;

	return {
		getMixedItemDistribution,
	};
};

export default fetchPracticeTestContents;
