/* -----------------Helpers--------------- */
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
import { getLearningModes } from "../helpers/learningModes";
import type { LearningModes } from "../types";

import Box from "@memorang/ui/src/components/Box";
/* -----------------Components--------------- */
import ListItemLearningMode from "components/ListItemLearningMode";

type NumItemsMap = {
	numFlashcards: number;
	numQuestions: number;
};
type Props = {
	selectedLearningMode: string;
	handleSetLearningMode: (learningMode: LearningModes) => void;
	numItemsMap: NumItemsMap;
	disableAll?: boolean;
};
const LearningModesList = ({
	selectedLearningMode,
	handleSetLearningMode,
	disableAll,
	numItemsMap: { numFlashcards, numQuestions },
}: Props) => {
	const learningModes = getLearningModes(numFlashcards, numQuestions);

	return (
		<Box>
			<CustomListSubheaderContainer enableGutters>
				Learning Mode
			</CustomListSubheaderContainer>
			{learningModes.map(({ title, value, disabled, icon, count }) => {
				const selected = value === selectedLearningMode;
				return (
					<ListItemLearningMode
						title={title}
						key={title}
						value={value}
						disabled={disabled || disableAll}
						icon={icon}
						count={count}
						selected={selected}
						handleRadioSelection={handleSetLearningMode}
					/>
				);
			})}
		</Box>
	);
};

export default LearningModesList;
