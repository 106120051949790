/* -----------------Globals--------------- */
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { IconButton, Dialog as PaperDialog } from "react-native-paper";

/* -----------------Types--------------- */
import type { FeedbackParams } from "../types";

import CustomSnackbar from "@memorang/ui/src/components/CustomSnackbar";
/* -----------------Child components--------------- */
import { FeedbackContent } from "./FeedbackContent";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../useAppTheme";

interface Props {
	title?: string;
	subtitle?: string;
	visible: boolean;
	onDismiss: () => void;
	handleTracking: () => void;
	feedbackDialogParams: FeedbackParams;
	subtitleSuffix?: string;
	feedbackPlaceholder?: string;
	icon?: string;
}

const FeedbackDialog = ({
	title,
	subtitle,
	visible,
	onDismiss,
	handleTracking,
	feedbackDialogParams,
	subtitleSuffix,
	feedbackPlaceholder,
	icon,
}: Props) => {
	const theme = useAppTheme();
	const [showFeedbackConfirmation, setShowFeedbackConfirmation] =
		useState(false);

	const DialogHeader = () => {
		return (
			<>
				{icon && <PaperDialog.Icon icon={icon} />}
				<DialogTitle
					sx={{
						color: theme.colors.onSurface,
						textAlign: "center",
						pt: 3,
					}}
				>
					{title || "Help us improve"}
				</DialogTitle>
				<IconButton
					icon="close"
					onPress={onDismiss}
					style={{
						position: "absolute",
						right: 8,
						top: 6,
					}}
				/>
			</>
		);
	};

	return (
		<>
			<Dialog
				open={visible}
				onClose={onDismiss}
				maxWidth="xs"
				PaperProps={{
					sx: {
						// Note: This is 7 x 4 = 28px
						// https://m3.material.io/components/dialogs/specs
						borderRadius: 7,
						backgroundColor: theme.colors.surface,
					},
				}}
			>
				<DialogHeader />
				<DialogContent>
					<FeedbackContent
						feedbackDialogParams={feedbackDialogParams}
						handleTracking={handleTracking}
						onDismiss={onDismiss}
						subtitle={subtitle}
						subtitleSuffix={subtitleSuffix}
						feedbackPlaceholder={feedbackPlaceholder}
						setShowFeedbackConfirmation={setShowFeedbackConfirmation}
					/>
				</DialogContent>
			</Dialog>
			<CustomSnackbar
				visible={showFeedbackConfirmation}
				message="Thank you for your feedback!"
				handleDismiss={() => setShowFeedbackConfirmation(false)}
			/>
		</>
	);
};

export { FeedbackDialog };
