import { convertToMinutes } from "@memorang/helpers/src/common";

const timePerQuestion = 72;
const timePerCST = 7 * 60;
export { timePerQuestion, timePerCST };

const useProcessTimeEstimate = (numItemsSelected: number) => {
	return `~${convertToMinutes(numItemsSelected * timePerQuestion)} minutes`;
};

export default useProcessTimeEstimate;
