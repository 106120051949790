import { useCopilotChat, useCopilotContext } from "@copilotkit/react-core";
import { Role, TextMessage } from "@copilotkit/runtime-client-gql";
import { randomId } from "@copilotkit/shared";
import { reloadSuggestions } from "@features/redeem-copilot/helpers/suggestions";
import type { Suggestion } from "@features/redeem-copilot/types";
import Box from "@memorang/ui/src/components/Box";
import { useEffect, useState } from "react";
import { Text } from "react-native-paper";
import SuggestedAction from "./SuggestedAction";

type Props = {
	title?: string;
	instructions?: string;
	minSuggestions?: number;
	maxSuggestions?: number;
};

const SelectSuggestion = ({
	title,
	instructions = "",
	minSuggestions,
	maxSuggestions,
	setSkipSuggestions,
}: Props & {
	setSkipSuggestions?: (skipSuggestions: boolean) => void;
}) => {
	const context = useCopilotContext();
	const { appendMessage, isLoading } = useCopilotChat();
	const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
	const [sectionTitle, setSectionTitle] = useState<string>(
		title ?? "Suggestions",
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: Valid use case
	useEffect(() => {
		const id = randomId();

		context.addChatSuggestionConfiguration(id, {
			instructions,
			minSuggestions,
			maxSuggestions,
		});

		if (isLoading) {
			setSuggestions([]);
		} else {
			reloadSuggestions(
				context,
				setSuggestions,
				title ? undefined : setSectionTitle,
				setSkipSuggestions,
			);
		}

		return () => {
			context.removeChatSuggestionConfiguration(id);
		};
	}, [isLoading]);

	if (suggestions.length === 0) {
		return <></>;
	}

	return (
		<Box gap={8}>
			<Text variant="labelSmall" style={{ opacity: 0.7 }}>
				{sectionTitle}
			</Text>
			<Box gap={8}>
				{suggestions.map((suggestion) => (
					<SuggestedAction
						key={`${suggestion.title}-${suggestion.message}`}
						title={suggestion.title}
						message={suggestion.message}
						partial={suggestion.partial ?? false}
						handlePress={(message) => {
							setSkipSuggestions?.(false);
							appendMessage(
								new TextMessage({
									role: Role.User,
									content: message,
								}),
							);
						}}
					/>
				))}
			</Box>
		</Box>
	);
};

export default SelectSuggestion;
