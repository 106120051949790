import { Role, TextMessage } from "@copilotkit/runtime-client-gql";
import { isWeb } from "@helpers/platform";
import ProductIcon from "components/ProductIcon";
import { Button, Card, Text } from "react-native-paper";
import { formatCase } from "../helpers/string";

/* --------------- Hooks --------------- */
import { useCopilotChat } from "@copilotkit/react-core";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { useExams } from "@features/exam/hooks/useExams";
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@memorang/ui/src/useDevice";
import { useRouter } from "expo-router";
import { useState } from "react";

/* --------------- Analytics --------------- */
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../analytics";

type Props = {
	bundleType: "STUDY_PACK" | "PRACTICE_TEST";
	examName?: string;
	icon?: string;
};

export const ContentSelector = ({ bundleType, examName, icon }: Props) => {
	const router = useRouter();

	const { isMobile } = useDevice();
	const exams = useExams((store) => store.exams);
	const { currentExamContext, handleSwitchExamContext } = useExamContext();
	const quizEnabled = useAppStore((store) => store.exam.quizEnabled);

	const { appendMessage } = useCopilotChat();
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	const [examSwitched, setExamSwitched] = useState(false);

	const isCurrentContext = currentExamContext?.examName === examName;
	const contentType =
		bundleType === "PRACTICE_TEST" ? "Practice Tests" : "Study Pack";

	const switchContextSubtitle = isCurrentContext
		? `You're already in the ${examName} exam. Would you like to go to your ${contentType}?`
		: `Switch exam context to ${examName}?`;

	const goToContentSubtitle = `Would you like to go to your ${contentType}?`;
	const switchContextButton = isCurrentContext
		? "Go to Content"
		: "Switch Exam Context";
	const goToContentButton = "Go to Content";

	const systemMessages = {
		contextSwitched: `
			# CONTEXT
			- Action: Exam context switched to ${examName}
			
			# INSTRUCTIONS
			- Inform the user that their exam context has been switched successfully
			- Ask if they would like to go to their ${contentType}
			- DO NOT mention "redirecting" or "navigation"
		`,
		alreadyInContext: `
			# CONTEXT
			- Action: User is already in ${examName} context
			
			# INSTRUCTIONS
			- Inform the user that they are already in the correct exam context
			- Ask if they would like to go to their ${contentType}
			- DO NOT mention "redirecting" or "navigation"
		`,
		contentAccessed: `
			# CONTEXT
			- Action: User chose to view their ${contentType}
			
			# INSTRUCTIONS
			- Inform the user that they can find their content in the ${contentType} section
			- DO NOT mention "redirecting" or "navigation"
			- DO NOT say "I have taken you to..."
		`,
	};

	const handleNavigation = () => {
		trackRedeemEvent(rcEvents.contentRedirectAttempted, {
			interactionType: "manual",
			interactionElementType: "button",
			productType: bundleType,
			productName: contentType,
			success: true,
		});

		if (bundleType === "PRACTICE_TEST") {
			router.replace("/(protected)/(tabs)/practice-tests");
		} else {
			router.replace("/study-pack");
		}
	};

	const switchExamContext = () => {
		const examDetails = exams.find(
			(examDetails) => examDetails.exam.name === examName,
		);

		if (examDetails) {
			if (isCurrentContext) {
				trackRedeemEvent(rcEvents.examContextSwitchAttempted, {
					interactionType: "manual",
					interactionElementType: "button",
					productType: bundleType,
					productName: examName,
					success: true,
				});

				appendMessage(
					new TextMessage({
						role: Role.System,
						content: systemMessages.alreadyInContext,
					}),
				);
			} else {
				handleSwitchExamContext(examDetails);

				trackRedeemEvent(rcEvents.examContextSwitchAttempted, {
					interactionType: "manual",
					interactionElementType: "button",
					productType: bundleType,
					productName: examName,
					success: true,
				});

				appendMessage(
					new TextMessage({
						role: Role.System,
						content: systemMessages.contextSwitched,
					}),
				);
			}
			setExamSwitched(true);
		} else {
			trackRedeemEvent(rcEvents.examContextSwitchAttempted, {
				interactionType: "manual",
				interactionElementType: "button",
				productType: bundleType,
				productName: examName,
				success: false,
				errorType: "exam_not_found",
			});
		}
	};

	const handlePress = () => {
		if (examSwitched || isCurrentContext) {
			trackRedeemEvent(rcEvents.contentRedirectAttempted, {
				interactionType: "manual",
				interactionElementType: "button",
				productType: bundleType,
				productName: contentType,
				success: true,
			});

			if (isWeb && !isMobile && quizEnabled) {
				router.replace({
					pathname: "/home",
					params: {
						startDiagnostic: "true",
					},
				});
			} else {
				handleNavigation();
			}

			appendMessage(
				new TextMessage({
					role: Role.System,
					content: systemMessages.contentAccessed,
				}),
			);
		} else {
			switchExamContext();
		}
	};

	return (
		<Card>
			<Card.Title
				title={examName}
				titleVariant="titleMedium"
				subtitle={formatCase(bundleType)}
				subtitleVariant="labelSmall"
				left={icon ? () => <ProductIcon image={icon} size={48} /> : undefined}
			/>
			<Card.Content>
				<Text variant="bodyLarge">
					{examSwitched ? goToContentSubtitle : switchContextSubtitle}
				</Text>
			</Card.Content>
			<Card.Actions style={{ marginVertical: 16, marginRight: 8 }}>
				<Button onPress={handlePress}>
					{examSwitched ? goToContentButton : switchContextButton}
				</Button>
			</Card.Actions>
		</Card>
	);
};

export default ContentSelector;
