import { useAppTheme } from "@hooks/useAppTheme";
import Box from "@memorang/ui/src/components/Box";
/* -----------------Child components--------------- */
import { Button } from "react-native-paper";

/* -----------------Props--------------- */
export type NextButtonProps = {
	handlePrevious: () => void;
	handleNext: () => void;
	showButtonPrevious?: boolean;
	showButtonNavSkip?: boolean;
	showButtonNavNext?: boolean;
	showButtonNavFinish?: boolean;
	disableNextButton?: boolean;
	nextButtonText?: string;
	prevButtonText?: string;
	progressText?: string;
	isSummativeTest?: boolean;
	toggleHelpDialog: () => void;
	inReviewMode?: boolean;
};

/* -----------------Component--------------- */
const NavButtons = ({
	handlePrevious,
	handleNext,
	showButtonPrevious,
	showButtonNavSkip,
	showButtonNavNext,
	showButtonNavFinish,
	disableNextButton,
	nextButtonText,
	prevButtonText,
	isSummativeTest,
	inReviewMode,
	toggleHelpDialog,
}: NextButtonProps) => {
	const theme = useAppTheme();

	const nextButtonMode = showButtonNavSkip
		? "outlined"
		: isSummativeTest
			? "elevated"
			: "contained";

	const getNextButtonNext = () => {
		switch (true) {
			case showButtonNavSkip:
				return "Skip";
			case showButtonNavFinish:
				return "Finish";
			case showButtonNavNext:
				return nextButtonText || "Next";
			default:
				return "Finish";
		}
	};

	const nextButtonIcon = showButtonNavSkip
		? "skip-next-outline"
		: "chevron-right";

	const PreviousButton = () => {
		return (
			<>
				{showButtonPrevious ? (
					<Button
						mode="outlined"
						textColor={isSummativeTest ? theme.colors.white : undefined}
						icon={"chevron-left"}
						style={{
							borderColor: isSummativeTest
								? theme.colors.white
								: theme.colors.divider,
						}}
						onPress={handlePrevious}
					>
						{prevButtonText || "Prev"}
					</Button>
				) : (
					<Box width={96} />
				)}
			</>
		);
	};

	const getNextButtonBackgroundColor = () => {
		if (isSummativeTest) {
			if (showButtonNavSkip) {
				return "transparent";
			}
			return theme.colors.onPrimary;
		}
		return showButtonNavSkip ? "transparent" : theme.colors.primary;
	};

	const bg = getNextButtonBackgroundColor();
	const NextButton = () => {
		return (
			<Button
				onPress={handleNext}
				mode={nextButtonMode}
				style={{
					borderColor: isSummativeTest
						? theme.colors.white
						: theme.colors.divider,
					backgroundColor: bg,
				}}
				textColor={
					isSummativeTest
						? showButtonNavSkip
							? theme.colors.white
							: theme.colors.primary
						: undefined
				}
				icon={nextButtonIcon}
				contentStyle={{
					flexDirection: "row-reverse",
				}}
				disabled={disableNextButton}
			>
				{getNextButtonNext()}
			</Button>
		);
	};

	const SummativeTestNavButtons = () => {
		return (
			<Box flexDirection="row" gap={16}>
				<PreviousButton />
				<NextButton />
			</Box>
		);
	};

	const FormativeTestNavButtons = () => {
		return (
			<>
				<PreviousButton />
				{/* {progressText && <Text variant="bodySmall">{progressText}</Text>} */}
				<NextButton />
			</>
		);
	};

	return (
		<Box
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
			width="100%"
		>
			{isSummativeTest && !inReviewMode && (
				<Button
					icon={"help-circle-outline"}
					textColor={theme.colors.white}
					onPress={toggleHelpDialog}
				>
					Help
				</Button>
			)}
			{isSummativeTest ? (
				<SummativeTestNavButtons />
			) : (
				<FormativeTestNavButtons />
			)}
		</Box>
	);
};

export default NavButtons;
