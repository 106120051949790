/* -----------------Globals--------------- */
import { useState } from "react";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------UI--------------- */
import { List } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

import { useExams } from "@features/exam/hooks/useExams";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
import { ExamsFilterDialog } from "components/ExamsFilterDialog";
/* -----------------Child components--------------- */
import ProductIcon from "components/ProductIcon";

export const ExamContextListItem = ({
	title,
	icon,
}: {
	title: string;
	icon?: string;
}) => {
	const theme = useAppTheme();

	const exams = useExams((store) => store.exams);

	const hasMoreThanOneExam = exams.length > 1;
	const [showExamsFilterDialog, setShowExamsFilterDialog] = useState(false);

	const { handleExamsFilterNavigation } = useCustomNavigation();
	const toggleShowExamsFilterDialog = () => {
		setShowExamsFilterDialog((prev) => !prev);
	};

	const handleNavigateToExamsRoute = () => {
		handleExamsFilterNavigation(toggleShowExamsFilterDialog);
	};

	const leftIcon = () => {
		if (!icon?.includes(".")) {
			return (
				<Icon
					name={
						(icon ?? "file-document-outline") as React.ComponentProps<
							typeof Icon
						>["name"]
					}
					size={24}
					color={theme.colors.onSurfaceVariant}
				/>
			);
		}
		return <ProductIcon image={icon} size={34} />;
	};
	return (
		<>
			<List.Item
				style={{
					paddingVertical: 0,
					paddingHorizontal: 16,
				}}
				onPress={hasMoreThanOneExam ? handleNavigateToExamsRoute : undefined}
				title={title}
				right={(p) => {
					return hasMoreThanOneExam ? (
						<List.Icon {...p} icon={"swap-horizontal"} />
					) : null;
				}}
				titleStyle={{
					...theme.typography.fonts.medium,
				}}
				left={leftIcon}
			/>
			{showExamsFilterDialog && (
				<ExamsFilterDialog
					visible={showExamsFilterDialog}
					handleClose={toggleShowExamsFilterDialog}
				/>
			)}
		</>
	);
};
