import { Platform, StyleSheet } from "react-native";

/* -----------------UI--------------- */
import type { AppTheme } from "@memorang/ui/src/useAppTheme";
import { useAppTheme } from "@memorang/ui/src/useAppTheme";
import { useDevice } from "@memorang/ui/src/useDevice";
import * as Clipboard from "expo-clipboard";
import { openInbox } from "react-native-email-link";
/* -----------------Third parties--------------- */
import OTPTextView from "react-native-otp-textinput";
import type OTPTextViewType from "react-native-otp-textinput/dist/index"; // * workaround for a typescript namespace error.
import WebOTPTextView from "react-otp-input";

/* -----------------Child components--------------- */
import AuthButton from "@memorang/ui/src/components/AuthButton";
import AuthContainer from "@memorang/ui/src/components/AuthContainer";
import Box from "@memorang/ui/src/components/Box";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import { useRef } from "react";
import { otpErrorMessage, otpLength } from "../constants";
import ContactSupport from "./ContactSupport";
import VerfiyEmailFooter from "./VerifyEmailFooter";

/* -----------------Styles--------------- */
const getStyles = (theme: AppTheme, isMobile?: boolean) =>
	StyleSheet.create({
		otpContainerStyle: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			gap: isMobile ? 2 : 16,
		},
		otpTextStyle: {
			borderRadius: theme.roundness,
			width: 40,
			height: 40,
			fontWeight: "bold",
			color: theme.colors.text,
			fontSize: 16,
			borderWidth: 2,
			borderBottomWidth: 1.5,
			borderColor: theme.colors.divider,
			backgroundColor: theme.colors.surface,
		},
		otpFocusStyle: {
			borderColor: theme.colors.primary,
		},
	});

interface Props {
	loading?: boolean;
	email: string;
	showError?: boolean;
	handleResendOTP: () => void;
	resendingOtp: boolean;
	handleOpenSupport: () => void;
	handleSubmitCode: () => void;
	disabled?: boolean;
	code?: string;
	handleSetCode: (code: string) => void;
}
const VerifyEmailView = ({
	email,
	showError,
	handleResendOTP,
	resendingOtp,
	handleOpenSupport,
	handleSubmitCode,
	loading,
	disabled,
	code,
	handleSetCode,
}: Props) => {
	const theme = useAppTheme();

	const { isMobile } = useDevice();
	const styles = getStyles(theme, isMobile);

	const caption = showError
		? otpErrorMessage
		: `We sent a 6-digit **email verification code** to **${email}**. This code will expire shortly, so please enter it soon.`;

	const handleOpenMail = () => {
		openInbox();
	};

	const isWeb = Platform.OS === "web";
	const ref = useRef<OTPTextViewType>(null);

	const handleCopyCode = async () => {
		const copiedCode = await Clipboard.getStringAsync();

		if (!copiedCode) {
			return;
		}

		if (
			!Number.isNaN(Number.parseInt(copiedCode)) &&
			copiedCode.length === otpLength
		) {
			ref.current?.setValue(copiedCode, true);
		} else {
			ref.current?.setValue("");
		}
		await Clipboard.setStringAsync("");
	};

	return (
		<>
			<AuthContainer>
				<Box gap={24}>
					<Box gap={8}>
						{isWeb ? (
							<WebOTPTextView
								value={code}
								shouldAutoFocus
								onChange={handleSetCode}
								numInputs={otpLength}
								isInputNum
								inputStyle={styles.otpTextStyle}
								containerStyle={styles.otpContainerStyle}
								focusStyle={styles.otpFocusStyle}
							/>
						) : (
							<OTPTextView
								inputCount={otpLength}
								tintColor={theme.colors.primary}
								offTintColor={theme.colors.divider}
								handleTextChange={handleSetCode}
								handleCellTextChange={handleCopyCode}
								keyboardType="numeric"
								autoFocus={false}
								ref={ref}
								textInputStyle={styles.otpTextStyle}
								containerStyle={styles.otpContainerStyle}
							/>
						)}
						<Box padding={8} paddingHorizontal={24}>
							<MarkdownRenderer
								customStyles={{
									text: {
										color: showError
											? theme.colors.error
											: theme.colors.onSurfaceVariant,
										lineHeight: 22,
									},
								}}
								textAlign="center"
								useCustomLink={false}
								text={caption}
							/>
						</Box>
					</Box>

					{!isWeb && (
						<AuthButton
							mode="outlined"
							icon="email-outline"
							disabled={loading}
							onPress={handleOpenMail}
						>
							Open Email
						</AuthButton>
					)}
					<AuthButton
						loading={loading}
						disabled={disabled}
						onPress={handleSubmitCode}
					>
						Continue
					</AuthButton>
					<VerfiyEmailFooter
						resendingOtp={resendingOtp}
						disabled={loading}
						handleResendOTP={handleResendOTP}
						hasError={showError}
					/>
				</Box>
				<ContactSupport
					label="Need help?"
					disabled={loading}
					buttonText="Contact support"
					handleOpenSupport={handleOpenSupport}
				/>
			</AuthContainer>
		</>
	);
};

export default VerifyEmailView;
