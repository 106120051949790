import { isWeb } from "@helpers/platform";
/* -----------------Globals--------------- */
import { useState } from "react";

import { images } from "@helpers/images";
import Box from "@memorang/ui/src/components/Box";
import type { IconType } from "components/types/icon";
/* -----------------UI--------------- */
import { FAB } from "react-native-paper";
import { Drawer } from "../../../CustomDrawer";

import { useExamContext } from "@features/exam/contexts/ExamContext";
/* -----------------Child components--------------- */
import { SessionConfigDialog } from "@features/session-config/components/SessionConfigDialog";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";

/* -----------------Types--------------- */
import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { getShowStudyPackViews } from "@helpers/exams";
import useBreakpoints from "@hooks/useBreakpoints";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
import { DrawerContents, type Section } from "components/DrawerContents";
import type { Href } from "expo-router";
import { ScrollView } from "react-native";
import { QuickStudyButtonSkeleton } from "../components/Skeletons/QuickStudyButtonSkeleton";
import { drawerWidths } from "../constants";

export const AppDrawer = () => {
	const appLinks = useAppStore((store) => store.app.links);
	const appLogo = useAppStore((store) => store.app.logo);
	const navigationRoutes = useAppStore((store) => store.navigation.routes.main);
	const showStudyPackAsHome = useAppStore((store) => store.showStudyPackAsHome);
	const store = useAppStore((store) => store.store);

	const [showSessionConfigDialog, setShowSessionConfigDialog] = useState(false);
	const { userDetails } = useAuthContext();

	const { handleSessionConfigNavigation } = useCustomNavigation();
	const userName = userDetails?.name;
	const userAvatar = userDetails?.iconUrl;
	const {
		currentExamContext: { studyPackProductId, practiceTestProductId, examId },
	} = useExamContext();

	const showStudyPackViews = getShowStudyPackViews(studyPackProductId);

	const { smUp, mdDown } = useBreakpoints();
	const isCollapsed = smUp && mdDown;
	const leftDrawerWidth = isCollapsed
		? drawerWidths.collapsed
		: drawerWidths.expanded;
	const drawerType = smUp ? "permanent" : "slide";

	const sections: Section[] = [
		{
			title: "",
			items: navigationRoutes
				.filter((item) => {
					if (item.name === "profile") {
						return !isWeb;
					}
					return item.enabled;
				})
				.map((item) => {
					return {
						label: item.title,
						focusedIcon: item.icon.selected as IconType,
						unfocusedIcon: item.icon.default as IconType,
						route: `/${item.name}` as Href,
					};
				}),
		},
	];

	if (isWeb) {
		if (practiceTestProductId) {
			sections[0].items.splice(1, 0, {
				label: "Practice Tests",
				focusedIcon: "clipboard-check",
				unfocusedIcon: "clipboard-check-outline",
				route: "/practice-tests",
			});
		}
		if (showStudyPackViews && !showStudyPackAsHome) {
			sections[0].items.splice(2, 0, {
				label: "Study-Pack",
				focusedIcon: "study-pack",
				unfocusedIcon: "study-pack-outline",
				route: "/study-pack",
				custom: true,
			});
		}

		if (store.enabled && isWeb) {
			sections[0].items.push({
				label: "Redeem",
				focusedIcon: "gift",
				unfocusedIcon: "gift-outline",
				route: "/redeem?step=redeem",
			});
		}
	}
	const toggleSessionConfigDialog = () => {
		setShowSessionConfigDialog((prev) => !prev);
	};

	const QuickStudyButton = () => {
		if (!examId) {
			return <QuickStudyButtonSkeleton />;
		}
		return (
			showStudyPackViews && (
				<Box paddingBottom={16} paddingTop={16} alignSelf="baseline">
					<FAB
						icon="play-outline"
						variant="primary"
						onPress={() => {
							handleSessionConfigNavigation(toggleSessionConfigDialog, {
								reset: "true",
							});
						}}
						label={isCollapsed ? "" : "Quick-Study"}
						testID="quick-study-button"
					/>
					{showSessionConfigDialog ? (
						<SessionConfigDialog
							visible={showSessionConfigDialog}
							handleClose={toggleSessionConfigDialog}
						/>
					) : null}
				</Box>
			)
		);
	};

	return (
		<Drawer
			drawerContent={(props) => (
				<ScrollView
					showsVerticalScrollIndicator={false}
					contentContainerStyle={{
						flexGrow: 1,
					}}
				>
					<DrawerContents
						drawerHeader={{
							appLogo: appLogo,
							renderFab: () => {
								return <QuickStudyButton />;
							},
						}}
						{...props}
						sections={sections}
						isCollapsed={isCollapsed}
						footer={{
							userName,
							userAvatar,
							storeDownloadBadge: {
								ios: {
									badge: images.appStores.ios,
									href: appLinks.ios,
								},
								android: {
									badge: images.appStores.android,
									href: appLinks.android,
								},
							},
						}}
					/>
				</ScrollView>
			)}
			screenOptions={{
				drawerType: drawerType,
				headerTitle: "",
				drawerStyle: {
					width: leftDrawerWidth,
					borderRightColor: "transparent",
				},
				header: () => null,
			}}
		/>
	);
};
