/* -----------------Globals--------------- */
import { useCallback, useEffect, useState } from "react";

/* -----------------Helpers & Hooks--------------- */
import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { trackCustomEvent } from "analytics";
import { useFocusEffect } from "expo-router";
import { getShouldShowReviewPrompt } from "../helpers";
import { useReviewStore } from "./useReviewStore";

import { currentAppVersion } from "@helpers/expo-extras";
/* -----------------Constants--------------- */
import { feedbackEvents } from "../constants";

/* -----------------Third party libraries--------------- */
import * as StoreReview from "expo-store-review";

export const useReview = () => {
	const [showSnackbar, setShowSnackBar] = useState(false);
	const [showReviewPrompt, setShouldShowReviewPrompt] = useState(false);
	const [hasFocus, setHasFocus] = useState(false);

	const [currentStep, setCurrentStep] = useState<"prompt" | "feedback">(
		"prompt",
	);
	const isFeedbackStep = currentStep === "feedback";

	const resetTrackedAppReviewCritieria = useReviewStore(
		(state) => state.resetTrackedAppReviewCritieria,
	);
	const isRoutedFromSession = useReviewStore(
		(state) => state.isRoutedFromSession,
	);
	const updateIsRoutedFromSession = useReviewStore(
		(state) => state.updateIsRoutedFromSession,
	);
	const updateLastAppVersion = useReviewStore(
		(state) => state.updateLastAppVersion,
	);

	const { userDetails } = useAuthContext();
	const isPremiumUser = userDetails?.isPremiumUser;

	useFocusEffect(
		useCallback(() => {
			setHasFocus(true);
			return () => {
				setHasFocus(false);
				updateIsRoutedFromSession(false);
			};
		}, [updateIsRoutedFromSession]),
	);

	useFocusEffect(
		useCallback(() => {
			if (isPremiumUser === undefined) {
				return;
			}
			const shouldShowReviewPrompt = getShouldShowReviewPrompt(isPremiumUser);
			setShouldShowReviewPrompt(shouldShowReviewPrompt);
		}, [isPremiumUser]),
	);

	const showReviewDialog = showReviewPrompt && hasFocus && isRoutedFromSession;

	useEffect(() => {
		if (showReviewDialog) {
			const eventName = isFeedbackStep
				? feedbackEvents.feedbackDialogOpened
				: feedbackEvents.reviewDialogOpened;
			trackCustomEvent({
				eventName,
			});
		}
	}, [isFeedbackStep, showReviewDialog]);

	useEffect(() => {
		if (showReviewDialog) {
			updateLastAppVersion(currentAppVersion!);
		}
	}, [showReviewDialog, updateLastAppVersion]);

	const handleDismiss = () => {
		const eventName = isFeedbackStep
			? feedbackEvents.feedbackDialogClosed
			: feedbackEvents.reviewDialogClosed;
		trackCustomEvent({
			eventName,
		});
		setShouldShowReviewPrompt(false);
		resetTrackedAppReviewCritieria();
	};

	const handleReviewButtonsClick = (type: "positive" | "negative") => {
		if (type === "positive") {
			trackCustomEvent({
				eventName: feedbackEvents.reviewPositiveClicked,
			});
			StoreReview.requestReview().then(handleDismiss);
		} else {
			resetTrackedAppReviewCritieria();
			trackCustomEvent({
				eventName: feedbackEvents.reviewNegativeClicked,
			});
			setCurrentStep("feedback");
		}
	};

	const handleDismissFeedback = () => {
		setShowSnackBar(true);
		handleDismiss();
	};

	return {
		showSnackbar,
		showReviewPrompt,
		hasFocus,
		isRoutedFromSession,
		handleReviewButtonsClick,
		handleDismissFeedback,
		currentStep,
		setShowSnackBar,
	};
};
