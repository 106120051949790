/* -----------------Globals--------------- */
import { useState } from "react";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MUIMenu from "@mui/material/Menu";
import MUIMenuItem from "@mui/material/MenuItem";
/* -----------------UI--------------- */
import { IconButton } from "react-native-paper";

/* -----------------Types--------------- */
import type { MenuAction } from "../types";

export const AppBarMenu = ({ menuActions }: { menuActions: MenuAction[] }) => {
	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorElement);

	const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElement(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorElement(null);
	};

	return (
		<>
			{/* @ts-expect-error this is only intended for web, where it does get the proper mouse event*/}
			<IconButton icon="dots-vertical" onPress={openMenu} />
			<MUIMenu
				anchorEl={anchorElement}
				open={open}
				onClose={closeMenu}
				MenuListProps={{
					"aria-labelledby": "chat-appbar-menu",
				}}
			>
				{menuActions.map(({ title, handleAction, icon }) => (
					<MUIMenuItem
						key={title}
						onClick={() => {
							handleAction();
							closeMenu();
						}}
					>
						<ListItemIcon>
							{icon && <Icon name={icon} color="inherit" size={20} />}
						</ListItemIcon>
						<ListItemText>{title}</ListItemText>
					</MUIMenuItem>
				))}
			</MUIMenu>
		</>
	);
};
