import { zustandStorage } from "@helpers/storage";
/* -----------------Globals--------------- */
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

/* -----------------Types--------------- */
type State = {
	numSessionCompleted: number | null;
	lastShownPromptDate: number | null;
	lastAppVersion: string | null;
	isRoutedFromSession: boolean;
	trackAppReviewCritieria: (isPositiveScore: boolean) => void;
	resetTrackedAppReviewCritieria: () => void;
	updateIsRoutedFromSession: (isRoutedFromSession: boolean) => void;
	updateLastAppVersion: (lastAppVersion: string) => void;
};

/* -----------------Store--------------- */
export const useReviewStore = create<State>()(
	persist(
		(set) => ({
			numSessionCompleted: null,
			lastShownPromptDate: null,
			lastAppVersion: null,
			isRoutedFromSession: false,
			trackAppReviewCritieria: (isPositiveScore) => {
				set((state) => {
					const numSessionCompleted = (state.numSessionCompleted || 0) + 1;
					return {
						...state,
						numSessionCompleted,
						isRoutedFromSession: isPositiveScore,
					};
				});
			},
			resetTrackedAppReviewCritieria: () => {
				set((state) => {
					return {
						...state,
						numSessionCompleted: null,
						lastShownPromptDate: Date.now(),
					};
				});
			},
			updateIsRoutedFromSession: (isRoutedFromSession: boolean) => {
				set((state) => {
					return {
						...state,
						isRoutedFromSession,
					};
				});
			},
			updateLastAppVersion: (lastAppVersion: string) => {
				set((state) => {
					return {
						...state,
						lastAppVersion,
					};
				});
			},
		}),

		{
			name: "review-store",
			storage: createJSONStorage(() => zustandStorage),
		},
	),
);
