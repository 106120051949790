import { ChangeAvatarDialog } from "@features/profile/dialogs/ChangeAvatarDialog";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
import Box from "@memorang/ui/src/components/Box";
import Loader from "@memorang/ui/src/components/Loader";
/* -----------------Types--------------- */
import type { Stat } from "components/RowStats";
import { useState } from "react";
/* -----------------Child components--------------- */
import ProfileHeader from "./ProfileHeader";
import ProfileStatsView from "./ProfileStatsView";

type Section = {
	title: string;
	data: {
		title: string;
		subtitle?: string;
		icon?: string;
		type?: string;
		rightText?: string;
	}[];
};
type Props = {
	headerStats?: Stat[];
	avatar: string;
	name: string;
	statsTabData?: Section[];
};

const ProfileView = ({
	headerStats,
	statsTabData,
	avatar,
	name: _name,
}: Props) => {
	const [showChangeAvatarDialog, setShowChangeAvatarDialog] = useState(false);
	const { handleChangeAvatarNavigation } = useCustomNavigation();

	const toggleChangeAvatarDialog = () => {
		setShowChangeAvatarDialog(!showChangeAvatarDialog);
	};

	if (!statsTabData?.length) {
		return (
			<Box
				flexGrow={1}
				height={"100%"}
				padding={24}
				style={{
					minHeight: 200,
				}}
			>
				<Loader />
			</Box>
		);
	}

	const handleAvatarClick = () => {
		handleChangeAvatarNavigation(toggleChangeAvatarDialog);
	};
	return (
		<>
			<ProfileHeader
				stats={headerStats!}
				iconUrl={avatar}
				handleAvatarClick={handleAvatarClick}
			/>
			<ProfileStatsView sections={statsTabData} />
			{showChangeAvatarDialog && (
				<ChangeAvatarDialog
					visible={showChangeAvatarDialog}
					handleClose={toggleChangeAvatarDialog}
				/>
			)}
		</>
	);
};

export default ProfileView;
