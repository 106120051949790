import { useCopilotContext } from "@copilotkit/react-core";
import { useAppTheme } from "@memorang/ui/src/useAppTheme";
import useBreakpoints from "@memorang/ui/src/useBreakpoints";
import { useEffect } from "react";
import { Pressable, View } from "react-native";
import { FAB } from "react-native-paper";
import { useRedeemCopilotChat } from "../../context";
import Appbar from "./Appbar";

const offsetX = 16;
const offsetY = 16;

type Props = {
	title?: string;
	open?: boolean;
	instructions?: string;
	children?: React.ReactNode;
};

const Popup = ({ title, open, instructions, children }: Props) => {
	const theme = useAppTheme();
	const { xsDown } = useBreakpoints();

	const {
		chatState: { openChat: openState, handleOnOpenChange },
		setPartialChatState,
	} = useRedeemCopilotChat();

	const openChat = open ?? openState;

	const { setChatInstructions } = useCopilotContext();

	// biome-ignore lint/correctness/useExhaustiveDependencies: Valid use case
	useEffect(() => {
		if (instructions) {
			setChatInstructions(instructions);
		}
		setPartialChatState({ openChat });
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: Valid use case
	useEffect(() => {
		handleOnOpenChange?.(openChat);
	}, [openChat]);

	return (
		<>
			<FAB
				mode="elevated"
				icon={openChat ? "chevron-down" : "chat"}
				onPress={() => setPartialChatState({ openChat: !openChat })}
				color={theme.colors.onPrimary}
				style={{
					position: "absolute",
					bottom: offsetY,
					right: offsetX,
					zIndex: 2,
					backgroundColor: theme.colors.primary,
					display: xsDown && openChat ? "none" : "flex",
				}}
			/>

			<View
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 1,
					display: openChat ? "flex" : "none",
				}}
			>
				<Pressable
					style={{ flex: 1 }}
					onPress={() =>
						setPartialChatState({ openChat: false, openChatMode: "manual" })
					}
				>
					<View
						style={{
							position: "absolute",
							top: xsDown ? 0 : undefined,
							left: xsDown ? 0 : undefined,
							bottom: xsDown ? 0 : offsetY + 72,
							right: xsDown ? 0 : offsetX,
							width: xsDown ? "100%" : 400,
							height: xsDown ? "100%" : "85%",
							backgroundColor: theme.colors.surface,
							borderRadius: xsDown ? 0 : theme.roundness,
							shadowColor: theme.colors.elevation[0],
							shadowOpacity: 0.1,
							shadowRadius: 10,
							elevation: 5,
							zIndex: 2,
							overflow: "hidden",
							display: "flex",
						}}
					>
						<Pressable
							style={{ flex: 1, display: "flex" }}
							onPress={(e) => e.stopPropagation()}
						>
							<Appbar
								title={title}
								showClose={xsDown}
								handleClose={() => setPartialChatState({ openChat: false })}
							/>
							<View
								style={{
									flex: 1,
									display: "flex",
									minHeight: 0,
									padding: 8,
									paddingBottom: 32,
									gap: 16,
								}}
							>
								{children}
							</View>
						</Pressable>
					</View>
				</Pressable>
			</View>
		</>
	);
};

export default Popup;
