import type {
	SessionChildItem,
	SessionItem,
	SessionItemTypes,
} from "@memorang/types/src/session/common";
import type { CheckboxType } from "../components/dialogs/ReviewAnswersDialog";

const getFilteredChildrenItems = (
	children: SessionChildItem[],
	filters: CheckboxType[],
) => {
	// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
	const filteredItems = children.filter(({ answers, marked }) => {
		const hasSelection =
			answers &&
			(answers.selectedChoiceIds?.length > 0 || answers.selectedaction != null);

		if (
			filters.includes("correct") &&
			filters.includes("incorrect") &&
			filters.includes("skipped") &&
			filters.includes("almostcorrect") &&
			filters.includes("marked")
		) {
			return answers != null || marked;
		}
		if (filters.includes("correct") && filters.includes("incorrect")) {
			return answers ? answers.isCorrect != null : false;
		}
		if (filters.includes("correct") && filters.includes("skipped")) {
			return answers
				? answers.isCorrect || !(hasSelection || answers.score)
				: false;
		}

		if (filters.includes("marked") && filters.includes("correct")) {
			return (answers ? answers.isCorrect : false) || marked;
		}
		if (filters.includes("marked") && filters.includes("incorrect")) {
			return (answers ? !answers.isCorrect : false) || marked;
		}
		if (filters.includes("marked") && filters.includes("skipped")) {
			return (
				(answers
					? answers.isCorrect || !(hasSelection || answers.score)
					: false) || marked
			);
		}
		if (filters.includes("incorrect") && filters.includes("skipped")) {
			return answers
				? !(answers.isCorrect && (hasSelection || answers.score))
				: false;
		}
		if (filters.includes("almostcorrect")) {
			return answers ? answers.score >= 50 && answers.score <= 84 : false;
		}

		if (filters.includes("correct")) {
			return answers ? answers.isCorrect : false;
		}
		if (filters.includes("incorrect")) {
			return answers ? !answers.isCorrect : false;
		}
		if (filters.includes("skipped")) {
			return answers ? !(hasSelection || answers.score) : false;
		}
		if (filters.includes("marked")) {
			return marked;
		}
		return false;
	});
	return filteredItems;
};

export const getFilteredSessionItems = (
	sessionItems: SessionItem[],
	filters: CheckboxType[],
	itemType?: SessionItemTypes,
) => {
	const filteredItems = sessionItems.map((sessionItem) => {
		const { children, ...rest } = sessionItem;
		const filteredChildren = children.filter((item) => {
			return item.itemType === itemType;
		});
		return {
			...rest,
			children: getFilteredChildrenItems(filteredChildren, filters),
		};
	});

	return filteredItems.flatMap((item) => item.children);
};
