/* -----------------Globals--------------- */
import {
	Stack,
	useFocusEffect,
	useLocalSearchParams,
	useRouter,
} from "expo-router";

import { pageViewTypes } from "@constants/tracking";
/* -----------------Child components--------------- */
import RedeemCodeCopilotWrapper from "@features/redeem/components/RedeemCodeCopilotWrapper";
import { replayManager } from "@helpers/lib/sentry";
import useAnalytics from "@hooks/useAnalytics";
import CustomAppbar from "components/CustomAppbar";
import { useCallback } from "react";
import { Appbar } from "react-native-paper";

const RedeemRoute = () => {
	const { step } = useLocalSearchParams<{
		step: string;
	}>();

	useFocusEffect(
		useCallback(() => {
			replayManager().startReplay();
		}, []),
	);

	useAnalytics({
		type: pageViewTypes.redeem,
	});
	const router = useRouter();
	const isSuccessStep = step === "success";
	const title = isSuccessStep ? "Access code redeemed" : "Have an access code?";

	return (
		<>
			<Stack.Screen
				options={{
					headerShown: true,
					header: () => {
						return (
							<CustomAppbar
								options={{
									headerTitle: title,
									headerRight: () => null,
									headerLeft: () => (
										<Appbar.BackAction
											onPress={() => {
												if (router.canGoBack()) {
													router.back();
												} else {
													router.push("/(protected)/(tabs)/home");
												}
											}}
										/>
									),
								}}
							/>
						);
					},
				}}
			/>
			<RedeemCodeCopilotWrapper />
		</>
	);
};

export default RedeemRoute;
