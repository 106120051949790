/* -----------------Child components--------------- */
import { FilterView } from "@memorang/ui/src/components/FilterView";

import { useToast } from "@contexts/ToastContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { useExams } from "@features/exam/hooks/useExams";
/* -----------------Others--------------- */
import { useRouter } from "expo-router";
import SearchNoResult from "../../../../assets/no-search-results.svg";

type Props = {
	handleClose?: () => void;
	searchIcon?: string;
};
const ExamsFilterRouteView = ({ handleClose, searchIcon }: Props) => {
	const groupedExams = useExams((store) => store.groupedExams);
	const exams = useExams((store) => store.exams);

	const { showToast } = useToast();

	const {
		currentExamContext: { examId, examName },
		handleSwitchExamContext,
	} = useExamContext();

	const router = useRouter();

	const currentCategoryExamSelected = groupedExams?.find((item) => {
		return item.data.find(({ exam }) => exam.id === examId);
	});
	const sectionData = [...(groupedExams || [])]
		.sort(({ title }) => {
			return title === currentCategoryExamSelected?.title ? -1 : 1;
		})
		.map((group) => ({
			title: group.title,
			data: group.data.map(({ exam }) => ({
				name: exam.name,
				id: exam.id,
			})),
		}));

	const handleAction = async (selectedItemId: string) => {
		const examDetails = exams.find(({ exam }) => exam.id === selectedItemId);
		const message = `Switched Exam from "${examName}" to "${examDetails?.exam.name}"`;
		showToast({ message, duration: 500 });
		handleClose?.();
		router.replace("/home");
		await handleSwitchExamContext(examDetails!);
	};
	return (
		<>
			<FilterView
				sectionData={sectionData}
				handleAction={handleAction}
				initSelectedItemId={examId}
				actionCta="Switch exam"
				searchIcon={searchIcon}
				emptySearchResultSvg={SearchNoResult}
				currentItemSelectedLabel="Current Exam"
			/>
		</>
	);
};

export default ExamsFilterRouteView;
