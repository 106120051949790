import { useState } from "react";

/* -----------------Hooks--------------- */
import DateTimePicker from "components/DateTimePicker";

import { pageViewTypes } from "@constants/tracking";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import useAnalytics from "@hooks/useAnalytics";
/* -----------------Components--------------- */
import ChooseDateView from "../components/ChooseDateView";

const ChooseDateContainer = () => {
	const {
		updateExamDate,
		currentExamContext: { examDate },
	} = useExamContext();
	const [showDatePicker, setShowDatePicker] = useState(false);

	useAnalytics({
		type: pageViewTypes.chooseDate,
	});
	const toggleDatePicker = () => {
		setShowDatePicker(!showDatePicker);
	};

	return (
		<>
			<ChooseDateView
				selectedExamDate={examDate}
				toggleDatePicker={() => setShowDatePicker(true)}
			/>
			{showDatePicker ? (
				<DateTimePicker
					isVisible
					onCancel={toggleDatePicker}
					onConfirm={(date) => {
						toggleDatePicker();
						updateExamDate(date);
					}}
				/>
			) : null}
		</>
	);
};

export default ChooseDateContainer;
