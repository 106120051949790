/**
 * @generated SignedSource<<75c5a5eeae4178397ed36695d2411cd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExternalAuthInput = {
  appId?: string | null;
  tenantId: string;
  token: string;
};
export type handleExternalAuthMutation$variables = {
  authInput: ExternalAuthInput;
};
export type handleExternalAuthMutation$data = {
  readonly handleExternalAuth: {
    readonly data: {
      readonly email: any | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly userId: string | null;
      readonly userName: string | null;
    } | null;
    readonly message: string;
    readonly status: number;
    readonly token: {
      readonly accessToken: string | null;
      readonly idToken: string | null;
      readonly refreshToken: string | null;
    } | null;
  };
};
export type handleExternalAuthMutation = {
  response: handleExternalAuthMutation$data;
  variables: handleExternalAuthMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "authInput"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "authInput",
        "variableName": "authInput"
      }
    ],
    "concreteType": "ExternalAuthResponse",
    "kind": "LinkedField",
    "name": "handleExternalAuth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserToken",
        "kind": "LinkedField",
        "name": "token",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accessToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "idToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refreshToken",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthResponse",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "handleExternalAuthMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "handleExternalAuthMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "39c940704bb1ff92e174550ac3e6954a",
    "id": null,
    "metadata": {},
    "name": "handleExternalAuthMutation",
    "operationKind": "mutation",
    "text": "mutation handleExternalAuthMutation(\n  $authInput: ExternalAuthInput!\n) {\n  handleExternalAuth(authInput: $authInput) {\n    status\n    message\n    token {\n      accessToken\n      idToken\n      refreshToken\n    }\n    data {\n      firstName\n      lastName\n      userId\n      userName\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "836c68f3c89071fb2391d75d5a08a361";

export default node;
