/* ======== Hooks ========= */
import useProfile from "../hooks/useProfile";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { isWeb } from "@helpers/platform";
import { useGlobalStore } from "@hooks/useGlobalStore";
import Box from "@memorang/ui/src/components/Box";
import ScrollContainer from "@memorang/ui/src/components/ScrollContainer";
import SnackBarCustom from "components/CustomSnackbar";
import { Card, Portal } from "react-native-paper";
/* ======== Components ========= */
import ProfileView from "../components/Profile/ProfileView";

const ProfileContainer = () => {
	const { profileDetails } = useProfile();

	const { userDetails } = useAuthContext();

	const { headerStats, statsTabData } = profileDetails || {};

	const setShowAvatarUpdatedMessage = useGlobalStore(
		(state) => state.setShowAvatarUpdatedMessage,
	);

	const showAvatarUpdatedMessage = useGlobalStore(
		(state) => state.showAvatarUpdatedMessage,
	);

	const Wrapper = isWeb ? Card : Box;
	return (
		<ScrollContainer
			contentContainerStyle={{
				flexGrow: 1,
				paddingBottom: 40,
			}}
			maxWidth="sm"
		>
			<Wrapper
				style={{
					marginTop: 16,
				}}
				height="100%"
				flexGrow={1}
			>
				<ProfileView
					avatar={userDetails?.iconUrl!}
					name={userDetails?.name!}
					statsTabData={statsTabData}
					headerStats={headerStats}
				/>
			</Wrapper>
			{showAvatarUpdatedMessage && (
				<Portal>
					<SnackBarCustom
						message="Avatar updated successfully"
						duration={2000}
						visible
						handleDismiss={() => {
							setShowAvatarUpdatedMessage(false);
						}}
					/>
				</Portal>
			)}
		</ScrollContainer>
	);
};

export default ProfileContainer;
