import { baseNextjsUrl } from "@memorang/constants/src/urls";
import type { AiAssistantInfo } from "@memorang/ui/src/components/ChatUI/types";

export const ENDPOINT_RESEND_EMAIL = `${baseNextjsUrl}/api/ai/send`;
export const ENDPOINT_RECOMMENDED_QUERIES = `${baseNextjsUrl}/api/ai/questions-beta`;
export const ENDPOINT_AIRTABLE_FEEDBACK = `${baseNextjsUrl}/api/ai/feedback`;
export const GET_SIGNED_HEADERS = `${baseNextjsUrl}/api/ai/get-signed-headers`;
export const ENDPOINT_THREADS = `${baseNextjsUrl}/api/ai/threads`;
export const ENDPOINT_ASSISTANT_SESSION_REVIEW = `${baseNextjsUrl}/api/ai/assistant-session-review`;
export const RECOMMENDED_QUESTIONS_QUERY_KEY = "RECOMMENDED_QUESTIONS";
export const GET_THREAD_QUERY_KEY = "THREAD";

export const summaryActionIcons = (showEmail?: boolean) => [
	...(showEmail
		? [
				{
					icon: "email-outline",
					type: "email",
					tooltip: "Email summary text",
				},
			]
		: []),
	{
		icon: "content-copy",
		type: "copy",
		tooltip: "Copy summary text",
	},
	{
		icon: "thumb-up-outline",
		type: "feedback-positive",
		tooltip: "This was helpful",
	},
	{
		icon: "thumb-down-outline",
		type: "feedback-negative",
		tooltip: "This could be better",
	},
];

export const events = {
	copilotFeedbackSubmitted: "mem_copilot_feedback_submitted",
	copilotResponseCopyClicked: "mem_copilot_response_copy_clicked",
	sessionReportSummaryCopyClicked: "mem_session_report_summary_copy_clicked",
	sessionReportFeedbackProvided: "mem_session_report_feedback_provided",
	positiveFeedbackSubmitted: "mem_positive_feedback_submitted",
	negativeFeedbackSubmitted: "mem_negative_feedback_submitted",
	feedbackSubmitted: "mem_feedback_submitted",
	copilotFollowupSelected: "mem_copilot_followup_selected",
	searchDialogOpened: "mem_search_dialog_opened",
	copilotTextSubmitted: "mem_copilot_text_submitted",
	clearedQuery: "mem_cleared_query",
	emailTriggered: "mem_email_triggered",
	emailSent: "mem_email_sent",
	chatOpened: "mem_chat_opened",
};

export const ailaEmptyStateImg =
	"https://memorang-prod-media.s3.amazonaws.com/aila_empty.png";

export const searchContexts = {
	currentView: "Current View",
	currentBook: "Current Book",
	allFilteredBooks: "All Filtered Books",
} as const;

export const initialAiMessage = (
	context: string,
	topic: string,
	aiAssistantInfo: Omit<AiAssistantInfo, "icon">,
) =>
	`👋 Hey there, I'm **${aiAssistantInfo.alias}**, your **${aiAssistantInfo.title}**, and I'm here to help you better prepare for **${
		context ?? "your exam"
	}!** Let's specifically dig into the question you answered about **${topic}.**`;
