import { isWeb } from "@helpers/platform";
import { useRouter } from "expo-router";
import { useDevice } from "./useDevice";

export const useCustomNavigation = () => {
	const { isMobile } = useDevice();

	const router = useRouter();

	const handleChangeAvatarNavigation = (toggleChangeAvatar: () => void) => {
		const navigateToChangeAvatar = () => {
			router.push("/profile/change-avatar");
		};
		if (isWeb) {
			if (isMobile) {
				navigateToChangeAvatar();
			} else {
				toggleChangeAvatar();
			}
		} else {
			navigateToChangeAvatar();
		}
	};

	const handleExamsFilterNavigation = (toggleExamsFilter: () => void) => {
		const navigateToExamsFilter = () => {
			router.push("/(protected)/exams");
		};
		if (isWeb) {
			if (isMobile) {
				navigateToExamsFilter();
			} else {
				toggleExamsFilter();
			}
		} else {
			navigateToExamsFilter();
		}
	};

	const handleSessionConfigNavigation = (
		toggleSessionConfig: () => void,
		params?: Record<string, string | string[]>,
	) => {
		const navigateToSessionConfig = () => {
			router.push({
				pathname: "/session/customize",
				params,
			});
		};
		if (isWeb) {
			if (isMobile) {
				navigateToSessionConfig();
			} else {
				toggleSessionConfig();
			}
		} else {
			navigateToSessionConfig();
		}
	};

	const handleSuggestCorrectionNavigation = (
		toggleSuggestCorrection: () => void,
		currentItemIndex?: number,
	) => {
		const navigateToSuggestCorrection = () => {
			router.push({
				pathname: "/suggestcorrection",
				params: {
					index: `${currentItemIndex}`,
				},
			});
		};

		if (isWeb) {
			if (isMobile) {
				navigateToSuggestCorrection();
			} else {
				toggleSuggestCorrection();
			}
		} else {
			navigateToSuggestCorrection();
		}
	};

	const handleFeedbackNavigation = (toggleFeedback: () => void) => {
		const navigateToFeedback = () => {
			router.push("/(protected)/feedback");
		};

		if (isWeb) {
			if (isMobile) {
				navigateToFeedback();
			} else {
				toggleFeedback();
			}
		} else {
			navigateToFeedback();
		}
	};

	return {
		handleSessionConfigNavigation,
		handleExamsFilterNavigation,
		handleSuggestCorrectionNavigation,
		handleFeedbackNavigation,
		handleChangeAvatarNavigation,
	};
};
