/* -----------------Globals--------------- */
import { Redirect, useGlobalSearchParams, useRouter } from "expo-router";

/* -----------------Child components--------------- */
import Loader from "@memorang/ui/src/components/Loader";

import { pageViewTypes } from "@constants/tracking";
import DeeplinkProvider from "@contexts/DeeplinkProvider";
import OnboardingAppBar from "@features/auth/components/OnboardingAppBar";
/* -----------------Helpers & Hooks--------------- */
import { useAuthContext } from "@features/auth/contexts/AuthContext";
import Landing from "@features/auth/screens/Landing";
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@hooks/useDevice";
import { trackPageView } from "analytics";
import { useEffect } from "react";
export default function IndexRoute() {
	const router = useRouter();

	const tos = useAppStore((store) => store.app.links.tos);
	const privacy = useAppStore((store) => store.app.links.privacy);
	const appName = useAppStore((store) => store.app.name);
	const appLogo = useAppStore((store) => store.app.logo);

	const { auth_token } = useGlobalSearchParams<{ auth_token: string }>();

	const { isAuthenticated, loading } = useAuthContext();

	const { isMobile } = useDevice();
	useEffect(() => {
		trackPageView({ type: pageViewTypes.splash });
	}, []);

	if (loading) {
		return <Loader />;
	}
	if (isAuthenticated && !auth_token) {
		return <Redirect href="/(protected)/(tabs)/home" />;
	}

	const handleNext = () => {
		router.push("/(auth)/login");
	};
	return (
		<DeeplinkProvider>
			{!isMobile && <OnboardingAppBar showLogo />}

			<Landing
				tos={tos}
				privacy={privacy}
				appName={appName}
				appLogo={appLogo}
				handleNext={handleNext}
			/>
		</DeeplinkProvider>
	);
}
