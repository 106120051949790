import Box from "@memorang/ui/src/components/Box";
import { useEffect } from "react";
import { Text } from "react-native-paper";
import useSessionStore from "../../hooks/useSessionStore";
import useCountDown from "../../hooks/useTimer";

type Props = {
	breakTime: number;
};
const SubtitleComponentContainer = ({ breakTime }: Props) => {
	const breakTimeElapsed = useSessionStore((state) => state.breakTimeElapsed);
	const updateBreakTimeElapsed = useSessionStore(
		(state) => state.updateBreakTimeElapsed,
	);

	const breaks = useSessionStore((state) => state.breaks);

	const firstBreak = breaks?.[0];

	const startTime = firstBreak?.startTime;

	useEffect(() => {
		let interval: NodeJS.Timeout | null = null;
		interval = setInterval(() => {
			const currentTime = Date.now();
			const breakStartTime = startTime
				? new Date(startTime).getTime()
				: currentTime;
			const timeElapsedMs = Math.max(0, currentTime - breakStartTime);
			const timeElapsedSeconds = Math.floor(timeElapsedMs / 1000);
			updateBreakTimeElapsed(timeElapsedSeconds);
		}, 1000);
		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [updateBreakTimeElapsed, startTime]);

	const timeRemaining = breakTime - breakTimeElapsed;

	const { minutes, seconds } = useCountDown(timeRemaining * 1000, true);

	const formattedTime = `${minutes}:${seconds}`;

	return (
		<Box alignItems="center" justifyContent="center" marginTop={16}>
			<Text variant="titleLarge">{formattedTime}</Text>
			<Text
				variant="bodySmall"
				style={{
					fontStyle: "italic",
					fontFamily: "OpenSans-SemiBold",
					letterSpacing: 1.5,
				}}
			>
				Time Remaining
			</Text>
		</Box>
	);
};

export default SubtitleComponentContainer;
