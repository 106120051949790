import Box from "@memorang/ui/src/components/Box";
import CopyButton from "@memorang/ui/src/components/CopyButton";
import { Card, Text } from "react-native-paper";
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../analytics";

export type SupportDetailsProps = {
	supportEmail: string;
	subject: string;
	body: string;
	appName: string;
	platform: string;
	version?: string;
};

const SupportDetails = ({
	supportEmail,
	subject,
	body,
	appName,
	platform,
	version,
}: SupportDetailsProps) => {
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	const copyText = `${supportEmail}

${subject}

${body}`;

	const handleCopy = () => {
		trackRedeemEvent(rcEvents.supportContactInitiated, {
			interactionType: "manual",
			interactionElementType: "button",
			success: true,
		});
	};

	return (
		<Card mode="elevated" style={{ overflow: "hidden" }}>
			<Card.Title
				title={<Text variant="titleLarge">Support Details</Text>}
				right={() => <CopyButton text={copyText} handlePress={handleCopy} />}
			/>
			<Card.Content>
				<Box style={{ gap: 8 }}>
					<Box>
						<Text variant="labelSmall">Mail</Text>
						<Text variant="bodyLarge">{supportEmail}</Text>
					</Box>

					<Box>
						<Text variant="labelSmall">App name</Text>
						<Text variant="bodyLarge" numberOfLines={2}>
							{appName}
						</Text>
					</Box>

					{!!version && (
						<Box>
							<Text variant="labelSmall">App version</Text>
							<Text variant="bodyLarge">{version}</Text>
						</Box>
					)}

					<Box>
						<Text variant="labelSmall">Platform</Text>
						<Text variant="bodyLarge" numberOfLines={2}>
							{platform}
						</Text>
					</Box>
				</Box>
			</Card.Content>
		</Card>
	);
};

export default SupportDetails;
