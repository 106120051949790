/* -----------------Globals--------------- */
import type { ViewStyle } from "react-native";

/* -----------------UI--------------- */
import { List, type ListItemProps, RadioButton } from "react-native-paper";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppTheme } from "@hooks/useAppTheme";
import { formatNumber } from "@memorang/helpers/src/common";
import Box from "@memorang/ui/src/components/Box";
import CustomChip from "@memorang/ui/src/components/CustomChip";

/* -----------------Type declarations--------------- */
type HandleRadioSelectionAction<T> = (value: T) => void;

/* -----------------Props--------------- */
type Props<T> = {
	value: T;
	selected: boolean;
	handleRadioSelection: HandleRadioSelectionAction<T>;
	icon: keyof typeof Icon.glyphMap;
	count: number;
} & ListItemProps;

/* -----------------Component--------------- */
const ListItemLearningMode = <T extends string>(props: Props<T>) => {
	const {
		value,
		title,
		disabled,
		count,
		selected,
		handleRadioSelection,
		icon,
	} = props;

	const handleClick = () => {
		handleRadioSelection(value);
	};
	const theme = useAppTheme();
	const RadioButtonLeft = () => (
		<RadioButton.Android
			value={value}
			onPress={handleClick}
			status={selected ? "checked" : "unchecked"}
		/>
	);

	const renderLeft = () => (
		<Box flexDirection="row" alignItems="center" gap={8}>
			<Box height={24} width={24} alignItems="center" justifyContent="center">
				<RadioButtonLeft />
			</Box>
			<Icon name={icon} size={24} color={theme.colors.onSurface} />
		</Box>
	);

	const renderRight = (p: { color: string; style?: ViewStyle | undefined }) => (
		<CustomChip
			style={{
				...p.style,
			}}
		>
			{`${formatNumber(count)}`}
		</CustomChip>
	);

	return (
		<List.Item
			onPress={handleClick}
			left={renderLeft}
			disabled={disabled}
			style={{
				opacity: disabled ? 0.5 : 1,
				paddingRight: 16,
				paddingLeft: 16,
			}}
			title={title}
			right={renderRight}
		/>
	);
};

export default ListItemLearningMode;
