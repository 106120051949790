import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { StyleSheet } from "react-native";
import { Avatar, Card } from "react-native-paper";
import { type AppTheme, useAppTheme } from "../../useAppTheme";
import TooltipWrapper from "../TooltipWrapper";

const getStyles = (theme: AppTheme) =>
	StyleSheet.create({
		avatar: {
			backgroundColor: theme.colors.secondaryContainer,
			alignSelf: "center",
		},
	});
type CardHeaderAvatarProps = {
	icon: string;
};
type Props = {
	icon: string;
	title: string;
	subtitle: string;
	tooltip?: string;
};
export const HeaderCardAvatar = ({ icon }: CardHeaderAvatarProps) => {
	const theme = useAppTheme();
	const styles = getStyles(theme);
	return (
		<Avatar.Icon
			style={styles.avatar}
			size={40}
			icon={icon}
			color={theme.palette.text.secondary}
		/>
	);
};
const HeaderCard = ({ title, subtitle, icon, tooltip }: Props) => {
	const theme = useAppTheme();
	return (
		<Card.Title
			title={title}
			style={{
				paddingVertical: 16,
			}}
			titleVariant="titleMedium"
			subtitle={subtitle}
			subtitleVariant="bodySmall"
			subtitleNumberOfLines={2}
			left={() => <HeaderCardAvatar icon={icon} />}
			right={() =>
				tooltip ? (
					<TooltipWrapper title={tooltip}>
						<Icon
							color={theme.colors.onSurfaceVariant}
							style={{
								paddingRight: 16,
							}}
							name="information-outline"
							size={24}
						/>
					</TooltipWrapper>
				) : null
			}
		/>
	);
};
export default HeaderCard;
