import { Image, ScrollView } from "react-native";

/* -----------------Child components--------------- */
import AccountListItem from "components/AccountListItem";

import { useAppTheme } from "@hooks/useAppTheme";
import Box from "@memorang/ui/src/components/Box";
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
/* -----------------Types--------------- */
import type { Section } from "../types";

type Props = {
	sections: Section[];
};

const PoweredBy = () => {
	const theme = useAppTheme();

	const footerLogo = theme.dark
		? require("../../../../assets/core/logo_poweredby_dark.png")
		: require("../../../../assets/core/logo_poweredby_light.png");
	return (
		<Box flexDirection="column" alignItems="center" padding={16}>
			<Image
				source={footerLogo}
				style={{
					height: 72,
				}}
				resizeMode="contain"
			/>
		</Box>
	);
};
const AccountView = ({ sections }: Props) => {
	return (
		<ScrollView>
			{sections.map(({ sectionTitle, items }) => (
				<Box key={sectionTitle}>
					<CustomListSubheaderContainer
						style={{
							paddingLeft: 16,
						}}
					>
						{sectionTitle}
					</CustomListSubheaderContainer>
					{items.map((view) => (
						<AccountListItem key={view.title} {...view} />
					))}
				</Box>
			))}
			<PoweredBy />
		</ScrollView>
	);
};

export default AccountView;
