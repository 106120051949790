/* -----------------Globals--------------- */
import { useLocalSearchParams, useRouter } from "expo-router";

/* -----------------UI--------------- */
import { Appbar } from "react-native-paper";

import { events, pageViewTypes } from "@constants/tracking";
import fetchBundleDetails from "@features/store/relay/queries/FetchBundleDetails";
import { isWeb } from "@helpers/platform";
import useAnalytics from "@hooks/useAnalytics";
import { useDevice } from "@hooks/useDevice";
import type { BundleDetails } from "@memorang/types/src/bundle";
/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import Loader from "@memorang/ui/src/components/Loader";
import ScrollContainer from "@memorang/ui/src/components/ScrollContainer";
import { AccessGrantedView } from "components/AccessGrantedView";
import CustomAppbar from "components/CustomAppbar";
import DocumentTitle from "components/DocumentTitle";
import { useEffect, useState } from "react";

const SuccessRoute = () => {
	const [bundleDetails, setBundleDetails] = useState<BundleDetails | null>(
		null,
	);

	const router = useRouter();

	const { explicitCallTrackCustomEvent } = useAnalytics({
		type: pageViewTypes.purchaseSuccess,
	});

	const { isMobile } = useDevice();

	const {
		id: idFromParams,
		planId,
		duration,
		interval,
		currency,
		amount,
	} = useLocalSearchParams<{
		id: string;
		fromStore: string;
		planId: string;
		duration: string;
		interval: string;
		currency: string;
		amount: string;
	}>();

	useEffect(() => {
		if (idFromParams) {
			const numericId = Number.parseInt(idFromParams);
			fetchBundleDetails(numericId).then((bundleDetails) => {
				explicitCallTrackCustomEvent({
					eventName: events.paymentSuccessful,
					product_id: bundleDetails.bundle.id,
					product_name: bundleDetails.bundle.name,
					product_type: bundleDetails.bundle.bundleType?.toLowerCase(),
					plan_id: planId,
					plan_duration: `${duration} ${interval}`,
					plan_price: amount,
					plan_currency: currency,
				});
				setBundleDetails(bundleDetails);
			});
		}
	}, [
		idFromParams,
		explicitCallTrackCustomEvent,
		planId,
		duration,
		interval,
		currency,
		amount,
	]);

	const headerTitle = "Thank You for Your Purchase!";

	if (!bundleDetails) {
		return (
			<Box flex={1} height="100%" justifyContent="center">
				<CustomAppbar
					options={{
						headerTitle,
						subtitle: isMobile ? "" : "Your Purchase is Successful.",
						headerLeft: () => (
							<Appbar.BackAction
								onPress={() => router.replace("/(protected)/(tabs)/home")}
							/>
						),
						headerRight: () => null,
					}}
				/>
				<Loader />
			</Box>
		);
	}

	const {
		bundle: {
			name,
			bundleType,
			examName,
			id,
			icon: { url },
			features,
		},
	} = bundleDetails;

	const title = `Access to your ${name} has been successfully granted`;
	const isStudyPack = bundleType === "STUDY_PACK";
	const subtitle = isMobile ? "" : `Your ${name} Purchase is Successful.`;

	const products = [
		{
			id,
			title: name,
			description: isStudyPack ? "Study-Pack" : "Practice Test",
			icon: url,
			features,
			isCustomAccess: false,
		},
	];

	return (
		<>
			<CustomAppbar
				options={{
					headerTitle,
					subtitle,
					headerRight: () => null,
					headerLeft: () => (
						<Appbar.BackAction
							onPress={() => {
								//Not sure but for some reason the router.replace is not working on mobile
								if (isWeb) {
									router.replace("/(protected)/(tabs)/home");
								} else {
									router.back();
								}
							}}
						/>
					),
				}}
			/>
			<DocumentTitle title="Purchase" />
			<ScrollContainer maxWidth="xs">
				<Box paddingTop={24} paddingBottom={44}>
					<AccessGrantedView
						products={products}
						examName={examName}
						title={title}
					/>
				</Box>
			</ScrollContainer>
		</>
	);
};

export default SuccessRoute;
