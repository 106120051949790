import { events } from "@constants/tracking";
import OnboardingAppBar from "@features/auth/components/OnboardingAppBar";
import { buildType } from "@helpers/expo-extras";
import useAnalytics from "@hooks/useAnalytics";
import { useAppStore } from "@hooks/useAppStore";
import useDeeplinkHandling from "@hooks/useDeeplinkHandling";
import { getPlatform } from "@memorang/helpers/src/common";
import { composeMessage } from "@memorang/helpers/src/email";
import Box from "@memorang/ui/src/components/Box";
import EmptyState from "@memorang/ui/src/components/EmptyView";
import LoaderDialog from "@memorang/ui/src/components/LoaderDialog";
import { useDevice } from "@memorang/ui/src/useDevice";
import AccessRestrictedDialog from "components/AccessRestrictedDialog";
import { router } from "expo-router";
import { Button } from "react-native-paper";
import AccessDeniedImage from "../../assets/undraw_access_denied.svg";

const DeeplinkProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const {
		isLoading,
		messages,
		showError,
		email,
		isAccessRestricted,
		setIsAccessRestricted,
	} = useDeeplinkHandling();
	const { isMobile } = useDevice();

	const supportEmail = useAppStore((store) => store.supportEmail);

	const { explicitCallTrackCustomEvent } = useAnalytics();

	const handleContactSupport = () => {
		if (!email) {
			return;
		}
		explicitCallTrackCustomEvent({
			eventName: events.contactSupportClicked,
			email,
		});
		const platform = getPlatform();
		const subject = `${buildType} Support | No Access | ${platform} | ${email}`;
		const body = `Hi, my account email is ${email} and I'm reaching out because:`;

		composeMessage(subject, body, supportEmail);
	};

	const AccessRestrictedActions = () => {
		const secondaryCta = "Choose exam";
		const primaryCta = "Contact support";

		return (
			<Box gap={8} flexDirection="row" alignItems="center">
				<Button onPress={() => router.replace("/onboarding")}>
					{secondaryCta}
				</Button>
				<Button mode="contained" onPress={handleContactSupport}>
					{primaryCta}
				</Button>
			</Box>
		);
	};

	if (isLoading) {
		return (
			<>
				{!isMobile && <OnboardingAppBar showLogo />}
				<LoaderDialog messages={messages} visible />
			</>
		);
	}

	if (isAccessRestricted) {
		return (
			<AccessRestrictedDialog
				visible={isAccessRestricted}
				actions={<AccessRestrictedActions />}
				dismissCallback={() => {
					setIsAccessRestricted(false);
					router.replace("/home");
				}}
			/>
		);
	}

	if (showError) {
		return (
			<EmptyState
				title="Unable to authenticate"
				subtitle="We are sorry, but we are unable to authenticate you. Go to login to continue."
				image={AccessDeniedImage}
				cta="Go to login"
				handleAction={() => {
					router.replace("/login");
				}}
			/>
		);
	}

	return children;
};

export default DeeplinkProvider;
