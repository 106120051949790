import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppStore } from "@hooks/useAppStore";
import { useAppTheme } from "@hooks/useAppTheme";
import type { IconType } from "@memorang/ui/src";
import Box from "@memorang/ui/src/components/Box";
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";
/* -----------------UI--------------- */
import { Button, Dialog, Text } from "react-native-paper";
import { confidenceDefaultDesc } from "./constants";
/* -----------------Props--------------- */
type Props = {
	open: boolean;
	handleClose: () => void;
};

const ConfidenceListItem = ({
	variant,
}: {
	variant: "high" | "medium" | "low";
}) => {
	const theme = useAppTheme();
	const icons: Record<string, IconType> = {
		high: "emoticon-happy-outline",
		medium: "emoticon-neutral-outline",
		low: "emoticon-sad-outline",
	};
	const appFont = useAppStore((store) => store.app.font) || "opensans";
	return (
		<Box display="flex" flexDirection="row" alignItems="center" marginTop={16}>
			<Box
				height={32}
				backgroundColor={theme.colors.confidence[variant]}
				width={72}
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="center"
				borderRadius={16}
				marginRight={16}
			>
				<Icon name={icons[variant]} color={theme.colors.white} size={20} />
			</Box>
			<Text allowFontScaling={false} style={{ fontSize: 16 }}>
				<Text
					variant="bodyMedium"
					style={{
						textTransform: "capitalize",
						fontFamily: `${appFont}-semibold`,
					}}
				>
					{variant}
				</Text>{" "}
				confidence
			</Text>
		</Box>
	);
};
/* -----------------Component--------------- */
const ConfidencebarOnboardingDialog = (props: Props) => {
	const { open, handleClose } = props;

	const title = "Rank your confidence";
	const cta = "Got it";
	const description = confidenceDefaultDesc;
	const confidenceLevels = ["high", "medium", "low"] as const;
	return (
		<DialogWrapper visible={open} handleClose={handleClose} maxWidth={"xs"}>
			<Dialog.Icon icon="emoticon-happy-outline" />
			<Dialog.Title
				allowFontScaling={false}
				style={{
					textAlign: "center",
				}}
			>
				{title}
			</Dialog.Title>
			<Dialog.Content>
				<Box gap={8}>
					<Text variant="bodyMedium" allowFontScaling={false}>
						{description}
					</Text>
					<Box gap={8}>
						{confidenceLevels.map((variant) => (
							<ConfidenceListItem key={variant} variant={variant} />
						))}
					</Box>
				</Box>
			</Dialog.Content>
			<Dialog.Actions>
				<Button
					mode="contained"
					style={{
						paddingHorizontal: 16,
					}}
					onPress={handleClose}
				>
					{cta}
				</Button>
			</Dialog.Actions>
		</DialogWrapper>
	);
};

export default ConfidencebarOnboardingDialog;
