import { forwardRef } from "react";

/* -----------------Types--------------- */
import type { SessionItem } from "@memorang/types/src/session/common";
/* -----------------UI--------------- */
import type { FlashList } from "@shopify/flash-list";

/* -----------------Child components--------------- */
import SessionListDesktop from "./SessionListDesktop";

interface Props {
	items: SessionItem[];
}

const SessionList = forwardRef<FlashList<SessionItem>, Props>(
	({ items }, ref) => {
		const _ref = ref;
		return <SessionListDesktop items={items} />;
	},
);
export default SessionList;
