/* ----------------- React Native Paper --------------- */
import { Card, List } from "react-native-paper";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "../../../useAppTheme";

import CardContainer from "../../CardContainer";
/* ----------------- Components --------------- */
import { SummaryListItem } from "./SummaryListItem";

/* ----------------- Types --------------- */
import type { Distribution } from "../../../types";

/* -----------------Child components--------------- */
type ListItemData = {
	icon: string;
	title: string;
	description: string;
	chipLabel: string;
	iconColor?: string;
	key?: string;
	tooltip?: string;
};

type Section = {
	name: string;
	dist?: Distribution;
	list: ListItemData[];
};
type Props = {
	title: string;
	subtitle: string;
	tooltip?: string;
	data: Section[];
};
export const ProgressSummaryCard = ({
	title,
	subtitle,
	data,
	tooltip,
}: Props) => {
	const theme = useAppTheme();
	const colorMap: Record<string, string> = {
		high: theme.colors.progress.high,
		medium: theme.colors.progress.medium,
		low: theme.colors.progress.low,
		skip: theme.colors.progress.noneBackground,
	};

	const Content = () => (
		<Card.Content
			style={{
				paddingHorizontal: 0,
				paddingBottom: 8,
			}}
		>
			{data.map(({ name, list }) => (
				<List.Section
					key={name}
					title={name}
					titleStyle={{
						fontFamily: "OpenSans-SemiBold",
					}}
				>
					{list.map(
						({
							description,
							chipLabel,
							iconColor,
							icon,
							key,
							title: listTitle,
							tooltip,
						}) => (
							<SummaryListItem
								key={listTitle}
								description={description}
								icon={icon}
								iconColor={iconColor || colorMap[`${key || ""}`]}
								chipLabel={chipLabel}
								title={listTitle}
								tooltip={tooltip}
							/>
						),
					)}
				</List.Section>
			))}
		</Card.Content>
	);

	return (
		<CardContainer
			title={title}
			tooltip={tooltip}
			subtitle={subtitle}
			icon="poll"
		>
			<Content />
		</CardContainer>
	);
};
