import type {
	CreateSessionInput,
	SessionResponse,
} from "@memorang/types/src/session/common";
import { commitMutation, graphql } from "react-relay";
import relayEnv from "relay/relayenv";

interface CreateSessionResponse {
	createSession: SessionResponse;
}

const createSession = async (
	input: CreateSessionInput,
): Promise<SessionResponse> => {
	const mutation = graphql`
    mutation CreateSessionMutation($request: CreateSessionInput) {
      createSession(request: $request) {
        id
        numericId
        errors{
          title
          message
        }
        examName
        totalTime
        reportSessionType
        startTime
        sessionItems {
          id
          containerId
          numItems
          stem
          itemType
          variant
          displayName
          tags {
            id
            type
            label
          }
          sessionConfig
          parentStem
          parentType
          maxChoiceCount
          minChoiceCount
          video
          children {
            id
            stem
            displayName
            itemType
            variant
            parentType
            numFirstSelections
            tags {
              id
              label
              type
            }
            maxChoiceCount
            minChoiceCount
            points
            video
            parentStem
            instruction
            text
            isCorrect
            explanation
            children {
              id
              stem
              displayName
              text
              isCorrect
              itemType
              feedback
              variant
              explanation
              instruction
              maxChoiceCount
              minChoiceCount
              points
              video
              tags {
                id
                label
                type
              }
              children {
                id
                text
                feedback
                stem
                isCorrect
                explanation
              }
            }
          }
        }
      }
    }
  `;

	const promise = new Promise<SessionResponse>((resolve, reject) => {
		const variables = {
			request: input,
		};

		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const finalResponse = response as CreateSessionResponse;
				if (
					finalResponse.createSession.sessionItems?.length === 0 &&
					!finalResponse.createSession.errors
				) {
					reject("No session items");
				} else {
					resolve(finalResponse.createSession);
				}
			},
			onError: (err) => {
				reject(err);
			},
		});
	});
	return promise;
};

export default createSession;
