/* ----------------- React Native Paper --------------- */
import { List, Text } from "react-native-paper";

import { Platform } from "react-native";
import CustomChip from "../../CustomChip";
/* ----------------- Components --------------- */
import TooltipWrapper from "../../TooltipWrapper";

const isWeb = Platform.OS === "web";

type Props = {
	icon: string;
	title: string;
	description: string;
	chipLabel: string;
	iconColor: string;
	tooltip?: string;
};

const chipProps = {
	textStyle: {
		cursor: "help",
	},
};
export const SummaryListItem = ({
	icon,
	title,
	description,
	chipLabel,
	iconColor,
	tooltip,
}: Props) => (
	<List.Item
		title={<Text variant="bodyMedium">{title}</Text>}
		description={<Text variant="bodySmall">{description}</Text>}
		style={{
			paddingRight: 16,
			cursor: "auto",
			paddingVertical: 4,
		}}
		left={(props) => <List.Icon {...props} color={iconColor} icon={icon} />}
		right={(p) => (
			<List.Icon
				{...p}
				icon={() => (
					<TooltipWrapper title={tooltip!}>
						<CustomChip {...(isWeb ? { ...chipProps } : {})}>
							{chipLabel}
						</CustomChip>
					</TooltipWrapper>
				)}
			/>
		)}
	/>
);
