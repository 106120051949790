/* -----------------Types--------------- */
import type { Plan } from "@memorang/types/src/bundle";

/* -----------------Child components--------------- */
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";

type RecommendedPlanTextProps = {
	recommendedPlan: Plan;
	selectedDate?: string;
};
const RecommendedPlanText = ({
	recommendedPlan,
	selectedDate,
}: RecommendedPlanTextProps) => {
	const text = `We recommend the “**${recommendedPlan.alias}**” plan to best prepare for your exam on **${selectedDate}**`;
	return <MarkdownRenderer text={text} textAlign="center" />;
};
export default RecommendedPlanText;
