export { COPILOT_URL } from "./urls";
export {
	COPILOT_INSTRUCTIONS,
	COPILOT_CHAT_SUGGESTIONS_INSTRUCTIONS,
} from "./instructions/top-level";
export {
	CONTACT_SUPPORT_INSTRUCTIONS,
	PURCHASE_VERIFICATION_INSTRUCTIONS,
	REDEEM_CODE_INSTRUCTIONS,
	REDIRECT_TO_CONTENT_INSTRUCTIONS,
	VERIFY_MASKED_EMAIL_INSTRUCTIONS,
	NAVIGATE_BY_ROUTE_INSTRUCTIONS,
} from "./instructions/handler";

export const warningMessage =
	"This AI assistant is in **Beta**—responses may occasionally be inaccurate or incomplete.";
