export const redeemCopilotEvents = {
	// Chat Events
	chatOpened: "RCE:Chat_Opened",
	chatClosed: "RCE:Chat_Closed",
	messageSent: "RCE:Message_Sent",
	suggestionClicked: "RCE:Suggestion_Clicked",

	// Assistant Events
	assistantResponded: "RCE:Assistant_Responded",
	assistantSuggestedAction: "RCE:Assistant_Suggested_Action",

	// Support Events
	supportContactInitiated: "RCE:Support_Contact_Initiated",

	// Access Code Events
	codeRedemptionAttempted: "RCE:Code_Redemption_Attempted",
	codeRedemptionSucceeded: "RCE:Code_Redemption_Succeeded",
	codeRedemptionFailed: "RCE:Code_Redemption_Failed",

	// Purchase Verification Events
	purchaseVerificationAttempted: "RCE:Purchase_Verification_Attempted",
	purchaseVerificationSucceeded: "RCE:Purchase_Verification_Succeeded",
	purchaseVerificationFailed: "RCE:Purchase_Verification_Failed",

	// Navigation Events
	contentRedirectAttempted: "RCE:Content_Redirect_Attempted",
	contentRedirectSucceeded: "RCE:Content_Redirect_Succeeded",
	contentRedirectFailed: "RCE:Content_Redirect_Failed",

	// Context Switch Events
	examContextSwitchAttempted: "RCE:Exam_Context_Switch_Attempted",
	examContextSwitchSucceeded: "RCE:Exam_Context_Switch_Succeeded",
	examContextSwitchFailed: "RCE:Exam_Context_Switch_Failed",

	// Masked Email Events
	maskedEmailVerificationAttempted: "RCE:Masked_Email_Verification_Attempted",
} as const;
