/* -----------------Globals--------------- */
import { Stack, useLocalSearchParams, useRouter } from "expo-router";

import FeedbackForm from "@features/feedback/components/FeedbackForm";
import {
	defaultFeedbackSubtitle,
	dialogTexts,
} from "@features/feedback/constants";
/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import ModalHeader from "components/ModalHeader";

const FeedbackRoute = () => {
	const { view } = useLocalSearchParams<{
		view?: string;
	}>();
	const router = useRouter();
	const config = dialogTexts.feedback;

	const handleDismissFeedback = () => {
		router.back();
	};
	return (
		<>
			<Stack.Screen
				options={{
					presentation: "modal",
					headerShown: true,
					header: () => (
						<ModalHeader backIcon="arrow-left" headerTitle={config.title} />
					),
				}}
			/>
			<Box padding={16}>
				<FeedbackForm
					view={view || "profile"}
					handleDismiss={handleDismissFeedback}
					feedbackSubtitle={defaultFeedbackSubtitle}
				/>
			</Box>
		</>
	);
};

export default FeedbackRoute;
