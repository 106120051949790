import type { Bundle } from "@memorang/types/src/bundle";
import ProductIcon from "components/ProductIcon";
import { useState } from "react";
import { Button, type ButtonProps, List, Text } from "react-native-paper";
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../../analytics";

type Props = {
	id: string;
	title: string;
	description: string;
	imageUrl: string;
	code: string;
	numCodes: number;
	bundle: Bundle;
	initialExpanded?: boolean;
	buttonText?: string;
	buttonMode?: ButtonProps["mode"];
	handleButtonClick?: (code: string, bundle: Bundle) => void;
	children?: React.ReactNode;
};

const Accordion = ({
	id,
	title,
	description,
	imageUrl,
	code,
	numCodes,
	bundle,
	initialExpanded = false,
	buttonText = "Redeem",
	buttonMode = "outlined",
	handleButtonClick,
	children,
}: Props) => {
	const [expanded, setExpanded] = useState(initialExpanded);
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	const handlePress = () => {
		setExpanded(!expanded);

		trackRedeemEvent(rcEvents.chatOpened, {
			interactionType: "manual",
			interactionElementType: "button",
			productName: title,
			productType: description,
		});
	};

	return (
		<List.Accordion
			id={id}
			title={<Text variant="titleMedium">{title}</Text>}
			description={<Text variant="labelSmall">{description}</Text>}
			titleNumberOfLines={2}
			left={(props) => (
				<List.Icon
					icon={() => <ProductIcon image={imageUrl} size={48} />}
					{...props}
				/>
			)}
			right={code || children ? undefined : () => null}
			onPress={handlePress}
			expanded={expanded}
		>
			{children}
			{code && (
				<List.Item
					key={code}
					title={
						<Text variant="labelSmall">Access Codes ({numCodes} found)</Text>
					}
					description={<Text variant="bodyLarge">{code}</Text>}
					descriptionStyle={{ marginTop: 4 }}
					style={{ marginBottom: 16 }}
					right={(props) => (
						<Button
							{...props}
							mode={buttonMode}
							onPress={() => handleButtonClick?.(code, bundle)}
						>
							<Text variant="labelSmall">{buttonText}</Text>
						</Button>
					)}
				/>
			)}
		</List.Accordion>
	);
};

export default Accordion;
