/* -----------------Globals--------------- */
import { useState } from "react";

/* -----------------Helpers & Hooks--------------- */
import { router, useLocalSearchParams } from "expo-router";

/* -----------------Types--------------- */
import type { AccessCodeResult } from "@features/auth/relay/applyAccessCode";

import Box from "@memorang/ui/src/components/Box";
import ScrollContainer from "@memorang/ui/src/components/ScrollContainer";

/* -----------------Child components--------------- */
import RedeemCodeContainer from "./RedeemCodeContainer";
import RedeemCodeSuccessContainer from "./RedeemCodeSuccessContainer";

const RedeemCodeRouter = () => {
	const params = useLocalSearchParams<{
		step: string;
	}>();

	const currentStepParam = params?.step!;
	const [accessCodeResult, setAccessCodeResult] = useState<
		AccessCodeResult | undefined
	>(undefined);

	const handleNext = (result: AccessCodeResult) => {
		setAccessCodeResult(result);
		router.setParams({ step: "success" });
	};

	const lookUp: Record<string, React.ReactNode | null> = {
		redeem: <RedeemCodeContainer handleNext={handleNext} />,
		success: accessCodeResult ? (
			<RedeemCodeSuccessContainer accessCodeResult={accessCodeResult} />
		) : null,
	};

	return (
		<ScrollContainer maxWidth="xs">
			<Box paddingTop={24}>{lookUp[currentStepParam] || lookUp.redeem}</Box>
		</ScrollContainer>
	);
};

export default RedeemCodeRouter;
