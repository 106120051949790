/* -----------------UI--------------- */
import { Button, Dialog, Text } from "react-native-paper";

import { useAppTheme } from "@hooks/useAppTheme";
/* -----------------Child components--------------- */
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import { ScrollView } from "react-native";

type Props = {
	visible: boolean;
	title: string;
	icon?: string;
	body: string;
	dismissCallback?: () => void;
	dismissCta?: string;
	confirmCallback?: () => void;
	confirmCta?: string;
	actions?: React.ReactNode;
	subtitle?: string;
	maxWidth?: unknown;
	showCloseButton?: boolean;
	confirmCtaLoading?: boolean;
	subtitleComponent?: React.ReactNode;
};
export const ConfirmDialog = ({
	visible,
	title,
	icon,
	body,
	dismissCallback,
	dismissCta,
	confirmCallback,
	confirmCta,
	actions,
	subtitle,
	maxWidth,
	showCloseButton = true,
	confirmCtaLoading,
	subtitleComponent,
}: Props) => {
	const theme = useAppTheme();
	const showIcon = !!icon;
	const textAlign = icon ? "center" : "left";
	const showDismissButton = !!dismissCta;
	const showConfirmButton = !!confirmCta;
	return (
		<DialogWrapper
			maxWidth={maxWidth}
			visible={visible}
			handleClose={dismissCallback}
			showCloseButton={showCloseButton}
		>
			{showIcon && <Dialog.Icon icon={icon} />}
			<Dialog.Title style={{ textAlign }}>{title}</Dialog.Title>
			<Dialog.ScrollArea
				theme={{
					colors: {
						surfaceVariant: "transparent",
					},
				}}
			>
				<ScrollView showsVerticalScrollIndicator={false}>
					{subtitle && (
						<Text variant="titleSmall" style={{ textAlign, marginBottom: 16 }}>
							{subtitle}
						</Text>
					)}
					<MarkdownRenderer text={body} />
					{subtitleComponent}
				</ScrollView>
			</Dialog.ScrollArea>
			<Dialog.Actions>
				{actions || (
					<>
						{showDismissButton && (
							<Button
								onPress={dismissCallback}
								labelStyle={{ color: theme.colors.onSurfaceVariant }}
							>
								{dismissCta}
							</Button>
						)}
						{showConfirmButton && (
							<Button
								disabled={confirmCtaLoading}
								loading={confirmCtaLoading}
								onPress={confirmCallback}
							>
								{confirmCta}
							</Button>
						)}
					</>
				)}
			</Dialog.Actions>
		</DialogWrapper>
	);
};
