/* -----------------UI--------------- */
import Dialog, { type DialogProps } from "@mui/material/Dialog";
import { IconButton } from "react-native-paper";

type Props = {
	visible: boolean;
	handleClose?: () => void;
	maxWidth?: DialogProps["maxWidth"];
	children?: React.ReactNode;
	showCloseButton?: boolean;
	height?: number | string;
};
export const DialogWrapperWeb = ({
	visible,
	handleClose,
	children,
	maxWidth,
	showCloseButton = true,
	height,
}: Props) => {
	return (
		<Dialog
			open={visible}
			fullWidth
			onClose={handleClose}
			maxWidth={maxWidth || "sm"}
			PaperProps={{
				sx: {
					borderRadius: 7,
					position: "relative",
					height: height || "auto",
					minHeight: "150px",
					backgroundImage: "none",
				},
			}}
		>
			{showCloseButton && (
				<IconButton
					icon={"close"}
					onPress={handleClose}
					style={{
						position: "absolute",
						right: 8,
						top: 6,
						zIndex: 1000,
					}}
				/>
			)}
			{children}
		</Dialog>
	);
};
