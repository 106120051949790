import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { useAppStore } from "@hooks/useAppStore";
import { trackCustomEvent } from "analytics/index";
import { useEffect, useMemo } from "react";
import { useRedeemCopilotChat } from "../../context";
import { prepareTrackingProperties } from "../helpers/prepareProperties";
import type {
	RedeemCopilotEvent,
	RedeemCopilotEventProperties,
	SensitiveProperties,
} from "../types";

export const useRedeemCopilotAnalytics = (
	params?: RedeemCopilotEventProperties,
	runOnMount = false,
) => {
	const { currentExamContext } = useExamContext();
	const tenantId = useAppStore((store) => store.tenant.id);
	const tenantReadableName = useAppStore((store) => store.tenant.name);

	const { userDetails, viewerId } = useAuthContext();

	const { chatState } = useRedeemCopilotChat();

	const baseProperties = useMemo(
		() => ({
			examId: currentExamContext.examId,
			examName: currentExamContext.examName,
			studyPackProductNumericId:
				currentExamContext.studyPackProductNumericId?.toString(),
			practiceTestProductNumericId:
				currentExamContext.practiceTestProductNumericId?.toString(),
			examDate: currentExamContext.examDate,
			tenantId,
			tenantReadableName,
			userId: viewerId,
			username: userDetails?.name || "",
			userEmail: userDetails?.email || "",
			chatOpen: chatState.openChat?.toString(),
			lastRedeemedBundleId: chatState.lastRedeemedBundleId,
		}),
		[
			currentExamContext,
			tenantId,
			tenantReadableName,
			chatState,
			viewerId,
			userDetails,
		],
	);

	useEffect(() => {
		if (runOnMount && params) {
			trackCustomEvent(
				prepareTrackingProperties(baseProperties, params, {
					accessCode: chatState.accessCode,
					lastRedeemedAccessCode: chatState.accessCode,
				}),
			);
		}
	}, [runOnMount, params, baseProperties, chatState]);

	const trackRedeemEvent = (
		eventName: RedeemCopilotEvent,
		properties: RedeemCopilotEventProperties,
		sensitiveData?: SensitiveProperties,
	) => {
		trackCustomEvent({
			eventName,
			...prepareTrackingProperties(baseProperties, properties, {
				accessCode: sensitiveData?.accessCode,
				lastRedeemedAccessCode: chatState.accessCode,
			}),
		});
	};

	return { trackRedeemEvent };
};

export type RedeemCopilotAnalytics = ReturnType<
	typeof useRedeemCopilotAnalytics
>;
