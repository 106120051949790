/* -----------------Globals--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppStore } from "@hooks/useAppStore";
import { useAppTheme } from "@hooks/useAppTheme";
import Box from "@memorang/ui/src/components/Box";
import MemorangIcons from "components/MemorangIcons";
import type { IconType } from "components/types/icon";
import { Tabs } from "expo-router";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const AppBottomTabs = () => {
	const theme = useAppTheme();
	const appFont = useAppStore((store) => store.app.font);

	const tabs = useAppStore((store) => store.navigation.routes.main);
	const showStudyPackAsHome = useAppStore((store) => store.showStudyPackAsHome);
	const insets = useSafeAreaInsets();

	return (
		<Tabs
			screenOptions={{
				headerShown: false,
				tabBarActiveTintColor: theme.colors.onSecondaryContainer,
				tabBarStyle: {
					height: 80 + insets.bottom,
					borderTopWidth: 0,
					backgroundColor: theme.colors.elevation.level2,
					paddingTop: 16,
				},
				tabBarLabelStyle: {
					fontFamily: `${appFont}-regular`,
					fontSize: 12,
					paddingBottom: 16,
					color: theme.colors.text,
				},
			}}
		>
			{tabs.map(({ name, icon, title, enabled, custom }) => {
				//TODO:Temp fix, information should come from the backend
				const isHomeTab = name === "home";
				if (isHomeTab && showStudyPackAsHome) {
					name = "home";
					title = "Study-Pack";
					icon = { default: "study-pack-outline", selected: "study-pack" };
					custom = true;
				}
				return (
					<Tabs.Screen
						key={name}
						name={name}
						options={{
							...(enabled
								? {}
								: {
										href: null,
									}),
							tabBarIcon: ({ focused, color }) => {
								const selectedIcon = icon.selected as IconType;
								const defaultIcon = icon.default as IconType;
								return (
									<Box
										width={64}
										height={34}
										borderRadius={34 / 2}
										backgroundColor={
											focused ? theme.colors.secondaryContainer : "transparent"
										}
										alignItems="center"
										justifyContent="center"
										marginBottom={12}
									>
										{custom ? (
											<MemorangIcons
												name={focused ? selectedIcon : defaultIcon}
												size={24}
												color={color}
											/>
										) : (
											<Icon
												name={focused ? selectedIcon : defaultIcon}
												size={24}
												color={color}
											/>
										)}
									</Box>
								);
							},

							tabBarLabel: title,
						}}
					/>
				);
			})}
			<Tabs.Screen
				name="practice-tests"
				options={{
					href: null,
				}}
			/>
		</Tabs>
	);
};
