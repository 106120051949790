import * as SentryReact from "@sentry/react";
import * as SentryNative from "@sentry/react-native";
/* -----------------Globals--------------- */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { isAuthenticated } from "@memorang/helpers/src/amplify/amplifyAuth";
import { useGlobalSearchParams } from "expo-router";
/* -----------------Functions--------------- */
import { Platform } from "react-native";
import fetchUserDetails from "relay/user/FetchUserDetails";
/* -----------------Type declarations--------------- */
export type UserDetails = {
	name: string;
	email: string;
	iconUrl?: string;
	isPremiumUser?: boolean;
};
type AuthContextValue = {
	isAuthenticated: boolean;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	user?: any;
	refreshAuth: () => void;
	setUserInfo: (
		latestAvatarUrl?: string,
		setShowAvatarUpdated?: (value: boolean) => void,
	) => Promise<void>;
	viewerId?: string;
	loading: boolean;
	userDetails?: UserDetails;
	email?: string;
};

// constants
const initialContextValue: AuthContextValue = {
	isAuthenticated: false,
	user: null,
	refreshAuth: () => {
		throw new Error("refreshAuth is not implemented");
	},
	setUserInfo: () => {
		throw new Error("setUserInfo is not implemented");
	},
	viewerId: undefined,
	loading: false,
	userDetails: undefined,
	email: undefined,
};

// context
const AuthContext = React.createContext<AuthContextValue>(initialContextValue);

const setSentryUser = (
	user: {
		username: string;
		attributes: Record<string, string>;
	},
	app: string,
) => {
	const logicalIdKey = "custom:logicalId";
	const {
		username,
		attributes: { [logicalIdKey]: id, email, preferred_username: pUserName },
	} = user;
	const sentryInstance = Platform.OS === "web" ? SentryReact : SentryNative;
	sentryInstance.setTag("app", app);
	sentryInstance.setUser({
		id,
		email,
		username: pUserName || username,
	});
};

/* -----------------Component--------------- */
const AuthProvider = ({
	children,
	identifyMixpanelUser,
	app,
}: {
	children: React.ReactNode;
	identifyMixpanelUser: (viewerId: string) => void;
	app: string;
}) => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const { auth_token } = useGlobalSearchParams<{
		auth_token: string;
	}>();
	const [userDetails, setUserDetails] = useState<
		| {
				name: string;
				email: string;
				iconUrl?: string;
				isPremiumUser?: boolean;
		  }
		| undefined
	>(undefined);

	const setUserInfo = useCallback(
		async (
			latestAvatarUrl?: string,
			setShowAvatarUpdated?: (value: boolean) => void,
		) => {
			if (user) {
				if (latestAvatarUrl) {
					setUserDetails((prev) => {
						if (prev) {
							return {
								...prev,
								iconUrl: latestAvatarUrl,
							};
						}
					});
					setShowAvatarUpdated?.(true);
				} else {
					const details = await fetchUserDetails();
					setUserDetails(details);
				}
			}
		},
		[user],
	);

	useEffect(() => {
		if (!auth_token) {
			setUserInfo();
		}
	}, [setUserInfo, auth_token]);
	const refreshAuth = useCallback(async () => {
		try {
			const result = await isAuthenticated();
			if (result && Object.keys(result).length > 0) {
				setSentryUser(
					result as {
						username: string;
						attributes: Record<string, string>;
					},
					app,
				);
				//@ts-ignore
				const viewerId = result ? result.attributes["custom:logicalId"] : "";
				identifyMixpanelUser(viewerId);
			}
			//@ts-ignore
			setUser(result);

			return !!result;
		} catch (_er) {
			setUser(null);
			return false;
		} finally {
			setLoading(false);
		}
	}, [app, identifyMixpanelUser]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		refreshAuth();
	}, []);

	const contextValue = useMemo(() => {
		return {
			isAuthenticated: !!user,
			user,
			refreshAuth,
			//@ts-ignore
			viewerId: user ? user.attributes["custom:logicalId"] : "",
			loading,
			userDetails,
			setUserInfo,
			//@ts-ignore
			email: user?.attributes.email || "",
		};
	}, [user, refreshAuth, loading, userDetails, setUserInfo]);

	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	);
};

const useAuthContext = () => {
	const context = React.useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuthContext must be used within a AuthProvider");
	}
	return context;
};
export { AuthProvider, useAuthContext };
