/* -----------------Globals--------------- */
import { ActivityIndicator, Modal, Platform } from "react-native";

/* -----------------Third parties--------------- */
import ImageViewer from "react-native-image-zoom-viewer";

/* -----------------UI--------------- */
import { IconButton, Portal, Text } from "react-native-paper";

import { useState } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";
import MarkdownRenderer from "../MarkdownRenderer";

/* -----------------Props--------------- */
type Props = {
	images: { url: string; caption?: string }[];
	handleClose: () => void;
	visible: boolean;
	initialIndex?: number;
};

type Position = Parameters<
	NonNullable<React.ComponentProps<typeof ImageViewer>["onMove"]>
>[0];

const Caption = ({ text, isZoomed }: { text: string; isZoomed: boolean }) => {
	const { bottom } = useSafeAreaInsets();
	const theme = useAppTheme();

	return (
		<Box paddingHorizontal={16} bottom={bottom}>
			<Text
				style={{
					textAlign: "center",
					fontSize: 14,
					fontWeight: isZoomed ? "bold" : "400",
					color: isZoomed
						? theme.colors.primary
						: theme.colors.onSurfaceVariant,
				}}
			>
				{text}
			</Text>
		</Box>
	);
};

/* -----------------Component--------------- */
const ImageViewerModal = ({
	images,
	handleClose,
	visible,
	initialIndex = 0,
}: Props) => {
	const [isZoomed, setIsZoomed] = useState(false);

	const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex);
	const theme = useAppTheme();

	const insets = useSafeAreaInsets();

	if (!visible) {
		return null;
	}

	const handleOnMove = (position: Position) => {
		if (position?.scale && position.scale > 1) {
			setIsZoomed(true);
		} else {
			setIsZoomed(false);
		}
	};

	const handleOnChange = (index?: number) => {
		setCurrentImageIndex(index || 0);
	};

	const caption = images[currentImageIndex]?.caption;

	const showIndicator = images.length > 1;
	const showLeftArrow = currentImageIndex > 0;
	const showRightArrow = currentImageIndex < images.length - 1;

	return (
		<Portal>
			<Modal
				transparent={false}
				style={{
					paddingTop: insets.top,
				}}
			>
				<Box
					height={insets.top}
					width="100%"
					backgroundColor={theme.colors.backdrop}
				/>
				<Box position="absolute" zIndex={100000} left={0} top={insets.top + 8}>
					<IconButton icon="close" size={24} onPress={handleClose} />
				</Box>

				<ImageViewer
					style={{
						// for full screen images, it should have some spacing at the bottom.
						maxHeight: Platform.OS === "web" ? "90%" : "85%",
					}}
					enableImageZoom
					index={currentImageIndex}
					onMove={handleOnMove}
					renderIndicator={() => <></>}
					imageUrls={images}
					renderArrowRight={() =>
						showIndicator && showRightArrow ? (
							<IconButton
								mode="contained-tonal"
								iconColor={theme.colors.onSurfaceVariant}
								icon="chevron-right"
								size={44}
								style={{
									zIndex: 1,
									marginRight: 16,
								}}
							/>
						) : (
							<></>
						)
					}
					onCancel={handleClose}
					onChange={handleOnChange}
					backgroundColor="white"
					loadingRender={() => <ActivityIndicator size="small" />}
					renderArrowLeft={() =>
						showIndicator && showLeftArrow ? (
							<IconButton
								mode="contained-tonal"
								iconColor={theme.colors.onSurfaceVariant}
								icon="chevron-left"
								size={44}
								style={{
									marginLeft: 16,
								}}
							/>
						) : (
							<></>
						)
					}
					onSwipeDown={handleClose}
					enableSwipeDown
					useNativeDriver
					enablePreload
					pageAnimateTime={300}
				/>
				{caption && <Caption text={caption} isZoomed={isZoomed} />}
			</Modal>
		</Portal>
	);
};

export default ImageViewerModal;
