import { zustandStorage } from "@helpers/storage";
import type { BundleDetails } from "@memorang/types/src/bundle";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type State = {
	currentlySelectedBundleDetails?: BundleDetails;
	currentlySelectedBundleDetailsForIAP?: BundleDetails;
	currentlySelectedBundleDetailsForPracticeTests?: BundleDetails;
	setCurrentlySelectedBundleDetails: (bundle: BundleDetails) => void;
	setCurrentlySelectedBundleDetailsForIAP: (bundle: BundleDetails) => void;
	setCurrentlySelectedBundleDetailsForPracticeTests: (
		bundle: BundleDetails,
	) => void;
};

const useBundleStore = create<State>()(
	persist(
		(set) => ({
			setCurrentlySelectedBundleDetailsForIAP: (bundle: BundleDetails) => {
				set({ currentlySelectedBundleDetailsForIAP: bundle });
			},
			setCurrentlySelectedBundleDetailsForPracticeTests: (
				bundle: BundleDetails,
			) => {
				set({ currentlySelectedBundleDetailsForPracticeTests: bundle });
			},

			setCurrentlySelectedBundleDetails: (bundle: BundleDetails) => {
				set({ currentlySelectedBundleDetails: bundle });
			},
		}),
		{
			name: "bundle-studypack-storage",
			storage: createJSONStorage(() => zustandStorage),
		},
	),
);

export default useBundleStore;
