import { useCallback, useEffect, useState } from "react";
import { ENDPOINT_SESSION_ANALYSIS } from "../constants";

type Params = {
	sessionId: string;
	examName: string;
	tenantName: string;
};
export const useSessionAnalysis = ({
	sessionId,
	examName,
	tenantName,
}: Params) => {
	const [sessionAnalysis, setSessionAnalysis] = useState("");
	const [loading, setLoading] = useState(false);
	const [streaming, setStreaming] = useState(false);

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const fetchSessionAnalysis = useCallback(async () => {
		if (sessionId) {
			setLoading(true);
			const response = await fetch(ENDPOINT_SESSION_ANALYSIS, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					sessionId,
					examName,
					tenantName,
				}),
			});
			const reader = await response.body?.getReader();
			let result = "";
			const encoder = new TextDecoder();
			let done = false;
			setLoading(false);
			setStreaming(true);
			while (!done && reader) {
				const { value, done: _done } = await reader.read();
				done = _done;
				if (value) {
					result += encoder.decode(value, { stream: !done });
				}
				setSessionAnalysis(result);
			}
			setStreaming(false);
		}
	}, [sessionId]);

	useEffect(() => {
		fetchSessionAnalysis();
	}, [fetchSessionAnalysis]);
	return {
		sessionAnalysis,
		loading,
		streaming,
	};
};
