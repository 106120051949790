/* -----------------Globals--------------- */
import { router, useLocalSearchParams } from "expo-router";

import { ChooseCategoryContainer } from "../screens/ChooseCategoryContainer";
/* -----------------Child components--------------- */
import ChooseDateContainer from "../screens/ChooseDateContainer";
import { ChooseExamContainer } from "../screens/ChooseExamContainer";
import { RedeemContainer } from "../screens/RedeemContainer";

/* -----------------UI--------------- */
import Box from "@memorang/ui/src/components/Box";

const OnboardingRouteContainer = () => {
	const searchParams = useLocalSearchParams();

	const step = (searchParams?.step || "category") as string;
	const handleUpdateParam = (newStep: string) => {
		router.setParams({
			step: newStep,
		});
	};

	const comps: Record<string, React.ReactNode> = {
		chooseexam: <ChooseExamContainer handleNext={handleUpdateParam} />,
		userdetails: <ChooseDateContainer />,
		redeem: (
			<RedeemContainer
				handleNext={() => {
					handleUpdateParam("category");
				}}
			/>
		),
		category: <ChooseCategoryContainer />,
	};

	return <Box flex={1}>{comps[step]}</Box>;
};

export default OnboardingRouteContainer;
