import type { ResultMessage } from "@copilotkit/runtime-client-gql";
import {
	type ActionName,
	ActionRenderer,
	actionComponents,
} from "./ActionRenderer";

export const ActionRendererContainer = ({
	message,
	loading,
}: {
	message: ResultMessage;
	loading?: boolean;
}) => {
	const validComponent = actionComponents[message.actionName as ActionName];

	if (!validComponent) {
		return null;
	}

	const result = JSON.parse(message.result);

	return (
		<ActionRenderer
			key={message.id}
			actionName={message.actionName as ActionName}
			result={loading ? {} : result}
			status={loading ? "executing" : "complete"}
		/>
	);
};
