import { useCopilotChat } from "@copilotkit/react-core";
import { Role, TextMessage } from "@copilotkit/runtime-client-gql";
import { useRef, useState } from "react";
import { TextInput, type TextInputProps } from "react-native-paper";
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../../analytics";

const Textarea = ({ label, right, ...rest }: TextInputProps) => {
	const [value, setValue] = useState("");
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();
	const messageStartTimeRef = useRef<number | null>(null);

	const { appendMessage } = useCopilotChat();

	const handleMessageSend = (text: string) => {
		if (!text) {
			return;
		}

		if (messageStartTimeRef.current) {
			trackRedeemEvent(rcEvents.messageSent, {
				interactionType: "manual",
				interactionElementType: "text",
				timeToComplete: Date.now() - messageStartTimeRef.current,
			});
		}

		const message = new TextMessage({
			role: Role.User,
			content: text,
		});

		appendMessage(message);
		setValue("");
		messageStartTimeRef.current = null; // Reset timer after sending
	};

	const handleTextChange = (text: string) => {
		setValue(text);
		// Start timing on first character
		if (text.length === 1 && !messageStartTimeRef.current) {
			messageStartTimeRef.current = Date.now();
		}
	};

	const rightIcon = right || (
		<TextInput.Icon
			icon="send"
			size={24}
			forceTextInputFocus
			onPress={() => handleMessageSend(value)}
		/>
	);

	return (
		<TextInput
			autoFocus
			focusable
			mode="outlined"
			blurOnSubmit={false}
			label={label || "Type a message..."}
			style={{ marginHorizontal: 8 }}
			right={rightIcon}
			value={value}
			onChangeText={handleTextChange}
			onKeyPress={(e) => {
				if (e.nativeEvent.key === "Enter") {
					handleMessageSend(value);
				}
			}}
			{...rest}
		/>
	);
};

export default Textarea;
