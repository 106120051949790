/* -----------------UI--------------- */
import { Button, Dialog, Subheading } from "react-native-paper";

import { useAppStore } from "@hooks/useAppStore";
/* -----------------Helpers & Hooks--------------- */
import { restorePurchaseTemplateWeb } from "../helpers";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { buildType } from "@helpers/expo-extras";
import { getPlatform } from "@memorang/helpers/src/common";
import { composeMessage } from "@memorang/helpers/src/email";
/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import { useRouter } from "expo-router";
import { Pressable } from "react-native";

/* -----------------Types--------------- */
type Props = {
	handleDismiss: () => void;
	handleClose: () => void;
};

/* -----------------Component--------------- */
const RestorePurchaseContent = (props: Props) => {
	const { handleDismiss, handleClose } = props;
	const supportEmail = useAppStore((store) => store.supportEmail);
	const body = restorePurchaseTemplateWeb();

	const { user, userDetails } = useAuthContext();
	const username = userDetails?.name || "";

	const email = user?.attributes?.email || "";
	const cta = "Go Back";
	const router = useRouter();
	const handleRedeemAccess = () => {
		router.push("/redeem");
		handleClose();
	};

	const handlePressContactSupport = () => {
		const platform = getPlatform();
		const subject = `${buildType} Support | ${platform} | ${username}`;
		const body = `Hi, my username is ${username} and account email is ${email} and I'm reaching out because:`;
		composeMessage(subject, body, supportEmail);
	};

	return (
		<Box paddingHorizontal={24} gap={16}>
			<Box alignItems="center">
				<Button mode="contained-tonal" onPress={handleRedeemAccess}>
					Have an access code?
				</Button>
			</Box>
			<Subheading>If not, try this:</Subheading>
			<Pressable onPress={handlePressContactSupport}>
				<MarkdownRenderer
					variant="explanation"
					text={body}
					useCustomLink={false}
				/>
			</Pressable>
			<Dialog.Actions>
				<Button onPress={handleDismiss}>{cta}</Button>
			</Dialog.Actions>
		</Box>
	);
};

export default RestorePurchaseContent;
