/**
 * @generated SignedSource<<be15436ea8ab67de4f713db17fc85d43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContentType = "BUNDLE" | "DIAGRAM_SET" | "FOLDER" | "QUESTION_SET" | "READING" | "RECORDING" | "STUDY_SET" | "VIDEO" | "%future added value";
export type CreateSessionType = "CST" | "FLASHCARD" | "MIXED" | "PRACTICE" | "%future added value";
export type LearningOrder = "LINEAR" | "OPTIMIZED" | "SHUFFLED" | "%future added value";
export type MixedSessionMode = "FORMATIVE_SESSION" | "GET_MIXED_SESSION_QUESTIONS" | "SUMMATIVE_SESSION" | "%future added value";
export type ParentType = "BLOCK" | "CST" | "PASSAGE" | "SECTION" | "%future added value";
export type ReportSessionType = "DIAGNOSTIC" | "PRACTICETEST" | "STUDYPACK" | "%future added value";
export type SessionItemType = "ACTION" | "BLOCK" | "CHOICE" | "CST" | "PASSAGE" | "QUESTION" | "SECTION" | "%future added value";
export type SessionVariant = "CST" | "MCQ" | "PASSAGE" | "SATA" | "%future added value";
export type StudySessionContextType = "SAMPLE" | "SELECTION" | "%future added value";
export type CreateSessionInput = {
  action?: MixedSessionMode | null;
  bundleId?: string | null;
  contentId?: string | null;
  contentType?: ContentType | null;
  context?: SessionContextInput | null;
  createSessionType?: CreateSessionType | null;
  demoSession?: boolean | null;
  distribution?: ProgressDistributionInput | null;
  examId?: string | null;
  learningOrder?: LearningOrder | null;
  tags?: ReadonlyArray<ReadonlyArray<string | null> | null> | null;
  userId?: string | null;
};
export type SessionContextInput = {
  bundleId?: string | null;
  contents: ReadonlyArray<StudyContentInput>;
  contextType: StudySessionContextType;
};
export type StudyContentInput = {
  contentId: string;
  contentType: ContentType;
};
export type ProgressDistributionInput = {
  almostThere?: number | null;
  high?: number | null;
  low?: number | null;
  mastered?: number | null;
  medium?: number | null;
  needsWork?: number | null;
  none?: number | null;
  notAnswered?: number | null;
};
export type CreateSessionMutation$variables = {
  request?: CreateSessionInput | null;
};
export type CreateSessionMutation$data = {
  readonly createSession: {
    readonly errors: {
      readonly message: string | null;
      readonly title: string | null;
    } | null;
    readonly examName: string | null;
    readonly id: string | null;
    readonly numericId: number | null;
    readonly reportSessionType: ReportSessionType | null;
    readonly sessionItems: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly children: ReadonlyArray<{
          readonly children: ReadonlyArray<{
            readonly explanation: string | null;
            readonly feedback: string | null;
            readonly id: string;
            readonly isCorrect: boolean | null;
            readonly stem: string | null;
            readonly text: string | null;
          } | null> | null;
          readonly displayName: string | null;
          readonly explanation: string | null;
          readonly feedback: string | null;
          readonly id: string;
          readonly instruction: string | null;
          readonly isCorrect: boolean | null;
          readonly itemType: SessionItemType | null;
          readonly maxChoiceCount: number | null;
          readonly minChoiceCount: number | null;
          readonly points: number | null;
          readonly stem: string | null;
          readonly tags: ReadonlyArray<{
            readonly id: string | null;
            readonly label: string | null;
            readonly type: string | null;
          } | null> | null;
          readonly text: string | null;
          readonly variant: SessionVariant | null;
          readonly video: string | null;
        } | null> | null;
        readonly displayName: string | null;
        readonly explanation: string | null;
        readonly id: string;
        readonly instruction: string | null;
        readonly isCorrect: boolean | null;
        readonly itemType: SessionItemType | null;
        readonly maxChoiceCount: number | null;
        readonly minChoiceCount: number | null;
        readonly numFirstSelections: number | null;
        readonly parentStem: string | null;
        readonly parentType: ParentType | null;
        readonly points: number | null;
        readonly stem: string | null;
        readonly tags: ReadonlyArray<{
          readonly id: string | null;
          readonly label: string | null;
          readonly type: string | null;
        } | null> | null;
        readonly text: string | null;
        readonly variant: SessionVariant | null;
        readonly video: string | null;
      }>;
      readonly containerId: string | null;
      readonly displayName: string | null;
      readonly id: string;
      readonly itemType: SessionItemType | null;
      readonly maxChoiceCount: number | null;
      readonly minChoiceCount: number | null;
      readonly numItems: number | null;
      readonly parentStem: string | null;
      readonly parentType: ParentType | null;
      readonly sessionConfig: any | null;
      readonly stem: string | null;
      readonly tags: ReadonlyArray<{
        readonly id: string | null;
        readonly label: string | null;
        readonly type: string | null;
      } | null> | null;
      readonly variant: SessionVariant | null;
      readonly video: string | null;
    }> | null;
    readonly startTime: any | null;
    readonly totalTime: number | null;
  } | null;
};
export type CreateSessionMutation = {
  response: CreateSessionMutation$data;
  variables: CreateSessionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stem",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "variant",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentStem",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxChoiceCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minChoiceCount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "TagDetails",
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v7/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instruction",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCorrect",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "explanation",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feedback",
  "storageKey": null
},
v20 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "MixedItemSession",
    "kind": "LinkedField",
    "name": "createSession",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numericId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MixedItemSessionError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "examName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reportSessionType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionItem",
        "kind": "LinkedField",
        "name": "sessionItems",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "containerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numItems",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TagDetails",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sessionConfig",
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SessionChildItem",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numFirstSelections",
                "storageKey": null
              },
              (v13/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v14/*: any*/),
              (v12/*: any*/),
              (v8/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SessionChildItem",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v3/*: any*/),
                  (v19/*: any*/),
                  (v4/*: any*/),
                  (v18/*: any*/),
                  (v15/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v14/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SessionChildItem",
                    "kind": "LinkedField",
                    "name": "children",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v16/*: any*/),
                      (v19/*: any*/),
                      (v2/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSessionMutation",
    "selections": (v20/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSessionMutation",
    "selections": (v20/*: any*/)
  },
  "params": {
    "cacheID": "10bbf1381a7217a7cbf26d7d56c915aa",
    "id": null,
    "metadata": {},
    "name": "CreateSessionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSessionMutation(\n  $request: CreateSessionInput\n) {\n  createSession(request: $request) {\n    id\n    numericId\n    errors {\n      title\n      message\n    }\n    examName\n    totalTime\n    reportSessionType\n    startTime\n    sessionItems {\n      id\n      containerId\n      numItems\n      stem\n      itemType\n      variant\n      displayName\n      tags {\n        id\n        type\n        label\n      }\n      sessionConfig\n      parentStem\n      parentType\n      maxChoiceCount\n      minChoiceCount\n      video\n      children {\n        id\n        stem\n        displayName\n        itemType\n        variant\n        parentType\n        numFirstSelections\n        tags {\n          id\n          label\n          type\n        }\n        maxChoiceCount\n        minChoiceCount\n        points\n        video\n        parentStem\n        instruction\n        text\n        isCorrect\n        explanation\n        children {\n          id\n          stem\n          displayName\n          text\n          isCorrect\n          itemType\n          feedback\n          variant\n          explanation\n          instruction\n          maxChoiceCount\n          minChoiceCount\n          points\n          video\n          tags {\n            id\n            label\n            type\n          }\n          children {\n            id\n            text\n            feedback\n            stem\n            isCorrect\n            explanation\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e58a06daba5ab6c611f7344c64fde915";

export default node;
