import { useCopilotAction } from "@copilotkit/react-core";
import type { AccessCodeResult } from "@features/auth/relay/applyAccessCode";
import { REDEEM_CODE_INSTRUCTIONS } from "../constants";
import { useRedeemCopilotChat } from "../context";
import { extractMaskedEmail, isEmailMatch } from "../helpers/string";
import type { ActionHandlerReturn } from "../types";

import { validateCode } from "@helpers/string-utils";
/* --------------- Analytics --------------- */
import {
	type RedeemCopilotAnalytics,
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../analytics";
import type { SetPartialChatState } from "../context/state";

type Handler = ({ code }: { code: string }) => Promise<{
	success: boolean;
	error: string;
	bundleId?: string;
	resultData?: AccessCodeResult;
}>;

type RedeemCodeResult = {
	sameEmail?: boolean;
	maskedEmail?: string;
	bundleId?: string;
	resultData?: AccessCodeResult;
};

const handleRedemptionFailure = ({
	code,
	error,
	bundleId,
	email,
	trackRedeemEvent,
	setPartialChatState,
}: {
	code: string;
	error: string;
	bundleId?: string;
	email: string;
	trackRedeemEvent: RedeemCopilotAnalytics["trackRedeemEvent"];
	setPartialChatState: SetPartialChatState;
}): RedeemCodeResult | undefined => {
	const validationError = validateCode(code);

	trackRedeemEvent(
		rcEvents.codeRedemptionFailed,
		{
			interactionType: "auto",
			interactionElementType: "action",
			errorType: validationError
				? "invalid_access_code_format"
				: "access_code_not_found",
			success: false,
		},
		{ accessCode: code },
	);

	setPartialChatState({
		accessCode: code,
		openChat: true,
		openChatMode: "auto",
		errorMessage: error,
		lastRedeemedBundleId: bundleId,
	});

	const { maskedEmail } = extractMaskedEmail(error);

	if (!(maskedEmail && bundleId)) {
		trackRedeemEvent(
			rcEvents.maskedEmailVerificationAttempted,
			{
				interactionType: "auto",
				interactionElementType: "action",
				errorType: "masked_email_not_found",
				success: false,
			},
			{ accessCode: code },
		);

		return;
	}

	const sameEmail = isEmailMatch(maskedEmail, email);

	trackRedeemEvent(
		rcEvents.maskedEmailVerificationAttempted,
		{
			interactionType: "auto",
			interactionElementType: "action",
			errorType: sameEmail ? undefined : "email_mismatch",
			success: true,
		},
		{ accessCode: code },
	);

	trackRedeemEvent(
		rcEvents.codeRedemptionFailed,
		{
			interactionType: "auto",
			interactionElementType: "action",
			errorType: sameEmail
				? "already_redeemed_by_current_user"
				: "already_redeemed_by_other_user",
			success: false,
		},
		{ accessCode: code },
	);

	return { sameEmail, maskedEmail, bundleId };
};

const handleRedemptionSuccess = ({
	code,
	redeemedResult,
	trackRedeemEvent,
	setPartialChatState,
}: {
	code: string;
	redeemedResult?: AccessCodeResult;
	trackRedeemEvent: RedeemCopilotAnalytics["trackRedeemEvent"];
	setPartialChatState: SetPartialChatState;
}) => {
	trackRedeemEvent(
		rcEvents.codeRedemptionSucceeded,
		{
			interactionType: "auto",
			interactionElementType: "action",
			productType: redeemedResult?.data.bundleType,
			productId: redeemedResult?.data.bundleId,
			productName: redeemedResult?.data.bundleName,
			success: true,
		},
		{ accessCode: code },
	);

	setPartialChatState({
		accessCode: code,
		lastRedeemedBundleId: redeemedResult?.data.bundleId,
		lastRedeemedResult: redeemedResult,
	});

	return { resultData: redeemedResult };
};

export const useRedeemCodeActionTrigger = ({
	handler,
	email,
}: {
	handler: Handler;
	email: string;
}) => {
	const { setPartialChatState } = useRedeemCopilotChat();
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	useCopilotAction({
		name: "redeemCode",
		description:
			"Redeem Access Code, ONLY when user explicitly asks you to do so, OR you suggest it, and user agrees.",
		parameters: [{ name: "code", type: "string", description: "Access Code" }],
		handler: async ({
			code,
		}): Promise<ActionHandlerReturn<RedeemCodeResult>> => {
			const {
				success,
				error,
				bundleId,
				resultData: redeemedResult,
			} = await handler({ code });

			const resultData = success
				? handleRedemptionSuccess({
						code,
						redeemedResult,
						trackRedeemEvent,
						setPartialChatState,
					})
				: handleRedemptionFailure({
						code,
						error,
						bundleId,
						email,
						trackRedeemEvent,
						setPartialChatState,
					});

			return {
				success,
				error,
				instructions: REDEEM_CODE_INSTRUCTIONS,
				resultData,
			};
		},
	});
};
