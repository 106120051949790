/* ----------------- React --------------- */
import { type ReactNode, useEffect, useState } from "react";

/* ----------------- Relay --------------- */
import { fetchReport } from "../relay/FetchReport";

/* ----------------- Auth --------------- */
import { useAuthContext } from "@features/auth/contexts/AuthContext";

/* ----------------- Router --------------- */
import { usePathname } from "expo-router";

/* ----------------- Helpers --------------- */
import { getTopicsTableData } from "../helpers/topics";

/* ----------------- API Client --------------- */
import fetchCumulativeContentsDist from "relay/content/FetchCumulativeContentsDist";

/* ----------------- Features --------------- */
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { isUuid } from "@memorang/helpers/src/common";

type Column = {
	key: string;
	title: string;
};

type Breadcrumb = {
	label: string;
	href?: string;
};
type Row = {
	accuracy: {
		score: number;
	};
	title: string;
	correct: number;
	incorrect: number;
	items: number;
	exam?: number | null | undefined;

	opportunity?: number;
};
type Data = {
	rows: Row[];
	columns: Column[];
	title: string;
	description: string;
	subheader: string;
	breadcrumbs: Breadcrumb[];
	footerData: Record<string, ReactNode>;
};

type Params = {
	id?: string;
	type: string;
	examName?: string;
};
const useDetailedListTopics = ({ id, type, examName }: Params) => {
	const sessionId = isUuid(id) ? id : undefined;
	const sessionNumericId = isUuid(id) ? undefined : Number(id);
	const { viewerId: userId } = useAuthContext();
	const pathname = usePathname();

	const {
		currentExamContext: { examId },
	} = useExamContext();
	const isReportPage = pathname.includes("report");

	const [detailMastertyScreenData, setDetailMasteryScreenData] =
		useState<Data | null>(null);
	const [ascendingMap, setIsAscendingMap] = useState<{
		[key: string]: boolean;
	}>({
		"accuracy.score": true,
	});
	useEffect(() => {
		if (isReportPage) {
			if (userId) {
				fetchReport({
					sessionId: sessionId!,
					numericId: sessionNumericId,
					userId: userId!,
				}).then((data) => {
					const screenData = getTopicsTableData(
						data.tagDetails,
						type,
						sessionNumericId,
						examName,
					);
					setDetailMasteryScreenData(screenData);
				});
			}
		} else {
			fetchCumulativeContentsDist(examId).then(
				({ getMixedItemDistribution: { tags } }) => {
					const screenData = getTopicsTableData(
						tags,
						type,
						sessionNumericId,
						examName,
					);
					setDetailMasteryScreenData(screenData);
				},
			);
		}
	}, [
		examId,
		examName,
		isReportPage,
		sessionId,
		sessionNumericId,
		type,
		userId,
	]);

	const updateRows = (key: string) => {
		setIsAscendingMap({
			[key]: !ascendingMap[`${key}`],
		});

		const rows = detailMastertyScreenData?.rows?.sort((a, b) => {
			const keyOfRow = key as keyof Row;
			const aValue = a[keyOfRow] as number;
			const bValue = b[keyOfRow] as number;
			if (ascendingMap[`${key}`] ? aValue < bValue : aValue > bValue) {
				return 1;
			}
			return -1;
		});

		setDetailMasteryScreenData((prevState) => ({
			...prevState,
			rows: rows || [],
			columns: prevState?.columns || [],
			title: prevState?.title || "",
			description: prevState?.description || "",
			subheader: prevState?.subheader || "",
			breadcrumbs: prevState?.breadcrumbs || [],
			footerData: prevState?.footerData || {},
		}));
	};
	return {
		detailMastertyScreenData,
		ascendingMap,
		updateRows,
	};
};

export default useDetailedListTopics;
