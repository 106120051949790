/**
 * @generated SignedSource<<18df39d3140df909aefe8f9db93733c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DashboardAction = "COMMON_PROGRESS_QUERY" | "GETEXAMS" | "GETEXAMSANDTAGS" | "GETEXAMSRETOOL" | "%future added value";
export type FetchExamsCountQuery$variables = {
  bundleId: string;
  dashboardAction?: DashboardAction | null;
};
export type FetchExamsCountQuery$data = {
  readonly getMixedItemDistribution: {
    readonly exams: ReadonlyArray<{
      readonly hasAccess: boolean | null;
      readonly id: string | null;
      readonly numericId: number | null;
      readonly status: string | null;
      readonly title: string | null;
    } | null> | null;
  } | null;
};
export type FetchExamsCountQuery = {
  response: FetchExamsCountQuery$data;
  variables: FetchExamsCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bundleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dashboardAction"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bundleId",
        "variableName": "bundleId"
      },
      {
        "kind": "Variable",
        "name": "dashboardAction",
        "variableName": "dashboardAction"
      }
    ],
    "concreteType": "MixedItemDistribution",
    "kind": "LinkedField",
    "name": "getMixedItemDistribution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Exam",
        "kind": "LinkedField",
        "name": "exams",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasAccess",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numericId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchExamsCountQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchExamsCountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "23ae5dabb53857273df1ed889c2e1ccc",
    "id": null,
    "metadata": {},
    "name": "FetchExamsCountQuery",
    "operationKind": "query",
    "text": "query FetchExamsCountQuery(\n  $bundleId: ID!\n  $dashboardAction: DashboardAction\n) {\n  getMixedItemDistribution(bundleId: $bundleId, dashboardAction: $dashboardAction) {\n    exams {\n      id\n      status\n      title\n      hasAccess\n      numericId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "45c93bb293e3f00c1f03d84e79874e29";

export default node;
