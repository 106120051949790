/* -----------------Child components--------------- */
import CustomAppbar from "components/CustomAppbar";

/* -----------------Globals--------------- */
import { Stack } from "expo-router";

import { pageViewTypes } from "@constants/tracking";
/* -----------------Types--------------- */
import AccountSettings from "@features/account/screens/AccountSettings";
import useAnalytics from "@hooks/useAnalytics";
import { useDevice } from "@memorang/ui/src/useDevice";
import DocumentTitle from "components/DocumentTitle";

const AccountSettingsRoute = () => {
	const { isMobile } = useDevice();
	useAnalytics({
		type: pageViewTypes.settings,
	});
	return (
		<>
			<Stack.Screen
				options={{
					headerShown: true,
					header: () => (
						<CustomAppbar
							options={{
								headerTitle: "Account Settings",
								subtitle: isMobile ? "" : "Manage your account settings",
								headerRight: () => null,
							}}
							back
						/>
					),
				}}
			/>
			<DocumentTitle title="Account Settings" />
			<AccountSettings />
		</>
	);
};

export default AccountSettingsRoute;
