import { useCopilotAction } from "@copilotkit/react-core";
import { VERIFY_MASKED_EMAIL_INSTRUCTIONS } from "../constants";
import { extractMaskedEmail, isEmailMatch } from "../helpers/string";
import type { ActionHandlerReturn } from "../types";

/* --------------- Analytics --------------- */
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../analytics";

type Result = {
	maskedEmail: string;
	currentEmail: string;
	isSameAccount: boolean;
};

export const useVerifyMaskedEmailActionTrigger = ({
	email,
}: { email: string }) => {
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	useCopilotAction({
		name: "verifyMaskedEmail",
		description: "Verify if a masked email matches the current user's email",
		parameters: [
			{
				name: "errorMessage",
				type: "string",
				description: "Error message containing the masked email",
			},
		],
		handler: ({ errorMessage }): ActionHandlerReturn<Result> => {
			const { maskedEmail } = extractMaskedEmail(errorMessage);

			if (!maskedEmail) {
				trackRedeemEvent(rcEvents.maskedEmailVerificationAttempted, {
					interactionType: "auto",
					interactionElementType: "action",
					errorType: "masked_email_not_found",
					success: false,
				});

				return {
					success: false,
					error: "No masked email found in error message",
					instructions: VERIFY_MASKED_EMAIL_INSTRUCTIONS,
				};
			}

			const isSameAccount = isEmailMatch(maskedEmail, email);

			trackRedeemEvent(rcEvents.maskedEmailVerificationAttempted, {
				interactionType: "auto",
				interactionElementType: "action",
				errorType: isSameAccount ? undefined : "email_mismatch",
				success: true,
			});

			return {
				success: true,
				resultData: {
					maskedEmail,
					currentEmail: email,
					isSameAccount,
				},
				instructions: VERIFY_MASKED_EMAIL_INSTRUCTIONS,
			};
		},
	});
};
