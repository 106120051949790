//----------------------Components--------------------//
export { RedeemCopilotChat } from "./components/Chat";

//----------------------Hooks--------------------//
export { useActionTriggerContext } from "./hooks/useActionTriggerContext";
export { usePurchaseVerificationActionTrigger } from "./hooks/usePurchaseVerificationActionTrigger";
export { useRedeemCodeActionTrigger } from "./hooks/useRedeemCodeActionTrigger";
export { useRedirectToContentActionTrigger } from "./hooks/useRedirectToContentActionTrigger";
export { useContactSupportActionTrigger } from "./hooks/useContactSupportActionTrigger";
export { useNavigateByRouteActionTrigger } from "./hooks/useNavigateByRouteActionTrigger";
export { useVerifyMaskedEmailActionTrigger } from "./hooks/useVerifyMaskedEmailActionTrigger";

//----------------------Constants--------------------//
export {
	COPILOT_URL,
	COPILOT_INSTRUCTIONS,
	COPILOT_CHAT_SUGGESTIONS_INSTRUCTIONS,
	warningMessage,
} from "./constants";

//----------------------Helpers--------------------//
export { getGreetingMessage, getAssistantTitle } from "./helpers/assistant";

//----------------------Context--------------------//
export { useRedeemCopilotChat } from "./context";
