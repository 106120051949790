/* -----------------Child components--------------- */
import { pageViewTypes } from "@constants/tracking";
import SessionContainer from "@features/session/screens/Session";
import { replayManager } from "@helpers/lib/sentry";
import useAnalytics from "@hooks/useAnalytics";
import DocumentTitle from "components/DocumentTitle";

/* -----------------Globals--------------- */
import { Stack, useFocusEffect } from "expo-router";
import { useCallback, useMemo } from "react";

const SessionRoute = () => {
	useFocusEffect(
		useCallback(() => {
			replayManager().stopReplay();
		}, []),
	);

	const eventsObject = useMemo(() => {
		return {
			type: pageViewTypes.session,
		};
	}, []);
	useAnalytics(eventsObject);
	return (
		<>
			<Stack.Screen
				options={{
					headerShown: false,
				}}
			/>
			<DocumentTitle title="Session" />
			<SessionContainer />
		</>
	);
};

export default SessionRoute;
