/* -----------------UI--------------- */
import { Chip } from "react-native-paper";

import Box from "@memorang/ui/src/components/Box";
/* -----------------Utils--------------- */
import { filterChips } from "./constants";

/* -----------------Child components--------------- */

type Props = {
	handleSelectChip: (type: string) => void;
	selectedChip: string;
};
const FilterChips = (props: Props) => {
	const { handleSelectChip, selectedChip } = props;
	return (
		<Box flexDirection="row" justifyContent="center" gap={8}>
			{filterChips.map((item) => {
				const selected = item.type === selectedChip;
				return (
					<Chip
						mode={selected ? "flat" : "outlined"}
						selected={selected}
						key={item.type}
						onPress={() => handleSelectChip(item.type)}
					>
						{item.label}
					</Chip>
				);
			})}
		</Box>
	);
};
export default FilterChips;
