import ChangeAvatarRouteContainer from "@features/profile/containers/ChangeAvatarRouteContainer";

const ChangeAvatarRoute = () => {
	return (
		<>
			<ChangeAvatarRouteContainer />
		</>
	);
};

export default ChangeAvatarRoute;
