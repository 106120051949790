/**
 * @generated SignedSource<<1a52d5d44c3913c1446f73fb99501ec3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccessType = "PREMIUM" | "PRIVATE" | "PUBLIC" | "%future added value";
export type BundleType = "EXAM" | "PRACTICE_TEST" | "STUDY_PACK" | "%future added value";
export type FetchExamDetailsQuery$variables = {
  id?: string | null;
  numericId?: number | null;
  sku?: number | null;
  tenantId?: string | null;
};
export type FetchExamDetailsQuery$data = {
  readonly examDetails: {
    readonly exam: {
      readonly bundles: ReadonlyArray<{
        readonly bundle: {
          readonly accessType: AccessType;
          readonly bundleType: BundleType | null;
          readonly id: string;
          readonly isActive: boolean;
          readonly name: string;
          readonly numericId: number;
          readonly plans: ReadonlyArray<{
            readonly amount: number;
            readonly duration: number;
            readonly id: string;
            readonly interval: string;
          } | null> | null;
        };
      } | null>;
      readonly category: ReadonlyArray<{
        readonly icon: any | null;
        readonly id: string;
        readonly name: string;
      } | null> | null;
      readonly icon: any | null;
      readonly id: string;
      readonly isActive: boolean | null;
      readonly name: string;
      readonly numericId: number;
    };
    readonly id: string;
    readonly metadata: {
      readonly examDate: string | null;
    };
  };
};
export type FetchExamDetailsQuery = {
  response: FetchExamDetailsQuery$data;
  variables: FetchExamDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numericId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sku"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "numericId",
    "variableName": "numericId"
  },
  {
    "kind": "Variable",
    "name": "sku",
    "variableName": "sku"
  },
  {
    "kind": "Variable",
    "name": "tenantId",
    "variableName": "tenantId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ExamMetaData",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numericId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Category",
  "kind": "LinkedField",
  "name": "category",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Bundle",
  "kind": "LinkedField",
  "name": "bundle",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v10/*: any*/),
    (v7/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bundleType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Plan",
      "kind": "LinkedField",
      "name": "plans",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchExamDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ExamDetails",
        "kind": "LinkedField",
        "name": "examDetails",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamContext",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BundleDetails",
                "kind": "LinkedField",
                "name": "bundles",
                "plural": true,
                "selections": [
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "FetchExamDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ExamDetails",
        "kind": "LinkedField",
        "name": "examDetails",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamContext",
            "kind": "LinkedField",
            "name": "exam",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BundleDetails",
                "kind": "LinkedField",
                "name": "bundles",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c85f8b6811dcb0b3340f19dd49924b8f",
    "id": null,
    "metadata": {},
    "name": "FetchExamDetailsQuery",
    "operationKind": "query",
    "text": "query FetchExamDetailsQuery(\n  $id: ID\n  $numericId: Int\n  $tenantId: ID\n  $sku: Int\n) {\n  examDetails(id: $id, tenantId: $tenantId, numericId: $numericId, sku: $sku) {\n    metadata {\n      examDate\n    }\n    id\n    exam {\n      id\n      name\n      icon\n      isActive\n      numericId\n      category {\n        id\n        name\n        icon\n      }\n      bundles {\n        bundle {\n          id\n          numericId\n          name\n          isActive\n          accessType\n          bundleType\n          plans {\n            id\n            duration\n            interval\n            amount\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "31cf60a9c44d768b92b9eafaddd36d79";

export default node;
