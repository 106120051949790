import { router } from "expo-router";
import markdownIt from "markdown-it";
//@ts-ignore
import mdSub from "markdown-it-sub";
//@ts-ignore
import mdSup from "markdown-it-sup";
/* -----------------Global--------------- */
import { Platform, Pressable, StyleSheet, View } from "react-native";
/* -----------------Third parties--------------- */
import Markdown from "react-native-markdown-display";
/* -----------------UI--------------- */
import { Text } from "react-native-paper";
import { type AppTheme, useAppTheme } from "../../useAppTheme";
import { ZoomImage } from "./ZoomImage";
import MathView from "./mathview";
import rules from "./rules";

const normalizeFontSize = (original: number, custom: number) => {
	const defaultFontSize = 14;
	return (original * custom) / defaultFontSize;
};

export type TextAlign =
	| "left"
	| "auto"
	| "right"
	| "center"
	| "justify"
	| undefined;
const getStyles = (
	theme: AppTheme,
	fontSize = 14,
	textAlign: TextAlign = "left",
	customStyles = {},
) =>
	StyleSheet.create({
		// Headings
		heading1: {
			fontSize: normalizeFontSize(32, fontSize),
			lineHeight: normalizeFontSize(32, fontSize) * 1.5,
			marginVertical: normalizeFontSize(32, fontSize) * 0.2,
			fontWeight: "500",
		},
		heading2: {
			fontSize: normalizeFontSize(24, fontSize),
			lineHeight: normalizeFontSize(24, fontSize) * 1.5,
			marginVertical: normalizeFontSize(32, fontSize) * 0.2,
			fontWeight: "500",
		},
		heading3: {
			fontSize: normalizeFontSize(18, fontSize),
			lineHeight: normalizeFontSize(18, fontSize) * 1.5,
			marginVertical: normalizeFontSize(32, fontSize) * 0.2,
			fontWeight: "500",
		},
		heading4: {
			fontSize: normalizeFontSize(16, fontSize),
			lineHeight: normalizeFontSize(16, fontSize) * 1.5,
			fontWeight: "500",
			marginVertical: normalizeFontSize(32, fontSize) * 0.2,
		},
		heading5: {
			fontSize: normalizeFontSize(13, fontSize),
			lineHeight: normalizeFontSize(13, fontSize) * 1.5,
			marginVertical: normalizeFontSize(32, fontSize) * 0.2,
		},
		heading6: {
			fontSize: normalizeFontSize(11, fontSize),
			lineHeight: normalizeFontSize(11, fontSize) * 1.5,
			marginVertical: normalizeFontSize(32, fontSize) * 0.2,
		},
		// Horizontal Rule
		hr: {
			backgroundColor: theme.colors.divider,
		},
		// Blockquotes
		blockquote: {
			backgroundColor: theme.colors.background,
			borderColor: theme.colors.divider,
		},

		// Lists
		bullet_list: {
			fontSize,
			letterSpacing: 0.1,
			lineHeight: fontSize * 1.5,
			marginBottom: fontSize * 0.5,
			color: theme.palette.text.secondary,
		},
		ordered_list: {
			fontSize,
			letterSpacing: 0.1,
			lineHeight: fontSize * 1.5,
			marginBottom: fontSize * 0.5,
			color: theme.palette.text.secondary,
		},
		list_item: {
			...theme.fonts.bodyMedium,
			marginBottom: 4,
			lineHeight: fontSize * 1.5,
			textAlign,
			fontSize,
		},
		// Code
		code_inline: {
			borderWidth: 1,
			borderColor: theme.colors.divider,
			backgroundColor: theme.colors.background,
			padding: 8,
			lineHeight: Platform.OS === "web" ? fontSize * 2.5 : fontSize * 1.5,
			color: theme.palette.text.primary,
		},
		code_block: {
			borderColor: theme.colors.divider,
			backgroundColor: theme.colors.background,
			color: theme.palette.text.primary,
		},
		fence: {
			borderColor: theme.colors.divider,
			backgroundColor: theme.colors.background,
			color: theme.palette.text.primary,
		},

		// Tables
		table: {
			borderColor: theme.colors.divider,
		},
		thead: {
			fontWeight: "bold",
		},
		tr: {
			borderColor: theme.colors.divider,
			padding: 8,
		},
		// Links
		link: {
			textDecorationLine: "underline",
			color: theme.colors.secondary,
		},
		blocklink: {
			borderColor: theme.colors.divider,
		},
		td: {
			padding: 0,
			paddingHorizontal: 4,
		},
		// Text Output
		text: {
			textAlign,
			color: theme.palette.text.primary,
		},
		textgroup: {
			textAlign,
		},
		paragraph: {
			marginTop: normalizeFontSize(10, fontSize),
			marginBottom: normalizeFontSize(10, fontSize),
		},
		// Custom styles
		sup: {
			fontSize: 0.7 * fontSize,
			lineHeight: 0.7 * fontSize * 2,
		},
		subContainer: {
			marginBottom: (-0.7 * fontSize) / 3,
		},
		sub: {
			fontSize: 0.7 * fontSize,
			lineHeight: 0.7 * fontSize,
		},
		image: {},
		...customStyles,
	});

type CustomStyle = {
	[P in keyof ReturnType<typeof getStyles>]?: any;
};

// https://github.com/iamacup/react-native-markdown-display/blob/master/src/lib/renderRules.js
const getRenderRules = (
	fontSize: number,
	textAlign: TextAlign,
	customStyles?: CustomStyle,
	useCustomLink?: boolean,
) => ({
	s: (node: any) => {
		const item = node.children[0]?.content;
		return <MathView math={item} />;
	},

	ins: (_: any, children: any) => (
		<Text
			style={{
				textDecorationLine: "underline",
				fontSize,
			}}
		>
			{children}
		</Text>
	),

	hardbreak: (node: any, _children: any, _: any, styles: any) => (
		<Text key={node.key} style={styles.hardbreak}>
			{"\n"}
		</Text>
	),
	softbreak: (node: any, _children: any, _: any, styles: any) => (
		<Text key={node.key} style={styles.softbreak}>
			{"\n"}
		</Text>
	),

	paragraph: (_node: any, children: React.ReactNode[]) =>
		children.map((item, index) => (
			<Text
				key={index}
				style={{
					textAlign,
					...(customStyles ? customStyles.paragraph : {}),
					fontSize,
					fontFamily: undefined,
					fontWeight: undefined,
				}}
			>
				{item}
			</Text>
		)),

	// text: (node: any) => (
	//   <Text
	//     key={node.key}
	//     style={{
	//       ...customStyles?.text,
	//       fontSize,
	//       fontFamily: undefined,
	//       fontWeight: undefined,
	//       textAlign,
	//     }}
	//   >
	//     {node?.content}
	//   </Text>
	// ),

	...(useCustomLink
		? {
				link: (node: any, children: any, _: any, styles: any) => (
					<Pressable
						key={node.key}
						style={{
							...styles.link,
							fontSize,
						}}
						onPress={() => {
							const href = node.attributes.href;
							const fragment = href.split("#")[1];
							router.push(href as any);
							if (fragment) {
								setTimeout(() => {
									window.location.hash = `${fragment}`;
								}, 500);
							}
						}}
					>
						{children}
					</Pressable>
				),
			}
		: {}),

	sup: (_node: any, children: any, _: any, styles: any) => (
		<View>
			<Text style={styles.sup}>{children}</Text>
		</View>
	),

	sub: (_node: any, children: any, _: any, styles: any) => (
		<View style={styles.subContainer}>
			<Text style={styles.sub}>{children}</Text>
		</View>
	),
	image: (node: any) => {
		const { src } = node.attributes;

		return (
			<ZoomImage
				key={node.key}
				url={src}
				allImages={[
					{
						url: src,
					},
				]}
				{...customStyles?.image}
			/>
		);
	},
});

/* -----------------Props--------------- */
type Props = {
	text?: string;
	fontSize?: number;
	textAlign?: TextAlign;
	variant?: "term" | "fact" | "description" | "stem" | "answer" | "explanation";
	customStyles?: CustomStyle;
	useCustomLink?: boolean;
	overrideRules?: Record<string, any>;
};

/* -----------------Component--------------- */
const RenderMarkdown = (props: Props) => {
	const {
		text,
		fontSize = 14,
		textAlign = "left",
		variant = "explanation",
		customStyles,
		useCustomLink = true,
		overrideRules,
	} = props;

	const theme = useAppTheme();
	const styles = getStyles(theme, fontSize, textAlign, customStyles);

	const finalStyles = {
		...styles,
	};
	const { config, disabled } = rules[variant];
	const md = markdownIt(config);
	md.use(mdSup).use(mdSub);
	md.disable(disabled);

	const renderRules = getRenderRules(
		fontSize,
		textAlign,
		customStyles,
		useCustomLink,
	);
	return (
		// @ts-ignore
		<Markdown
			style={finalStyles}
			markdownit={md}
			rules={{
				...renderRules,
				...overrideRules,
			}}
		>
			{text}
		</Markdown>
	);
};

export default RenderMarkdown;
