import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import { Platform, ScrollView } from "react-native";

type Props = {
	sessionAnalysisData: string;
	bottomContent?: React.ReactNode;
};
const FullReportContent = ({ sessionAnalysisData, bottomContent }: Props) => {
	return (
		<ScrollView
			contentContainerStyle={{
				paddingHorizontal: 16,
				paddingBottom: 24,
			}}
		>
			<MarkdownRenderer
				variant="explanation"
				text={sessionAnalysisData}
				customStyles={{
					textgroup: {
						marginBottom: Platform.OS === "web" ? "1em" : 16,
						// backgroundColor: 'red',
						display: Platform.OS === "web" ? "inline-block" : "flex",
					},
				}}
			/>
			{bottomContent}
		</ScrollView>
	);
};

export default FullReportContent;
