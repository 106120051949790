/* -----------------Globals--------------- */
import { StyleSheet } from "react-native";

/* -----------------Third parties--------------- */
import { VictoryPie } from "victory-native";

/* -----------------UI--------------- */
import { TouchableRipple } from "react-native-paper";
import type { Distribution } from "../../types";
import { type AppTheme, useAppTheme } from "../../useAppTheme";
import Box from "../Box";
import { getColorScale, getProgressRingData } from "./helpers";

/* -----------------Props--------------- */
type Props = {
	innerRadius: number;
	radius: number;
	distribution: Distribution | number[];
	children?: React.ReactNode;
	selected?: boolean;
	handleSelect?: () => void;
	variant?: "mastery" | "progress";
	colorScale?: string[];
	score?: number;
};

/* -----------------Styles--------------- */
const getStyles = (theme: AppTheme) =>
	StyleSheet.create({
		container: {
			justifyContent: "center",
		},
		childContainer: {
			position: "absolute",
			flex: 1,
			alignSelf: "center",
		},
		parent: {
			alignSelf: "center",
			position: "relative",
		},
		selected: {
			backgroundColor: theme.colors.secondary,
			borderRadius: 50,
		},
	});

/* -----------------Component--------------- */
const DonutChart = (props: Props) => {
	const {
		innerRadius,
		radius,
		children,
		distribution,
		selected,
		handleSelect,
		variant = "mastery",
		colorScale,
		score,
	} = props;

	const theme = useAppTheme();

	const styles = getStyles(theme);

	const progressRingData = getProgressRingData(distribution, selected);

	const progressRingColorScale =
		colorScale || getColorScale(theme, selected, variant, score);

	const parentStyle = {
		parent: styles.parent,
	};

	const animatedProp = {
		duration: 1000,
	};
	return (
		<>
			<Box alignItems="center" justifyContent="center" display="flex">
				<>
					<TouchableRipple onPress={handleSelect}>
						<VictoryPie
							data={progressRingData}
							style={parentStyle}
							colorScale={progressRingColorScale}
							innerRadius={selected ? 0 : innerRadius}
							radius={radius}
							animate={animatedProp}
							labels={[]}
							width={radius * 2}
							height={radius * 2}
						/>
					</TouchableRipple>
					<Box
						position="absolute"
						height="100%"
						width="100%"
						alignItems="center"
						justifyContent="center"
					>
						{children}
					</Box>
				</>
			</Box>
		</>
	);
};

export default DonutChart;
