/* ----------------- Globals --------------- */
import { ScrollView } from "react-native";

/* ----------------- UI --------------- */
import CustomChip from "@memorang/ui/src/components/CustomChip";
import TooltipWrapper from "@memorang/ui/src/components/TooltipWrapper";

/* ----------------- Helpers --------------- */
import { isWeb } from "../../helpers/platform";

/* ----------------- Components --------------- */
import type { QuickActionsChipType } from "./types";

/* ----------------- Types --------------- */

type Props = {
	quickActionsChips: QuickActionsChipType[];
	handleQuickActionPress: (action: QuickActionsChipType["type"]) => void;
};
export const QuickActionChips = ({
	quickActionsChips,
	handleQuickActionPress,
}: Props) => {
	return (
		<ScrollView
			horizontal
			showsHorizontalScrollIndicator={false}
			contentContainerStyle={{
				gap: 8,
				paddingHorizontal: 16,
			}}
		>
			{quickActionsChips?.map((chip) => {
				const Content = () => (
					<CustomChip
						icon={chip.icon}
						mode="outlined"
						onPress={() => {
							handleQuickActionPress(chip.type);
						}}
					>
						{chip.label}
					</CustomChip>
				);
				return isWeb ? (
					<TooltipWrapper title={chip.tooltip} key={chip.label}>
						<Content />
					</TooltipWrapper>
				) : (
					<Content key={chip.label} />
				);
			})}
		</ScrollView>
	);
};
