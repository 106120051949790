/* -----------------UI--------------- */
import { FlatList, StyleSheet } from "react-native";
import { TouchableRipple } from "react-native-paper";

import { Image } from "expo-image";
import { useAppTheme } from "../../useAppTheme";
/* -----------------Third parties--------------- */
import { avatarContainerSize, avatarSize } from "./constants";

export type Avatar = {
	id: string;
	url: string;
};

/* -----------------Props--------------- */
type Props = {
	data: Avatar[];
	handleSelectAvatar: (id: string) => void;
	selectedAvatarId?: string;
};

type AvatarItemProps = {
	item: Avatar;
	handleSelectAvatar: (id: string) => void;
	isSelected: boolean;
};

const styles = StyleSheet.create({
	avatar: {
		width: avatarContainerSize,
		height: avatarContainerSize,
		borderRadius: avatarContainerSize / 2,
		margin: 16,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	columnWrapper: {
		justifyContent: "space-around",
	},
});

/* -----------------AvatarItem Component--------------- */
const AvatarItem = ({
	item,
	handleSelectAvatar,
	isSelected,
}: AvatarItemProps) => {
	const theme = useAppTheme();
	return (
		<TouchableRipple
			onPress={() => handleSelectAvatar(item.id)}
			style={[
				styles.avatar,
				{
					backgroundColor: isSelected
						? theme.colors.outlineVariant
						: theme.colors.background,
				},
			]}
			key={item.id}
			borderless
		>
			<Image
				style={{
					width: avatarSize,
					height: avatarSize,
					borderRadius: avatarSize / 2,
				}}
				source={{ uri: item.url }}
			/>
		</TouchableRipple>
	);
};

/* -----------------AvatarList Component--------------- */
const AvatarList = (props: Props) => {
	const { data, handleSelectAvatar, selectedAvatarId } = props;

	const renderItem = ({ item }: { item: Avatar }) => (
		<AvatarItem
			item={item}
			handleSelectAvatar={handleSelectAvatar}
			isSelected={selectedAvatarId === item.id}
		/>
	);

	return (
		<FlatList
			renderItem={renderItem}
			data={data}
			showsVerticalScrollIndicator={false}
			keyExtractor={(item) => item.id}
			numColumns={3}
			initialNumToRender={40}
			columnWrapperStyle={styles.columnWrapper}
		/>
	);
};

export default AvatarList;
