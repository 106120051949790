import { useAppStore } from "@hooks/useAppStore";
import { MD3LightTheme } from "react-native-paper";

const breakpoints = {
	xxs: 400,
	xs: 500,
	sm: 768,
	md: 1024,
	lg: 1280,
};
const componentOverrides = {
	dialog: {
		marginVertical: 48,
		marginHorizontal: 56,
		maxWidth: 560,
		minWidth: 328,
		alignSelf: "center" as const,
	},
	snackbar: {
		maxWidth: 400,
	},
	navDrawer: {
		expanded: {
			width: 360,
		},
		collapsed: {
			width: 80,
		},
	},
};

const appFont = useAppStore.getState().app.font || "opensans";

const fontConfig = {
	...Object.entries(MD3LightTheme.fonts).reduce(
		(acc, [key, value]) => ({
			// biome-ignore lint/performance/noAccumulatingSpread: // TODO fix me later
			...acc,
			[key]: {
				...value,
				fontFamily: `${appFont}-regular`,
			},
		}),
		{},
	),
	labelSmall: {
		fontFamily: `${appFont}-regular`,
		fontSize: 11,
		fontWeight: "500" as const,
		letterSpacing: 0.5,
		lineHeight: 16,
	},
	labelMedium: {
		fontFamily: `${appFont}-semibold`,
		fontSize: 12,
		fontWeight: "500" as const,
		letterSpacing: 0.5,
		lineHeight: 16,
	},

	labelLarge: {
		fontFamily: `${appFont}-regular`,
		fontSize: 14,
		fontWeight: "500" as const,
		letterSpacing: 0.1,
		lineHeight: 20,
	},
	titleSmall: {
		fontFamily: `${appFont}-semibold`,
		fontSize: 14,
		fontWeight: "500" as const,
		letterSpacing: 0.1,
		lineHeight: 20,
	},

	titleMedium: {
		fontFamily: `${appFont}-semibold`,
		fontSize: 16,
		fontWeight: "500" as const,
		letterSpacing: 0.15,
		lineHeight: 24,
	},

	titleLarge: {
		fontFamily: `${appFont}-semibold`,
		fontSize: 22,
		fontWeight: "400" as const,
		letterSpacing: 0,
		lineHeight: 28,
	},
};

export { breakpoints, componentOverrides, fontConfig };
