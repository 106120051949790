export const dialogTexts = {
	prompt: {
		title: "You're on a roll!",
		body: "We're happy to see how much you're studying!\n How are you liking the app?",
	},
	feedback: {
		title: "Help us improve",
		body: "We’re sorry the app isn’t up to your standards and are determined to improve. Would you mind **leaving feedback?**",
	},
	deletefeedback: {
		title: "Delete Account",
		body: "We’re sorry to see you go and wish you the best of luck on your academic and professional journey!",
		options: [
			"Too expensive",
			"Already took my exam",
			"Didn't like the content",
			"Missing features/functionality",
			"Other",
		],
	},
	confirmdelete: {
		title: "Confirm Account Deletion",
	},
};

export const defaultFeedbackSubtitle =
	"What do you like? What could be improved? We make daily improvements to the platform and our team reads every piece of feedback. Thank you!";

export const feedbackEvents = {
	reviewDialogOpened: "Review_Dialog_Opened",
	reviewDialogClosed: "Review_Dialog_Closed",
	reviewNegativeClicked: "Review_Negative_Clicked",
	reviewPositiveClicked: "Review_Positive_Clicked",
	feedbackDialogOpened: "Feedback_Dialog_Opened",
	feedbackDialogClosed: "Feedback_Dialog_Closed",
	feedbackSent: "Feedback_Sent",
	deleteAccountDialogOpened: "Delete_Account_Dialog_Opened",
	deleteAccountDialogDimissed: "Delete_Account_Dialog_Dimissed",
	deleteAccountConfirmShown: "Delete_Account_Confirm_Shown",
	deleteFeedbackSent: "Delete_Feedback_Sent",
	accountDeleted: "Account_Deleted",
};

export const reviewPromptButtons = [
	{
		icon: "🤔",
		label: "Could be better",
		type: "negative" as const,
	},
	{
		icon: "🔥",
		label: "It's awesome",
		type: "positive" as const,
	},
];
