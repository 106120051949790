/* ----------------- React --------------- */
import { useEffect, useState } from "react";

import { AppReviewDialog } from "@features/feedback/components/dialogs/AppReviewDialog";
import { SessionConfigDialog } from "@features/session-config/components/SessionConfigDialog";
import BottomFab from "@memorang/ui/src/components/BottomFab";
/* ----------------- UI --------------- */
import Box from "@memorang/ui/src/components/Box";
import CustomAppbar from "components/CustomAppbar";
import type { QuickActionsChipType } from "components/QuickActionChips/types";
import { HomeView } from "./HomeView";
import AutoRedeemDialog from "./dialogs/AutoRedeemDialog";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { useAppStore } from "@hooks/useAppStore";
/* ----------------- Hooks --------------- */
import { useDevice } from "@hooks/useDevice";
import { useHome } from "../hooks/useHome";

/* ----------------- Helpers --------------- */
import { isWeb } from "@helpers/platform";
import { retrieveKey, storeKey } from "@memorang/helpers/src/storage";
import { getProductItemDescription } from "../helpers/common";

/* ----------------- Queries --------------- */
import fetchActiveCodes, {
	type ActiveCodesResult,
} from "../queries/FetchActiveCodes";

/* ----------------- Router --------------- */
import { useLocalSearchParams, useRouter } from "expo-router";

import { events } from "@constants/tracking";
import CollectUserDetailsDialog from "@features/account/components/dialogs/CollectUserDetailsDialog";
import FreeQuizSessionDialog from "@features/auth/components/dialogs/FreeQuizSessionDialog";
import useBundleStore from "@features/store/hooks/useBundleStore";
import { getShowStudyPackViews } from "@helpers/exams";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
/* ----------------- Analytics --------------- */
import { trackCustomEvent } from "analytics";
import { ExamsFilterDialog } from "components/ExamsFilterDialog";
import { OLXExamEligibilityDialog } from "../../../components/OLXExamEligibilityDialog";
import { PurchaseDialog } from "./PurchaseDialog";

export const HomeRouteContainer = () => {
	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();
	const { isMobile, isMediumWindowSize } = useDevice();
	const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);
	const [showExamsFilterDialog, setShowExamsFilterDialog] = useState(false);

	const { handleExamsFilterNavigation } = useCustomNavigation();

	const toggleShowExamsFilterDialog = () => {
		setShowExamsFilterDialog((prev) => !prev);
	};
	const toggleShowPurchaseDialog = () => {
		setShowPurchaseDialog((prev) => !prev);
	};

	const handleSwitchExam = () => {
		handleExamsFilterNavigation(toggleShowExamsFilterDialog);
	};

	const { startDiagnostic } = useLocalSearchParams<{
		startDiagnostic: string;
	}>();

	const { userDetails } = useAuthContext();
	const tenantId = useAppStore((store) => store.tenant.id);

	const email = userDetails?.email;

	const router = useRouter();

	const setCurrentlySelectedBundleDetailsForIAP = useBundleStore(
		(store) => store.setCurrentlySelectedBundleDetailsForIAP,
	);
	const currentlySelectedBundleDetails = useBundleStore(
		(store) => store.currentlySelectedBundleDetails,
	);

	const showStudyPackViews = getShowStudyPackViews(studyPackProductId);

	const [selectedWeakTags, setSelectedWeakTags] = useState<
		string[] | undefined
	>(undefined);

	const {
		sections,
		headerData,
		quickActionsChips,
		weakTagsIds,
		studyPackSubtitle,
		showPurchaseDialog: showPracticeTestPurchaseDialog,
		toggleShowPurchaseDialog: toggleShowPracticeTestPurchaseDialog,
		olxError,
		setOlxError,
		showCollectUserDetailsDialog,
		toggleShowCollectUserDetailsDialog,
	} = useHome();

	const [showAutoRedeemDialog, setShowAutoRedeemDialog] = useState(false);

	const toggleAutoRedeemDialog = () => {
		setShowAutoRedeemDialog((prev) => !prev);
	};

	const [activeCodesResponse, setActiveCodesResponse] = useState<
		ActiveCodesResult[] | []
	>([]);

	const handleDimissAutoRedeemDialog = () => {
		const currentTime = Date.now();
		storeKey("lastShownRedeemDialog", currentTime);
		toggleAutoRedeemDialog();
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
		const fetchCodes = async () => {
			const lastShownRedeemDialog = retrieveKey("lastShownRedeemDialog");
			const currentTime = Date.now();
			const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

			if (
				!lastShownRedeemDialog ||
				currentTime - Number(lastShownRedeemDialog) > oneDay
			) {
				if (email) {
					const res = await fetchActiveCodes(tenantId, email);
					const filteredRes = isWeb
						? res
						: res.filter(
								(item) =>
									item.bundle.bundleType !== "EXAM" &&
									item.bundle.bundleType !== "PRACTICE_TEST",
							);
					if (filteredRes.length > 0) {
						setActiveCodesResponse(filteredRes);
						toggleAutoRedeemDialog();
					}
				}
			}
		};

		fetchCodes();
	}, [email, tenantId]);

	const handleSessionConfigNavigation = (tagIds: string[]) => {
		if (isWeb) {
			setSelectedWeakTags(tagIds);
		} else {
			router.push({
				pathname: "/session/customize",
				params: {
					selectedTagIds: tagIds,
				},
			});
		}
	};

	const handleQuickActionPress = (action: QuickActionsChipType["type"]) => {
		trackCustomEvent({
			eventName: events.quickActionClicked,
			action,
		});
		switch (action) {
			case "weak":
				handleSessionConfigNavigation(weakTagsIds);
				break;
			case "leaderboard":
				router.push("/(protected)/(tabs)/leaderboard");
				break;
			case "progress":
				router.push("/(protected)/(tabs)/progress");
				break;
			default:
				break;
		}
	};

	const handleQuickStudy = () => {
		trackCustomEvent({
			eventName: events.studyFabClicked,
		});
		router.push("/session/customize");
	};

	const showSessionConfigDialog = selectedWeakTags != null;

	const showBottomFab = (isMobile || isMediumWindowSize) && showStudyPackViews;

	const handleSelectTopic = (topicId: string) => {
		handleSessionConfigNavigation([topicId]);
	};

	const redeemableProducts = activeCodesResponse.map((item) => {
		return {
			id: item.bundle.id,
			icon: item.bundle.icon?.url,
			title: item.bundle.name,
			bundleType: item.bundle.bundleType,
			description: getProductItemDescription(item.bundle.bundleType),
			codes: item.codes,
			features: item.bundle.features,
			isCustomAccess: item.bundle.isCustomAccess,
		};
	});

	// const redeemableProducts = testRedeemableProducts;

	const showFreeQuizDialog = startDiagnostic === "true";

	const handleShowPurchase = () => {
		if (!currentlySelectedBundleDetails) {
			return;
		}
		setCurrentlySelectedBundleDetailsForIAP(currentlySelectedBundleDetails);
		if (isWeb) {
			toggleShowPurchaseDialog?.();
		} else {
			router.push({
				pathname: "/purchase",
				params: {
					productType: "STUDY_PACK",
				},
			});
		}
	};

	return (
		<Box position="relative" flexGrow={1} height={"100%"}>
			<CustomAppbar
				mode={isMobile ? "small" : "medium"}
				align="flex-start"
				showCommandBar={isWeb}
				options={{
					headerTitle: isWeb && isMobile ? "" : "Home",
				}}
				showHambugerMenu
			/>
			<HomeView
				headerData={headerData}
				sections={sections}
				quickActionsChips={quickActionsChips}
				handleQuickActionPress={handleQuickActionPress}
				handleSelectTopic={handleSelectTopic}
				handleSwitchExam={handleSwitchExam}
				handleShowPurchase={handleShowPurchase}
				studyPackSubtitle={studyPackSubtitle}
				showPurchaseDialog={showPracticeTestPurchaseDialog}
				toggleShowPurchaseDialog={toggleShowPracticeTestPurchaseDialog}
			/>
			{showBottomFab && (
				<BottomFab
					icon={"play-outline"}
					label="Quick Study"
					onPress={handleQuickStudy}
					bottom={16}
				/>
			)}

			{showSessionConfigDialog ? (
				<SessionConfigDialog
					visible={showSessionConfigDialog}
					selectedTagIds={selectedWeakTags}
					handleClose={() => setSelectedWeakTags(undefined)}
				/>
			) : null}
			{!isWeb && <AppReviewDialog />}
			{showAutoRedeemDialog && redeemableProducts.length > 0 ? (
				<AutoRedeemDialog
					purchases={redeemableProducts}
					visible={showAutoRedeemDialog}
					onDismiss={handleDimissAutoRedeemDialog}
				/>
			) : null}
			{showFreeQuizDialog && (
				<FreeQuizSessionDialog
					visible={showFreeQuizDialog}
					onClose={() => {
						router.setParams({
							startDiagnostic: "false",
						});
					}}
				/>
			)}
			{showPurchaseDialog && (
				<PurchaseDialog
					visible={showPurchaseDialog}
					handleClose={toggleShowPurchaseDialog}
					productType="STUDY_PACK"
				/>
			)}
			{!!olxError && (
				<OLXExamEligibilityDialog
					visible={!!olxError}
					handleClose={() => setOlxError?.(null)}
					title={olxError?.title}
					message={olxError?.message}
				/>
			)}
			{showCollectUserDetailsDialog && toggleShowCollectUserDetailsDialog && (
				<CollectUserDetailsDialog
					visible={showCollectUserDetailsDialog}
					handleClose={toggleShowCollectUserDetailsDialog}
				/>
			)}
			{showExamsFilterDialog && (
				<ExamsFilterDialog
					visible={showExamsFilterDialog}
					handleClose={toggleShowExamsFilterDialog}
				/>
			)}
		</Box>
	);
};
