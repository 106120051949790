/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------Components--------------- */
import { Confidence } from "@memorang/types/src/session/common";
import Box from "@memorang/ui/src/components/Box";
import { StatItem } from "@memorang/ui/src/components/StatItem";
import type { IconType } from "components/types/icon";

/* -----------------Props--------------- */
type Props = {
	timeTaken: string;
	confidence?: Confidence;
	numAnsweredStat?: string;
	correct?: boolean;
	pointsEarnedStatValue?: string;
};

/* -----------------Component--------------- */
const PostAnswerStats = (props: Props) => {
	const {
		numAnsweredStat,
		confidence,
		timeTaken,
		correct,
		pointsEarnedStatValue,
	} = props;
	const theme = useAppTheme();

	const getIcon = (name: string, color: string) => (
		<Icon name={name as IconType} color={color} size={24} />
	);

	const getConfidenceData = () => {
		switch (confidence) {
			case Confidence.high:
				return {
					text: "High",
					icon: getIcon("emoticon-happy-outline", theme.colors.confidence.high),
				};
			case Confidence.low:
				return {
					text: "Low",
					icon: getIcon("emoticon-sad-outline", theme.colors.confidence.low),
				};
			default: {
				return {
					text: "Medium",
					icon: getIcon(
						"emoticon-neutral-outline",
						theme.colors.confidence.medium,
					),
				};
			}
		}
	};

	const list = [
		{
			icon: correct ? "thumb-up-outline" : "thumb-down-outline",
			label: "ANSWERED",
			value: numAnsweredStat,
			iconColor: correct ? theme.colors.success.main : theme.colors.error,
		},
		...(confidence != null
			? [
					{
						icon: getConfidenceData().icon,
						label: "CONFIDENCE",
						value: getConfidenceData().text,
					},
				]
			: []),
		{
			icon: (
				<Icon
					name="timer-outline"
					size={24}
					color={theme.colors.onSurfaceVariant}
				/>
			),
			label: "SPEED",
			value: timeTaken,
			iconColor: theme.colors.onSurfaceVariant,
		},
		...(pointsEarnedStatValue
			? [
					{
						icon: getIcon("star-outline", theme.colors.onSurfaceVariant),
						label: "POINTS EARNED",
						value: pointsEarnedStatValue,
					},
				]
			: []),
	];

	return (
		<Box flexDirection="row" alignItems="center" justifyContent="space-around">
			{list.map(({ icon: i, label: l, value, iconColor }) => (
				<Box key={l} flexDirection="row" flex={1} justifyContent="center">
					<StatItem icon={i} value={value} overlineText={l} color={iconColor} />
				</Box>
			))}
		</Box>
	);
};

export default PostAnswerStats;
