/* -----------------UI--------------- */
import FreeQuizSessionDialog from "@features/auth/components/dialogs/FreeQuizSessionDialog";
import type { ExamDetails } from "@features/exam/types";
import Box from "@memorang/ui/src/components/Box";
import ScrollContainer from "@memorang/ui/src/components/ScrollContainer";
import { AccessGrantedView } from "components/AccessGrantedView";

/* -----------------Types--------------- */
import type { Purchase } from "components/RedeemPostPurchase";

type Props = {
	examName: string;
	products: Purchase[];
	title: string;
	showFreeQuizDialog: boolean;
	handleCloseFreeQuizDialog: () => void;
	examDetails: ExamDetails;
};

const RedeemCodeSuccessView = ({
	examName,
	products,
	title,
	showFreeQuizDialog,
	handleCloseFreeQuizDialog,
	examDetails,
}: Props) => {
	return (
		<>
			<ScrollContainer maxWidth="xs">
				<Box paddingVertical={16} gap={16}>
					<AccessGrantedView
						products={products}
						examName={examName}
						title={title}
						externalPurchase
						examDetails={examDetails}
					/>
				</Box>
			</ScrollContainer>
			{showFreeQuizDialog && (
				<FreeQuizSessionDialog
					visible={showFreeQuizDialog}
					onClose={handleCloseFreeQuizDialog}
				/>
			)}
		</>
	);
};

export default RedeemCodeSuccessView;
