import { storage } from "@memorang/helpers/src/storage";
import type { MixpanelAsyncStorage } from "mixpanel-react-native";
import type { StateStorage } from "zustand/middleware";

export const zustandStorage: StateStorage = {
	setItem: (name, value) => {
		return storage.set(name, value);
	},
	getItem: (name) => {
		const value = storage.getString(name);
		return value ?? null;
	},
	removeItem: (name) => {
		return storage.delete(name);
	},
};

export const mixpanelStorage: MixpanelAsyncStorage = {
	// biome-ignore lint/suspicious/useAwait: required by mixpanel
	setItem: async (name, value) => {
		return storage.set(name, value);
	},
	// biome-ignore lint/suspicious/useAwait: required by mixpanel
	getItem: async (name) => {
		const value = storage.getString(name);
		return value ?? null;
	},
	// biome-ignore lint/suspicious/useAwait: required by mixpanel
	removeItem: async (name) => {
		return storage.delete(name);
	},
};
