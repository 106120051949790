import DialogSession from "@features/session/components/SessionDialog";
import {
	getBreakDialogConfig,
	getBreakExpiredDialogConfig,
	getBreakOngoingDialogConfig,
} from "@features/session/constants/dialog-configs";
import useSessionStore from "@features/session/hooks/useSessionStore";
import useCountDown from "@features/session/hooks/useTimer";
import { isWeb } from "@helpers/platform";
import Box from "@memorang/ui/src/components/Box";
import Container from "@memorang/ui/src/components/Container";

import CustomAppbar from "components/CustomAppbar";
import { Stack } from "expo-router";
import { useEffect, useState } from "react";
import { Button, Text } from "react-native-paper";

const breakTime = 100;
const SubtitleComponentContainer = () => {
	const breakTimeElapsed = useSessionStore((state) => state.breakTimeElapsed);
	const updateBreakTimeElapsed = useSessionStore(
		(state) => state.updateBreakTimeElapsed,
	);

	const breaks = useSessionStore((state) => state.breaks);

	const firstBreak = breaks?.[0];

	const startTime = firstBreak?.startTime;

	useEffect(() => {
		let interval: NodeJS.Timeout | null = null;
		interval = setInterval(() => {
			const currentTime = Date.now();
			const breakStartTime = startTime
				? new Date(startTime).getTime()
				: currentTime;
			const timeElapsedMs = Math.max(0, currentTime - breakStartTime);
			const timeElapsedSeconds = Math.floor(timeElapsedMs / 1000);
			updateBreakTimeElapsed(timeElapsedSeconds);
		}, 1000);
		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [updateBreakTimeElapsed, startTime]);

	const timeRemaining = breakTime - breakTimeElapsed;

	const { minutes, seconds } = useCountDown(timeRemaining * 1000, true);

	const formattedTime = `${minutes}:${seconds}`;

	return (
		<Box alignItems="center" justifyContent="center" marginTop={16}>
			<Text variant="titleLarge">{formattedTime}</Text>
			<Text
				variant="bodySmall"
				style={{
					fontStyle: "italic",
					fontFamily: "OpenSans-SemiBold",
					letterSpacing: 1.5,
				}}
			>
				Time Remaining
			</Text>
		</Box>
	);
};

const ExpiredBreakDialogContainer = ({
	setShowBreakDialog,
}: {
	setShowBreakDialog: (showBreakDialog: boolean) => void;
}) => {
	const [showExpiredBreakDialog, setShowExpiredBreakDialog] = useState(false);

	const toggleBreakOngoing = useSessionStore(
		(state) => state.toggleBreakOngoing,
	);

	const breakTimeElapsed = useSessionStore((state) => state.breakTimeElapsed);

	const isBreakOngoing = useSessionStore((state) => state.isBreakOngoing);

	const isBreakExpired = breakTimeElapsed >= breakTime;

	useEffect(() => {
		if (isBreakExpired && isBreakOngoing) {
			setShowExpiredBreakDialog(true);
			toggleBreakOngoing(false);
			setShowBreakDialog(false);
		}
	}, [isBreakExpired, isBreakOngoing, toggleBreakOngoing, setShowBreakDialog]);

	return (
		showExpiredBreakDialog && (
			<DialogSession
				customConfig={getBreakExpiredDialogConfig()}
				open={showExpiredBreakDialog}
				handleClickButton={() => setShowExpiredBreakDialog(false)}
				handleClose={() => {
					setShowExpiredBreakDialog(false);
				}}
			/>
		)
	);
};

const BreakDialogContainer = ({
	showBreakDialog,
	setShowBreakDialog,
}: {
	showBreakDialog: boolean;
	setShowBreakDialog: (showBreakDialog: boolean) => void;
}) => {
	const toggleBreakOngoing = useSessionStore(
		(state) => state.toggleBreakOngoing,
	);

	const updateBreakTimeElapsed = useSessionStore(
		(state) => state.updateBreakTimeElapsed,
	);

	const updateBreak = useSessionStore((state) => state.updateBreak);

	const isBreakOngoing = useSessionStore((state) => state.isBreakOngoing);

	const toggleShowBreakDialog = () => {
		setShowBreakDialog(!showBreakDialog);
	};

	useEffect(() => {
		if (isWeb && isBreakOngoing) {
			window.onbeforeunload = () => {
				return "";
			};

			return () => {
				window.onbeforeunload = null;
			};
		}
	}, [isBreakOngoing]);

	const handleClickButton = (type: string) => {
		if (type === "confirm") {
			if (isBreakOngoing) {
				toggleShowBreakDialog();
				toggleBreakOngoing(false);
				updateBreakTimeElapsed(0);
			} else {
				toggleBreakOngoing(true);
				updateBreak({
					startTime: new Date().toISOString(),
					allotedTime: breakTime,
					status: "STARTED",
				});
			}
		} else {
			toggleShowBreakDialog();
		}
	};
	return (
		<>
			{showBreakDialog && (
				<DialogSession
					subtitleComponent={isBreakOngoing && <SubtitleComponentContainer />}
					customConfig={
						isBreakOngoing
							? getBreakOngoingDialogConfig()
							: getBreakDialogConfig(breakTime)
					}
					open={showBreakDialog}
					handleClickButton={handleClickButton}
					showCloseButton={false}
				/>
			)}
			<ExpiredBreakDialogContainer setShowBreakDialog={setShowBreakDialog} />
		</>
	);
};

const SandboxRoute = () => {
	const [showBreakDialog, setShowBreakDialog] = useState(false);

	const toggleShowBreakDialog = () => {
		setShowBreakDialog(!showBreakDialog);
	};

	return (
		<>
			<Stack.Screen
				options={{
					headerShown: true,
					header: () => (
						<CustomAppbar
							options={{
								headerTitle: "Sandbox",
							}}
							back
						/>
					),
				}}
			/>
			<Container
				containerStyle={{
					padding: 16,
				}}
			>
				<Button onPress={() => toggleShowBreakDialog()}>Trigger Break</Button>
				<BreakDialogContainer
					showBreakDialog={showBreakDialog}
					setShowBreakDialog={setShowBreakDialog}
				/>
			</Container>
		</>
	);
};

export default SandboxRoute;
