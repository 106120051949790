import { Card, Divider } from "react-native-paper";
import HeaderCard from "../HeaderCard";

type Props = {
	children: React.ReactNode;
	title: string;
	subtitle: string;
	icon: string;
	actions?: React.ReactNode;
	onPress?: () => void;
	tooltip?: string;
};
const CardContainer = ({
	children,
	title,
	subtitle,
	icon,
	actions,
	onPress,
	tooltip,
}: Props) => {
	return (
		<Card onPress={onPress}>
			<HeaderCard
				tooltip={tooltip}
				title={title}
				subtitle={subtitle}
				icon={icon}
			/>
			<Divider />
			{children}
			{actions}
		</Card>
	);
};

export default CardContainer;
