import { CopilotKit } from "@copilotkit/react-core";
import { ChatContextProvider } from "../../context";
import Messages from "./Messages";
import Popup from "./Popup";
import Textarea from "./Textarea";

type Props = {
	runtimeUrl: string;
	children?: React.ReactNode;
};

export const RedeemCopilotChat = ({ runtimeUrl, children }: Props) => {
	return (
		<CopilotKit runtimeUrl={runtimeUrl} showDevConsole={false}>
			<ChatContextProvider>{children}</ChatContextProvider>
		</CopilotKit>
	);
};

RedeemCopilotChat.Popup = Popup;
RedeemCopilotChat.Messages = Messages;
RedeemCopilotChat.Textarea = Textarea;
