import type { SessionChildItem } from "@memorang/types/src/session/common";

export const getUserPrompt = (
	{
		stem,
		choices,
		explanation,
		topics,
	}: {
		stem: string;
		choices: SessionChildItem[];
		explanation?: string;
		topics?: (string | undefined)[];
	},
	exam: string,
) => {
	const correctChoices = choices.find(({ isCorrect }) => isCorrect);
	const incorrectChoices = choices
		.filter(({ isCorrect }) => !isCorrect)
		.map(({ stem }, index) => `Incorrect_Choice_${index + 1}: ${stem}`)
		.join("\n");
	const topicsArray = topics
		? topics
				.map((topic, index) => `Topic Level ${index + 1}: ${topic}`)
				.join("\n")
		: [];
	const text = `\`\`\`json
    {
      Topic: [${exam},
      ${topicsArray}],
      Correct_Choice: ${correctChoices?.stem},
      ${incorrectChoices},
      Stem: ${stem},
      explanation: ${explanation},
    }
    \`\`\``;

	return text;
};
