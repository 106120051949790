/* ----------------- React Native --------------- */
import type { FlexAlignType } from "react-native";

/* ----------------- React Native Paper --------------- */
import { List, type ListItemProps, Text } from "react-native-paper";

/* ----------------- Internal Types --------------- */
import type { Distribution } from "../../types";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "../../useAppTheme";

/* ----------------- Components --------------- */
import Box from "../Box";
import CustomChip from "../CustomChip";
import DonutChart from "../DonutChart";
import TooltipWrapper from "../TooltipWrapper";

interface Style {
	marginLeft?: number;
	marginRight?: number;
	marginVertical?: number;
	alignSelf?: FlexAlignType;
}
export type MasteryListItemProps = {
	title: string;
	divider?: boolean;
	header?: boolean;
	distribution: Distribution;
	condensed?: boolean;
	locked?: boolean;
	variant?: "child" | "subchild";
	rightText?: string;
	tooltip?: string;
	onPress?: () => void;
} & ListItemProps;

const getRadius = (variant?: "child" | "subchild") => {
	switch (variant) {
		case "child":
			return {
				innerRadius: 10,
				radius: 14,
			};
		case "subchild":
			return {
				innerRadius: 0,
				radius: 12,
			};
		default:
			return {
				innerRadius: 10,
				radius: 16,
			};
	}
};
export const MasteryListItem = (props: MasteryListItemProps) => {
	const {
		title,
		distribution,
		titleNumberOfLines = 2,
		header,
		disabled,
		locked,
		variant,
		description,
		rightText,
		onPress,
		tooltip,
	} = props;

	const { innerRadius, radius } = getRadius(variant);
	const theme = useAppTheme();

	const renderLeft = (p: { color: string; style: Style }) => (
		<>
			{locked ? (
				<List.Icon
					{...p}
					icon="lock-outline"
					style={{ ...p.style, paddingRight: 8 }}
					color={theme.colors.onSurfaceVariant}
				/>
			) : (
				<Box {...p}>
					<DonutChart
						distribution={distribution}
						innerRadius={header ? 10 : innerRadius}
						radius={header ? 18 : radius}
					/>
				</Box>
			)}
		</>
	);

	const renderRight = (p: {
		color: string;
		style?: Style;
	}) =>
		rightText ? (
			<TooltipWrapper title={tooltip || ""}>
				<CustomChip {...p}>{rightText}</CustomChip>
			</TooltipWrapper>
		) : null;
	return (
		<List.Item
			onPress={onPress}
			title={title}
			style={{
				cursor: onPress ? "pointer" : "auto",
			}}
			titleEllipsizeMode="tail"
			description={
				description && (
					<Text variant="bodySmall">{description as React.ReactNode}</Text>
				)
			}
			titleStyle={{
				fontSize: 14,
			}}
			titleNumberOfLines={titleNumberOfLines}
			disabled={disabled}
			left={renderLeft}
			right={renderRight}
		/>
	);
};
