/* -----------------Globals--------------- */
import type { Distribution } from "@memorang/types/src/content";

/* -----------------UI--------------- */
import Box from "@memorang/ui/src/components/Box";

/* -----------------Helpers & Hooks--------------- */
import { distributionFilterItems } from "../constants/options";

import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
import type { CheckboxState } from "../types/filter";
/* -----------------Child components--------------- */
import CheckboxListItem, { type BgColorType } from "./CheckboxListItem";

interface Props {
	selectedCheckboxMap?: Record<string, boolean>;
	handleSelectCheckbox: (checkboxState: CheckboxState) => void;
	distribution: Distribution;
	disableAll?: boolean;
}
const DistributionFilterList = ({
	selectedCheckboxMap,
	handleSelectCheckbox,
	distribution,
	disableAll,
}: Props) => {
	const interceptSelectCheckbox = (distkey: string) => {
		const newCheckboxMap = {
			...(selectedCheckboxMap || {}),
			[distkey]: !selectedCheckboxMap?.[distkey],
		};
		handleSelectCheckbox(newCheckboxMap);
	};
	return (
		<Box>
			<CustomListSubheaderContainer enableGutters>
				Mastery
			</CustomListSubheaderContainer>
			{distributionFilterItems.map(({ distKey, label }, index) => {
				const status = selectedCheckboxMap?.[distKey] ? "checked" : "unchecked";
				return (
					<CheckboxListItem
						title={label}
						key={distKey}
						disableAll={disableAll}
						status={status}
						count={distribution[distKey as keyof Distribution]}
						bgColorType={distKey as BgColorType}
						handleSelectCheckbox={() => interceptSelectCheckbox(distKey)}
						testID={`mastery-checkbox-${index}`}
					/>
				);
			})}
		</Box>
	);
};

export default DistributionFilterList;
