import Box from "@memorang/ui/src/components/Box";
/* -----------------Icons--------------- */
import { useEffect, useState } from "react";
import { Dialog, Portal, Text } from "react-native-paper";
import type { FeedbackParams } from "../../feedback/types";
import { SummaryActions } from "../../qa-search/SummaryActions";
import type { HandleTracking } from "../../types";
import { events } from "../constants";
import type { SessionMetadata } from "../types";
import FullReportContent from "./FullReportContent";

type Props = {
	sessionAnalysisData: string;
	visible: boolean;
	handleDismiss: () => void;
	feedbackDialogParams?: FeedbackParams;
	handleTracking: HandleTracking;
	bottomContent?: React.ReactNode;
	sessionMetadata?: SessionMetadata;
	streaming: boolean;
};

const FullReportDialog = ({
	sessionAnalysisData,
	visible,
	handleDismiss,
	feedbackDialogParams,
	handleTracking,
	bottomContent,
	sessionMetadata,
	streaming,
}: Props) => {
	const [openTime, setOpenTime] = useState(0);

	useEffect(() => {
		if (visible && !streaming) {
			setOpenTime(Date.now());
			handleTracking(events.fullReportViewed);
		}
	}, [handleTracking, visible, streaming]);

	const handleOnDismiss = () => {
		const readingTimeInSeconds = Math.floor((Date.now() - openTime) / 1000);
		handleTracking(events.readingTime, {
			readTime: `${readingTimeInSeconds} seconds`,
		});
		handleDismiss();
	};

	const SessionMetadata = () => {
		if (!sessionMetadata) {
			return null;
		}

		const { sessionDate, sessionTitle, numItems } = sessionMetadata;
		const caption = `${numItems} items, ${sessionDate}`;
		return (
			<Box alignItems="center" paddingBottom={16}>
				<Text>{sessionTitle}</Text>
				<Text
					variant="bodySmall"
					style={{
						fontStyle: "italic",
					}}
				>
					{caption}
				</Text>
			</Box>
		);
	};

	return (
		<Portal>
			<Dialog
				visible={visible}
				onDismiss={handleOnDismiss}
				style={{
					width: "40%",
					alignSelf: "center",
					maxHeight: "90%",
				}}
			>
				<Dialog.Icon icon={"brain"} />
				<Dialog.Title
					style={{
						textAlign: "center",
					}}
				>
					Analysis and Insights
				</Dialog.Title>
				<SessionMetadata />
				<Dialog.ScrollArea
					style={{
						paddingHorizontal: 0,
						paddingTop: 8,
					}}
				>
					<FullReportContent
						bottomContent={bottomContent}
						sessionAnalysisData={sessionAnalysisData}
					/>
				</Dialog.ScrollArea>
				<Dialog.Actions>
					<SummaryActions
						align="flex-end"
						showEmail
						text={sessionAnalysisData}
						handleTracking={handleTracking}
						feedbackDialogParams={feedbackDialogParams}
					/>
				</Dialog.Actions>
			</Dialog>
		</Portal>
	);
};

export default FullReportDialog;
