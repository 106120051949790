/* -----------------Globals--------------- */
import { pageViewTypes } from "@constants/tracking";
import PracticeTestsContainer from "@features/dashboard/screens/PracticeTestsContainer";
import { isWeb } from "@helpers/platform";
import useAnalytics from "@hooks/useAnalytics";
import DocumentTitle from "components/DocumentTitle";
import { Redirect, Stack } from "expo-router";

/* -----------------Child components--------------- */

const DrawerPracticeTestsRoute = () => {
	useAnalytics({
		type: pageViewTypes.practiceTest,
	});
	if (!isWeb) {
		return <Redirect href={"/(protected)/study-pack"} />;
	}
	return (
		<>
			<Stack.Screen
				options={{
					header: () => null,
				}}
			/>

			<DocumentTitle title="Practice Tests" />
			<PracticeTestsContainer />
		</>
	);
};

export default DrawerPracticeTestsRoute;
