import { formatNumber } from "@memorang/helpers/src/common";
import type { FolderContentsItemsCount } from "@memorang/types/src/content";

export const getItemsCountDescription = (
	contentsItemsCount?: FolderContentsItemsCount,
) => {
	if (contentsItemsCount) {
		const items = [
			...(contentsItemsCount.numQuestions
				? [
						{
							label: "questions",
							count: contentsItemsCount.numQuestions,
						},
					]
				: []),
			...(contentsItemsCount.numFacts
				? [
						{
							label: "facts",
							count: contentsItemsCount.numFacts,
						},
					]
				: []),
		];
		return items
			.map(({ label, count }) => `${formatNumber(count)} ${label}`)
			.join(", ");
	}
	return "";
};
