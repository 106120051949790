/* -----------------Globals--------------- */
import { useMemo } from "react";
import { Platform, StyleSheet } from "react-native";

/* -----------------UI--------------- */
import { Text } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../useAppTheme";

/* -----------------Types--------------- */
import type {
	AiAssistantInfo,
	GetMessageActions,
	MessageData,
	ToolInfo,
	VectorDocument,
} from "./types";

/* -----------------Child components--------------- */
import Box from "../Box";
import { CustomListSubheader } from "../CustomListSubheader";
import { FadeView } from "../FadeView";
import { Message } from "./Message";
import { RecommendedChips } from "./RecommendedChips";
import { RecommendedChipsSkeleton } from "./RecommendedChipsSkeleton";
import { References } from "./References";

type Props = {
	assistantInfo: AiAssistantInfo;
	messageActions?: GetMessageActions;
	streaming?: boolean;
	messages: MessageData[];
	recommendedQueries: string[];
	handleSelectQuery: (query: string) => void;
	showRecommendedChipsLoading?: boolean;
	loader?: React.ReactNode;
	handleNavigateToReference?: (url: string) => void;
	showReference?: boolean;
	showSummarizeResponse?: boolean;
	referenceThumbnails?: Record<string, string>;
};

type MessageProps = {
	assistantInfo: AiAssistantInfo;
	type: "human" | "assistant";
	content: string;
	loading?: boolean;
	streaming?: boolean;
	references?: VectorDocument[] | string;
	loader?: React.ReactNode;
	handleNavigateToReference?: (url: string) => void;
	showReference?: boolean;
	messageActions?: GetMessageActions;
	referenceThumbnails?: Record<string, string>;
	runId?: string;
	toolInfo?: ToolInfo | null;
};

const ChatMessage = ({
	type,
	content,
	loading,
	streaming,
	references,
	loader,
	handleNavigateToReference,
	messageActions,
	referenceThumbnails,
	assistantInfo,
	runId,
	toolInfo,
}: MessageProps) => {
	const theme = useAppTheme();
	const styles = StyleSheet.create({
		baseMessage: {
			borderRadius: 24,
		},
		userMessageContainer: {
			alignSelf: "flex-end",
			borderRadius: 16,
			paddingHorizontal: 16,
			borderTopRightRadius: 2,
			borderWidth: 2,
			paddingVertical: 8,
			backgroundColor: theme.colors.primaryContainer,
			borderColor: theme.colors.primary,
			color: theme.colors.onPrimaryContainer,
			maxWidth: "80%",
		},
		assistantMessageContainer: {
			alignSelf: "flex-start",
			borderBottomLeftRadius: 2,
			backgroundColor: theme.colors.secondaryContainer,
			color: theme.colors.onSurface,
			paddingTop: 8,
			// List.Subheader in MessageHeader has paddingVertical: 13
			paddingBottom: 8 + 13,
			overflow: "hidden",
			width: "100%",
			minHeight: 100,
		},
	});

	const shouldShowReferences = useMemo(
		() => references && references.length > 0,
		[references],
	);

	return (
		<Box
			style={[
				styles.baseMessage,
				type === "human"
					? styles.userMessageContainer
					: styles.assistantMessageContainer,
			]}
		>
			{type === "assistant" ? (
				<>
					<Message
						loading={loading}
						content={content}
						streaming={streaming}
						subheader={assistantInfo.alias}
						profileImageUri={assistantInfo.icon}
						isConversationMode
						loader={loader}
						toolbarPosition="top"
						messageActions={messageActions}
						subheaderStyle={{
							fontWeight: "bold",
							color: theme.colors.onSurfaceVariant,
						}}
						runId={runId}
						toolInfo={toolInfo}
					/>
					{shouldShowReferences &&
						(loading || streaming ? (
							<RecommendedChipsSkeleton
								numLines={1}
								width={150}
								paddingHorizontal={16}
								style={{ opacity: 0 }}
							/>
						) : (
							<References
								references={references!}
								handleNavigateToReference={handleNavigateToReference}
								referenceThumbnails={referenceThumbnails}
							/>
						))}
				</>
			) : (
				<Text
					style={[
						{
							fontSize: 14,
						},
						Platform.select({
							web: {
								color: "inherit",
							},
						}),
					]}
				>
					{content}
				</Text>
			)}
		</Box>
	);
};

export const ChatList = ({
	assistantInfo,
	messageActions,
	messages,
	recommendedQueries = [],
	handleSelectQuery,
	showRecommendedChipsLoading = false,
	loader,
	handleNavigateToReference,
	showReference,
	showSummarizeResponse,
	referenceThumbnails,
}: Props) => {
	return (
		<>
			{messages.map(
				(
					{
						type,
						loading,
						content,
						streaming,
						references,
						id,
						runId,
						tools: messageToolInfo,
					},
					index,
				) => (
					<FadeView key={`${id}`} duration={500}>
						<Box gap={16}>
							<Box
								alignItems={type === "human" ? "flex-end" : "flex-start"}
								flexDirection="column"
								maxWidth="100%"
								gap={16}
								paddingHorizontal={16}
							>
								<ChatMessage
									type={type}
									loading={loading}
									content={content}
									streaming={streaming}
									references={references}
									loader={loader}
									handleNavigateToReference={handleNavigateToReference}
									showReference={showReference}
									messageActions={messageActions}
									referenceThumbnails={referenceThumbnails}
									assistantInfo={assistantInfo}
									runId={runId}
									toolInfo={messageToolInfo}
								/>
								{type === "assistant" &&
									index === messages.length - 1 &&
									!loading &&
									!streaming && (
										<CustomListSubheader
											enableGutters={false}
											style={{
												paddingVertical: 0,
												alignSelf: "flex-end",
											}}
										>
											Follow-Ups
										</CustomListSubheader>
									)}
							</Box>
							{type === "assistant" &&
								index === messages.length - 1 &&
								!messages[index].streaming &&
								!messages[index].loading &&
								recommendedQueries.length > 0 && (
									<Box>
										{showRecommendedChipsLoading ? (
											<Box
												flexDirection="row"
												alignItems="center"
												paddingLeft={16}
												gap={12}
											>
												<RecommendedChipsSkeleton
													numLines={1}
													alignSelf="flex-end"
													skeletonHeight={52}
													width="63.5%"
												/>
												<RecommendedChipsSkeleton
													numLines={1}
													alignSelf="flex-end"
													skeletonHeight={52}
													width="36.5%"
												/>
											</Box>
										) : (
											<RecommendedChips
												numberOfLines={3}
												maxChipWidth={275}
												queries={recommendedQueries}
												handleSelectQuery={handleSelectQuery}
												showSummarizeResponse={showSummarizeResponse}
												textVariant="bodyMedium"
												containerProps={{
													paddingHorizontal: 16,
												}}
											/>
										)}
									</Box>
								)}
						</Box>
					</FadeView>
				),
			)}
		</>
	);
};
