import type { Distribution } from "../../types";
import type { AppTheme } from "../../useAppTheme";

type ProgessData = {
	x: string;
	y: number;
};

const getMasteryProgessData = (dist: Distribution): ProgessData[] => {
	const { high, low, medium, none } = dist;
	const sortedDist = {
		high,
		medium,
		low,
		none,
	};
	const tArray = Object.keys(sortedDist).map((item) => ({
		x: item,
		y: dist[item as keyof Distribution],
	}));
	return tArray;
};
const getProgressRingData = (
	distribution: Distribution | number[],
	selected?: boolean,
) => {
	if (!Array.isArray(distribution)) {
		return getMasteryProgessData(distribution);
	}
	if (selected) {
		return [{ x: "select", y: 1 }];
	}
	return [
		{ x: "select", y: distribution[0] },
		{ x: "select", y: distribution[1] },
	];
};

export const getProgressColorScale = (score: number, theme: AppTheme) => {
	const { high, low, none: empty, medium } = theme.colors.progress;
	if (score < 75) {
		return [low, empty];
	}
	if (score < 85) {
		return [medium, empty];
	}
	return [high, empty];
};

const getColorScale = (
	theme: AppTheme,
	selected?: boolean,
	variant?: "progress" | "mastery",
	score?: number,
) => {
	const {
		colors: { progress, secondary },
	} = theme;
	const { high, low, none: empty, medium } = progress;
	if (selected) {
		return [secondary];
	}
	if (variant === "progress") {
		return getProgressColorScale(score || 0, theme);
	}
	return [high, medium, low, empty];
};
export { getProgressRingData, getColorScale };
