const rankStyles = [
	// Gold
	{
		podiumBg: "rgb(255, 224, 131)",
		textColor: "rgb(35, 27, 0)",
		height: 156,
		marginTop: 16,
		avatarSize: 56,
	},
	// Silver
	{
		podiumBg: "rgb(219, 228, 230)",
		height: 128,
		textColor: "rgb(47, 21, 0)",
		marginTop: 16,
		avatarSize: 48,
	},
	// Bronze
	{
		podiumBg: "rgb(255, 220, 195)",
		textColor: "rgb(47, 21, 0)",
		height: 112,
		marginTop: 16,
		avatarSize: 40,
	},
];

const isScreenshotMode = false;

export const testRedeemableProducts = [
	{
		id: "123",
		icon: "https://memorang-prod-icon.s3.amazonaws.com/icon/icon_bundle_45.png",
		title: "NCLEX-RN",
		description: "Study-Pack",
		codes: ["1234", "12345"],
		bundleType: "PRACTICE_TEST",
	},
];

export const blackListedStudypackIdsForWeb = [
	"ec813a37-bb72-43fb-8435-e161bf29f15d",
	"7d2cf2cf-5d2e-4b16-b9f1-9d7cf8c29876",
	"1f3d229e-4dbd-4762-966e-e3eae1d4b13b",
	"335f06bc-a8ac-4359-86bc-b2e96c2476a9",
];
export const defaultMasteryLabels = {
	low: "Needs Work",
	medium: "Almost There",
	high: "Mastered",
	none: "Not Answered",
};
export { rankStyles, isScreenshotMode };
