import { useState } from "react";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { useAppStore } from "@hooks/useAppStore";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import { trackCustomEvent } from "analytics";
import { ScrollView } from "react-native";
/* -----------------UI--------------- */
import { Button, TextInput, useTheme } from "react-native-paper";
import { dialogTexts } from "../constants";
import { sendFeedback } from "../helpers";

import { events } from "@constants/tracking";
/* -----------------Helpers & Hooks--------------- */
import { isAndroid } from "@helpers/platform";

type Props = {
	handleDismiss: () => void;
	view: string;
	feedbackSubtitle?: string;
};
const FeedbackForm = (props: Props) => {
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const [feedback, setFeedback] = useState<string>("");

	const {
		currentExamContext: { examName },
	} = useExamContext();

	const { userDetails } = useAuthContext();

	const companyName = useAppStore((store) => store.companyName);
	const appName = useAppStore((store) => store.app.name);

	const userName = userDetails?.name;
	const email = userDetails?.email;

	const { handleDismiss, view, feedbackSubtitle } = props;

	const isPremiumUser = userDetails?.isPremiumUser;
	const trimmedFeedback = feedback.trim();

	const {
		feedback: { body },
	} = dialogTexts;
	const handleSendFeedback = async () => {
		setLoading(true);
		trackCustomEvent({
			eventName: events.feedbackSubmitted,
			source: "review_dialog",
		});

		const params = {
			userName: userName!,
			email: email!,
			appName,
			feedback: trimmedFeedback,
			view,
			isPremiumUser,
			examName,
			companyName,
			type: "Review" as const,
		};
		try {
			await sendFeedback(params);
		} finally {
			handleDismiss();
			setLoading(false);
		}
	};
	const SendFeedbackFAB = () => (
		<Button
			icon="send"
			uppercase={false}
			mode="contained"
			onPress={handleSendFeedback}
			loading={loading}
			disabled={loading || !trimmedFeedback}
		>
			Send Feedback
		</Button>
	);

	const handleTextChange = (text: string) => {
		setFeedback(text);
	};

	const finalFeedbackSubtitle = feedbackSubtitle || body;
	return (
		<ScrollView
			contentContainerStyle={{
				gap: 16,
				// flexGrow: 1,
			}}
		>
			<MarkdownRenderer textAlign="center" text={finalFeedbackSubtitle} />
			<TextInput
				placeholder="Enter feedback"
				mode="outlined"
				multiline
				autoFocus
				onChangeText={handleTextChange}
				style={{
					backgroundColor: theme.colors.surface,
					minHeight: 176,
					paddingTop: isAndroid ? 16 : "auto",
				}}
				disabled={loading}
			/>
			<SendFeedbackFAB />
		</ScrollView>
	);
};

export default FeedbackForm;
