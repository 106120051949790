import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* ----------------- React Imports --------------- */
import { useMemo } from "react";
import { useWindowDimensions } from "react-native";
/* ----------------- Third Party Imports --------------- */
import { Button, Card, Text } from "react-native-paper";

import type { SESSION_STATUS } from "../../types";
import { useAppTheme } from "../../useAppTheme";
import { useDevice } from "../../useDevice";
import Box from "../Box";
import DonutChart from "../DonutChart";
import { getButtonIconAndLabel } from "./helpers";

/* ----------------- Helper Imports --------------- */
import { calculateCardsToShow } from "@memorang/helpers/src/common";
import FreemiumChip from "./FreemiumChip";
import FreemiumRibbon from "./FreemiumRibbon";

interface Props {
	title: string;
	subtitle?: string;
	status: SESSION_STATUS;
	handleClickTask: () => void;
	body?: string;
	loading?: boolean;
	completion?: number;
	showRibbon?: boolean;
	free?: boolean;
	showReport?: boolean;
}

const TaskCard = ({
	title,
	subtitle,
	status,
	handleClickTask,
	body,
	loading,
	completion = 0,
	showRibbon,
	free,
	showReport = true,
}: Props) => {
	const {
		icon: buttonIcon,
		label: buttonLabel,
		mode: buttonMode,
		progressIcon,
	} = getButtonIconAndLabel(status, showReport)!;
	const theme = useAppTheme();

	const { isMobile, isLargeWindowSize } = useDevice();
	const { width } = useWindowDimensions();
	const cardWidth = useMemo(() => {
		const cardsToShow = calculateCardsToShow(width);
		return (width + (isMobile ? 64 : 2) * (cardsToShow + 1)) / cardsToShow;
	}, [width, isMobile]);

	return (
		<Card
			style={{
				width: cardWidth,
				maxWidth: 400,
				borderColor: theme.colors.surfaceVariant,
				overflow: "hidden",
			}}
			contentStyle={{
				position: "relative",
			}}
			onPress={handleClickTask}
			disabled={loading}
		>
			{isLargeWindowSize && showRibbon && <FreemiumRibbon free={free} />}
			<Card.Title
				title={title}
				right={() => {
					return (
						!isLargeWindowSize && showRibbon && <FreemiumChip free={free} />
					);
				}}
				titleVariant="titleMedium"
				subtitle={subtitle}
				titleStyle={{
					maxWidth: isMobile ? "100%" : 250,
				}}
				subtitleStyle={{
					lineHeight: 14,
					color: theme.colors.neutral,
				}}
				left={(props) => {
					return (
						<Box {...props}>
							<DonutChart
								distribution={[completion, 100 - completion]}
								radius={23}
								variant="progress"
								innerRadius={18}
							>
								<Icon
									name={progressIcon}
									size={24}
									color={theme.colors.onSurfaceVariant}
								/>
							</DonutChart>
						</Box>
					);
				}}
			/>
			{!isMobile && (
				<Card.Content>
					<Text
						numberOfLines={3}
						style={{
							maxWidth: 350,
						}}
						variant="bodyMedium"
					>
						{body}
					</Text>
				</Card.Content>
			)}
			{buttonLabel && (
				<Card.Actions
					style={{
						alignSelf: "flex-start",
						paddingBottom: 16,
					}}
				>
					<Button
						disabled={loading}
						loading={loading}
						icon={buttonIcon}
						mode={buttonMode}
						onPress={handleClickTask}
					>
						{buttonLabel}
					</Button>
				</Card.Actions>
			)}
		</Card>
	);
};

export default TaskCard;
