import { filterPlans } from "@helpers/content/tag";
import { fetchData } from "@memorang/helpers/src/fetchData";
import type { BundleDetails } from "@memorang/types/src/bundle";
import { graphql } from "react-relay";
import relayEnv from "relay/relayenv";

type Response = {
	bundleDetails: BundleDetails;
};
const query = graphql`
  query FetchBundleDetailsQuery($id: ID, $numericId: Int) {
    bundleDetails(id: $id, numericId: $numericId) {
      id
      metadata {
        subscription {
          status
          endDate
        }
      }
      bundle {
        id
        description
        name
        examId
        examName
        icon {
          url
        }
        bundleType
        contentsItemsCount {
          numFacts
          numQuestions
        }
        purchasable
        numericId
        features
        plans {
          id
          amount
          duration
          interval
          alias
          isMobile
        }
      }
    }
  }
`;

const fetchBundleDetails = async (bundleId: number | string) => {
	const variables =
		typeof bundleId === "string" ? { id: bundleId } : { numericId: bundleId };

	const { bundleDetails } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as Response;

	const filteredPlans = filterPlans(bundleDetails.bundle.plans);

	return {
		...bundleDetails,
		bundle: {
			...bundleDetails.bundle,
			plans: filteredPlans,
		},
	};
};

export default fetchBundleDetails;
