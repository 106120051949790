import { API } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";
import { getAwsExportsJson } from "@configs/awsexports";
import { cacheKeys } from "@constants/cache-keys";
import { events } from "@constants/tracking";
import { useAuthContext } from "@features/auth/contexts/AuthContext";
import applyAccessCode from "@features/auth/relay/applyAccessCode";
import { handleExternalAuth } from "@features/auth/relay/handleExternalAuth";
import { usePracticeTests } from "@features/dashboard/hooks/usePracticeTests";
import fetchActiveCodes, {
	type ActiveCodesResult,
} from "@features/dashboard/queries/FetchActiveCodes";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { getUserId, retrieveExamContextFromKV } from "@features/exam/helpers";
import updateExamMeta from "@features/exam/mutations/UpdateExamMetadata";
import { fetchExamDetails } from "@features/exam/queries/FetchExamDetails";
import { fetchExams } from "@features/exam/queries/FetchExams";
import setUpAmplifyConfig from "@memorang/helpers/src/amplify/setUpAmplify";
import type { IconType } from "@memorang/ui/src";
import { useQuery } from "@tanstack/react-query";
import {
	CognitoAccessToken,
	CognitoIdToken,
	CognitoRefreshToken,
	CognitoUser,
	CognitoUserPool,
	CognitoUserSession,
} from "amazon-cognito-identity-js";
import { trackCustomEvent } from "analytics";
import { router, useGlobalSearchParams } from "expo-router";
import { useState } from "react";
import fetchExamsCount from "relay/content/FetchExamsCount";
import type { EXAM_STATUS } from "types/tag";

import { useAppStore } from "./useAppStore";

const finalAwsExports = getAwsExportsJson();

const start_session = "true";
const auto_redeem = "true";

const establishSessionWithAccessToken = async ({
	accessToken,
	email,
	idToken,
	refreshToken,
}: {
	accessToken: string;
	email: string;
	idToken: string;
	refreshToken: string;
}) => {
	try {
		const poolData = new CognitoUserPool({
			UserPoolId: finalAwsExports.UserPoolId,
			ClientId: finalAwsExports.UserPoolClientId,
		});

		const cognitoAccessToken = new CognitoAccessToken({
			AccessToken: accessToken,
		});

		const cognitoIdToken = new CognitoIdToken({
			IdToken: idToken,
		});
		const cognitoRefreshToken = new CognitoRefreshToken({
			RefreshToken: refreshToken,
		});

		const session = new CognitoUserSession({
			IdToken: cognitoIdToken,
			AccessToken: cognitoAccessToken,
			RefreshToken: cognitoRefreshToken,
		});

		const cognitoUser = new CognitoUser({
			Username: email,
			Pool: poolData,
		});

		cognitoUser.setSignInUserSession(session);

		const authuser = await Auth.currentAuthenticatedUser();

		return authuser;
	} catch (error) {
		console.info("Error establishing session with access token:", error);
		return null;
	}
};

const findExistingExamContext = async (
	tenantId: string,
	appId: string,
	userId: string,
) => {
	const cachedExamContext = await retrieveExamContextFromKV(userId);
	if (cachedExamContext?.examId) {
		return cachedExamContext.examId;
	}

	const latestExams = await fetchExams(tenantId, appId);
	const purchasedExam = latestExams.find((item) =>
		item.exam.bundles.some(
			(bundle) => bundle.metadata?.subscription?.status === "ACTIVE",
		),
	);

	return purchasedExam?.exam.id;
};

const handleActiveCodesRedemption = async (
	activeCodesResult: ActiveCodesResult[],
	auto_redeem: string,
	source: string,
	setShowAutoRedeemDialog: (show: boolean) => void,
) => {
	if (!activeCodesResult?.length) {
		return null;
	}

	if (auto_redeem !== "true") {
		setShowAutoRedeemDialog(true);
		return null;
	}

	const accessCode = activeCodesResult[0].codes[0];
	trackCustomEvent({
		eventName: events.promoCodeEntered,
		source,
		examId: accessCode,
	});

	const result = await applyAccessCode(accessCode);

	if (!result.success) {
		return null;
	}

	trackCustomEvent({
		eventName: events.promoCodeRedeemed,
		source,
		examId: result.data.examId,
		code: accessCode,
	});

	return result.data.examId;
};

type LoadingState = {
	key: "accessCode" | "examDetails" | "exams" | "session";
	isLoading: boolean;
};

const getLoadingMessages = (
	states: LoadingState[],
): { text: string; icon: IconType }[] => {
	const messageMap: Record<
		LoadingState["key"],
		{ text: string; icon: IconType }
	> = {
		accessCode: { text: "Activating your access", icon: "lock-open-outline" },
		examDetails: {
			text: "Fetching exam information",
			icon: "clipboard-outline",
		},
		exams: { text: "Loading practice tests", icon: "book-open-outline" },
		session: { text: "Securely logging you in", icon: "shield-check-outline" },
	};

	return states
		.filter((state) => state.isLoading)
		.map((state) => messageMap[state.key]);
};

const useDeeplinkHandling = () => {
	const tenantId = useAppStore((state) => state.tenant.id);
	const [showAutoRedeemDialog, setShowAutoRedeemDialog] = useState(false);
	const appId = useAppStore((state) => state.app.id);
	const [isAccessRestricted, setIsAccessRestricted] = useState(false);

	const { refreshAuth } = useAuthContext();

	const {
		handleSwitchExamContext,
		currentExamContext: { practiceTestProductId },
	} = useExamContext();

	const { handlePracticeTestSession, creatingSession } = usePracticeTests();

	const {
		exam_date,
		source,
		auth_token,
		sku: skuFromParams,
	} = useGlobalSearchParams<{
		start_session: string;
		auto_redeem: string;
		exam_date: string;
		source: string;
		auth_token: string;
		sku: string;
	}>();

	const {
		data: email,
		isLoading: isLoadingSession,
		isError: isErrorSession,
	} = useQuery({
		queryKey: ["session", auth_token],
		queryFn: async () => {
			const latestConfig = setUpAmplifyConfig(finalAwsExports, true, false);
			API.configure(latestConfig);
			const result = await handleExternalAuth({
				token: auth_token,
				tenantId,
				appId,
			});
			const accessToken = result?.token.accessToken;
			const idToken = result?.token.idToken;
			const email = result.data.email;
			const refreshToken = result?.token.refreshToken;

			if (accessToken) {
				const authenticatedUser = await establishSessionWithAccessToken({
					accessToken,
					idToken,
					email,
					refreshToken,
				});
				if (authenticatedUser) {
					await refreshAuth();
					const latestConfig = setUpAmplifyConfig(finalAwsExports, true, true);
					API.configure(latestConfig);
					return email;
				}
			}
			return null;
		},
		staleTime: 0,
		enabled: !!auth_token,
		gcTime: 0,
		retry: false,
	});

	const {
		data: examId,
		isLoading: isLoadingApplyAccessCode,
		isError: isErrorApplyAccessCode,
	} = useQuery({
		queryKey: ["redeem_access_code", tenantId, email, auth_token],
		queryFn: async () => {
			if (!email) {
				return;
			}

			const activeCodesResult = await fetchActiveCodes(
				tenantId,
				email,
				skuFromParams ? Number(skuFromParams) : undefined,
			);

			const examIdFromRedemption = await handleActiveCodesRedemption(
				activeCodesResult,
				auto_redeem,
				source,
				setShowAutoRedeemDialog,
			);
			if (examIdFromRedemption) {
				return examIdFromRedemption;
			}

			if (skuFromParams) {
				const examDetails = await fetchExamDetails({
					sku: Number(skuFromParams),
					tenantId,
				});
				return examDetails.exam.id;
			}

			const userId = await getUserId();
			const existingExamId = await findExistingExamContext(
				tenantId,
				appId,
				userId,
			);

			if (!existingExamId) {
				setIsAccessRestricted(true);
				return undefined;
			}

			return existingExamId;
		},
		// enabled: !!tenantId && !!email && !!auth_token && !!viewerId && !!session,
		enabled: !!tenantId && !!email && !!auth_token,
		staleTime: 0,
		gcTime: 0,
		retry: false,
	});

	const { data: examDetails, isLoading: isLoadingExamDetails } = useQuery({
		queryKey: [cacheKeys.examDetails, examId, tenantId, auth_token],
		queryFn: async () => {
			if (!examId) {
				return;
			}
			const result = await fetchExamDetails({
				examId,
				tenantId: tenantId,
			});
			await handleSwitchExamContext({
				...result,
				...(exam_date
					? {
							metadata: {
								examDate: exam_date,
								isActiveExam: true,
							},
						}
					: {}),
			});
			if (exam_date) {
				updateExamMeta({
					id: examId,
					examDate: exam_date,
				});
			}
			return result;
		},
		enabled: !!examId && !!auth_token,
		staleTime: 0,
		gcTime: 0,
	});

	const { isLoading: isLoadingExams } = useQuery({
		queryKey: [cacheKeys.exams, examId, tenantId, auth_token],
		queryFn: async () => {
			if (!examDetails) {
				return;
			}

			const practiceTestProductId = examDetails.exam.bundles.find(
				(bundleDetails) => bundleDetails.bundle.bundleType === "PRACTICE_TEST",
			)?.bundle.id;
			if (!practiceTestProductId) {
				return;
			}
			const result = await fetchExamsCount(practiceTestProductId);
			const [firstExam] = result.exams;

			if (!firstExam.hasAccess) {
				setIsAccessRestricted(true);
				return;
			}
			if (start_session === "true") {
				await handlePracticeTestSession(
					firstExam.id,
					firstExam.status as EXAM_STATUS,
					firstExam.numericId!,
					true,
					true,
					false,
				);
			} else {
				router.push("/practice-tests");
			}
			return result;
		},
		enabled: !!examDetails && !!auth_token && !!practiceTestProductId,
		staleTime: 0,
		gcTime: 0,
	});

	const loadingStates: LoadingState[] = [
		{ key: "accessCode", isLoading: isLoadingApplyAccessCode },
		{ key: "examDetails", isLoading: isLoadingExamDetails },
		{ key: "exams", isLoading: isLoadingExams },
		{ key: "session", isLoading: isLoadingSession },
	];

	const messages = getLoadingMessages(loadingStates);

	const isLoading =
		isLoadingApplyAccessCode ||
		isLoadingExamDetails ||
		isLoadingExams ||
		isLoadingSession;

	const showError = auth_token && (isErrorApplyAccessCode || isErrorSession);
	return {
		creatingSession,
		showAutoRedeemDialog,
		isLoading,
		messages,
		showError,
		email,
		isAccessRestricted,
		setIsAccessRestricted,
	};
};

export default useDeeplinkHandling;
