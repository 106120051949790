/* ----------------- Helpers --------------- */
import { isWeb } from "@helpers/platform";

/* ----------------- UI Components --------------- */
import Box from "@memorang/ui/src/components/Box";
import { VideoPlayer } from "@memorang/ui/src/components/VideoPlayer";
import { useDevice } from "@memorang/ui/src/useDevice";

/* ----------------- Child Components --------------- */
import ItemStem from "./ItemStem";

interface Props {
	stem: string;
	highlightedHtml?: string;
	video?: string;
	parentStem?: string;
	handleStoreHtml?: (html: string) => void;
}
export const StemContainer = ({
	stem,
	highlightedHtml,
	video,
	parentStem,
	handleStoreHtml,
}: Props) => {
	const { isMobile } = useDevice();

	return (
		<Box paddingHorizontal={isWeb && !isMobile ? 0 : 16} gap={8}>
			{parentStem && <ItemStem text={parentStem} />}
			<ItemStem
				text={stem}
				highlightedHtml={highlightedHtml}
				handleStoreHtml={handleStoreHtml}
			/>
			{video && <VideoPlayer videoUrl={video} />}
		</Box>
	);
};
