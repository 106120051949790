/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import ChoicesList from "../../choices/ChoicesList";

/* -----------------Helpers & Hooks--------------- */
import useSessionStore from "../../../hooks/useSessionStore";

import { events } from "@constants/tracking";
import { isWeb } from "@helpers/platform";
import type { SessionChildItem } from "@memorang/types/src/session/common";
/* -----------------Types--------------- */
import { QuestionVariant } from "@memorang/types/src/session/question";
import { useDevice } from "@memorang/ui/src/useDevice";
import { trackCustomEvent } from "analytics";
import SnackBarCustom from "components/CustomSnackbar";
import { useState } from "react";
import { Caption, Portal } from "react-native-paper";
import { sessionMaxWidth } from "../../../constants";
import HintContainer from "../../../containers/HintContainer";
import { StemContainer } from "../../StemContainer";

interface Props {
	item: SessionChildItem;
}
// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
const QuestionItem = ({ item }: Props) => {
	const {
		stem,
		children,
		variant,
		id,
		answers,
		highlight,
		crossOut,
		video,
		maxChoiceCount,
		parentStem,
	} = item;
	const multiSelect = variant === QuestionVariant.sata;

	const { isMobile } = useDevice();

	const updateItemStates = useSessionStore((state) => state.updateItemStates);
	const itemStates = useSessionStore((state) => state.itemStates);
	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);
	const inReviewMode = useSessionStore((state) => state.inReviewMode);
	const blockStates = useSessionStore((state) => state.blockStates);
	const currentBlockIndex = useSessionStore((state) => state.currentBlockIndex);
	const sessionItems = useSessionStore((state) => state.sessionItems);

	const [showMaxChoiceError, setShowMaxChoiceError] = useState(false);
	const updateHighlightedItems = useSessionStore(
		(state) => state.updateHighlightedItems,
	);
	const currentBlock = sessionItems[currentBlockIndex];
	const currentBlockState = blockStates.get(currentBlock?.id);

	const highlightedHtml =
		highlight || currentBlockState?.highlightedItems?.get(id);

	const selectedChoiceIds =
		itemStates?.get(id)?.selectedChoiceIds || answers?.selectedChoiceIds;

	const correctAnswerIds =
		itemStates?.get(id)?.correctAnswerIds ||
		children?.filter((choice) => choice.isCorrect).map((choice) => choice.id);
	const crossedOutChoiceIds =
		itemStates?.get(id)?.crossedOutChoiceIds || crossOut;

	const isAnswered =
		itemStates?.get(id)?.answered ||
		(answers != null && (isSummativeTest ? inReviewMode : true));
	const handleSelectChoice = (choiceId: string, crossingOut?: boolean) => {
		trackCustomEvent({
			eventName: crossingOut
				? events.sessionItemCrossedOut
				: events.sessionItemOptionSelected,
			choiceId,
			itemId: id!,
		});
		if (variant === "SATA" && maxChoiceCount) {
			if (
				selectedChoiceIds &&
				selectedChoiceIds.length >= maxChoiceCount &&
				selectedChoiceIds.indexOf(choiceId) === -1
			) {
				setShowMaxChoiceError(true);
				return;
			}
		}
		// trackCustomEvent({
		//   eventName: crossingOut ? events.crossedOutAnswer : events.answerSelected,
		//   choiceId,
		//   multiSelect: String(multiSelect),
		//   itemId: id,
		//   variant,
		//   stem,
		// });
		updateItemStates({
			itemId: id,
			selectedChoiceId: choiceId,
			multiSelect,
			crossOut: crossingOut,
		});
	};

	// TODO: Instructions different based on type
	// Should come from config and/or backend and/or schema?
	const instructions = "Select the best answer";
	const Instructions = ({ text }: { text: string }) => (
		<Caption style={{ paddingLeft: 0, fontSize: 16 }}>{text}</Caption>
	);

	const handleStoreHtml = (html: string) => {
		updateHighlightedItems(id, html);
	};

	const showHint = !(isSummativeTest || isAnswered);

	return (
		<Box gap={16} height="100%">
			<StemContainer
				stem={stem}
				highlightedHtml={highlightedHtml}
				video={video}
				parentStem={parentStem}
				key={id}
				handleStoreHtml={handleStoreHtml}
			/>
			<Box>
				{!multiSelect && (
					<Box
						paddingHorizontal={isWeb && !isMobile ? 0 : 16}
						paddingVertical={8}
					>
						<Instructions text={instructions} />
					</Box>
				)}
				<Box
					// TODO: there is conflicting style/logic between summative and formative, with child vs. parent components.
					// TODO: This needs to be carefully corrected upstream, and let internal components be "pure" without padding assumptions
					paddingHorizontal={isSummativeTest || (isWeb && !isMobile) ? 0 : 16}
					// TODO: move breakpoints to constants
					maxWidth={isSummativeTest ? sessionMaxWidth.summative : 960}
				>
					<ChoicesList
						choices={children!}
						useCardWrapper={!isSummativeTest}
						showCrossOut={!isSummativeTest}
						isAnswered={isAnswered}
						selectedChoiceIds={selectedChoiceIds}
						multiSelect={multiSelect}
						correctAnswerIds={correctAnswerIds}
						handleSelectChoice={handleSelectChoice}
						crossedOutChoiceIds={crossedOutChoiceIds}
					/>
				</Box>
				<Box marginTop={16} marginHorizontal={isMobile ? 16 : 0}>
					{showHint && <HintContainer key={item.id} item={item} />}
				</Box>
			</Box>
			{showMaxChoiceError && (
				<Portal>
					<SnackBarCustom
						handleDismiss={() => setShowMaxChoiceError(false)}
						visible={showMaxChoiceError}
						duration={2000}
						message={`Maximum limit reached. You can only select up to ${maxChoiceCount} answers.`}
					/>
				</Portal>
			)}
		</Box>
	);
};

export default QuestionItem;
