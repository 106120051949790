import type {
	Hint,
	Mode,
	SessionItem,
	SessionResponse,
} from "@memorang/types/src/session/common";
import { create } from "zustand";
import { getSessionConfig } from "../containers/StudySessionBottombarContainer/helpers";
import type {
	Answer,
	BlockState,
	ItemState,
	SessionState,
	UpdateItemStatesPayload,
} from "./types";

const useSessionStore = create<SessionState>()((set) => ({
	sessionItems: [],
	currentItemIndex: 0,
	currentProgress: 0,
	id: "",
	numericId: 0,
	isSummativeTest: false,
	currentBlockIndex: 0,
	numItemsRequested: 0,
	breakTimeElapsed: 0,
	mode: "QUESTION",
	totalTime: 0,
	sessionTime: 0,
	numGoal: 0,
	inReviewMode: false,
	timeElapsed: 0,
	startTime: "",
	blockStates: new Map<string, BlockState>(),
	itemStates: new Map<string, ItemState>(),
	updateBreakTimeElapsed: (breakTimeElapsed: number) => {
		set({ breakTimeElapsed });
	},
	updateTimeElapsed: (timeElapsed: number) => {
		set({ timeElapsed });
	},
	updateAnswerEventInProgress: (isAnswerEventInProgress: boolean) => {
		set({ isAnswerEventInProgress });
	},
	updateEndingSessionInProgress: (isEndingSessionInProgress: boolean) => {
		set({ isEndingSessionInProgress });
	},
	updateCurrentBlockIndex: (index: number) => {
		set({ currentBlockIndex: index });
	},

	toggleShowMoreItemsAlert: () => {
		set((state) => {
			return {
				showMoreItemsAlert: !state.showMoreItemsAlert,
			};
		});
	},
	toggleShowBreakDialog: (show?: boolean) => {
		set(() => {
			return {
				showBreakDialog: show,
			};
		});
	},
	toggleBreakOngoing: (isBreakOngoing: boolean) => {
		set(() => {
			return {
				isBreakOngoing,
			};
		});
	},
	updateBreak: (breakData?: {
		startTime: string;
		allotedTime: number;
		status: "STARTED" | "EXPIRED";
		totalBreakTimeTakenInSeconds?: number;
	}) => {
		if (breakData) {
			set((state) => {
				return {
					breaks: [...(state.breaks || []), breakData],
				};
			});
		} else {
			set(() => {
				return {
					breaks: [],
				};
			});
		}
	},
	updateSession: ({
		sessionResponse,
		isReverseMode,
		mode,
		isSummativeTest,
		inReviewMode,
		numItemsRequested,
		isRecommendedTask,
	}: {
		sessionResponse: SessionResponse;
		isReverseMode?: boolean;
		mode?: Mode;
		isSummativeTest?: boolean;
		inReviewMode?: boolean;
		numItemsRequested?: number;
		isRecommendedTask?: boolean;
	}) => {
		const initBlockStates = isSummativeTest
			? sessionResponse.sessionItems.reduce((acc, item) => {
					acc.set(item.id, {
						currentProgress: 0,
						completed: false,
						completedStatuses: {},
						markedItemsForReview: [],
						highlightedItems: new Map<string, string>(),
					});
					return acc;
				}, new Map<string, BlockState>())
			: new Map<string, BlockState>();

		const numGoal = isSummativeTest
			? sessionResponse.sessionItems[0].children.length
			: sessionResponse.sessionItems.length;

		const showMoreItemsAlert = (numItemsRequested || 0) < numGoal;

		const isOlx = sessionResponse.examName?.includes("OLX");

		const startTime = sessionResponse.startTime;
		const totalTime = sessionResponse.totalTime || 0;

		const examBreak = getSessionConfig(sessionResponse.examBreak);

		// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
		const getOlxElapsedTime = () => {
			if (startTime) {
				const currentTime = Date.now();
				const totalElapsed = Math.floor(
					(currentTime - new Date(startTime).getTime()) / 1000,
				);
				let timeElapsed = totalElapsed;

				const firstBreak = examBreak;
				if (firstBreak?.status && firstBreak.status === "EXPIRED") {
					set({
						serverExamBreak: sessionResponse.examBreak,
					});
					const totalBreakTimeTakenInSeconds =
						firstBreak.totalBreakTimeTakenInSeconds || 0;
					timeElapsed = totalElapsed - totalBreakTimeTakenInSeconds;
				}

				if (
					firstBreak?.status &&
					firstBreak.status !== "EXPIRED" &&
					firstBreak.startTime
				) {
					// break is ongoing
					const breakStartTime = firstBreak?.startTime;
					const breakAllotedTime = firstBreak?.allotedTime || 0;

					// Calculate actual break time taken
					const actualBreakTime = breakStartTime
						? Math.floor(
								(currentTime - new Date(breakStartTime).getTime()) / 1000,
							)
						: 0;

					// Use the lesser of actual break time or allotted break time
					const breakTimeToSubtract = Math.min(
						actualBreakTime,
						breakAllotedTime,
					);
					const breakTimeRemaining = breakAllotedTime - actualBreakTime;
					if (breakTimeRemaining > 0) {
						set({
							breakTimeElapsed: actualBreakTime,
							isBreakOngoing: true,
							showBreakDialog: true,
							breaks: [
								{
									startTime: breakStartTime,
									allotedTime: breakAllotedTime,
									status: "STARTED",
								},
							],
							serverExamBreak: sessionResponse.examBreak,
						});
					}
					timeElapsed = Math.max(0, totalElapsed - breakTimeToSubtract);
				}

				return timeElapsed;
			}
			return 0;
		};
		const finalSessionTime = isOlx
			? getOlxElapsedTime()
			: sessionResponse.sessionTime || 0;

		set({
			sessionItems: sessionResponse.sessionItems,
			numGoal,
			id: sessionResponse.id,
			isReverseMode,
			mode,
			numericId: sessionResponse.numericId,
			isSummativeTest,
			examName: sessionResponse.examName,
			blockStates: initBlockStates,
			totalTime,
			inReviewMode: inReviewMode,
			reportSessionType: sessionResponse.reportSessionType,
			sessionTime: finalSessionTime,
			showMoreItemsAlert,
			numItemsRequested: numItemsRequested || 0,
			isRecommendedTask,
			startTime,
			isEndingSessionInProgress: true,
		});
	},
	updateCurrentItemIndex: (index: number) => set({ currentItemIndex: index }),
	updateAnswers: (id: string, answer: Answer) => {
		set((state) => {
			const answers = state.answers || new Map<string, Answer>();
			const selectedChoiceIds = state.itemStates!.get(id)!.selectedChoiceIds;
			answers.set(id, {
				...answer,
				selectedChoiceIds: selectedChoiceIds,
			});
			return {
				answers: new Map(answers),
			};
		});
	},

	updateProgress: (progress: number) => {
		set((state) => {
			const { currentBlockIndex, isSummativeTest } = state;
			const blockId = state.sessionItems?.[currentBlockIndex].id || "";
			const existingBlockState = state.blockStates.get(blockId);
			if (isSummativeTest) {
				const newBlockStates = new Map(state.blockStates);
				newBlockStates.set(blockId, {
					...(existingBlockState || {}),
					currentProgress: progress,
				});
				return { blockStates: newBlockStates };
			}
			return { currentProgress: progress };
		});
	},
	updateProgressForEachBlock: (progress: number, blockId: string) => {
		set((state) => {
			const blockStates = state.blockStates || new Map<string, BlockState>();
			const blockState = blockStates.get(blockId) || {};
			blockStates.set(blockId, { ...blockState, currentProgress: progress });
			return { blockStates: new Map(blockStates) };
		});
	},
	updateHighlightedItems: (itemId: string, highlightedHtml?: string) =>
		set((state) => {
			const { currentBlockIndex } = state;
			const blockId = state.sessionItems?.[currentBlockIndex].id || "";
			const existingBlockState = state.blockStates.get(blockId);
			const currentHighlightedItems =
				existingBlockState?.highlightedItems || new Map<string, string>();

			const updatedHighlightedItems = new Map(currentHighlightedItems);
			updatedHighlightedItems.set(itemId, highlightedHtml);

			const updatedBlockState = {
				...existingBlockState,
				highlightedItems: updatedHighlightedItems,
			};

			const updatedBlockStates = new Map(state.blockStates);
			updatedBlockStates.set(blockId, updatedBlockState);

			return {
				blockStates: updatedBlockStates,
			};
		}),
	updateItemStates: ({
		itemId,
		selectedChoiceId,
		multiSelect,
		correctAnswerIds,
		crossOut,
		scores,
		answered,
	}: UpdateItemStatesPayload) => {
		// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
		set((state) => {
			const itemStates = state.itemStates || new Map<string, ItemState>();
			const itemState = itemStates.get(itemId);
			let selectedChoiceIds = itemState?.selectedChoiceIds || [];
			const crossedOutChoiceIds = itemState?.crossedOutChoiceIds || [];
			const hints = itemState?.hints;
			const index = selectedChoiceIds.indexOf(selectedChoiceId);
			const crossOutIndex = crossedOutChoiceIds.indexOf(selectedChoiceId);

			if (crossOut) {
				if (crossOutIndex > -1) {
					crossedOutChoiceIds.splice(crossOutIndex, 1);
				} else {
					crossedOutChoiceIds.push(selectedChoiceId);
					if (index > -1) {
						selectedChoiceIds.splice(index, 1);
					}
				}
			} else {
				if (multiSelect) {
					if (index > -1) {
						selectedChoiceIds.splice(index, 1);
					} else {
						selectedChoiceIds.push(selectedChoiceId);
					}
				} else if (!correctAnswerIds?.length) {
					if (index > -1) {
						selectedChoiceIds = [];
					} else {
						selectedChoiceIds = [selectedChoiceId];
					}
				}
				if (crossOutIndex > -1) {
					crossedOutChoiceIds.splice(crossOutIndex, 1);
				}
			}

			itemStates.set(itemId, {
				selectedChoiceIds: [...selectedChoiceIds],
				correctAnswerIds,
				crossedOutChoiceIds: [...crossedOutChoiceIds],
				scores: scores || [],
				answered: answered,
				hints,
			});
			return { itemStates: new Map(itemStates) };
		});
	},
	updateHintsForItem: (itemId: string, hints: Hint[]) =>
		set((state) => {
			const itemStates = state.itemStates || new Map();
			const itemState = itemStates.get(itemId);
			const newItemState = {
				...itemState,
				scores: itemState?.scores || [],
				hints,
			};
			itemStates.set(itemId, newItemState);
			return {
				itemStates: new Map(itemStates),
			};
		}),
	updateInReview: (inReviewMode: boolean, sessionItems: SessionItem[]) =>
		set((state) => {
			if (inReviewMode) {
				return {
					inReviewMode,
					persistedCurrentProgress: state.currentProgress,
					currentProgress: 0,
					currentItemIndex: 0,
					persistedItems: state.sessionItems,
					sessionItems,
					numGoal: sessionItems.length,
				};
			}
			return {
				inReviewMode,
				currentProgress: state.persistedCurrentProgress,
				items: state.persistedSessionItems,
			};
		}),
	updateMarkedItemsForReview: (itemId: string) =>
		set((state) => {
			const { currentBlockIndex } = state;
			const blockId = state.sessionItems?.[currentBlockIndex].id || "";
			const existingBlockState = state.blockStates.get(blockId);
			const currentMarkedItems = existingBlockState?.markedItemsForReview || [];
			const isItemMarked = currentMarkedItems.includes(itemId);

			const updatedMarkedItems = isItemMarked
				? currentMarkedItems.filter((id) => id !== itemId)
				: [...currentMarkedItems, itemId];

			const updatedBlockState = {
				...existingBlockState,
				markedItemsForReview: updatedMarkedItems,
			};

			const updatedBlockStates = new Map(state.blockStates);
			updatedBlockStates.set(blockId, updatedBlockState);

			return {
				blockStates: updatedBlockStates,
			};
		}),
	resetSessionStore: () =>
		set({
			currentItemIndex: 0,
			currentProgress: 0,
			itemStates: undefined,
			answers: undefined,
			numGoal: 0,
			isSummativeTest: false,
			isReverseMode: false,
			inReviewMode: false,
			timeElapsed: 0,
			currentBlockIndex: 0,
			blockStates: new Map<string, BlockState>(),
			totalTime: 0,
			sessionTime: 0,
			numItemsRequested: 0,
			isEndingSessionInProgress: false,
			isAnswerEventInProgress: false,
			breaks: [],
			breakTimeElapsed: 0,
			isBreakOngoing: false,
		}),
}));

export default useSessionStore;
