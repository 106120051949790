import { buildType, currentAppVersion } from "@helpers/expo-extras";
import { useAppStore } from "@hooks/useAppStore";
/* -----------------Third parties--------------- */
import { Linking } from "react-native";

import { events } from "@constants/tracking";
import { urls } from "@constants/urls";
import { useThemeContext } from "@contexts/ThemeContext";
import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { getPlatform } from "@memorang/helpers/src/common";
import { composeMessage } from "@memorang/helpers/src/email";
import { trackCustomEvent } from "analytics";
import { useRouter } from "expo-router";
/* -----------------Helpers && Hooks--------------- */
import type { Section } from "../types";

const isDevelopment = process.env.NODE_ENV === "development";

type Callbacks = {
	toggleShowDatePicker: () => void;
	toggleShowSignOutDialog: () => void;
	toggleDarkMode: () => void;
	toggleShowDeleteFeedbackDialog: () => void;
};
const useAccountSettings = ({
	toggleShowDatePicker,
	toggleShowSignOutDialog,
	toggleDarkMode,
	toggleShowDeleteFeedbackDialog,
}: Callbacks) => {
	const router = useRouter();
	const { user, userDetails } = useAuthContext();
	const {
		currentExamContext: { examDate, examName, examIcon },
	} = useExamContext();

	const { isDarkMode } = useThemeContext();

	const exam = useAppStore((store) => store.exam);
	const supportEmail = useAppStore((store) => store.supportEmail);

	const usernameValue = userDetails?.name || "";

	const email = user?.attributes?.email || "";

	const getAccountSections = (): Section[] => {
		return [
			...(exam?.enabled
				? [
						{
							sectionTitle: "Current Exam",
							items: [
								{
									icon: examIcon || "file-document-outline",
									type: "exam-filter",
									title: examName,
									iconRight: "swap-horizontal",
								},
							],
						},
						{
							sectionTitle: "Exam Date",
							items: [
								{
									icon: "calendar-outline",
									type: "datepicker",
									title: examDate || "Select date",
									onPress: toggleShowDatePicker,
									iconRight: "pencil-outline",
								},
							],
						},
					]
				: []),
			{
				sectionTitle: "Appearance",
				items: [
					{
						icon: isDarkMode ? "weather-sunny" : "weather-night",
						title: "Dark mode",
						type: "toggle",
						onPress: toggleDarkMode,
					},
				],
			},
			{
				sectionTitle: "Account",
				items: [
					{
						icon: "account-outline",
						title: "Name",
						description: usernameValue,
					},
					{
						icon: "email-outline",
						title: "Email",
						description: email,
					},
				],
			},

			...(isDevelopment
				? [
						{
							sectionTitle: "Dev",
							items: [
								{
									onPress: () => {
										router.push("/sandbox");
									},
									icon: "code-json",
									title: "Sandbox",
								},
							],
						},
					]
				: []),
			{
				sectionTitle: "About",
				items: [
					{
						onPress: () => {
							trackCustomEvent({
								eventName: events.termsOfServiceClicked,
							});
							router.push("/legal/terms");
						},
						icon: "gavel",
						title: "Terms of Service",
						iconRight: "open-in-new",
					},
					{
						onPress: () => {
							trackCustomEvent({
								eventName: events.privacyPolicyClicked,
							});
							router.push("/legal/privacy");
						},
						icon: "eye-outline",
						title: "Privacy Policy",
						iconRight: "open-in-new",
					},
					{
						onPress: () => {
							trackCustomEvent({
								eventName: events.contactSupportClicked,
								email,
							});
							const platform = getPlatform();
							const subject = `${buildType} Support | ${platform} | ${usernameValue}`;
							const body = `Hi, my username is ${usernameValue} and account email is ${email} and I'm reaching out because:`;
							composeMessage(subject, body, supportEmail);
						},
						icon: "help-circle-outline",
						title: "Contact Support",
						iconRight: "open-in-new",
					},
					{
						onPress: toggleShowSignOutDialog,
						icon: "logout",
						title: "Sign out",
					},
					{
						onPress: toggleShowDeleteFeedbackDialog,
						icon: "account-remove-outline",
						title: "Delete Account",
					},
				],
			},
			{
				sectionTitle: "Platform",
				items: [
					{
						onPress: () => {
							Linking.openURL(urls.core);
						},
						icon: "web",
						title: "Partnerships",
						iconRight: "open-in-new",
					},
					{
						icon: "unfold-more-vertical",
						title: currentAppVersion!,
						onPress: () => {
							// TODO: Add functionality for version information
						},
					},
				],
			},
		];
	};
	return {
		isLoading: false,
		getAccountSections,
		usernameValue,
		actualUsernameValue: usernameValue,
	};
};

export default useAccountSettings;
