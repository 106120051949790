{
	"name": "@memorang/features",
	"version": "1.0.87",
	"license": "MIT",
	"scripts": {
		"typecheck": "tsc --noEmit --pretty"
	},
	"dependencies": {
		"@memorang/constants": "workspace:*",
		"@memorang/helpers": "workspace:*",
		"@memorang/ui": "workspace:*"
	},
	"peerDependencies": {
		"@emotion/react": "^11.10.5",
		"@emotion/styled": "^11.10.5",
		"@gorhom/bottom-sheet": "^5.1.1",
		"@mui/material": "^5.10.16",
		"@react-native-community/hooks": "^3.0.0",
		"@tanstack/react-query": "^5.66.7",
		"expo-router": "~4.0.17",
		"houseform": "^1.13.0",
		"markdown-it": "^13.0.1",
		"markdown-it-sub": "^1.0.0",
		"markdown-it-sup": "^1.0.0",
		"react": "18.3.1",
		"react-fast-compare": "^3.2.2",
		"react-native": "0.76.7",
		"react-native-easy-content-loader": "^0.3.2",
		"react-native-markdown-display": "^7.0.0-alpha.2",
		"react-native-math-view": "^3.9.5",
		"react-native-paper": "^5.13.1",
		"react-native-reanimated": "~3.16.1",
		"react-native-safe-area-context": "4.12.0",
		"zod": "^3.24.2"
	},
	"devDependencies": {
		"@memorang/typescript": "workspace:*",
		"@types/markdown-it": "^13.0.1",
		"@types/react": "~18.3.12",
		"typescript": "^5.3.3"
	}
}
