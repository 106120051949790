const whitelistedEmails = [
	"appreview@memorang.com",
	"test1@therapyed.com",
	"test2@therapyed.com",
	"test3@therapyed.com",
	"test4@therapyed.com",
	"test5@therapyed.com",
	"test1@simwerx.com",
	"demo@memorang.com",
];
const whitelistedEmailRegex = /^.+\+demo@memorang\.com$/;
export const RESEND_INTERVAL_SECONDS = 3 * 60;

const otpLength = 6;
const otpErrorMessage =
	"Sorry, that code is invalid or expired. Please check for typos, resend the code, or go back to try another email";
export { whitelistedEmails, whitelistedEmailRegex, otpErrorMessage, otpLength };
