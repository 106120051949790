/* -----------------Globals--------------- */
import { Image } from "react-native";

import AuthButton from "@memorang/ui/src/components/AuthButton";
import AuthContainer from "@memorang/ui/src/components/AuthContainer";
import Box from "@memorang/ui/src/components/Box";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import useBreakpoints from "@memorang/ui/src/useBreakpoints";
import type { ImageSourcePropType } from "react-native";
/* -----------------UI--------------- */
import { Text, useTheme } from "react-native-paper";

type AppLogo = {
	stacked: {
		light: ImageSourcePropType;
		dark: ImageSourcePropType;
	};
};

type Props = {
	appLogo: AppLogo;
	handleNext: () => void;
	appName: string;
	tos: string;
	privacy: string;
	subtitle?: string;
};
const Landing = ({
	appLogo,
	handleNext,
	appName,
	tos,
	privacy,
	subtitle,
}: Props) => {
	const theme = useTheme();
	const {
		stacked: { light, dark },
	} = appLogo;

	const AppLogo = theme.dark ? dark : light;
	const { smDown } = useBreakpoints();

	const footerText = `By continuing, you hereby agree to ${appName}’s [Terms of Service](${tos}) and [Privacy Policy](${privacy}).`;

	const Footer = () => (
		<Box paddingBottom={24}>
			<MarkdownRenderer
				text={footerText}
				variant="explanation"
				textAlign="center"
				useCustomLink={false}
			/>
		</Box>
	);

	return (
		<AuthContainer
			containerStyle={{
				gap: smDown ? 0 : 24,
				paddingTop: smDown ? 0 : 48,
				paddingVertical: 24,
				flexGrow: smDown ? 1 : 0,
				paddingHorizontal: 16,
				position: "relative",
			}}
		>
			<Box
				alignItems="center"
				gap={24}
				justifyContent={smDown ? "center" : "flex-start"}
				flexGrow={1}
			>
				<Box alignItems="center" gap={24}>
					<Image
						resizeMode="contain"
						source={AppLogo}
						style={{
							width: 300,
							height: smDown ? 200 : 150,
							maxWidth: "100%",
							alignSelf: "center",
						}}
					/>
					{subtitle && (
						<Text
							style={{
								fontStyle: "italic",
								fontFamily: "Open Sans",
							}}
							variant="headlineSmall"
						>
							{subtitle}
						</Text>
					)}
				</Box>
			</Box>
			<Box
				alignItems="center"
				gap={16}
				position={smDown ? "absolute" : "relative"}
				bottom={smDown ? 16 : undefined}
				left={smDown ? 16 : undefined}
				right={smDown ? 16 : undefined}
			>
				<AuthButton
					testID="continue-with-email"
					icon={"email-outline"}
					fullWidth={smDown}
					onPress={handleNext}
				>
					{"Continue with email"}
				</AuthButton>
				<Footer />
			</Box>
		</AuthContainer>
	);
};

export default Landing;
