import Box from "@memorang/ui/src/components/Box";
import MasonryGrid from "components/MasonryGrid";

interface Props {
	cards: React.ReactElement[];
}
const ReportCardsContainer = ({ cards }: Props) => {
	const [first, second, ...rest] = cards;

	return (
		<>
			<MasonryGrid fullWidth>{first}</MasonryGrid>
			<Box marginTop={-16} paddingLeft={16} paddingVertical={8}>
				{second}
			</Box>
			<MasonryGrid>{rest}</MasonryGrid>
		</>
	);
};

export default ReportCardsContainer;
