export const pageViewTypes = {
	// Authentication and Onboarding
	splash: "splash",
	login: "login",
	verifyEmail: "verify_email",
	chooseExam: "choose_exam",
	chooseDate: "choose_date",
	chooseCategory: "choose_category",

	// Main Sections
	home: "home",
	leaderboard: "leaderboard",
	progress: "progress",
	settings: "settings",
	more: "more",
	store: "store",
	practiceTest: "practice_tests",
	studyPack: "study_packs",
	profile: "profile",
	// Learning and Practice
	sessionConfig: "session_config",
	session: "session",
	report: "report",
	review: "review",
	stimulus: "stimulus",
	suggestCorrection: "suggest_correction",

	// Purchase and Subscription
	purchase: "purchase",
	checkout: "checkout",
	purchaseSuccess: "purchase_success",
	redeem: "redeem",
	// Support and Feedback
	faq: "faq",
	feedback: "feedback",

	// AI Assistant
	aila: "aila",
};
export const events = {
	// Authentication & Account Management
	userLoggedIn: "mem_user_logged_in",
	userLoggedOut: "mem_user_logged_out",
	userRegistered: "mem_user_registered",
	userDeleted: "mem_user_deleted",
	accountDeleted: "mem_account_deleted",
	deleteFeedbackSent: "mem_delete_feedback_sent",
	examContextSet: "mem_exam_context_set",
	examDateSet: "mem_exam_date_set",
	userPreferenceChanged: "mem_user_preference_changed",
	avatarChanged: "mem_avatar_changed",
	waitlistJoined: "mem_waitlist_joined",

	// Session Core Events
	sessionStarted: "mem_session_started",
	sessionEnded: "mem_session_ended",
	sessionPaused: "mem_session_timer_paused",
	sessionResumed: "mem_session_timer_resumed",
	sessionViewed: "mem_session_viewed",
	diagnosticSessionStarted: "mem_diagnostic_session_started",
	freeQuizStarted: "mem_free_quiz_started",
	sessionDiagnosticClicked: "mem_session_diagnostic_clicked",
	sessionConfigOpened: "mem_session_config_opened",

	// Session Navigation & Controls
	sessionPrevNavClicked: "mem_session_prev_nav_clicked",
	sessionNextNavClicked: "mem_session_next_nav_clicked",
	sessionFinishNavClicked: "mem_session_finish_nav_clicked",
	sessionConfigModalViewed: "mem_session_config_modal_viewed",

	// Session Item Interactions
	sessionItemViewed: "mem_session_item_viewed",
	sessionItemNavigated: "mem_session_item_navigated",
	sessionItemOptionSelected: "mem_session_item_option_selected",
	sessionItemConfidenceClicked: "mem_session_item_confidence_clicked",
	sessionItemCrossedOut: "mem_session_item_crossed_out",
	sessionItemHintGiven: "mem_session_item_hint_given",
	sessionItemTextInserted: "mem_session_item_text_inserted",

	// Audio Related Events
	sessionItemAudioStarted: "mem_session_item_audio_started",
	sessionItemAudioPaused: "mem_session_item_audio_paused",
	sessionItemAudioEnded: "mem_session_item_audio_ended",
	sessionItemListenToTextClicked: "mem_session_item_listen_to_text_clicked",
	sessionItemResponseAudioRecordingStarted:
		"mem_session_item_response_audio_recording_started",
	sessionItemResponseAudioRecordingEnded:
		"mem_session_item_response_audio_recording_ended",
	sessionItemResponseSubmitted: "mem_session_item_response_submitted",
	sessionReportListenToTextClicked: "mem_session_report_listen_to_text_clicked",

	// Question & Answer
	answerSelected: "mem_answer_selected",
	answerSubmitted: "mem_answer_submitted",
	crossedOutAnswer: "mem_crossed_out_answer",
	hintViewed: "mem_hint_viewed",
	explanationSheetOpened: "mem_explanation_sheet_opened",
	reviewAnswersClicked: "Review_Answers_Clicked",
	reviewViewed: "mem_review_viewed",
	reportViewed: "mem_report_viewed",
	sessionReportCardClicked: "mem_session_report_card_clicked",
	suggestCorrectionViewed: "mem_suggest_correction_viewed",

	// Stimulus Events
	stimulusViewed: "mem_stimulus_viewed",
	sessionStimulusOpened: "mem_session_stimulus_opened",
	sessionStimulusClosed: "mem_session_stimulus_closed",

	// Payment & Subscription
	paymentSubmitted: "mem_payment_submitted",
	paymentSuccessful: "mem_payment_successful",
	paymentFailed: "mem_payment_failed",
	paywallOpened: "mem_paywall_opened",
	paywallClosed: "mem_paywall_closed",
	subscriptionOptionClicked: "mem_subscription_option_clicked",
	purchaseModalViewed: "mem_purchase_modal_viewed",
	checkoutViewed: "mem_checkout_viewed",
	purchaseSuccessViewed: "mem_purchase_success_viewed",
	planSelected: "mem_plan_selected",
	upgradeChipClicked: "mem_upgrade_chip_clicked",
	lockedTagClicked: "mem_locked_tag_clicked",
	purchaseButtonClicked: "mem_purchase_button_clicked",
	redeemViewed: "mem_redeem_viewed",
	promoCodeEntered: "mem_promo_code_entered",
	promoCodeRedeemed: "mem_promo_code_redeemed",
	settingExamContextFromExamDetailsFailed:
		"mem_setting_exam_context_from_exam_details_failed",
	postRedeemExamContextSwitchFailed:
		"mem_post_redeem_exam_context_switch_failed",

	// Page Views
	loginViewed: "mem_login_viewed",
	verifyEmailViewed: "mem_verify_email_viewed",
	homeViewed: "mem_home_viewed",
	leaderboardViewed: "mem_leaderboard_viewed",
	progressViewed: "mem_progress_viewed",
	settingsViewed: "mem_settings_viewed",
	profileViewed: "mem_profile_viewed",
	faqsViewed: "mem_faqs_viewed",
	feedbackViewed: "mem_feedback_viewed",
	ailaViewed: "mem_aila_viewed",
	storeViewed: "mem_store_viewed",
	chooseExamViewed: "mem_choose_exam_viewed",
	chooseDateViewed: "mem_choose_date_viewed",
	chooseCategoryViewed: "mem_choose_category_viewed",

	// UI Interactions
	dialogOpened: "mem_dialog_opened",
	dialogClosed: "mem_dialog_closed",
	commandBarClicked: "mem_command_bar_clicked",
	commandBarOptionClicked: "mem_command_bar_option_clicked",
	studyFabClicked: "mem_study_fab_clicked",
	categorySelected: "Category_Selected",
	suggestedChipClicked: "Suggested_Chip_Clicked",
	quickActionClicked: "mem_quick_action_clicked",
	taskCardClicked: "mem_task_card_clicked",
	leaderboardIntervalClicked: "mem_leaderboard_interval_clicked",
	skillExpanded: "mem_skill_expanded",

	// Search
	storeSearchQuerySubmitted: "mem_store_search_query_submitted",
	qaSearchModalViewed: "mem_qa_search_modal_viewed",

	// Support & Feedback
	contactSupportClicked: "mem_contact_support_clicked",
	feedbackSubmitted: "mem_feedback_submitted",
	termsOfServiceClicked: "mem_terms_of_service_clicked",
	privacyPolicyClicked: "mem_privacy_policy_clicked",

	// Email & Communications
	emailSent: "mem_email_sent",
	emailTriggered: "mem_email_triggered",

	// Copilot/AI Assistant
	copilotOpened: "mem_copilot_opened",
	copilotClosed: "mem_copilot_closed",
	copilotTextSubmitted: "mem_copilot_text_submitted",
	copilotFeedbackSubmitted: "mem_copilot_feedback_submitted",
	copilotFollowupSelected: "mem_copilot_followup_selected",
};
