import { zodResolver } from "@hookform/resolvers/zod";
/* ----------------- Third parties --------------- */
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

/* ----------------- UI --------------- */
import { HelperText, Text, TextInput } from "react-native-paper";

import AuthButton from "@memorang/ui/src/components/AuthButton";
import Box from "@memorang/ui/src/components/Box";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
/* ----------------- Components --------------- */
import { useEffect } from "react";

interface Props {
	loading?: boolean;
	handleSubmitEmail: (email: string) => void;
	emailFromUrl?: string;
	lastLoggedInEmail?: string;
}

const emailSchema = z.object({
	email: z.string().email("Please enter a valid email address"),
});

const EmailForm = ({
	loading,
	handleSubmitEmail,
	emailFromUrl,
	lastLoggedInEmail,
}: Props) => {
	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: zodResolver(emailSchema),
		mode: "onChange",
	});

	const watchEmail = watch("email");

	useEffect(() => {
		const email = emailFromUrl || lastLoggedInEmail;
		if (email) {
			setValue("email", email);
		}
	}, [emailFromUrl, setValue, lastLoggedInEmail]);

	return (
		<Box gap={24} width="100%">
			{lastLoggedInEmail && (
				<Box paddingHorizontal={16}>
					<MarkdownRenderer
						overrideRules={{
							link: (
								node: { key: string },
								children: React.ReactNode,
								_: unknown,
								styles: { text: object },
							) => (
								<Text key={node.key} style={styles.text}>
									{children}
								</Text>
							),
						}}
						text={`You last logged in with the email **${lastLoggedInEmail}**`}
						textAlign="center"
						customStyles={{
							paragraph: {
								lineHeight: 22,
							},
						}}
					/>
				</Box>
			)}
			<Controller
				control={control}
				render={({ field: { onChange, onBlur, value } }) => (
					<Box>
						<TextInput
							mode="outlined"
							placeholder="e.g., dave@gmail.com"
							onChangeText={onChange}
							onBlur={onBlur}
							value={value}
							autoFocus
							autoCapitalize="none"
							onSubmitEditing={handleSubmit((data) =>
								handleSubmitEmail(data.email),
							)}
							keyboardType="email-address"
							disabled={loading}
							style={{ width: "100%" }}
						/>
						{errors.email && typeof errors.email.message === "string" && (
							<HelperText type="error">{errors.email.message}</HelperText>
						)}
					</Box>
				)}
				name="email"
				defaultValue=""
			/>
			<AuthButton
				onPress={handleSubmit((data) => handleSubmitEmail(data.email))}
				disabled={loading || Boolean(errors.email) || !watchEmail}
				loading={loading}
			>
				Continue
			</AuthButton>
		</Box>
	);
};

export default EmailForm;
