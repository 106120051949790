import { getShowStudyPackViews } from "@helpers/exams";

/* ----------------- Helpers --------------- */
import { groupTagsByType } from "@helpers/content/tag";

/* ----------------- Types --------------- */
import type { MixedItemDist, MixedItemTag } from "@memorang/types/src/tag";

/* ----------------- UI --------------- */
import { useDevice } from "@memorang/ui/src/useDevice";

/* ----------------- Hooks --------------- */
import { useTagsStore } from "./useTagsStore";

import { useExamContext } from "@features/exam/contexts/ExamContext";
/* ----------------- Router --------------- */
import { useRouter } from "expo-router";

const getMasteryKeyTopics = (
	mixedItemDist: MixedItemDist,
	hasAccess?: boolean,
	isMobile?: boolean,
	isDetailedList?: boolean,
) => {
	const getTagsToDisplay = (tags: MixedItemTag[]) => {
		if (isMobile && !isDetailedList) {
			if (hasAccess) {
				return tags.slice(0, 3);
			}
			const freeTags = tags.filter((tag) => !tag.locked);
			const premiumTags = tags.filter((tag) => tag.locked);
			return freeTags?.concat(premiumTags).slice(0, 3);
		}
		return tags.sort((a, b) => Number(a.locked) - Number(b.locked));
	};
	const { tags, freeTagIds } = mixedItemDist;
	const groupedTagsByType = groupTagsByType(
		tags,
		"Display",
		freeTagIds,
		hasAccess,
	);
	const allTags = Object.keys(groupedTagsByType).flatMap(
		(key) => groupedTagsByType[key],
	);
	const tagsToDisplay = getTagsToDisplay(allTags);
	return tagsToDisplay;
};
export const useMasteryKeyTopicsSectionData = (
	mixedItemDist?: MixedItemDist,
	hasAccess?: boolean,
) => {
	const router = useRouter();

	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();

	const setCurrentSelectedMasteryItems = useTagsStore(
		(state) => state.setCurrentSelectedMasteryItems,
	);
	const { isMobile } = useDevice();
	const section = {
		data: [],
		type: "progress",
		onPress: undefined,
	};

	const isStudyPackEnabled = getShowStudyPackViews(studyPackProductId);

	if (!mixedItemDist) {
		return {
			...section,
			sectionTitle: "Mastery Items",
			loading: true,
		};
	}

	if (!isStudyPackEnabled) {
		return null;
	}

	const { tags } = mixedItemDist;

	const tagType = tags.find((tag) =>
		tag.displayType?.includes("Display"),
	)?.type;

	const masteryTopics = getMasteryKeyTopics(mixedItemDist, hasAccess, isMobile);

	const mappedMasteryTopics = masteryTopics.map((item) => {
		return {
			...item,
			locked: isStudyPackEnabled ? item.locked : false,
		};
	});

	return {
		...section,
		sectionTitle: `${tagType || "Topic"} Mastery`,
		loading: false,
		data: mappedMasteryTopics,
		onPress: () => {
			const items = getMasteryKeyTopics(
				mixedItemDist,
				hasAccess,
				isMobile,
				true,
			);
			setCurrentSelectedMasteryItems(items);
			router.push({
				pathname: "/progress/detailed-list/mastery",
				params: {
					type: tagType || "Topic",
				},
			});
		},
	};
};
