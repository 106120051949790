import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";
import { Button, Dialog, Text } from "react-native-paper";

export const ErrorDialog = ({
	visible,
	handleClose,
	title,
	message,
}: {
	visible: boolean;
	handleClose: () => void;
	title: string;
	message: string;
}) => {
	return (
		<DialogWrapper visible={visible} handleClose={handleClose} maxWidth={"xs"}>
			<Dialog.Title>{title}</Dialog.Title>
			<Dialog.Content>
				<Text variant="bodyMedium">{message}</Text>
			</Dialog.Content>
			<Dialog.Actions>
				<Button onPress={handleClose}>OK</Button>
			</Dialog.Actions>
		</DialogWrapper>
	);
};
