/* -----------------Globals--------------- */
import {
	type LayoutChangeEvent,
	Platform,
	View,
	type ViewStyle,
} from "react-native";

/* -----------------UI--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Card, type ChipProps, Text } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";

/* -----------------Types--------------- */
import type { VariantProp } from "react-native-paper/lib/typescript/components/Typography/types";

type ChipMode = "flat" | "outlined";

// TODO: We are not using Chip, instead we are using Card. Either use Chip or use CardProps
type QueryChipProps = ChipProps & {
	query: string;
	index: number | null;
	mode?: ChipMode;
	baseStyle?: ViewStyle;
	flatStyle?: ViewStyle;
	outlinedStyle?: ViewStyle;
	iconColor?: string;
	textColor?: string;
	handleSelectQuery: (query: string) => void;
	contentStyle?: ViewStyle;
	textVariant?: VariantProp<never>;
	iconSize?: number;
	handleOnTextLayout?: (event: LayoutChangeEvent) => void;
	numberOfLines?: number;
	ellipsizeMode?: "head" | "middle" | "tail" | "clip";
};

interface KeyboardShortcutIconProps {
	index: number;
	color: string;
	textVariant: VariantProp<never>;
}

const KeyboardShortcutIcon = ({
	index,
	color,
	textVariant,
}: KeyboardShortcutIconProps) => {
	return (
		<View
			style={{
				flexDirection: "row",
				alignItems: "center",
				gap: 2,
				borderWidth: 1,
				paddingHorizontal: 4,
				borderRadius: 4,
				backgroundColor: "transparent",
				borderColor: color,
			}}
		>
			<Icon name="apple-keyboard-command" size={10} color={color} />
			<Text
				variant={textVariant}
				style={{
					color: color,
				}}
			>
				{index}
			</Text>
		</View>
	);
};

// Define the QueryChip component
export const QueryChip = ({
	query,
	mode = "flat",
	baseStyle = {},
	flatStyle = {},
	outlinedStyle = {},
	icon = "arrow-right-bottom", // Default icon
	handleSelectQuery,
	children,
	iconColor,
	textColor,
	index,
	contentStyle,
	textVariant = "labelLarge",
	iconSize = 24,
	handleOnTextLayout,
	numberOfLines,
	ellipsizeMode = "tail",
}: QueryChipProps) => {
	const theme = useAppTheme();
	const isOutlined = mode === "outlined";
	const defaultPrimaryColor =
		Platform.OS === "web"
			? "inherit"
			: isOutlined
				? theme.colors.primary
				: theme.colors.onPrimary;

	const defaultBaseStyle: ViewStyle = {
		borderRadius: 16,
		borderTopRightRadius: 2,
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		width: Platform.OS === "web" ? ("fit-content" as any) : "auto",
		cursor: "pointer",
	};
	const defaultFlatStyle: ViewStyle = {
		backgroundColor: theme.colors.primary,
		borderColor: "transparent",
		// @ts-expect-error - used for web
		color: theme.colors.onPrimary,
	};
	const defaultOutlinedStyle: ViewStyle = {
		backgroundColor: "transparent",
		borderColor: theme.colors.primary,
		// @ts-expect-error - used for web
		color: theme.colors.primary,
	};

	const handleCardPress = () => {
		handleSelectQuery(query);
	};

	return (
		<Card
			onPress={handleCardPress}
			mode={mode === "flat" ? "contained" : "outlined"}
			style={[
				defaultBaseStyle,
				baseStyle,
				isOutlined
					? [defaultOutlinedStyle, outlinedStyle]
					: [defaultFlatStyle, flatStyle],
			]}
		>
			<Card.Content
				style={[
					{
						flexDirection: "row",
						paddingHorizontal: 0,
						paddingLeft: icon ? 8 : 16,
						paddingRight: 16,
						paddingVertical: 6,
						gap: 8,
						alignItems: "center",
					},
					contentStyle,
				]}
			>
				{icon && (
					<Icon
						size={iconSize}
						// biome-ignore lint/suspicious/noExplicitAny: <explanation>
						name={icon as any}
						color={iconColor ?? defaultPrimaryColor}
					/>
				)}
				<Text
					variant={textVariant}
					numberOfLines={numberOfLines}
					ellipsizeMode={ellipsizeMode}
					onLayout={handleOnTextLayout}
					style={{
						color: textColor ?? defaultPrimaryColor,
						flexShrink: 1,
						fontSize: 14,
						lineHeight: 14 * 1.6,
						letterSpacing: 0,
					}}
				>
					{children}
				</Text>
				{index !== null && (
					<KeyboardShortcutIcon
						index={index + 1}
						color={isOutlined ? theme.colors.primary : theme.colors.onPrimary}
						textVariant={textVariant}
					/>
				)}
			</Card.Content>
		</Card>
	);
};
