import { zustandStorage } from "@helpers/storage";
/* -----------------Globals--------------- */
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

/* -----------------Types--------------- */
type State = {
	hasShownQuizAlert?: boolean;
	hasShownPracticeTestAlert?: boolean;
	hasShownConfidenceOnboardingDialog?: boolean;
	hasShownMasteryTopicsAlert?: boolean;
	lastLoggedInEmail?: string;
	showAvatarUpdatedMessage?: boolean;
	setLastLoggedInEmail: (email: string) => void;
	setHasShownMasteryTopicsAlert: (hasShown: boolean) => void;
	setHasShownQuizAlert: (hasShown: boolean) => void;
	setHasShownPracticeTestAlert: (hasShown: boolean) => void;
	setHasShownConfidenceOnboardingDialog: (hasShown: boolean) => void;
	setShowAvatarUpdatedMessage: (hasShown: boolean) => void;
};

/* -----------------Store--------------- */
export const useGlobalStore = create<State>()(
	persist(
		(set) => ({
			setHasShownMasteryTopicsAlert: (hasShown) =>
				set({ hasShownMasteryTopicsAlert: hasShown }),
			setHasShownQuizAlert: (hasShown) => set({ hasShownQuizAlert: hasShown }),
			setHasShownConfidenceOnboardingDialog: (hasShown) =>
				set({ hasShownConfidenceOnboardingDialog: hasShown }),
			setHasShownPracticeTestAlert: (hasShown) =>
				set({ hasShownPracticeTestAlert: hasShown }),
			setLastLoggedInEmail: (email) => set({ lastLoggedInEmail: email }),
			setShowAvatarUpdatedMessage: (hasShown) =>
				set({ showAvatarUpdatedMessage: hasShown }),
		}),

		{
			name: "global-store",
			storage: createJSONStorage(() => zustandStorage),
		},
	),
);
