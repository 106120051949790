import { StyleSheet } from "react-native";

/* -----------------UI--------------- */
import { Avatar, Text } from "react-native-paper";
import { type AppTheme, useAppTheme } from "../../useAppTheme";
import Box from "../Box";
import SvgAvatar from "./SvgAvatar";
/* -----------------Child components--------------- */

const getStyles = (theme: AppTheme, size: number) =>
	StyleSheet.create({
		avatar: {
			borderColor: theme.colors.secondary,
			borderWidth: 1,
			overflow: "hidden",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: "transparent",
			width: size,
			height: size,
		},
		school: {
			borderColor: "transparent",
			borderRadius: 0,
			backgroundColor: "transparent",
			overflow: "hidden",
			width: 40,
			height: 40,
		},
	});
type Props = {
	avatar: string;
	caption?: string;
	size?: number;
	border?: boolean;
};
const CustomAvatar = (props: Props) => {
	const { avatar, caption, border = true, size = 40 } = props;
	const theme = useAppTheme();
	const styles = getStyles(theme, size);

	return (
		<Box alignItems="center">
			{avatar.endsWith(".svg") ? (
				<SvgAvatar
					avatar={
						avatar ||
						"https://memorang.com/_next/static/images/AvatarSample-f01554e26e6549275315e54362fb4036.png"
					}
					size={size}
					style={
						border
							? [
									styles.avatar,
									{
										borderRadius: size / 2,
									},
								]
							: [styles.school, { borderRadius: size / 2 }]
					}
				/>
			) : (
				<Avatar.Image
					source={{
						uri:
							avatar ||
							"https://memorang.com/_next/static/images/AvatarSample-f01554e26e6549275315e54362fb4036.png",
					}}
					size={size}
					style={border ? styles.avatar : styles.school}
				/>
			)}

			{caption ? (
				<Text variant="bodySmall" numberOfLines={1}>
					{caption}
				</Text>
			) : null}
		</Box>
	);
};

export default CustomAvatar;
