import { API, graphqlOperation } from "@aws-amplify/api";
import Dexie from "dexie";
import {
	Environment,
	Network,
	RecordSource,
	type RequestParameters,
	Store,
	type Variables,
} from "relay-runtime";
import type { GraphQLResponse } from "relay-runtime";

const db = new Dexie("memrelaydb");
db.version(1).stores({ relayRecords: "&id" });

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const memoryCache = new Map<string, any>();

class IndexedDBRecordSource extends RecordSource {
	constructor() {
		super();
		this.loadInitialData();
	}

	private loadInitialData() {
		if (typeof window !== "undefined") {
			db.table("relayRecords")
				.toArray()
				.then((allRecords) => {
					for (const record of allRecords) {
						memoryCache.set(record.id, record.data);
					}
				});
		}
	}

	get(dataID: string) {
		return memoryCache.get(dataID);
	}

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	set(dataID: string, record: any) {
		super.set(dataID, record);
		memoryCache.set(dataID, record);
		db.table("relayRecords").put({ id: dataID, data: record });
	}
}

async function fetchQuery(
	operation: RequestParameters,
	variables: Variables,
): Promise<GraphQLResponse> {
	const response = await API.graphql(
		graphqlOperation(operation.text, variables),
	);
	return response as GraphQLResponse;
}

function createRelayEnvironment() {
	return new Environment({
		network: Network.create(fetchQuery),
		store: new Store(new IndexedDBRecordSource()),
		// log: RelayLazyLogger,
	});
}

const relayEnv = createRelayEnvironment();
export default relayEnv;
