import { useState } from "react";
import { Keyboard, ScrollView } from "react-native";

/* -----------------Third parties--------------- */
import { API } from "@aws-amplify/api";

import {
	handleCustomChallenge,
	isAuthenticated,
	signInUsingEmail,
} from "@memorang/helpers/src/amplify/amplifyAuth";
import setUpAmplifyConfig from "@memorang/helpers/src/amplify/setUpAmplify";

/* -----------------UI--------------- */
import { Portal } from "react-native-paper";
import useCognitoAuth from "../hooks/useCognitoAuth";

/* -----------------Child components--------------- */

import CustomSnackbar from "@memorang/ui/src/components/CustomSnackbar";
import VerifyEmailView from "../components/VerifyEmailView";
import DialogContactSupport from "../components/dialogs/DialogContactSupport";
import { otpLength } from "../constants";
import { useAuthContext } from "../contexts/AuthContext";

type Props = {
	supportEmail: string;
	companyName: string;
	awsExports: Record<string, string | null>;
	buildType: string;
	currentAppVersion: string;
	handlePostVerifyEmail: (
		userId: string,
		email: string,
		username: string,
		userExists?: boolean,
	) => void;
	appId: string;
	tenantId: string;
};
const VerifyEmailContainer = ({
	supportEmail,
	companyName,
	awsExports,
	handlePostVerifyEmail,
	appId,
	tenantId,
	buildType,
	currentAppVersion,
}: Props) => {
	const cognitoUser = useCognitoAuth((state) => state.cognitoUser);
	const setAuth = useCognitoAuth((state) => state.setAuth);
	const userExists = useCognitoAuth((state) => state.userExists);
	const [loading, setLoading] = useState(false);
	const [resendingOtp, setResendingOtp] = useState(false);
	const [code, setCode] = useState<string | undefined>(undefined);
	const [showError, setShowError] = useState(false);

	const { refreshAuth } = useAuthContext();
	const [showSnackbarProp, setShowSnackbarProp] = useState<{
		visible: boolean;
		message?: string;
	}>({
		visible: false,
		message: undefined,
	});
	const [showContactSupportDialog, setShowContactSupportDialog] =
		useState(false);

	const email = cognitoUser?.challengeParam
		? cognitoUser?.challengeParam?.email
		: "to your email";

	const handleSetCode = (text: string) => {
		setCode(text);
	};
	const handleReset = () => {
		setCode(undefined);
	};

	const toggleContactSupportDialog = () => {
		setShowContactSupportDialog(!showContactSupportDialog);
	};
	const handleOpenSupport = () => {
		toggleContactSupportDialog();
	};

	const handleSubmit = async () => {
		try {
			Keyboard.dismiss();
			setLoading(true);
			if (code) {
				if (cognitoUser) {
					const { username } = cognitoUser;
					try {
						const res = await handleCustomChallenge(code, cognitoUser);
						if (res) {
							const userId = res.attributes["custom:logicalId"];
							const { email } = res.attributes;

							await isAuthenticated();
							API.configure(setUpAmplifyConfig(awsExports, true, true));
							await refreshAuth();
							await handlePostVerifyEmail(userId, email, username, userExists);
						} else {
							setShowError(true);
							handleReset();
						}
					} catch (_error) {
						setShowError(true);
						handleReset();
					}
				}
			}
		} finally {
			setLoading(false);
		}
	};

	const otpSuccessMessage = "Code resent!";
	const handleResendOTP = () => {
		Keyboard.dismiss();
		setResendingOtp(true);
		if (cognitoUser) {
			const { username } = cognitoUser;
			signInUsingEmail(username, appId, tenantId)
				.then((ct) => {
					setAuth(ct);
				})
				.finally(() => {
					setResendingOtp(false);
					setShowSnackbarProp({ visible: true, message: otpSuccessMessage });
				});
		}
	};

	const isValidOTP = code && code.length === otpLength && !loading;

	return (
		<ScrollView
			contentContainerStyle={{
				height: "100%",
			}}
			showsVerticalScrollIndicator={false}
		>
			<VerifyEmailView
				email={email}
				showError={showError}
				handleResendOTP={handleResendOTP}
				resendingOtp={resendingOtp}
				handleOpenSupport={handleOpenSupport}
				handleSubmitCode={handleSubmit}
				loading={loading}
				disabled={!isValidOTP}
				code={code}
				handleSetCode={handleSetCode}
			/>
			{showContactSupportDialog ? (
				<DialogContactSupport
					visible={showContactSupportDialog}
					handleDismiss={toggleContactSupportDialog}
					supportEmail={supportEmail}
					buildType={buildType}
					currentAppVersion={currentAppVersion}
					companyName={companyName}
				/>
			) : null}
			{showSnackbarProp.visible && showSnackbarProp.message ? (
				<Portal>
					<CustomSnackbar
						handleDismiss={() => setShowSnackbarProp({ visible: false })}
						visible={showSnackbarProp.visible}
						duration={1000}
						message={showSnackbarProp.message}
					/>
				</Portal>
			) : null}
		</ScrollView>
	);
};

export default VerifyEmailContainer;
