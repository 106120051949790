/* -----------------Globals--------------- */
import { variatTitleMap } from "../../constants";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------UI--------------- */
import { Button, Text } from "react-native-paper";

/* -----------------Types--------------- */
import type { QuestionVariant } from "@memorang/types/src/session/question";

import { useAppTheme } from "@hooks/useAppTheme";
/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";

type Props = {
	cta: string;
	handleAction: () => void;
	icon?: string;
	isReview?: boolean;
	timeRemaining?: string;
	isSummativeTest?: boolean;
	variant?: QuestionVariant;
	isTimerExpired?: boolean;
	showPause?: boolean;
};

const TitleAndBody = ({
	title,
	body,
	isTimerExpired,
}: {
	title: string;
	body: string;
	isTimerExpired?: boolean;
}) => {
	const theme = useAppTheme();
	return (
		<Box flexDirection="column" gap={0}>
			<Text
				variant="labelSmall"
				style={{
					color: theme.colors.white,
				}}
			>
				{title}
			</Text>
			<Text
				variant="titleMedium"
				style={{
					color: isTimerExpired ? theme.colors.error : theme.colors.white,
				}}
			>
				{body}
			</Text>
		</Box>
	);
};
const ItemContext = ({ variant }: { variant: QuestionVariant }) => (
	<TitleAndBody title={`${variatTitleMap[variant]}`} body="" />
);
export const StudySessionAppBarRight = ({
	cta,
	handleAction,
	icon,
	isReview,
	timeRemaining,
	isSummativeTest,
	variant,
	isTimerExpired,
	showPause = true,
}: Props) => {
	const theme = useAppTheme();
	return (
		<Box flexDirection="row" gap={16}>
			{showPause && (
				<Button
					icon={icon}
					mode="outlined"
					style={{
						borderColor: theme.colors.white,
					}}
					textColor={theme.colors.white}
					onPress={handleAction}
				>
					{cta}
				</Button>
			)}
			{isSummativeTest && (
				<Box flexDirection="row" alignItems="center" gap={6}>
					<Icon
						name={isReview ? "format-list-checks" : "clock-outline"}
						size={24}
						color={theme.colors.white}
					/>
					{isReview ? (
						variant ? (
							<ItemContext variant={variant} />
						) : null
					) : (
						<TitleAndBody
							title="Time Remaining"
							isTimerExpired={isTimerExpired}
							body={timeRemaining!}
						/>
					)}
				</Box>
			)}
		</Box>
	);
};
