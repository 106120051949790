// ----------------- React Native ---------------
import { SectionList } from "react-native";

import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
// ----------------- Local Imports ---------------
import type { MoreSettingsItem, MoreSettingsSection } from "../types";
import MoreSettingsListItem from "./MoreSettingsListItem";
interface Props {
	sections: MoreSettingsSection[];
	handleClickMoreSettingsItem: (type: MoreSettingsItem) => void;
}
const MoreRouteView = ({ sections, handleClickMoreSettingsItem }: Props) => {
	return (
		<SectionList
			sections={sections}
			renderSectionHeader={({ section: { sectionTitle } }) => {
				return (
					<CustomListSubheaderContainer>
						{sectionTitle}
					</CustomListSubheaderContainer>
				);
			}}
			renderItem={({ item }) => {
				return (
					<MoreSettingsListItem
						item={item}
						handleClick={handleClickMoreSettingsItem}
					/>
				);
			}}
		/>
	);
};

export default MoreRouteView;
