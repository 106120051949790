import { baseNextjsUrl } from "@memorang/constants/src/urls";

const title = "Analysis";
const subtitle = "Personalized analysis and recommendations";

const ENDPOINT_SESSION_ANALYSIS = `${baseNextjsUrl}/api/ai/sessionAnalysis`;

const events = {
	viewReportClicked: "mem_view_report_clicked",
	fullReportViewed: "mem_full_report_viewed",
	readingTime: "mem_reading_time",
};
export { title, subtitle, ENDPOINT_SESSION_ANALYSIS, events };
