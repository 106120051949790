/* ----------------- Globals --------------- */
import { useState } from "react";

/* ----------------- Constants --------------- */
import { cacheKeys } from "@constants/cache-keys";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
/* ----------------- Hooks --------------- */
import { useMutation, useQuery } from "@tanstack/react-query";

import { events } from "@constants/tracking";
import { useGlobalStore } from "@hooks/useGlobalStore";
import { trackCustomEvent } from "analytics";
import { router } from "expo-router";
import changeAvatar from "../mutations/ChangeAvatar";
/* ----------------- Queries & Mutations --------------- */
import { fetchAvatars } from "../queries/FetchAvatars";

export const useAvatars = (handleClose?: () => void) => {
	const [selectedAvatarId, setSelectedAvatarId] = useState<string>("");

	const { setUserInfo } = useAuthContext();
	const setShowAvatarUpdatedMessage = useGlobalStore(
		(state) => state.setShowAvatarUpdatedMessage,
	);

	const { data: avatars, isLoading } = useQuery({
		queryKey: [cacheKeys.avatars],
		queryFn: async () => {
			return await fetchAvatars();
		},
		staleTime: 1000 * 60 * 60 * 24 * 7,
	});

	const { mutate: changeAvatarMutate, isPending: isUpdatingAvatar } =
		useMutation({
			// biome-ignore lint/suspicious/useAwait: <explanation>
			mutationFn: async (url: string) => {
				trackCustomEvent({
					eventName: events.avatarChanged,
				});
				setUserInfo(url, setShowAvatarUpdatedMessage);
				setSelectedAvatarId("");
				changeAvatar(url);
				if (handleClose) {
					handleClose();
				} else {
					router.back();
				}
			},
		});

	const handleUpdateAvatar = () => {
		const avatar = avatars?.find((avatar) => avatar.id === selectedAvatarId);
		if (avatar) {
			changeAvatarMutate(avatar.url);
		}
	};

	return {
		avatars,
		isLoading,
		isUpdatingAvatar,
		changeAvatarMutate,
		selectedAvatarId,
		handleUpdateAvatar,
		setSelectedAvatarId,
	};
};
