/* -----------------Child components--------------- */
import CustomAppbar from "components/CustomAppbar";

import { appBarMeta } from "@constants/appbar";
import { pageViewTypes } from "@constants/tracking";
import ProgressContainer from "@features/dashboard/screens/ProgressContainer";
/* -----------------Features--------------- */
import { isWeb } from "@helpers/platform";
import useAnalytics from "@hooks/useAnalytics";
import { useDevice } from "@hooks/useDevice";
import DocumentTitle from "components/DocumentTitle";
const ProgressRoute = () => {
	const { isMobile } = useDevice();

	useAnalytics({
		type: pageViewTypes.progress,
	});

	const finalHeaderSubtitle = isMobile ? "" : appBarMeta.progress.description;
	return (
		<>
			<CustomAppbar
				showHambugerMenu
				showCommandBar={isWeb}
				options={{
					headerTitle: appBarMeta.progress.title,
					subtitle: finalHeaderSubtitle,
				}}
			/>
			<DocumentTitle title="Progress" />
			<ProgressContainer />
		</>
	);
};

export default ProgressRoute;
