// ====== React imports ======
import { useState } from "react";
import { Platform } from "react-native";
// ====== External Libraries ======
import { IconButton, Portal } from "react-native-paper";

// ====== Local components ======
import Box from "@memorang/ui/src/components/Box";
import CustomSnackbar from "@memorang/ui/src/components/CustomSnackbar";
import TooltipWrapper from "@memorang/ui/src/components/TooltipWrapper";
import { FeedbackDialog } from "../feedback/dialogs";
import type { FeedbackParams } from "../feedback/types";
import type { HandleTracking } from "../types";
import { useAppTheme } from "../useAppTheme";
import { events, ENDPOINT_RESEND_EMAIL, summaryActionIcons } from "./constants";

type Props = {
	text?: string;
	searchQuery?: string;
	showEmail?: boolean;
	handleTracking: HandleTracking;
	feedbackDialogParams?: FeedbackParams;
	align?:
		| "flex-start"
		| "flex-end"
		| "center"
		| "space-between"
		| "space-around"
		| "space-evenly"
		| undefined;
};

const SummaryActions = ({
	text,
	handleTracking,
	feedbackDialogParams,
	align = "flex-start",
	searchQuery,
	showEmail,
}: Props) => {
	const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
	const [showCopyFeedback, setShowCopyFeedback] = useState(false);
	const [showEmailFeedback, setShowEmailFeedback] = useState(false);
	const [feedbackEventName, setFeedbackEventName] = useState("");
	const theme = useAppTheme();
	const toggleShowFeedbackDialog = (): void => {
		setShowFeedbackDialog((prev) => !prev);
	};

	const toggleShowEmailFeedback = () => {
		setShowEmailFeedback((prev) => !prev);
	};

	const handleSendEmail = async () => {
		await fetch(ENDPOINT_RESEND_EMAIL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				examName: feedbackDialogParams?.context,
				markdown: text,
				toEmail: feedbackDialogParams?.email,
				companyName: feedbackDialogParams?.companyName,
			}),
		});
		handleTracking(events.emailSent);
	};

	const handleActionIconPress = (type: string): void => {
		switch (type) {
			case "copy": {
				const textToCopy = searchQuery
					? `# Question:\n${searchQuery}\n\n# Answer:\n${text}`
					: text!;
				if (Platform.OS === "web") {
					navigator.clipboard.writeText(textToCopy);
				}
				handleTracking(events.copilotResponseCopyClicked, {
					query: searchQuery!,
					summary: text!,
				});
				setShowCopyFeedback(true);
				break;
			}
			case "feedback-positive":
			case "feedback-negative": {
				setFeedbackEventName(events.sessionReportFeedbackProvided);
				toggleShowFeedbackDialog();
				break;
			}
			case "email": {
				handleTracking(events.emailTriggered);
				handleSendEmail();
				setShowEmailFeedback(true);
				break;
			}
			default:
				break;
		}
	};
	return (
		<Box flexDirection="row" justifyContent={align}>
			{summaryActionIcons(showEmail).map(({ icon, type, tooltip }) => (
				<TooltipWrapper key={icon} title={tooltip}>
					<IconButton
						icon={icon}
						size={18}
						iconColor={theme.colors.onPrimaryContainer}
						onPress={() => handleActionIconPress(type)}
					/>
				</TooltipWrapper>
			))}
			{showFeedbackDialog && feedbackDialogParams && (
				<FeedbackDialog
					visible={showFeedbackDialog}
					onDismiss={toggleShowFeedbackDialog}
					handleTracking={() => handleTracking(feedbackEventName)}
					feedbackDialogParams={feedbackDialogParams}
					title="Help us improve this feature"
				/>
			)}
			{showCopyFeedback && (
				<Portal>
					<CustomSnackbar
						visible={showCopyFeedback}
						message="Copied to clipboard"
						handleDismiss={() => setShowCopyFeedback(false)}
					/>
				</Portal>
			)}
			{showEmailFeedback && (
				<Portal>
					<CustomSnackbar
						visible={showEmailFeedback}
						message={`Email sent to ${feedbackDialogParams?.email}`}
						handleDismiss={toggleShowEmailFeedback}
					/>
				</Portal>
			)}
		</Box>
	);
};

export { SummaryActions };
