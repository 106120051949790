/* -----------------Globals--------------- */
import type { FlashList } from "@shopify/flash-list";
import { type RefObject, useCallback, useEffect, useState } from "react";

import type {
	Confidence,
	SessionChildItem,
	SessionItem,
} from "@memorang/types/src/session/common";
import { QuestionVariant } from "@memorang/types/src/session/question";
import { useAilaStore } from "../../hooks/useAilaStore";
/* -----------------Hooks--------------- */
import useSessionStore from "../../hooks/useSessionStore";

import { events } from "@constants/tracking";
import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useDailyProgressStore } from "@features/dashboard/hooks/useDailyProgressStore";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { isWeb } from "@helpers/platform";
import useAnalytics from "@hooks/useAnalytics";
import { useGlobalStore } from "@hooks/useGlobalStore";
import Box from "@memorang/ui/src/components/Box";
import { ConfirmDialog } from "components/ConfirmDialog";
import { router, useLocalSearchParams } from "expo-router";
import DialogSession from "../../components/SessionDialog";
/* -----------------Components--------------- */
import StudySessionBottomAppBar from "../../components/StudySessionBottomAppBar";
import ConfidencebarOnboardingDialog from "../../components/dialogs/ConfidencebarOnboardingDialog";
import { type DialogType, helpDialog } from "../../constants/dialog-configs";
import { useSession } from "../../hooks/useSession";
import { recordSummativeTestEvents } from "../../relay/RecordSummativeTestEvents";
import BreakDialogContainer from "../BreakDialogContainer";
import ExpiredBreakDialogContainer from "../BreakDialogContainer/ExpiredBreakDialogContainer";
import {
	calculateAndUpdateProgress,
	getNextButtonStates,
	getSessionConfig,
	getShouldShowConfidencesSubmitButtons,
	getShowButtonNavFinish,
	handlePostAnswer,
	handleSendSummativeAnswerEvent,
} from "./helpers";

interface Props {
	flashlistRef?: RefObject<FlashList<SessionItem>>;
}
// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
const StudySessionBottombarContainer = ({ flashlistRef }: Props) => {
	const state = useSessionStore((state) => state);

	const { index } = useLocalSearchParams<{
		index: string;
	}>();

	const { explicitCallTrackCustomEvent } = useAnalytics();
	const currentItemIndex = index ? Number.parseInt(index) - 1 : 0;

	const [tempSelectedConfidence, setTempSelectedConfidence] =
		useState<Confidence | null>(null);

	const { viewerId } = useAuthContext();
	const {
		updateCurrentItemIndex,
		sessionItems,
		id,
		inReviewMode,
		isSummativeTest,
		currentBlockIndex,
		totalTime,
		blockStates,
		timeElapsed,
		sessionTime,
		updateProgress,
		numGoal,
		updateCurrentBlockIndex,
		numericId,
		reportSessionType,
		serverExamBreak,
		showBreakDialog,
		toggleShowBreakDialog,
	} = state;

	const { handleEndSession } = useSession();

	const [startTime, setStartTime] = useState<Date>(new Date());

	const [helpDialogVisible, setHelpDialogVisible] = useState(false);

	const dailyProgressStore = useDailyProgressStore();

	const [showSessionDialogData, setShowSessionDialogData] = useState<{
		show: boolean;
		type?: DialogType;
	}>({
		show: false,
	});

	const toggleHelpDialog = () => setHelpDialogVisible((prev) => !prev);

	const {
		currentExamContext: { examName, examId },
	} = useExamContext();

	const updateShowDrawer = useAilaStore((state) => state.updateShowDrawer);

	const breaks = useSessionStore((state) => state.breaks);

	const lastBreak = breaks?.length ? breaks[breaks.length - 1] : null;

	const isBreakExpired = lastBreak?.status === "EXPIRED";

	const currentBlock = sessionItems[currentBlockIndex];
	const sectionId = currentBlock.id;
	const isBreakOngoing = useSessionStore((state) => state.isBreakOngoing);

	const examBreak = getSessionConfig(
		currentBlock.sessionConfig || serverExamBreak,
	);

	const numSections = sessionItems.length;
	const currentSessionItems = isSummativeTest
		? currentBlock?.children
		: sessionItems;
	const currentItem = currentSessionItems[currentItemIndex] as SessionChildItem;

	const currentItemId = currentItem?.id;

	const currentBlockState = currentBlock
		? blockStates.get(currentBlock.id)
		: undefined;
	const currentBlockProgress = currentBlockState?.currentProgress || 0;
	const markedItems = currentBlockState?.markedItemsForReview;
	const highlightedItems = currentBlockState?.highlightedItems;
	const isMarked = currentItem?.marked || markedItems?.includes(currentItemId);
	const highlightedHtml =
		currentItem?.highlight || highlightedItems?.get(currentItemId);

	const handleUpdateItemStatePeriodically = useCallback(
		(time: number) => {
			const itemState = {
				sessionId: id,
				sessionTime: time,
				itemId: currentItemId,
				userId: viewerId!,
				variant: QuestionVariant.mcq,
				progress: currentBlockProgress,
				marked: isMarked,
				highlight: highlightedHtml,
			};
			recordSummativeTestEvents(itemState);
		},
		[
			currentBlockProgress,
			currentItemId,
			highlightedHtml,
			id,
			isMarked,
			viewerId,
		],
	);

	useEffect(() => {
		if (
			timeElapsed &&
			timeElapsed % 300 === 0 &&
			!inReviewMode &&
			isSummativeTest &&
			!isBreakOngoing
		) {
			handleUpdateItemStatePeriodically(timeElapsed + (sessionTime || 0));
		}
	}, [
		handleUpdateItemStatePeriodically,
		inReviewMode,
		isSummativeTest,
		sessionTime,
		timeElapsed,
		isBreakOngoing,
	]);

	const isDiagnosticTest = reportSessionType === "DIAGNOSTIC";

	const confirmDialogConfig = helpDialog({
		sessionItems: currentSessionItems as SessionItem[],
		numSections,
		examName,
		totalTime,
		isDiagnosticTest,
		numTotalQuestions: sessionItems?.reduce(
			(acc, item) => acc + item.children.length,
			0,
		),
	});

	const hasShownConfidenceOnboardingDialog = useGlobalStore(
		(state) => state.hasShownConfidenceOnboardingDialog,
	);

	const setHasShownConfidenceOnboardingDialog = useGlobalStore(
		(state) => state.setHasShownConfidenceOnboardingDialog,
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	useEffect(() => {
		setStartTime(new Date());
	}, [currentItemId]);

	const showConfidenceSubmitButtons =
		getShouldShowConfidencesSubmitButtons(state);

	// next button states
	const {
		text: nextButtonText,
		disabled: disableNextButton,
		showButtonNavSkip,
	} = getNextButtonStates(state, currentItemIndex);
	const showButtonNavFinish = getShowButtonNavFinish(state, currentItemIndex);

	const sessionLength = isSummativeTest
		? sessionItems[currentBlockIndex].children.length
		: sessionItems.length;
	const isLastItem = currentItemIndex === sessionLength - 1;

	const prevButtonText = "Prev";

	const showPreviousButton = currentItemIndex > 0;

	const isLastBlock = isSummativeTest
		? currentBlockIndex === sessionItems?.length - 1
		: false;

	const isLastItemInTheBlock =
		currentItemIndex === currentBlock?.children?.length - 1;

	useEffect(() => {
		if (currentItemIndex === 0) {
			const initProgress = 1 / numGoal!;
			updateProgress(initProgress);
		}
	}, [currentItemIndex, numGoal, updateProgress]);
	const handleUpdateAnswers = (confidence: Confidence) => {
		handlePostAnswer(state, startTime, confidence, dailyProgressStore, examId);
	};

	const onConfidenceProvided = (confidence: Confidence) => {
		// trackCustomEvent({
		//   eventName: events.answerSubmitted,
		//   confidence,
		//   itemId: currentItemId,
		// });
		if (hasShownConfidenceOnboardingDialog) {
			handleUpdateAnswers(confidence);
		} else {
			setTempSelectedConfidence(confidence);
		}
	};

	const handlePrevious = () => {
		explicitCallTrackCustomEvent({
			eventName: events.sessionItemNavigated,
			sessionId: id,
			itemId: currentItemId,
			type: "previous",
		});
		router.setParams({
			index: String(currentItemIndex),
		});
		updateCurrentItemIndex(currentItemIndex - 1);
		calculateAndUpdateProgress(state, false);
	};

	useEffect(() => {
		if (!isWeb) {
			flashlistRef?.current?.scrollToIndex({
				index: currentItemIndex,
				animated: true,
			});
		}
	}, [currentItemIndex, flashlistRef]);
	// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
	const handleNext = () => {
		const eventParams = {
			index: currentItemIndex,
			itemId: currentItemId,
			sessionId: id,
			inReviewMode: String(inReviewMode),
		};
		if (isLastItemInTheBlock && !inReviewMode && isSummativeTest) {
			updateShowDrawer(false);
			const type = isLastBlock ? "completeTest" : "completeSection";
			if (isSummativeTest && !inReviewMode) {
				handleSendSummativeAnswerEvent(
					state,
					currentItemIndex,
					viewerId!,
					startTime,
				);
			}
			if (examBreak && !isBreakExpired) {
				toggleShowBreakDialog(true);
			} else {
				setShowSessionDialogData({
					show: true,
					type,
				});
			}
			return;
		}
		if (showButtonNavFinish || isLastItem) {
			updateShowDrawer(false);
			explicitCallTrackCustomEvent({
				eventName: events.sessionFinishNavClicked,
				...eventParams,
				inReviewMode: String(inReviewMode),
			});
			if (inReviewMode) {
				router.back();
			} else {
				handleEndSession({
					id: id,
					sessionType: "FORMATIVE",
				});
				router.replace({
					pathname: "/(protected)/session/[id]/report",
					params: {
						id: id,
					},
				});
			}
			return;
		}
		explicitCallTrackCustomEvent({
			eventName: events.sessionItemNavigated,
			...eventParams,
			type: "next",
		});
		const newIndex = currentItemIndex + 1;

		router.setParams({
			index: String(newIndex + 1),
		});
		updateCurrentItemIndex(newIndex);
		if (isSummativeTest && !inReviewMode) {
			handleSendSummativeAnswerEvent(
				state,
				currentItemIndex,
				viewerId!,
				startTime,
			);
		}
		calculateAndUpdateProgress(state, true);
	};

	const progressText = isSummativeTest
		? ""
		: `ITEM ${currentItemIndex + 1} / ${sessionItems.length}`;

	const handleCloseDialog = () => {
		setShowSessionDialogData({
			show: false,
		});
	};
	const moveToNextBlock = () => {
		if (isLastBlock) {
			handleEndSession({
				id: id,
				sessionType: "SUMMATIVE",
			});
			router.replace({
				pathname: "/(protected)/session/[id]/report",
				params: {
					id: numericId,
				},
			});
		} else {
			router.setParams({
				index: "1",
			});
			updateCurrentItemIndex(1);
			updateCurrentBlockIndex(currentBlockIndex + 1);
		}
	};
	const handleClickActionButton = (type: string) => {
		if (type === "cancel") {
			handleCloseDialog();
		} else {
			handleCloseDialog();
			moveToNextBlock();
		}
	};
	const showConfidenceOnboardingDialog = tempSelectedConfidence != null;

	const handleTrackConfidenceClick = (confidence: Confidence) => {
		explicitCallTrackCustomEvent({
			eventName: events.sessionItemConfidenceClicked,
			confidence,
			itemId: currentItemId,
			sessionId: id,
		});
	};
	return (
		<Box maxWidth={"100%"} alignSelf="center" width="100%">
			<StudySessionBottomAppBar
				handleNext={handleNext}
				disableNextButton={disableNextButton}
				nextButtonText={nextButtonText}
				handlePrevious={handlePrevious}
				handleTrackConfidenceClick={handleTrackConfidenceClick}
				isSummativeTest={isSummativeTest}
				showButtonNavSkip={showButtonNavSkip}
				showButtonPrevious={showPreviousButton}
				prevButtonText={prevButtonText}
				showButtonNavFinish={showButtonNavFinish}
				onConfidenceProvided={onConfidenceProvided}
				showConfidenceSubmitButtons={showConfidenceSubmitButtons}
				progressText={progressText}
				toggleHelpDialog={toggleHelpDialog}
			/>
			{helpDialogVisible && (
				<ConfirmDialog
					visible={helpDialogVisible}
					title={confirmDialogConfig.title}
					icon={confirmDialogConfig.icon}
					body={confirmDialogConfig.body}
					dismissCallback={() => setHelpDialogVisible(false)}
					confirmCallback={() => setHelpDialogVisible(false)}
					confirmCta="Close"
				/>
			)}
			{showSessionDialogData.show && (
				<DialogSession
					type={showSessionDialogData.type}
					handleClickButton={handleClickActionButton}
					handleClose={handleCloseDialog}
					open={showSessionDialogData.show}
				/>
			)}
			{showConfidenceOnboardingDialog && (
				<ConfidencebarOnboardingDialog
					open={showConfidenceOnboardingDialog}
					handleClose={() => {
						if (tempSelectedConfidence) {
							handleUpdateAnswers(tempSelectedConfidence);
						}
						setTempSelectedConfidence(null);
						setHasShownConfidenceOnboardingDialog(true);
					}}
				/>
			)}
			{showBreakDialog && examBreak?.allotedTime && (
				<BreakDialogContainer
					showBreakDialog={showBreakDialog}
					setShowBreakDialog={toggleShowBreakDialog}
					breakTime={examBreak?.allotedTime}
					sectionId={sectionId}
				/>
			)}
			{examBreak?.allotedTime && (
				<ExpiredBreakDialogContainer
					setShowBreakDialog={toggleShowBreakDialog}
					breakTime={examBreak?.allotedTime}
					sectionId={sectionId}
					sessionId={id}
				/>
			)}
		</Box>
	);
};

export default StudySessionBottombarContainer;
