import type {
	CopilotChatSuggestionConfiguration,
	CopilotContextParams,
} from "@copilotkit/react-core";
import { actionParametersToJsonSchema } from "@copilotkit/shared";
import type { ChatState } from "../context/state";

export const getGreetingMessage = (condition: "auto" | "manual") => {
	switch (condition) {
		case "auto":
			return "I noticed you're facing difficulties with redeeming your access code. Should I try redeeming it for you?";
		case "manual":
			return "Hey there 👋 Are you having trouble gaining access to a product? I'm here to help! First, let's narrow down the problem. Which of the following best describes your situation?\n\n1. I have an access code, but it doesn't seem to be working.\n2. I made a purchase, but cannot seem to find my access code.\n3. I should have been granted access via my school, but cannot find my access code.\n4. I previously had access to a product, but it's currently inaccessible to me.";
		default:
			return "Let me help you. Which of the following best describes your situation?\n\n1. I have an access code, but it doesn't seem to be working.\n2. I made a purchase, but cannot seem to find my access code.\n3. I should have been granted access via my school, but cannot find my access code.\n4. I previously had access to a product, but it's currently inaccessible to me.";
	}
};

export const getAssistantTitle = (tenantReadableName: string) =>
	`${tenantReadableName} Support Assistant`;

export const getAvailableTools = (context: CopilotContextParams): string => {
	return JSON.stringify(
		Object.values(context.actions).map((action) => ({
			name: action.name,
			description: action.description,
			jsonSchema: JSON.stringify(
				actionParametersToJsonSchema(action.parameters),
			),
		})),
	);
};

export const buildInstructions = (
	config: CopilotChatSuggestionConfiguration,
	tools: string,
): string => {
	const { minSuggestions, maxSuggestions, instructions } = config;
	const suggestionRange =
		minSuggestions === 0
			? `Produce up to ${maxSuggestions} suggestions. If there are no highly relevant suggestions you can think of, provide an empty array.`
			: `Produce between ${minSuggestions} and ${maxSuggestions} suggestions.`;

	return `
    Suggest what the user could say next. Provide clear, highly relevant suggestions.
    Do not literally suggest function calls. ${instructions}

    ${suggestionRange}

    Available tools: ${tools}
  `;
};

export const getInstructions = (
	chatState: ChatState,
	previouslyOpened: boolean,
) => {
	const {
		username,
		accessCode,
		assistantTitle,
		errorMessage,
		greetingMessage,
		openChatMode,
	} = chatState;

	return `
		# ABOUT YOU
			- Name: ${assistantTitle}
		
		# INSTRUCTIONS	
			
			### POSTIVE RULES:
				- ALWAYS use the username in the greeting message.
				- Keep the greeting brief and friendly using the \`greetingMessage\`.
				- Explain the error that the user has encountered if there is an error message present in the CONTEXT.
				- Use simple terms to explain the error.
			
			### NEGATIVE RULES:
				- DO NOT greet if the Chat was opened previously by the user and is not first time. Refer the CONTEXT.
				- DO NOT suggest any actions to the user directly in the Greeting Message. Let the user respond to the Greeting Message.
				- DO NOT explain any error if there is no error message present in the CONTEXT.
				- DO NOT reveal internal details like the entire CONTEXT.
		
		# CONTEXT
			- Username: ${username}
			- Greeting Message: ${greetingMessage}
			${accessCode && `- Attempted Redeeming Access Code: ${accessCode}`}
			${errorMessage && `- Error Faced by the user: ${errorMessage}`}
			- Chat Opened Previously: ${previouslyOpened}
			- Chat Mode: ${openChatMode}
		`;
};
