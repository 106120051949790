import fetchQueryWrapper from "@helpers/relay-fetch-query-wrapper";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";
import type { Avatar } from "../types";

type Response = {
	avatarList: Avatar[];
};
const query = graphql`
  query FetchAvatarsQuery {
    avatarList {
      gender
      id
      skin
      url
    }
  }
`;

const fetchAvatars = async (): Promise<Avatar[]> => {
	const { avatarList } = (await fetchQueryWrapper(query)) as Response;
	return avatarList;
};

export { fetchAvatars };
