import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { useMemo } from "react";
import { ActivityIndicator, Text } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

type Props = {
	title: string;
	icon: keyof typeof MaterialCommunityIcons.glyphMap;
	status?: "completed" | "failed" | "running";
};

export const ToolLoadingIndicator = ({ title, icon, status }: Props) => {
	const theme = useAppTheme();

	const textColor =
		status === "failed" ? theme.colors.error : theme.colors.onSurfaceVariant;

	const tool = useMemo(() => {
		switch (status) {
			case "running":
				return (
					<>
						<ActivityIndicator
							animating={true}
							color={theme.colors.primary}
							size={32}
						/>
						<MaterialCommunityIcons
							name={icon}
							size={16}
							color={theme.colors.text}
							style={{
								position: "absolute",
							}}
						/>
					</>
				);
			case "completed":
				return (
					<MaterialCommunityIcons
						name="checkbox-marked-circle"
						color={theme.colors.primary}
						size={24}
						style={{
							paddingVertical: 4,
						}}
					/>
				);
			case "failed":
				return (
					<MaterialIcons
						name="report-gmailerrorred"
						color={theme.colors.error}
						size={24}
						style={{
							paddingVertical: 4,
						}}
					/>
				);
			default:
				return null;
		}
	}, [
		status,
		icon,
		theme.colors.primary,
		theme.colors.error,
		theme.colors.text,
	]);

	return (
		<Box
			flexDirection="row"
			alignItems="center"
			columnGap={8}
			paddingVertical={4}
		>
			<Box justifyContent="center" alignItems="center">
				{tool}
			</Box>
			<Text
				variant="labelMedium"
				style={{
					color: textColor,
				}}
			>
				{title}
			</Text>
		</Box>
	);
};
