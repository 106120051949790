/**
 * @generated SignedSource<<bd887bd492eea2bd2ca3b7cb26a41d36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LeaderboardType = "ALLTIME" | "MONTH" | "WEEK" | "%future added value";
export type FetchLeaderboardQuery$variables = {
  scope?: LeaderboardType | null;
  tenantId: string;
};
export type FetchLeaderboardQuery$data = {
  readonly leaderboard: ReadonlyArray<{
    readonly avatar: string | null;
    readonly username: string | null;
    readonly xp: number | null;
  } | null> | null;
};
export type FetchLeaderboardQuery = {
  response: FetchLeaderboardQuery$data;
  variables: FetchLeaderboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "scope",
        "variableName": "scope"
      },
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "LeaderboardRecord",
    "kind": "LinkedField",
    "name": "leaderboard",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "xp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchLeaderboardQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FetchLeaderboardQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "38b085c3b640a0929a4b68b2f388508f",
    "id": null,
    "metadata": {},
    "name": "FetchLeaderboardQuery",
    "operationKind": "query",
    "text": "query FetchLeaderboardQuery(\n  $tenantId: String!\n  $scope: LeaderboardType\n) {\n  leaderboard(tenantId: $tenantId, scope: $scope) {\n    xp\n    username\n    avatar\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd4dfe0df89fbc14adce31ceba61904f";

export default node;
