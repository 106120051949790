/* -----------------UI--------------- */
import { Badge, List } from "react-native-paper";

import Box from "@memorang/ui/src/components/Box";
import { useAppTheme } from "../useAppTheme";
/* -----------------Helpers & Hooks--------------- */
import { getChapterTextAndSource } from "./helpers/parse-metadata";
import type { VectorDocument } from "./types";

type Props = {
	references: VectorDocument[];
	handleNavigateToReference?: (url: string) => void;
};

const References = ({ references, handleNavigateToReference }: Props) => {
	const theme = useAppTheme();
	return (
		<List.Section style={{ marginVertical: 0 }}>
			<List.Subheader style={{ padding: 0 }}>References</List.Subheader>
			{references.map((document, index) => {
				const { url, title, shortenedReferenceText, chapter } =
					getChapterTextAndSource(document);
				// TODO: Should be its own component
				return (
					<List.Item
						key={`${index}-${title}`}
						title={`${title} • ${chapter}`}
						description={`...${shortenedReferenceText}`}
						titleNumberOfLines={2}
						descriptionNumberOfLines={2}
						titleEllipsizeMode="tail"
						onPress={() => handleNavigateToReference?.(url)}
						descriptionEllipsizeMode="tail"
						style={{
							paddingVertical: 4,
						}}
						descriptionStyle={{ fontSize: 11 }}
						titleStyle={{ color: theme.colors.secondary, fontSize: 14 }}
						left={() => (
							<Box paddingTop={4}>
								<Badge
									style={{
										backgroundColor: theme.colors.secondary,
										color: theme.colors.onSecondary,
									}}
								>
									{index + 1}
								</Badge>
							</Box>
							// <Avatar.Text
							//   label={index + 1}
							//   size={24}
							//   labelStyle={{
							//     fontSize: 12,
							//     color: theme.colors.secondary,
							//     lineHeight: 22,
							//   }}
							//   style={{
							//     backgroundColor: 'transparent',
							//     borderColor: theme.colors.secondary,
							//     borderWidth: 1,
							//     borderRadius: 4,
							//   }}
							// />
						)}
					/>
				);
			})}
		</List.Section>
	);
};

export default References;
