/* -----------------Globals--------------- */
import { useEffect } from "react";

/* -----------------UI--------------- */
import { Text } from "react-native-paper";

import { useExamContext } from "@features/exam/contexts/ExamContext";
import { useExams } from "@features/exam/hooks/useExams";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useLocalSearchParams } from "expo-router";

import { fetchExams } from "@features/exam/queries/FetchExams";
/* -----------------Types--------------- */
import type { ExamDetails } from "@features/exam/types";

/* -----------------Child components--------------- */
import { ProductsList } from "@features/dashboard/components/Store/ProductsList";
import AuthContainer from "@memorang/ui/src/components/AuthContainer";
import Box from "@memorang/ui/src/components/Box";
import Loader from "@memorang/ui/src/components/Loader";
import { useDevice } from "@memorang/ui/src/useDevice";

import { pageViewTypes } from "@constants/tracking";
import useAnalytics from "@hooks/useAnalytics";
import { useAppTheme } from "@hooks/useAppTheme";
/* -----------------Others--------------- */
import { useRouter } from "expo-router";
import OnboardingAppBar from "../components/OnboardingAppBar";

type Props = {
	handleNext: (step: string) => void;
};
export const ChooseExamContainer = ({ handleNext }: Props) => {
	const { category: selectedCategory, showBackButton = "true" } =
		useLocalSearchParams<{
			category: string;
			showBackButton: string;
		}>();

	const { handleSwitchExamContext } = useExamContext();
	const appId = useAppStore((store) => store.app.id);
	const tenantId = useAppStore((store) => store.tenant.id);
	const groupedExams = useExams((store) => store.groupedExams);
	const setExams = useExams((store) => store.setExams);
	const appFont = useAppStore((store) => store.app.font);

	const router = useRouter();
	const { isMobile } = useDevice();
	const theme = useAppTheme();
	useAnalytics({
		type: pageViewTypes.chooseExam,
		selectedCategory,
	});
	useEffect(() => {
		if (!groupedExams.length) {
			fetchExams(tenantId, appId).then((exams) => {
				setExams(exams);
			});
		}
	}, [tenantId, appId, setExams, groupedExams.length]);
	if (!groupedExams.length) {
		return <Loader />;
	}

	const filteredExams = groupedExams.filter((exam) => {
		return selectedCategory
			? exam.title.toLocaleLowerCase() === selectedCategory?.toLocaleLowerCase()
			: true;
	});

	const interceptHandleSwitchExamContext = async (examDetails: ExamDetails) => {
		await handleSwitchExamContext(examDetails);
		handleNext("userdetails");
	};

	return (
		<Box flex={1}>
			<OnboardingAppBar
				elevated
				onBackAction={
					showBackButton === "true"
						? () => {
								router.setParams({
									step: "category",
									category: selectedCategory,
								});
							}
						: undefined
				}
				showLogo={showBackButton === "false" && !isMobile}
				showProfileMenu={false}
				title={"Choose Exam"}
			/>
			<Box paddingTop={24} flex={1} backgroundColor={theme.colors.surface}>
				<Text
					variant="bodyMedium"
					style={{
						paddingHorizontal: 16,
						textAlign: "center",
						fontWeight: undefined,
						fontSize: 16,
					}}
				>
					Which specific
					<Text
						style={{
							fontWeight: "bold",
							fontFamily: `${appFont}-semibold`,
						}}
					>
						{selectedCategory ? ` ${selectedCategory?.toLowerCase()} ` : " "}
					</Text>
					exam are you preparing for?
				</Text>
				<AuthContainer maxWidth="xs">
					<ProductsList
						products={filteredExams}
						showProducts={false}
						showChevronRight
						handleSwitchExamContext={interceptHandleSwitchExamContext}
					/>
				</AuthContainer>
			</Box>
		</Box>
	);
};
