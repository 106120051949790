import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import type { FlashList } from "@shopify/flash-list";
/* -----------------Globals--------------- */
import { useRef } from "react";

/* -----------------Types--------------- */
import type { SessionItem } from "@memorang/types/src/session/common";

/* -----------------Components--------------- */
import Box from "@memorang/ui/src/components/Box";
import SessionList from "./SessionList";

import { isWeb } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
import { ScrollView } from "react-native";
import StudySessionBottombarContainer from "../containers/StudySessionBottombarContainer";
/* -----------------Containers--------------- */
import StudySessionFeedbackContainer from "../containers/StudySessionFeedbackContainer";
import { StudySessionAppBarContainer } from "./StudySessionAppBarContainer";

type Props = {
	sessionItems: SessionItem[];
	sessionId: string;
	isSummativeTest?: boolean;
	currentBlockIndex: number;
};
const StudySessionView = ({
	sessionItems,
	isSummativeTest,
	currentBlockIndex,
}: Props) => {
	const ref = useRef<FlashList<SessionItem>>(null);

	const { isMobile } = useDevice();

	const theme = useAppTheme();

	const finalSessionItems = isSummativeTest
		? sessionItems[currentBlockIndex].children
		: sessionItems;

	return (
		<Box flex={1} backgroundColor={theme.colors.background}>
			<BottomSheetModalProvider>
				<Box flex={1}>
					<StudySessionAppBarContainer />
					<Box
						flex={1}
						backgroundColor={theme.colors.surface}
						paddingTop={isMobile ? 0 : 24}
					>
						<ScrollView
							showsVerticalScrollIndicator={false}
							contentContainerStyle={{
								gap: 16,
								flexGrow: 1,
								paddingBottom: 180,
								alignItems: "center",
							}}
						>
							<SessionList
								ref={ref}
								items={finalSessionItems as SessionItem[]}
							/>
							{isWeb && <StudySessionFeedbackContainer />}
						</ScrollView>
						{!isWeb && <StudySessionFeedbackContainer />}
					</Box>
				</Box>
			</BottomSheetModalProvider>
			<StudySessionBottombarContainer flashlistRef={ref} />
		</Box>
	);
};

export default StudySessionView;
