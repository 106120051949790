import { buildType, currentAppVersion } from "@helpers/expo-extras";
import { isWeb } from "@helpers/platform";
import { isDev } from "@memorang/constants/src/urls";
import * as SentryReact from "@sentry/react";
import * as SentryNative from "@sentry/react-native";

export const reactNavigationIntegration =
	SentryNative.reactNavigationIntegration();

export const getSentryInstance = () => {
	if (isWeb) {
		return SentryReact;
	}
	return SentryNative;
};

export const initSentry = () => {
	const dsn =
		"https://1374ef5814c84f11a93bbc41734e8c9a@o6329.ingest.us.sentry.io/5219043";
	if (isWeb) {
		if (!SentryReact.isInitialized()) {
			SentryReact.init({
				dsn,
				enabled: !isDev,
				tracesSampleRate: 1.0,
				environment: process.env.NODE_ENV,
				replaysSessionSampleRate: 0,
				replaysOnErrorSampleRate: isDev ? 0 : 1,
				// debug: isDev,
				_experiments: {
					profilesSampleRate: 0.5,
				},
				integrations: [
					SentryReact.replayIntegration({
						maskAllText: false,
						maskAllInputs: false,
					}),
					SentryReact.browserTracingIntegration(),
				],
			});
			SentryReact.setTag("app", buildType);
			SentryReact.setTag("version", currentAppVersion);
		}
	} else {
		SentryNative.init({
			dsn,
			enabled: !isDev,
			integrations: [reactNavigationIntegration],
			// debug: isDev,
			tracesSampleRate: 1.0,
			environment: process.env.NODE_ENV,
			enableTracing: true,
			_experiments: {
				profilesSampleRate: 1.0,
			},
		});
		SentryNative.setTag("app", buildType);
		SentryNative.setTag("version", currentAppVersion);
	}
};

export const replayManager = () => {
	const replay = SentryReact.getReplay();

	return {
		startReplay: () => {
			if (replay && isWeb) {
				try {
					replay.start();
				} catch (error) {
					console.error("Failed to start replay:", error);
				}
			}
		},
		stopReplay: () => {
			if (replay && isWeb) {
				try {
					replay.stop();
				} catch (error) {
					console.error("Failed to stop replay:", error);
				}
			}
		},
	};
};
