import { Image, type ImageSourcePropType, StyleSheet } from "react-native";
import { Button, Card, Text } from "react-native-paper";
import type { SvgProps } from "react-native-svg";
import Box from "../Box";
import Container from "../Container";

/* -----------------Styles--------------- */
const styles = StyleSheet.create({
	image: {
		width: "100%",
		height: 256,
		alignSelf: "center",
		resizeMode: "contain",
	},
	card: {
		borderWidth: 1,
		borderRadius: 8,
		borderColor: "#6F7977",
		cursor: "pointer",
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		width: "fit-content" as any,
	},
});

type EmptyStateOption = {
	label: string;
	action: () => void;
};

/* -----------------T--------------- */
export type EmptyViewProps = {
	title: string;
	subtitle: string;
	cta?: string;
	icon?: string;
	image?: ImageSourcePropType | React.FC<SvgProps>;
	handleAction?: () => void;
	emptyStateOptions?: EmptyStateOption[];
};

/* -----------------Functional component--------------- */
const EmptyView = (props: EmptyViewProps) => {
	const { title, subtitle, cta, icon, image, handleAction, emptyStateOptions } =
		props;

	const showFab = cta;

	const EmptyImage = () => {
		if (!image) {
			return null;
		}
		if ((image as string).length === 1) {
			const SVG = image;
			//@ts-ignore
			return <SVG width={256} height={256} />;
		}
		return <Image source={image as ImageSourcePropType} style={styles.image} />;
	};
	return (
		<Container
			style={{
				height: "100%",
				justifyContent: "center",
			}}
		>
			<Box
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				paddingHorizontal={24}
				gap={16}
				height={"100%"}
				marginBottom={cta ? 150 : 24}
			>
				{image && <EmptyImage />}
				<Box gap={8}>
					<Text
						style={{
							textAlign: "center",
						}}
						variant="titleMedium"
					>
						{title}
					</Text>
					<Text
						style={{
							textAlign: "center",
						}}
						variant="bodySmall"
					>
						{subtitle}
					</Text>
				</Box>
				{showFab ? (
					<Button onPress={handleAction} icon={icon} mode="contained">
						{cta}
					</Button>
				) : null}
				{emptyStateOptions?.length
					? emptyStateOptions.map(({ action, label }, index) => (
							<Card
								style={styles.card}
								onPress={action}
								key={`${index}-${label}`}
								elevation={0}
							>
								<Box
									flexDirection="row"
									gap={8}
									paddingLeft={icon ? 8 : 16}
									paddingRight={16}
									paddingVertical={6}
									alignItems="center"
								>
									<Text variant="bodySmall">{label}</Text>
								</Box>
							</Card>
						))
					: null}
			</Box>
		</Container>
	);
};

export default EmptyView;
