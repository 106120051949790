/* -----------------------Globals-------------------- */
import { useCallback, useEffect, useState } from "react";

/* -----------------------UI-------------------- */
import Container from "@memorang/ui/src/components/Container";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
/* -----------------------Hooks-------------------- */
import { useExamContext } from "@features/exam/contexts/ExamContext";
import useSessionStore from "@features/session/hooks/useSessionStore";
import { useAppStore } from "@hooks/useAppStore";
import { useAppTheme } from "@hooks/useAppTheme";
import { Redirect, useLocalSearchParams } from "expo-router";

/* -----------------------Features-------------------- */
import applyAccessCode, {
	type AccessCodeResult,
} from "@features/auth/relay/applyAccessCode";

/* -----------------------Helpers-------------------- */
import { events } from "@constants/tracking";
import { createRelativeURL } from "@helpers/url";
import { composeMessage } from "@memorang/helpers/src/email";
import { trackCustomEvent } from "analytics";

import {
	getAssistantTitle,
	getGreetingMessage,
	useActionTriggerContext,
	useContactSupportActionTrigger,
	useNavigateByRouteActionTrigger,
	usePurchaseVerificationActionTrigger,
	useRedeemCodeActionTrigger,
	useRedeemCopilotChat,
	useRedirectToContentActionTrigger,
	useVerifyMaskedEmailActionTrigger,
} from "@features/redeem-copilot";

/* -----------------------Session-------------------- */
import {
	getSupportDetails,
	handleAccessCodeResult,
	handleApplyCodeErrorMessage,
} from "../helpers";

import { validateCode } from "@helpers/string-utils";
/* -----------------Child components--------------- */
import CollectUserDetailsDialogContainer from "./CollectUserDetailsDialogContainer";
import PostRedeemOlxDialog from "./PostRedeemOlxDialog";
import RedeemCodeView from "./RedeemCodeView";
import SessionHelpDialog from "./SessionHelpDialog";

type Props = {
	handleNext: (result: AccessCodeResult) => void;
};

const RedeemCodeContainer = ({ handleNext }: Props) => {
	const supportEmail = useAppStore((store) => store.supportEmail);
	const company = useAppStore((store) => store.app.logo.company);
	const appName = useAppStore((store) => store.app.name);
	const appId = useAppStore((store) => store.app.id);
	const tenantId = useAppStore((store) => store.tenant.id);
	const tenantReadableName = useAppStore((store) => store.tenant.name);

	const [helpDialogVisible, setHelpDialogVisible] = useState(false);

	const [showCollectUserDialog, setShowCollectUserDialog] = useState(false);

	const { setPartialChatState } = useRedeemCopilotChat();

	const toggleShowCollectUserDialog = () => {
		setShowCollectUserDialog((prev) => !prev);
	};

	const [olxDates, setOlxDates] = useState<{
		beginStartDate: string;
		endStartDate: string;
		tempAccessCodeResponse?: AccessCodeResult;
	} | null>(null);

	const params = useLocalSearchParams<{
		redeemCode: string;
		showOnboarding: string;
	}>();

	const pathCode = params ? params.redeemCode : "";
	const showOnboarding = params ? params.showOnboarding : "";

	const theme = useAppTheme();

	const [code, setCode] = useState(pathCode);

	const sessionItems = useSessionStore((state) => state.sessionItems);
	const examName = useSessionStore((state) => state.examName);
	const totalTime = useSessionStore((state) => state.totalTime);
	const numericId = useSessionStore((state) => state.numericId);

	useEffect(() => {
		setCode(pathCode);
	}, [pathCode]);

	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined,
	);

	const [loading, setLoading] = useState(false);

	const {
		isAuthenticated,
		loading: authLoading,
		userDetails,
		viewerId,
		email,
	} = useAuthContext();

	const { handleSwitchExamContext } = useExamContext();

	const username = userDetails?.name || "";
	const showError = errorMessage != null;

	// biome-ignore lint/correctness/useExhaustiveDependencies: Setting initial chat state
	useEffect(() => {
		setPartialChatState({
			userId: viewerId,
			username: userDetails?.name || "",
			userEmail: userDetails?.email || "",
			supportEmail,
			appName,
		});
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: Valid use case
	const handleApplyCode = useCallback(
		async ({ code }: { code?: string }) => {
			if (!code) {
				return { success: false, error: "No code entered" };
			}

			const validationError = validateCode(code);
			if (validationError) {
				return { success: false, error: validationError };
			}

			trackCustomEvent({
				eventName: events.promoCodeEntered,
				code,
			});

			try {
				const result = await applyAccessCode(code);
				await handleAccessCodeResult(
					result,
					appId,
					handleSwitchExamContext,
					setOlxDates,
					handleNext,
					tenantId,
				);
				return {
					success: true,
					error: "",
					resultData: result,
				};
			} catch (e) {
				const { error, bundleId } = handleApplyCodeErrorMessage(email || "", e);
				return { success: false, error, bundleId };
			}
		},
		[email, appId],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: Valid use case
	const handleSubmit = useCallback(async () => {
		setLoading(true);
		const { success, error, bundleId, resultData } = await handleApplyCode({
			code,
		});
		if (success) {
			setPartialChatState({
				username,
				accessCode: code,
				lastRedeemedBundleId: resultData?.data.bundleId,
				lastRedeemedResult: resultData,
			});
			setLoading(false);

			return;
		}

		setErrorMessage(error);
		setPartialChatState({
			username,
			accessCode: code,
			errorMessage: error,
			assistantTitle: getAssistantTitle(tenantReadableName),
			greetingMessage: getGreetingMessage("auto"),
			lastRedeemedBundleId: bundleId,
		});

		setLoading(false);
	}, [code, handleApplyCode]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: Valid use case
	const handleAskAssistant = useCallback(() => {
		const errMsg =
			errorMessage || "User does not have/could not find an Access Code.";

		setPartialChatState({
			openChat: true,
			openChatMode: "auto",
			username,
			userEmail: email,
			assistantTitle: getAssistantTitle(tenantReadableName),
			greetingMessage:
				"👋 Don't worry, I'm here to help you find your Access Code.",
			errorMessage: errMsg,
		});
	}, [userDetails, errorMessage]);

	const handlePressContactSupport = () => {
		const { subject, body } = getSupportDetails(appName, username, email || "");
		composeMessage(subject, body, supportEmail);
	};

	useActionTriggerContext({
		value: {
			username,
			email,
			tenantId,
			tenantReadableName,
			supportEmail,
			appName,
		},
	});

	useRedeemCodeActionTrigger({ handler: handleApplyCode, email: email || "" });
	usePurchaseVerificationActionTrigger({ tenantId, email: email || "" });
	useRedirectToContentActionTrigger();
	useNavigateByRouteActionTrigger({ redeemStep: "redeem" });
	useContactSupportActionTrigger({
		supportEmail,
		appName,
		supportHandler: handlePressContactSupport,
		...getSupportDetails(appName, username, email || ""),
	});
	useVerifyMaskedEmailActionTrigger({ email: email || "" });

	useEffect(() => {
		if (pathCode && isAuthenticated && !authLoading && viewerId) {
			handleSubmit();
		}
	}, [authLoading, handleSubmit, isAuthenticated, pathCode, viewerId]);

	if (!(isAuthenticated || authLoading)) {
		const newUrl = createRelativeURL("/(auth)/login", params);

		return <Redirect href={newUrl} />;
	}

	const handleChangeText = (text: string) => {
		if (showError) {
			setErrorMessage(undefined);
		}
		setCode(text);
	};

	const disableFab = !code || code.length < 6 || loading;

	const getCompanyLogo = () => {
		if (company) {
			const { light, dark } = company;
			return theme.dark ? dark : light;
		}
		return null;
	};

	const logo = getCompanyLogo();

	return (
		<Container maxWidth="xs">
			<RedeemCodeView
				title={appName}
				subtitle="Enter your complete access code including the dashes"
				logo={logo}
				defaultCode={pathCode}
				handleChangeText={handleChangeText}
				handleSubmit={handleSubmit}
				disableFab={disableFab}
				loading={loading}
				handleAskAssistant={handleAskAssistant}
				email={email || ""}
				errorMessage={errorMessage}
				showError={showError}
				showOnboarding={showOnboarding}
				handlePressContactSupport={handlePressContactSupport}
			/>
			<SessionHelpDialog
				helpDialogVisible={helpDialogVisible}
				sessionItems={sessionItems}
				examName={examName}
				totalTime={totalTime}
				numericId={numericId}
				setHelpDialogVisible={setHelpDialogVisible}
			/>
			<PostRedeemOlxDialog
				olxDates={olxDates}
				setOlxDates={setOlxDates}
				handleNext={handleNext}
				toggleShowCollectUserDialog={toggleShowCollectUserDialog}
			/>
			<CollectUserDetailsDialogContainer
				showCollectUserDialog={showCollectUserDialog}
				toggleShowCollectUserDialog={toggleShowCollectUserDialog}
				setHelpDialogVisible={setHelpDialogVisible}
			/>
		</Container>
	);
};

export default RedeemCodeContainer;
