import * as Clipboard from "expo-clipboard";
import { useEffect, useState } from "react";
import { IconButton } from "react-native-paper";

type Props = { text: string; size?: number; handlePress?: () => void };

const CopyButton = ({ text, size = 24, handlePress }: Props) => {
	const [copied, setCopied] = useState<boolean>(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (copied) {
				setCopied(false);
			}
		}, 1500);

		return () => clearTimeout(timeout);
	}, [copied]);

	const copyToClipboard = async (text: string) => {
		try {
			await Clipboard.setStringAsync(text);
			setCopied(true);
		} catch (err) {
			console.error("Failed to copy: ", err);
		}
	};

	const icon = copied ? "check" : "content-copy";
	const iconColor = copied ? "green" : "black";

	return (
		<IconButton
			icon={icon}
			iconColor={iconColor}
			size={size}
			onPress={() => {
				copyToClipboard(text);
				handlePress?.();
			}}
		/>
	);
};

export default CopyButton;
