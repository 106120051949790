import { useCopilotChat, useCopilotReadable } from "@copilotkit/react-core";
import { Role, TextMessage } from "@copilotkit/runtime-client-gql";
import { useEffect, useRef } from "react";
import { useRedeemCopilotChat } from "../context";
import { getInstructions } from "../helpers/assistant";

/* --------------- Analytics --------------- */
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../analytics";

export const useActionTriggerContext = ({ value }: { value: object }) => {
	const { appendMessage } = useCopilotChat();
	const { chatState } = useRedeemCopilotChat();
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	const { openChat, openChatMode } = chatState;

	useCopilotReadable({
		description: "The chat state config",
		value: { ...chatState, ...value },
	});

	const firstOpenRef = useRef<boolean>(true);

	const instructions = getInstructions(chatState, !firstOpenRef.current);

	const trackPopupEvent = (openChatMode: "auto" | "manual") => {
		if (openChatMode === "auto") {
			trackRedeemEvent(rcEvents.chatOpened, {
				interactionType: "auto",
				interactionElementType: "action",
			});

			return;
		}

		trackRedeemEvent(rcEvents.chatOpened, {
			interactionType: "manual",
			interactionElementType: "button",
		});
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: This is a valid useEffect
	useEffect(() => {
		if (openChat) {
			if (openChatMode === "auto") {
				appendMessage(
					new TextMessage({
						role: Role.System,
						content: instructions,
					}),
				);
			}

			if (openChatMode === "manual" && firstOpenRef.current) {
				appendMessage(
					new TextMessage({
						role: Role.System,
						content: instructions,
					}),
				);
			}

			trackPopupEvent(openChatMode);
			firstOpenRef.current = false;
		} else if (!firstOpenRef.current) {
			trackPopupEvent(openChatMode);
		}
	}, [openChat]);
};
