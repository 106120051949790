import { useExamContext } from "@features/exam/contexts/ExamContext";
import useBundleStore from "@features/store/hooks/useBundleStore";
import { hasBundleAccess } from "@helpers/content/tag";
import { getShowStudyPackViews } from "@helpers/exams";

export const useProductAccess = () => {
	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();
	const currentlySelectedBundleDetails = useBundleStore(
		(store) => store.currentlySelectedBundleDetails,
	);
	const currentlySelectedBundleDetailsForPracticeTests = useBundleStore(
		(store) => store.currentlySelectedBundleDetailsForPracticeTests,
	);

	const showStudyPackViews = getShowStudyPackViews(studyPackProductId);

	const hasStudyPackAccess =
		currentlySelectedBundleDetails && showStudyPackViews
			? hasBundleAccess(currentlySelectedBundleDetails)
			: false;

	const hasPracticeTestAccess = currentlySelectedBundleDetailsForPracticeTests
		? hasBundleAccess(currentlySelectedBundleDetailsForPracticeTests)
		: false;

	return {
		hasStudyPackAccess,
		hasPracticeTestAccess,
		showStudyPackViews,
	};
};
