// @flow
/* -----------------Globals--------------- */
import { useCallback, useState } from "react";

/* -----------------Types--------------- */
import type { SuggestionType } from "../types/suggest-correction";

import type { SessionChildItem } from "@memorang/types/src/session/common";
import suggestCorrection from "../relay/SuggestCorrection";
import useSessionStore from "./useSessionStore";

const useSuggestCorrection = (currentItemIndex?: number) => {
	const [loading, setLoading] = useState(false);
	const [source, setSource] = useState("");
	const [correction, setCorrection] = useState("");

	const sessionItems = useSessionStore((state) => state.sessionItems);
	const itemStates = useSessionStore((state) => state.itemStates);
	const getQuizModeDetails = (
		choices: SessionChildItem[],
		question?: string,
		selectedChoiceIds?: string[],
	) => {
		const correctChoice = choices.find((item) => item.isCorrect);
		const getChoices = () =>
			choices.map((choice) => {
				const currentChoice = {
					name: choice.stem,
					isCorrect: correctChoice?.id === choice.id,
					isChosen: selectedChoiceIds?.includes(choice.id),
				};
				return currentChoice;
			});

		const finalChoices = getChoices();
		const quiz = {
			question,
			choices: finalChoices,
		};
		return {
			mode: "QUIZ" as const,
			quiz,
		};
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const callSuggestCorrection = useCallback(
		async (suggestion: SuggestionType) => {
			setLoading(true);
			const { children, stem, answers, id } = sessionItems[
				currentItemIndex || 0
			] as SessionChildItem;

			const selectedChoiceIds =
				itemStates?.get(id)?.selectedChoiceIds || answers?.selectedChoiceIds;
			const modeDetails = getQuizModeDetails(
				children!,
				stem,
				selectedChoiceIds,
			);
			await suggestCorrection(id, suggestion, modeDetails);
			setLoading(false);
		},
		[currentItemIndex, itemStates, sessionItems],
	);

	const getFlipModeDetails = (
		frontOfCardContent: string,
		backOfCardContent: string,
	) => {
		const flip = {
			frontOfCardContent,
			backOfCardContent,
		};
		return {
			mode: "FLIP",
			flip,
		};
	};

	return {
		loading,
		callSuggestCorrection,
		getQuizModeDetails,
		getFlipModeDetails,
		setCorrection,
		setSource,
		source,
		correction,
	};
};

export default useSuggestCorrection;
