/* ----------------- UI Components --------------- */
import Box from "@memorang/ui/src/components/Box";
import Loader from "@memorang/ui/src/components/Loader";
import StickyButtonContainer from "@memorang/ui/src/components/StickyButtonContainer";
import ChangAvatarView from "../components/ChangeAvatarView";

/* ----------------- Custom Hooks --------------- */
import { useAvatars } from "../hooks/useAvatars";

const ChangeAvatarRouteContainer = () => {
	const {
		avatars,
		setSelectedAvatarId,
		selectedAvatarId,
		isUpdatingAvatar,
		handleUpdateAvatar,
	} = useAvatars();

	if (!avatars?.length) {
		return (
			<Box flex={1}>
				<Loader />
			</Box>
		);
	}

	return (
		<StickyButtonContainer
			buttonText={"Change Avatar"}
			onPress={handleUpdateAvatar}
			disabled={isUpdatingAvatar || !selectedAvatarId}
			loading={isUpdatingAvatar}
		>
			<ChangAvatarView
				avatars={avatars}
				handleSelectAvatar={(id) => setSelectedAvatarId(id)}
				selectedAvatarId={selectedAvatarId}
			/>
		</StickyButtonContainer>
	);
};

export default ChangeAvatarRouteContainer;
