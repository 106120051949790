import Box from "@memorang/ui/src/components/Box";
import LeaderboardPodium from "@memorang/ui/src/components/LeaderboardPodium";
/* -----------------Analytics--------------- */
import type { LeaderboardItemType } from "./types";

/* -----------------Child components--------------- */

type Props = {
	topRankers: LeaderboardItemType[];
};

const TopRankersList = ({ topRankers }: Props) => (
	<Box
		flexDirection="row"
		justifyContent="center"
		alignItems="flex-end"
		width="100%"
	>
		{topRankers.map(({ username, xp, rank, avatar }) => (
			<LeaderboardPodium
				key={username}
				title={username}
				xp={xp}
				rank={rank!}
				image={avatar}
			/>
		))}
	</Box>
);

export default TopRankersList;
