import { fetchData } from "@memorang/helpers/src/fetchData";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";
import relayEnv from "relay/relayenv";
import type { StudyGuideResponseItems } from "../types";

type Action = "GET_STUDY_GUIDE_ITEMS" | "GET_STUDY_GUIDE_TAGS";
type StudyGuideResponse = {
	fetchStudyGuideItems: StudyGuideResponseItems;
};

const query = graphql`
  query FetchStudyGuidesQuery(
    $bundleId: ID!
    $action: StudyGuideAction
    $tagID: ID
  ) {
    fetchStudyGuideItems(bundleId: $bundleId, action: $action, tagID: $tagID) {
      items {
        childTags {
          id
          label
          title
          type
          childTags {
            id
            label
            title
            type
            items {
              id
              label
              title
              type
              childTags {
                id
                label
                title
                type
                items {
                  id
                  label
                  title
                  type
                }
              }
            }
          }
          items {
            id
            label
            title
            type
          }
        }
        id
        items {
          id
          label
          title
          type
        }
        label
        title
        type
      }
    }
  }
`;

const fetchStudyGuides = async (
	bundleId: string,
	action?: Action,
	tagID?: string,
) => {
	const variables = {
		bundleId,
		action,
		tagID,
	};

	const { fetchStudyGuideItems } = (await fetchData(
		relayEnv,
		query,
		variables,
	)) as StudyGuideResponse;

	return {
		fetchStudyGuideItems,
	};
};

export default fetchStudyGuides;
