import { commitMutation, graphql } from "react-relay";
import relayEnv from "relay/relayenv";

interface Response {
	updateSessionConfig: boolean;
}

interface UpdateSessionConfigInput {
	allotedTime: number;
	section: string;
	startTime?: string;
	status: string;
	totalBreakTimeTakenInSeconds?: number;
}

const updateSessionConfig = async (
	input: UpdateSessionConfigInput,
	sessionId: string,
): Promise<boolean> => {
	const mutation = graphql`
    mutation UpdateSessionConfigMutation($config: [SessionConfigInput], $sessionId: ID) {
      updateSessionConfig(config: $config, sessionId: $sessionId) 
    }
  `;

	const promise = new Promise<boolean>((resolve, reject) => {
		const variables = {
			config: [input],
			sessionId: sessionId,
		};

		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const finalResponse = response as Response;
				resolve(finalResponse.updateSessionConfig);
			},
			onError: (err) => {
				reject(err);
			},
		});
	});
	return promise;
};

export { updateSessionConfig };
