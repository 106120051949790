import TaskCardSkeleton from "@memorang/ui/src/components/Skeletons/TaskCardSkeleton";
import { useDevice } from "@memorang/ui/src/useDevice";
import { FlatList } from "react-native";

export const TasksSkeleton = () => {
	const { isMobile } = useDevice();
	return (
		<FlatList
			horizontal
			showsHorizontalScrollIndicator={false}
			contentContainerStyle={{
				paddingBottom: 8,
				paddingHorizontal: isMobile ? 16 : 0,
				gap: 16,
			}}
			data={[...new Array(2).keys()]}
			renderItem={() => {
				return <TaskCardSkeleton />;
			}}
		/>
	);
};
