/* ----------------- React --------------- */
import { useState } from "react";

/* ----------------- Hooks --------------- */
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { useExams } from "@features/exam/hooks/useExams";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { formatDate, formatExamDate } from "@helpers/date";
import { getShowStudyPackViews } from "@helpers/exams";
import { isWeb } from "@helpers/platform";
/* ----------------- Helpers --------------- */
import { getPremiumContext } from "@memorang/helpers/src/bundle";

export const useMoreSettings = () => {
	const {
		currentExamContext: { examIcon, examName, examDate },
	} = useExamContext();

	const { userDetails } = useAuthContext();

	const userIcon = userDetails?.iconUrl;

	const [showExamsFilterDialog, setShowExamsFilterDialog] = useState(false);

	const toggleShowExamsFilterDialog = () => {
		setShowExamsFilterDialog((prev) => !prev);
	};

	const [showDatePicker, setShowDatePicker] = useState(false);

	const toggleShowDatePicker = () => {
		setShowDatePicker((prev) => !prev);
	};

	const groupedExams = useExams((store) => store.groupedExams);

	const exams = groupedExams?.flatMap(({ data }) => data);

	const purchasedExams = exams?.filter(({ exam: { bundles } }) => {
		return bundles.find((item) => {
			const { hasAccess } = getPremiumContext(item.metadata);
			return hasAccess;
		});
	});

	const purchasedProducts = purchasedExams?.flatMap(({ exam }) =>
		exam.bundles.filter((item) => {
			return (
				getPremiumContext(item.metadata).hasAccess &&
				(item.bundle.bundleType === "PRACTICE_TEST"
					? isWeb
					: getShowStudyPackViews(item.bundle.id))
			);
		}),
	);

	const moreSettingsSections = [
		{
			sectionTitle: "About You",
			data: [
				{
					icon: "account-outline" as const,
					title: "Profile",
					rightCta: "View",
					href: "/profile",
					type: "profile" as const,
				},
				{
					thumbnail: userIcon,
					title: "Change avatar",
					type: "change-avatar" as const,
					rightIcon: "account-edit-outline" as const,
				},
			],
		},
		{
			sectionTitle: "Your Exam",
			data: [
				{
					thumbnail: examIcon,
					title: examName,
					rightCta: "Switch",
					type: "switch-exam" as const,
				},
				{
					icon: "calendar-outline" as const,
					type: "change-date" as const,
					title: examDate ? formatExamDate(examDate) : "Select date",
					rightCta: "Update",
				},
			],
		},
		...(purchasedProducts.length > 0
			? [
					{
						sectionTitle: "Your Subscriptions",
						data: [
							...(isWeb
								? [
										{
											icon: "gift-outline" as const,
											title: "Redeem Purchase",
											type: "redeem" as const,
											href: "/redeem?step=redeem",
										},
									]
								: []),
							...purchasedProducts.map(
								({
									bundle: {
										name,
										icon: { url },
										id,
									},
									metadata,
								}) => {
									const endDate = metadata.subscription?.endDate;
									return {
										title: name,
										subtitle: endDate
											? `Expires in ${formatDate(endDate)}`
											: "",
										rightCta: "Extend",
										type: "subscription" as const,
										thumbnail: url,
										id: id,
									};
								},
							),
						],
					},
				]
			: []),
	];
	return {
		moreSettingsSections,
		toggleShowDatePicker,
		showDatePicker,
		showExamsFilterDialog,
		toggleShowExamsFilterDialog,
		purchasedProducts,
	};
};
