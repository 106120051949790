import { currentAppVersion, versionCode } from "@helpers/expo-extras";
import { mixpanelStorage } from "@helpers/storage";
import { Mixpanel } from "mixpanel-react-native";
import { OneSignal } from "../libs/onesignal";
import { getTokens } from "./constants";
type UserProps = {
	userId: string;
	username: string;
	email: string;
};

const buildVersionData = `${currentAppVersion}${versionCode ? ` (${versionCode})` : ""}`;

const { mixpanelToken } = getTokens();

const trackAutomaticEvents = false; // not compatible with expo
const useNative = false; // for expo & web
const optOutTrackingDefault = false;

export const mixpanel = new Mixpanel(
	mixpanelToken,
	trackAutomaticEvents,
	useNative,
	mixpanelStorage,
);

export const initializeMixpanel = () => {
	mixpanel.init(optOutTrackingDefault);
};

const identifyMixpanelUser = (userId: string) => {
	if (userId) {
		mixpanel.identify(userId);
		mixpanel.getPeople().set({
			mem_build_version: buildVersionData,
		});
		if (OneSignal) {
			OneSignal.login(userId);
		}
	}
};
const trackLogin = (userId?: string) => {
	mixpanel.track("mem_login", {
		build_version: buildVersionData,
	});
	if (userId) {
		identifyMixpanelUser(userId);
	}
};

const registerUserProperties = (params: UserProps) => {
	const { userId, email, username } = params;

	mixpanel.getPeople().set({
		uuid: userId,
		$email: email,
		$username: username,
		mem_build_version: buildVersionData,
	});
	if (OneSignal) {
		OneSignal.User.addEmail(email);
	}
};

const updateUserProperties = (properties: Record<string, string>) => {
	for (const key of Object.keys(properties)) {
		mixpanel.getPeople().set({
			[key]: properties[key],
		});
	}
};
const trackLogout = () => {
	mixpanel.track("mem_logout", {
		build_version: buildVersionData,
	});
	mixpanel.reset();
};

/* ======== PAGE VIEWS ========= */

const trackPageView = (
	params: Record<string, string | number | undefined> = {},
) => {
	// Mixpanel
	mixpanel.track("mem_page_viewed", {
		...params,
		build_version: buildVersionData,
	});
	if (params.type) {
		mixpanel.getPeople().union("mem_pages_visited", [params.type]);
	}
	mixpanel.getPeople().increment("mem_pages_viewed", 1);
};

const setProfileProperties = (properties: Record<string, string>) => {
	for (const key of Object.keys(properties)) {
		mixpanel.getPeople().set({
			[key]: properties[key],
		});
	}
};

const mergeProfileProperties = (name: string, value: string) => {
	mixpanel.getPeople().union(name, [value]);
};
const trackCustomEvent = (
	params: Record<string, string | number | undefined> = {},
	userProperty?: Record<string, string>,
) => {
	const { eventName, ...rest } = params;
	mixpanel.track(eventName as string, {
		...rest,
		build_version: buildVersionData,
	});
	if (userProperty) {
		setProfileProperties(userProperty);
	}
};

export {
	registerUserProperties,
	trackPageView,
	trackLogin,
	trackLogout,
	trackCustomEvent,
	setProfileProperties,
	identifyMixpanelUser,
	mergeProfileProperties,
	updateUserProperties,
};
