export const extractMaskedEmail = (text: string) => {
	const maskedEmailRegex =
		/[a-zA-Z0-9._%+-]+(\*+)?[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.*-]+(\*+)?\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?/;
	const match = text.match(maskedEmailRegex);

	const maskedEmail = match?.[0];

	return { maskedEmail };
};

export const matchParts = (maskedPart: string, normalPart: string): boolean => {
	for (let i = 0; i < maskedPart.length; i++) {
		if (maskedPart[i] === "*") {
			continue;
		}
		if (maskedPart[i] !== normalPart[i]) {
			return false;
		}
	}

	return true;
};

export const isEmailMatch = (
	maskedEmail: string,
	normalEmail: string,
): boolean => {
	if (maskedEmail.length !== normalEmail.length) {
		return false;
	}

	const maskedParts = maskedEmail.split("@");
	const normalParts = normalEmail.split("@");

	if (maskedParts.length !== 2 || normalParts.length !== 2) {
		return false;
	}

	const [maskedLocal, maskedDomain] = maskedParts;
	const [normalLocal, normalDomain] = normalParts;

	return (
		matchParts(maskedLocal, normalLocal) &&
		matchParts(maskedDomain, normalDomain)
	);
};

export const formatCase = (text: string, splitBy = "_", joinBy = " ") => {
	return text
		.toLowerCase()
		.split(splitBy)
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(joinBy);
};
