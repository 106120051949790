/* -----------------Globals--------------- */
import { memo, useEffect } from "react";

/* -----------------Child components--------------- */
import { ChatUI } from "@memorang/ui/src/components/ChatUI/ChatUI";
import { TypingIndicator } from "@memorang/ui/src/components/ChatUI/TypingIndicator";
import { useDevice } from "@memorang/ui/src/useDevice";

/* -----------------Helpers & Hooks--------------- */
import { useQASearch } from "@features/session/hooks/useQASearch";
import { useAppStore } from "@hooks/useAppStore";
import isEqual from "react-fast-compare";
import { useAilaStore } from "../../hooks/useAilaStore";

import { events } from "@constants/tracking";
import QASearchOverlay from "@memorang/features/src/qa-search/QASearchOverlay";
import { trackCustomEvent } from "analytics";
/* -----------------Constants--------------- */
import { EXCLUDED_EXAM_IDS } from "./constants";

const QAChatWrapper = () => {
	const itemId = useAilaStore((state) => state.itemId);
	const userPrompt = useAilaStore((state) => state.userPrompt);
	const showDrawer = useAilaStore((state) => state.showDrawer);
	const updateShowDrawer = useAilaStore((state) => state.updateShowDrawer);
	const selectedQuery = useAilaStore((state) => state.selectedQuery);
	const updateSelectedQuery = useAilaStore(
		(state) => state.updateSelectedQuery,
	);
	const isItemAnswered = useAilaStore((state) => state.isItemAnswered);
	const originalQuestionData = useAilaStore(
		(state) => state.originalQuestionData,
	);
	const {
		viewerId,
		examId,
		contextId,
		handleTracking,
		feedbackDialogParams,
		examName,
	} = useQASearch();
	const { isMobile } = useDevice();

	const handleDrawerClose = () => {
		trackCustomEvent({
			eventName: events.copilotClosed,
			itemId,
		});
		updateShowDrawer(false);
		updateSelectedQuery("");
	};
	const aiAssistant = useAppStore((store) => store.app.aiAssistant);

	useEffect(() => {
		if (showDrawer && itemId) {
			trackCustomEvent({
				eventName: events.copilotOpened,
				itemId,
			});
		}
	}, [showDrawer, itemId]);
	// If exam is Nail Technician Theory (Vietnamese) or Cosmetology Theory (Spanish), don't show the drawer

	if (EXCLUDED_EXAM_IDS.includes(examId) || !itemId || !userPrompt) {
		return null;
	}

	if (!showDrawer) {
		return null;
	}

	return (
		<ChatUI.Drawer
			visible={showDrawer}
			handleClose={handleDrawerClose}
			drawerWidth={isMobile ? "100%" : 450}
			variant="persistent"
		>
			<QASearchOverlay
				userPrompt={userPrompt}
				loader={<TypingIndicator size={16} />}
				vectorStoreParams={{
					examId,
					itemId,
					contextId,
				}}
				userId={viewerId!}
				context={examName}
				handleTracking={handleTracking}
				feedbackDialogParams={feedbackDialogParams}
				initShowConversationMode
				handleClose={handleDrawerClose}
				defaultQuery={selectedQuery}
				isItemAnswered={isItemAnswered}
				originalQuestionData={originalQuestionData}
				assistantInfo={aiAssistant}
			/>
		</ChatUI.Drawer>
	);
};

export default memo(QAChatWrapper, isEqual);
