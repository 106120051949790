import ExamsFilterRouteView from "@features/exam/components/ExamsFilterRouteView";
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";
/* -----------------UI--------------- */
import { Dialog as PaperDialog } from "react-native-paper";

type Props = {
	visible: boolean;
	handleClose?: () => void;
};
export const ExamsFilterDialog = ({ visible, handleClose }: Props) => {
	return (
		<DialogWrapper
			visible={visible}
			handleClose={handleClose}
			showCloseButton={false}
			height="100%"
		>
			<PaperDialog.Content
				style={{
					flex: 1,
					paddingBottom: 0,
					paddingHorizontal: 0,
				}}
			>
				<ExamsFilterRouteView handleClose={handleClose} searchIcon="magnify" />
			</PaperDialog.Content>
		</DialogWrapper>
	);
};
