import AuthButton from "@memorang/ui/src/components/AuthButton";
import Box from "@memorang/ui/src/components/Box";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import { useAppTheme } from "@memorang/ui/src/useAppTheme";
import { useRouter } from "expo-router";
import { Image, Pressable } from "react-native";
import { Button, Text, TextInput } from "react-native-paper";
import { redeemContactSupportTemplate } from "../constants";
import ErrorView from "./ErrorView";

type Props = {
	title: string;
	subtitle: string;
	logo: {
		uri: string;
	} | null;
	defaultCode: string;
	handleChangeText: (text: string) => void;
	handleSubmit: () => void;
	disableFab: boolean;
	loading: boolean;
	handleAskAssistant: () => void;
	email: string;
	showError: boolean;
	showOnboarding: string;
	handlePressContactSupport: () => void;
	errorMessage?: string;
};

const RedeemCodeView = ({
	title,
	subtitle,
	logo,
	defaultCode,
	handleChangeText,
	handleSubmit,
	disableFab,
	loading,
	handleAskAssistant,
	email,
	errorMessage,
	showError,
	showOnboarding,
	handlePressContactSupport,
}: Props) => {
	const theme = useAppTheme();
	const router = useRouter();

	return (
		<Box flex={1} gap={24} padding={16} width="100%">
			{logo && (
				<Image
					source={logo}
					resizeMode="contain"
					style={{
						width: "100%",
						height: 88,
						borderRadius: 20,
						alignSelf: "center",
					}}
				/>
			)}
			<Box>
				<Text
					variant="titleLarge"
					style={{
						color: theme.colors.text,
						textAlign: "center",
					}}
				>
					{title}
				</Text>
				<Text
					variant="bodyMedium"
					style={{
						color: theme.colors.onSurfaceVariant,
						textAlign: "center",
					}}
				>
					{subtitle}
				</Text>
			</Box>
			<Box>
				<TextInput
					placeholder="XXX-XXX-XXXXXXXX"
					mode="outlined"
					autoFocus
					defaultValue={defaultCode}
					onChangeText={handleChangeText}
					disabled={loading}
				/>
				<ErrorView showError={showError} errorMessage={errorMessage} />
			</Box>
			<Button
				mode="contained"
				onPress={handleSubmit}
				disabled={disableFab}
				loading={loading}
			>
				Submit
			</Button>
			<Button mode="text" onPress={handleAskAssistant} disabled={!email}>
				{showError
					? "Having trouble redeeming? Ask Assistant!"
					: "Need help finding your Access Code?"}
			</Button>
			{showError && (
				<Pressable
					onPress={handlePressContactSupport}
					style={{
						paddingBottom: 8,
					}}
				>
					<MarkdownRenderer
						variant="explanation"
						textAlign="center"
						text={redeemContactSupportTemplate}
						overrideRules={{
							// biome-ignore lint/suspicious/noExplicitAny: Valid use case
							strong: (node: any, children: any) => (
								<Text key={node.key} variant="titleSmall">
									{children}
								</Text>
							),
						}}
					/>
				</Pressable>
			)}
			{showOnboarding && (
				<AuthButton
					onPress={() => {
						if (showOnboarding) {
							router.replace("/onboarding");
						}
					}}
					mode="text"
					disabled={loading}
				>
					{"Skip this step"}
				</AuthButton>
			)}
		</Box>
	);
};

export default RedeemCodeView;
