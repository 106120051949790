/* ----------------- Memorang UI --------------- */
import TaskCard from "@memorang/ui/src/components/TaskCard";
import { useDevice } from "@memorang/ui/src/useDevice";

import { ScrollButtonFlatlist } from "@memorang/ui/src/components/ChatUI/ScrollButtonFlatlist";
/* ----------------- Local Types --------------- */
import type { SectionItems } from "../../types";
import { TasksSkeleton } from "../Skeletons/TasksSkeleton";

interface Props {
	items: SectionItems;
	loading?: boolean;
}
const TasksSection = ({ items, loading }: Props) => {
	const { isMobile } = useDevice();
	if (loading) {
		return <TasksSkeleton />;
	}
	const filteredItems = items.filter((item) => "status" in item);

	return (
		<ScrollButtonFlatlist
			data={filteredItems}
			renderItem={({ item }) => {
				if ("status" in item) {
					return <TaskCard key={item.title} {...item} />;
				}
				return null;
			}}
			horizontal
			showsHorizontalScrollIndicator={false}
			contentContainerStyle={{
				paddingHorizontal: isMobile ? 16 : 2,
				gap: 16,
				paddingBottom: 8,
			}}
		/>
	);
};

export default TasksSection;
