/* -----------------Hooks--------------- */
import type { LearningModes } from "../types";

import useProcessTimeEstimate from "@hooks/useProcessTimeEstimate";
/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import CustomChip from "@memorang/ui/src/components/CustomChip";
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
import { numMaxItemPerSession } from "../constants";
import CustomSlider from "./CustomSlider";
import SessionItemsAvailabilityCaption from "./SessionItemsAvailabilityCaption";

/* -----------------Props--------------- */
type Props = {
	label: string;
	availableItems: number;
	requiredItems: number;
	handleSliderChange: (value: number) => void;
	mode: LearningModes;
	disabled?: boolean;
	numTotalItemsFiltered: number;
	testID?: string;
};

/* -----------------Component--------------- */
const ItemSlider = (props: Props) => {
	const {
		handleSliderChange,
		requiredItems,
		label,
		availableItems,
		disabled,
		numTotalItemsFiltered,
		testID = "custom-slider",
	} = props;

	const maxSliderValue = Math.min(availableItems, numMaxItemPerSession);
	const numItemsSelected = Math.min(requiredItems, maxSliderValue);
	const timeEstimateText = useProcessTimeEstimate(numItemsSelected);

	const isMinAvailableItems = availableItems <= 6;
	const finalStepCount = isMinAvailableItems ? 1 : 5;

	const showAvailabilityCaption = numTotalItemsFiltered > numMaxItemPerSession;

	return (
		<>
			<Box
				justifyContent="space-between"
				display="flex"
				flexDirection="row"
				alignItems="center"
			>
				<CustomListSubheaderContainer enableGutters>
					{label}
				</CustomListSubheaderContainer>
				<CustomListSubheaderContainer enableGutters>
					{timeEstimateText}
				</CustomListSubheaderContainer>
			</Box>
			<Box gap={8}>
				<Box
					flexDirection="row"
					alignItems="center"
					gap={16}
					justifyContent="space-between"
					paddingHorizontal={16}
				>
					<CustomSlider
						maxValue={maxSliderValue}
						handleChange={handleSliderChange}
						value={numItemsSelected}
						step={finalStepCount}
						disabled={disabled}
						testID={testID}
					/>
					<CustomChip
						style={{
							paddingVertical: 2,
						}}
					>{`${numItemsSelected} / ${maxSliderValue} items`}</CustomChip>
				</Box>
				{showAvailabilityCaption && (
					<Box paddingHorizontal={16}>
						<SessionItemsAvailabilityCaption
							numTotalItemsFiltered={numTotalItemsFiltered}
							numItemSelected={numItemsSelected}
						/>
					</Box>
				)}
			</Box>
		</>
	);
};

export default ItemSlider;
