import { pageViewTypes } from "@constants/tracking";
import StoreContainer from "@features/dashboard/screens/StoreContainer";
import useAnalytics from "@hooks/useAnalytics";
import DocumentTitle from "components/DocumentTitle";
import { Stack } from "expo-router";
const StoreRoute = () => {
	useAnalytics({
		type: pageViewTypes.store,
	});
	return (
		<>
			<Stack.Screen
				options={{
					headerShown: false,
				}}
			/>
			<DocumentTitle title="Store" />
			<StoreContainer />
		</>
	);
};

export default StoreRoute;
