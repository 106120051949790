import TooltipWrapper from "@memorang/ui/src/components/TooltipWrapper";
import { Chip, type ChipProps } from "react-native-paper";

type Props = {
	tooltip: string;
	text: string;
	icon: string;
	mode: ChipProps["mode"];
};
export const PremiumChip = ({ tooltip, text, icon, mode }: Props) => {
	return (
		<TooltipWrapper title={tooltip}>
			<Chip
				compact
				icon={icon}
				style={{
					alignSelf: "center",
				}}
				mode={mode}
			>
				{text}
			</Chip>
		</TooltipWrapper>
	);
};
