import type Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { LearningModes } from "../types";

const learningModeIcon: Record<string, keyof typeof Icon.glyphMap> = {
	flip: "cards-outline",
	quiz: "format-list-checks",
	match: "checkerboard",
	questionSet: "format-list-checks",
	simulations: "call-split",
	questions: "order-bool-descending",
};

const getLearningModes = (_numFlashcards: number, numQuestions: number) => [
	{
		title: "Questions",
		icon: learningModeIcon.questions,
		value: LearningModes.questions,
		disabled: !numQuestions,
		count: numQuestions,
	},

	// ...(numFlashcards > 0
	//   ? [
	//       {
	//         title: 'Flashcards',
	//         icon: learningModeIcon.flip,
	//         value: LearningModes.flashcards,
	//         disabled: !numFlashcards,
	//         count: numFlashcards,
	//       },
	//     ]
	//   : []),
];

export { getLearningModes };
