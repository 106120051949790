import { commitMutation, graphql } from "react-relay";
import type { MutationParameters } from "relay-runtime";
/* -----------------Third parties--------------- */
import relayEnv from "relay/relayenv";

interface DeleteUserMutationResponse {
	adminDeleteUser: {
		success: boolean;
		message?: string;
	};
}

interface DeleteUserMutationVariables {
	input: string;
	tenant: string;
}

interface DeleteUserMutation extends MutationParameters {
	response: DeleteUserMutationResponse;
	variables: DeleteUserMutationVariables;
}

/* ----------------------- Query ------------------ */
const mutation = graphql`
  mutation DeleteUserMutation($input: String!, $tenant: String) {
    adminDeleteUser(input: $input, tenant: $tenant) {
      success
      message
    }
  }
`;

const deleteUser = async (input: string, tenant: string): Promise<boolean> => {
	const promise = new Promise<boolean>((resolve, reject) => {
		const variables = {
			input,
			tenant,
		};

		commitMutation<DeleteUserMutation>(relayEnv, {
			mutation,
			variables,
			onCompleted: (response, errors) => {
				if (errors) {
					reject(errors);
				} else if (response.adminDeleteUser) {
					resolve(response.adminDeleteUser.success);
				} else {
					reject(new Error("AdminDeleteUser is undefined"));
				}
			},
			onError: (error) => {
				reject(error);
			},
		});
	});

	return promise;
};

export default deleteUser;
