/* -----------------UI--------------- */
import Drawer from "@mui/material/Drawer";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../useAppTheme";

type Props = {
	visible: boolean;
	handleClose?: () => void;
	children: React.ReactNode;
	variant?: "permanent" | "persistent" | "temporary";
	drawerWidth?: number | string;
};

export const ChatDrawer = ({
	visible,
	handleClose,
	children,
	variant,
	drawerWidth = 550,
}: Props) => {
	//TODO:Migrate to expo-drawer since this is a feature component. need to migrate all to use expo-router. for now
	// let's just use the drawer from react native paper

	const theme = useAppTheme();

	return (
		<Drawer
			sx={{
				width: visible ? drawerWidth : 0,
				flexShrink: 0,
				"& .MuiDrawer-paper": {
					width: drawerWidth,
					boxSizing: "border-box",
					backgroundColor: theme.colors.surface,
					borderLeft: `1px solid ${theme.colors.divider}`,
				},
			}}
			anchor="right"
			open={visible}
			variant={variant}
			elevation={2}
			onClose={handleClose}
		>
			{children}
		</Drawer>
	);
};
