/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import { useDevice } from "@memorang/ui/src/useDevice";
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";

export const SectionHeader = ({ sectionTitle }: { sectionTitle: string }) => {
	const { isMobile } = useDevice();

	return (
		<Box justifyContent="space-between" alignItems="center" flexDirection="row">
			<CustomListSubheaderContainer enableGutters={isMobile}>
				{sectionTitle}
			</CustomListSubheaderContainer>
		</Box>
	);
};
