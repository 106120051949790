/* -----------------UI--------------- */
import { Image, type ImageSourcePropType, Pressable } from "react-native";
import { useTheme } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { getHeight } from "./helpers";

/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";

const AppStoreDownloadBadge = ({
	src,
	href,
}: {
	src: ImageSourcePropType;
	href: string;
}) => {
	const height = getHeight("small");

	const handlePress = () => {
		//TODO:Add analytics
		window.open(href, "_blank");
	};

	return (
		<Pressable onPress={handlePress} key={href}>
			<Box
				style={{
					cursor: "pointer",
				}}
			>
				<Image
					source={src}
					style={{
						height,
						width: 130,
						resizeMode: "contain",
					}}
				/>
			</Box>
		</Pressable>
	);
};

type Badge = {
	light: ImageSourcePropType;
	dark: ImageSourcePropType;
};
export type StoreDownloadBadgeProps = {
	size?: "small" | "medium";
	ios: {
		badge: Badge;
		href?: string;
	};
	android: {
		badge: Badge;
		href?: string;
	};
};
const StoresDownloadBadge = ({ ios, android }: StoreDownloadBadgeProps) => {
	const theme = useTheme();
	const platforms = [
		{
			platform: "ios",
			...ios,
		},
		{
			platform: "android",
			...android,
		},
	];

	const hasNoLinks = !(ios.href || android.href);

	if (hasNoLinks) {
		return null;
	}
	return (
		<Box
			style={{
				gap: 8,
				display: "flex",
				flexDirection: "row",
				// justifyContent: size === 'small' ? 'space-evenly' : 'center',
			}}
		>
			{platforms.map(({ platform, badge, href }) =>
				href ? (
					<AppStoreDownloadBadge
						key={platform}
						src={theme.dark ? badge.dark : badge.light}
						href={href}
					/>
				) : null,
			)}
		</Box>
	);
};

export default StoresDownloadBadge;
