/* -----------------Globals--------------- */
import { useMemo, useState } from "react";

/* -----------------UI--------------- */
import { Image } from "expo-image";
import { List, Text } from "react-native-paper";

import { useAppTheme } from "../../useAppTheme";
import { useDevice } from "../../useDevice";
/* -----------------Helpers & Hooks--------------- */
import {
	getChapterTextAndSource,
	getShortenedText,
} from "./helpers/parse-metadata";

/* -----------------Types--------------- */
import type { VectorDocument } from "./types";

/* -----------------Child components--------------- */
import Box from "../Box";
import MarkdownRenderer from "../MarkdownRenderer";
import { ScrollButtonFlatlist } from "./ScrollButtonFlatlist";

type ReferencesProps = {
	references: VectorDocument[] | string;
	handleNavigateToReference?: (url: string) => void;
	referenceThumbnails?: Record<string, string>;
};

type ReferenceAccordionProps = {
	children: React.ReactNode;
	title?: string;
};

const ReferenceAccordion = ({
	children,
	title = "Read more",
}: ReferenceAccordionProps) => {
	const theme = useAppTheme();
	const [isAccordionExpanded, setIsAccordianExpanded] = useState(false);

	const handleOnPressAccordion = () => {
		setIsAccordianExpanded(!isAccordionExpanded);
	};

	return (
		<List.Accordion
			right={() => null}
			style={{
				backgroundColor: theme.colors.secondaryContainer,
				// ? This is a hack to remove the margin vertical inside List.Accordion which cannot be removed with custom style
				marginBottom: isAccordionExpanded ? 0 : -16,
			}}
			title={
				<Box flexDirection="row" alignItems="center" gap={4}>
					<Text variant="labelMedium">{title}</Text>
					<List.Icon
						icon={isAccordionExpanded ? "chevron-up" : "chevron-down"}
					/>
				</Box>
			}
			expanded={isAccordionExpanded}
			onPress={handleOnPressAccordion}
		>
			{children}
		</List.Accordion>
	);
};

const Reference = ({
	reference,
	handleNavigateToReference,
	referenceThumbnail,
}: {
	reference: VectorDocument;
	handleNavigateToReference?: (url: string) => void;
	referenceThumbnail?: string;
}) => {
	const theme = useAppTheme();
	const { isMobile } = useDevice();
	const {
		url,
		title: bookName,
		chapter,
		subChapter,
	} = getChapterTextAndSource(reference);

	const wordLength = isMobile ? 3 : 4;

	const title = useMemo(() => {
		const subChapterText = getShortenedText(subChapter, wordLength);
		const title = `${getShortenedText(chapter, wordLength)}${
			subChapterText.length > 0 ? " • " : ""
		}${subChapterText}`;

		return (
			<Text variant="titleSmall" numberOfLines={1}>
				{title}
			</Text>
		);
	}, [chapter, subChapter, wordLength]);

	const thumbnail = useMemo(() => {
		const imageUri = reference.metadata.thumbnailUri ?? referenceThumbnail;

		if (!imageUri) {
			return null;
		}

		return (
			<Image
				cachePolicy="memory-disk"
				source={{
					uri: imageUri,
				}}
				style={{
					width: isMobile ? 12 : 12,
					height: isMobile ? 16 : 16,
					alignSelf: "center",
				}}
			/>
		);
	}, [reference, referenceThumbnail, isMobile]);

	const description = useMemo(() => {
		const description = getShortenedText(bookName);
		return (
			<Box flexDirection="row" alignItems="center" columnGap={8} marginTop={8}>
				{thumbnail}
				<Text
					variant="labelSmall"
					numberOfLines={1}
					style={{
						color: theme.colors.onSurfaceVariant,
					}}
				>
					{description}
				</Text>
			</Box>
		);
	}, [thumbnail, bookName, theme.colors.onSurfaceVariant]);

	return (
		<List.Item
			title={title}
			description={description}
			onPress={() => handleNavigateToReference?.(url)}
			style={{
				paddingVertical: 6,
				backgroundColor: theme.colors.surface,
				borderRadius: 12,
				overflow: "hidden",
				justifyContent: "center",
				maxWidth: 250,
			}}
		/>
	);
};

export const References = ({
	references,
	handleNavigateToReference,
	referenceThumbnails,
}: ReferencesProps) => {
	const theme = useAppTheme();

	if (typeof references === "string") {
		return (
			<ReferenceAccordion title="Question">
				<Box paddingHorizontal={16}>
					<MarkdownRenderer
						text={references}
						customStyles={{
							text: {
								fontSize: 14,
								lineHeight: 21,
								color: theme.colors.text,
							},
							paragraph: {
								marginBottom: 4,
							},
							image: {
								height: 120,
								width: 120,
								marginTop: 16,
								marginVertical: 16,
							},
						}}
					/>
				</Box>
			</ReferenceAccordion>
		);
	}

	return (
		<ReferenceAccordion>
			<ScrollButtonFlatlist
				contentContainerStyle={{
					columnGap: 12,
					paddingHorizontal: 16,
				}}
				data={references}
				keyExtractor={(_item, index) => index.toString()}
				renderItem={({ item }) => {
					return (
						<Reference
							reference={item}
							handleNavigateToReference={handleNavigateToReference}
							referenceThumbnail={
								item.metadata.thumbnailUri ??
								referenceThumbnails?.[item.metadata.SourceId]
							}
						/>
					);
				}}
			/>
		</ReferenceAccordion>
	);
};
