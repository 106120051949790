import { ConfirmDialog } from "./ConfirmDialog";

type Props = {
	dismissCallback: () => void;
	visible: boolean;
	actions?: React.ReactNode;
};
const AccessRestrictedDialog = ({
	dismissCallback,
	visible,
	actions,
}: Props) => {
	const title = "Access restricted";
	const subtitle =
		"You are not authorized to access this content. If you believe this is an error, please contact support.";

	const icon = "shield-alert-outline";

	return (
		<ConfirmDialog
			title={title}
			body={subtitle}
			visible={visible}
			maxWidth={"xs"}
			icon={icon}
			dismissCallback={dismissCallback}
			actions={actions}
		/>
	);
};

export default AccessRestrictedDialog;
