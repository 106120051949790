import { HelperText } from "react-native-paper";

const ErrorView = ({
	showError,
	errorMessage,
}: {
	showError: boolean;
	errorMessage?: string;
}) => {
	if (showError) {
		return <HelperText type="error">{errorMessage}</HelperText>;
	}
	return (
		<HelperText type="info">
			If you’ve previously purchased an access code, please enter it here,
			including the dashes.
		</HelperText>
	);
};

export default ErrorView;
