import { cacheKeys } from "@constants/cache-keys";
import { isScreenshotMode } from "@constants/common";
import { mockedLeaderboard } from "@constants/mocked-data";
import { useAppStore } from "@hooks/useAppStore";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import fetchLeaderboard from "relay/user/FetchLeaderboard";
import { filterChips } from "../components/Leaderboard/constants";

const useLeaderboard = () => {
	const tenantId = useAppStore((store) => store.tenant.id);
	const [selectedChip, setSelectedChip] = useState(filterChips[0].type);

	const { data: leaderboardResponse, isLoading: fetchingLeaderboard } =
		useQuery({
			queryKey: [cacheKeys.leaderboard, tenantId, selectedChip],
			queryFn: () => fetchLeaderboard(tenantId, selectedChip),
			enabled: !!tenantId,
		});

	const leaderboards = leaderboardResponse?.leaderboard || [];
	const finalLeaderboards = isScreenshotMode
		? mockedLeaderboard[selectedChip]
		: leaderboards;

	const leaderboardPodiumData = finalLeaderboards
		.slice(0, 3)
		.map((item, index) => ({
			...item,
			rank: index + 1,
		}));

	const reorderedPodiumData = leaderboardPodiumData?.length
		? [
				leaderboardPodiumData[1],
				leaderboardPodiumData[0],
				leaderboardPodiumData[2],
			]
		: [];
	return {
		leaderboards: finalLeaderboards,
		fetchingLeaderboard,
		podiumData: reorderedPodiumData,
		selectedChip,
		handleSelectChip: (chip: string) => setSelectedChip(chip),
	};
};

export default useLeaderboard;
