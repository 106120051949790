/* -----------------Globals--------------- */
import { useEffect } from "react";
import { Image } from "react-native";
import { List, Text } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";

/* -----------------Types--------------- */

import AuthContainer from "@memorang/ui/src/components/AuthContainer";
/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import Loader from "@memorang/ui/src/components/Loader";

import { cacheKeys } from "@constants/cache-keys";
import { events, pageViewTypes } from "@constants/tracking";
import { fetchCategories } from "@features/exam/queries/FetchCategories";
import type { Category } from "@features/exam/types";
import useAnalytics from "@hooks/useAnalytics";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
import { useQuery } from "@tanstack/react-query";
import { trackCustomEvent } from "analytics";
/* -----------------Third parties--------------- */
import { useRouter } from "expo-router";
import OnboardingAppBar from "../components/OnboardingAppBar";

export const ChooseCategoryContainer = () => {
	const appId = useAppStore((store) => store.app.id);
	const tenantId = useAppStore((store) => store.tenant.id);

	const router = useRouter();
	const theme = useAppTheme();
	const { isMobile } = useDevice();

	const { data: categories } = useQuery({
		queryKey: [cacheKeys.categories, tenantId, appId],
		queryFn: () => fetchCategories(tenantId, appId),
	});

	useAnalytics({
		type: pageViewTypes.chooseCategory,
	});

	useEffect(() => {
		if (categories?.length && categories.length < 2) {
			router.setParams({
				step: "chooseexam",
				showBackButton: "false",
			});
		}
	}, [categories, router]);
	if (!categories?.length) {
		return <Loader />;
	}
	const ListCategory = ({ categories }: { categories: Category[] }) => {
		return (
			<Box justifyContent="center" gap={16} paddingTop={24}>
				{categories.map(({ icon, name, id }) => {
					return (
						<List.Item
							key={id}
							onPress={() => {
								trackCustomEvent({
									eventName: events.categorySelected,
									category: name,
								});
								router.setParams({
									step: "chooseexam",
									category: name,
								});
							}}
							style={{
								paddingHorizontal: 16,
							}}
							title={name}
							left={() => (
								<Image
									source={{
										uri: icon,
									}}
									style={{
										width: 32,
										height: 32,
										tintColor: theme.colors.onSurface,
									}}
								/>
							)}
							right={(p) => <List.Icon {...p} icon="chevron-right" />}
						/>
					);
				})}
			</Box>
		);
	};
	return (
		<>
			<OnboardingAppBar
				showLogo={!isMobile}
				elevated
				title={"Choose Category"}
				showProfileMenu={!isMobile}
			/>
			<AuthContainer
				containerStyle={{
					paddingHorizontal: 0,
				}}
			>
				<Text
					style={{
						textAlign: "center",
						paddingTop: 24,
					}}
					variant="titleMedium"
				>
					{"Which type of exam are you preparing for?"}
				</Text>
				<ListCategory categories={categories} />
			</AuthContainer>
		</>
	);
};
