import { useQuery } from "@tanstack/react-query";
import { Platform } from "react-native";
//@ts-ignore
import packageJson from "../../../package.json";
import type { RecommendedQuestions } from "../../types";
import {
	ENDPOINT_RECOMMENDED_QUERIES,
	RECOMMENDED_QUESTIONS_QUERY_KEY,
} from "../constants";
import type { EpubParams, VectorStoreParams } from "../types";

type Props = {
	userPrompt: string;
	userId?: string;
	vectorStoreParams?: VectorStoreParams;
	epubParams?: EpubParams;
	defaultRecommendedQueries?: string[];
	threadId?: string;
};
const version = packageJson.version;

export const useRecommendedQueries = ({
	userPrompt,
	vectorStoreParams,
	userId,
	epubParams,
	defaultRecommendedQueries,
	threadId,
}: Props) => {
	const { data, isLoading, isRefetching } = useQuery<RecommendedQuestions>({
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		staleTime: 5 * 60 * 1000,
		...(defaultRecommendedQueries
			? {
					initialData: {
						topic: "",
						followUps: defaultRecommendedQueries || [],
					},
				}
			: {}),
		queryKey: [RECOMMENDED_QUESTIONS_QUERY_KEY, userPrompt],
		queryFn: async ({ signal }) => {
			if (!vectorStoreParams) {
				return {
					topic: "",
					followUps: [],
				};
			}
			const finalCacheKey = `${version} : ${JSON.stringify(
				vectorStoreParams,
			)} : ${userPrompt}`;
			const result = await fetch(ENDPOINT_RECOMMENDED_QUERIES, {
				signal,
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					userPrompt,
					cacheKey: finalCacheKey,
					examId: vectorStoreParams.examId,
					userId,
					platform: Platform.OS,
					pageText: epubParams?.pageText,
					selectedText: epubParams?.selectedText,
					threadId,
				}),
			});
			if (!result.ok) {
				throw new Error("Failed to fetch recommended queries");
			}
			const body = await result.json();
			return body;
		},
	});

	return {
		isLoading: isLoading || isRefetching,
		recommendedQueries: data?.followUps ?? [],
		recommendedTitle: data?.topic ?? "",
	};
};
