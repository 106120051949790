import { type CopilotContextParams, extract } from "@copilotkit/react-core";
import { CopilotRequestType } from "@copilotkit/runtime-client-gql";
import type { Suggestion } from "../types";
import { buildInstructions, getAvailableTools } from "./assistant";

export const reloadSuggestions = async (
	context: CopilotContextParams,
	setSuggestions: (suggestions: Suggestion[]) => void,
	setSectionTitle?: (sectionTitle: string) => void,
	setSkipSuggestions?: (skipSuggestions: boolean) => void,
) => {
	const tools = getAvailableTools(context);
	const configurations = Object.values(context.chatSuggestionConfiguration);

	const allSuggestions: Suggestion[] = [];

	for (const config of configurations) {
		try {
			const instructions = buildInstructions(config, tools);

			const result = await extract({
				context,
				instructions,
				requestType: CopilotRequestType.Task,
				parameters: [
					{
						name: "sectionTitle",
						type: "string",
						description:
							"The title of the suggested actions section. This is shown as a header above the suggestions.",
					},
					{
						name: "skipSuggestions",
						type: "boolean",
						description: `Set to true if ANY of these conditions are met in the last assistant message:
							- Message ends with a question mark
							- Message contains numbered options (1., 2., etc.)
							- Message is asking user to provide information
							- Message is waiting for user input
							Otherwise, set to false.`,
					},
					{
						name: "suggestions",
						type: "object[]",
						description: "Array of suggestion objects.",
						attributes: [
							{
								name: "title",
								description:
									"The title of the suggestion. This is shown as a button and should be short.",
								type: "string",
							},
							{
								name: "message",
								description:
									"The message to send when the suggestion is clicked. This should be a clear, complete sentence and will be sent as an instruction to the AI.",
								type: "string",
							},
						],
					},
				],
				include: {
					messages: true,
					readable: true,
				},
				stream: ({ status, args }) => {
					const sectionTitle = args.sectionTitle;
					if (sectionTitle) {
						setSectionTitle?.(sectionTitle);
					}

					if (typeof args.skipSuggestions === "boolean") {
						setSkipSuggestions?.(args.skipSuggestions);
					}

					const suggestions = args.suggestions || [];
					const newSuggestions: Suggestion[] = suggestions.map(
						(suggestion: Suggestion, index: number) => {
							const isPartial =
								index === suggestions.length - 1 && status !== "complete";
							return {
								...suggestion,
								partial: isPartial,
							};
						},
					);
					setSuggestions([...allSuggestions, ...newSuggestions]);
				},
			});

			allSuggestions.push(...result.suggestions);
			if (typeof result.skipSuggestions === "boolean") {
				setSkipSuggestions?.(result.skipSuggestions);
			}
		} catch (error) {
			console.error("Error loading suggestions", error);
		}
	}
};
