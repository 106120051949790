import CollectUserDetailsDialog from "@features/account/components/dialogs/CollectUserDetailsDialog";
import type { Dispatch, SetStateAction } from "react";

type Props = {
	showCollectUserDialog: boolean;
	toggleShowCollectUserDialog: () => void;
	setHelpDialogVisible: Dispatch<SetStateAction<boolean>>;
};

const CollectUserDetailsDialogContainer = ({
	showCollectUserDialog,
	toggleShowCollectUserDialog,
	setHelpDialogVisible,
}: Props) => {
	const handlePostSubmitUserDetails = () => {
		toggleShowCollectUserDialog();
		setHelpDialogVisible(true);
	};

	if (showCollectUserDialog) {
		return (
			<CollectUserDetailsDialog
				visible={showCollectUserDialog}
				handleClose={handlePostSubmitUserDetails}
			/>
		);
	}
	return null;
};

export default CollectUserDetailsDialogContainer;
