/* -----------------UI--------------- */
import muiTheme from "@configs/muitheme";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------Helpers & Hooks--------------- */
import { CommandBarProvider } from "@features/command-bar/CommandBarProvider";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { ExamsFilterDialog } from "components/ExamsFilterDialog";
import { useRouter } from "expo-router";
import { useRegisterActions } from "kbar";

import { events } from "@constants/tracking";
import { useThemeContext } from "@contexts/ThemeContext";
import { trackCustomEvent } from "analytics";
/* -----------------Types--------------- */
import type { Action } from "kbar";
import { useEffect, useState } from "react";
import type { IconType } from "../types/icon";

const CommandBarActions = () => {
	const router = useRouter();

	const theme = useAppTheme();

	const { toggleDarkMode } = useThemeContext();

	const handleTracking = (option: string) => {
		trackCustomEvent({
			eventName: events.commandBarOptionClicked,
			option,
		});
	};

	const [showExamsFilterDialog, setShowExamsFilterDialog] = useState(false);

	useEffect(() => {
		for (const key in localStorage) {
			if (key.startsWith("relay-records")) {
				localStorage.removeItem(key);
			}
		}
	}, []);

	const createIcon = (name: IconType) => (
		<Icon name={name} size={24} color={theme.colors.onSurfaceVariant} />
	);

	const toggleShowExamsFilterDialog = () => {
		setShowExamsFilterDialog((prev) => !prev);
	};

	const actions: Action[] = [
		{
			name: "Home",
			icon: createIcon("home-outline"),
			keywords: "home, h",
			shortcut: ["h"],
			perform: () => {
				handleTracking("home");
				router.push("/(protected)/(tabs)/home");
			},
			id: "home",
		},
		{
			name: "Progress",
			icon: createIcon("chart-timeline-variant-shimmer"),
			keywords: "progress",
			shortcut: ["p"],
			perform: () => {
				handleTracking("progress");
				router.push("/(protected)/(tabs)/progress");
			},
			id: "progress",
		},
		{
			name: "Change exam",
			icon: createIcon("circle-edit-outline"),
			keywords: "change exam, ce",
			shortcut: ["ce"],
			perform: () => {
				handleTracking("change_exam");
				toggleShowExamsFilterDialog();
			},
			id: "exams",
		},
		{
			name: "Change theme",
			icon: createIcon("theme-light-dark"),
			keywords: "change theme",
			shortcut: ["ct"],
			perform: () => {
				handleTracking("change_theme");
				toggleDarkMode();
			},
			id: "theme",
		},
		{
			name: "Profile",
			icon: createIcon("account-outline"),
			keywords: "profile",
			shortcut: ["pf"],
			perform: () => {
				handleTracking("profile");
				router.push("/profile");
			},
			id: "profile",
		},
		{
			name: "Store",
			icon: createIcon("cart-outline"),
			keywords: "upgrade",
			shortcut: ["u"],
			perform: () => {
				handleTracking("store");
				router.push("/store");
			},
			id: "store",
		},
		{
			name: "Leaderboard",
			icon: createIcon("trophy-outline"),
			keywords: "leaderboard",
			shortcut: ["l"],
			perform: () => {
				handleTracking("leaderboard");
				router.push("/(protected)/(tabs)/leaderboard");
			},
			id: "leaderboard",
		},
		{
			name: "Redeem access code",
			icon: createIcon("gift-outline"),
			keywords: "redeem",
			shortcut: ["r"],
			perform: () => {
				handleTracking("redeem");
				router.push("/redeem");
			},
			id: "redeem",
		},
		{
			name: "Take assessment",
			icon: createIcon("clipboard-check-outline"),
			keywords: "assessment",
			shortcut: ["ta"],
			section: "Study",
			perform: () => {
				handleTracking("take_assessment");
				router.push("/practice-tests");
			},
			id: "assesment",
		},
		//TODO:Revisit this
		// ...(showStudyPackViews
		//   ? [
		//       {
		//         name: 'Take study session',
		//         icon: (
		//           <MemorangIcons
		//             name="study-pack-outline"
		//             size={24}
		//             color={theme.colors.onSurfaceVariant}
		//           />
		//         ),
		//         keywords: 'study session',
		//         shortcut: ['ss'],
		//         section: 'Study',
		//         perform: () => {
		//           router.push('/study-pack/');
		//         },
		//         id: 'study-session',
		//       },
		//     ]
		//   : []),
		{
			name: "Settings",
			icon: createIcon("cog-outline"),
			keywords: "settings",
			shortcut: ["s"],
			section: "Account",
			perform: () => {
				handleTracking("settings");
				router.push("/account/settings");
			},
			id: "settings",
		},
	];
	useRegisterActions(actions);
	return (
		<>
			{showExamsFilterDialog && (
				<ExamsFilterDialog
					visible={showExamsFilterDialog}
					handleClose={toggleShowExamsFilterDialog}
				/>
			)}
		</>
	);
};
export const CommandBarProviderWrapper = ({
	children,
}: {
	children: React.ReactElement;
}) => {
	const { isDarkMode } = useThemeContext();
	const theme = muiTheme(isDarkMode);

	return (
		<ThemeProvider theme={theme}>
			<CommandBarProvider>
				<CommandBarActions
					key={isDarkMode ? isDarkMode.toString() : "command-bar"}
				/>
				{children}
			</CommandBarProvider>
		</ThemeProvider>
	);
};
