import {
	useActionTriggerContext,
	useNavigateByRouteActionTrigger,
	usePurchaseVerificationActionTrigger,
	useRedeemCopilotChat,
	useRedirectToContentActionTrigger,
	useVerifyMaskedEmailActionTrigger,
} from "@features/redeem-copilot";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@hooks/useDevice";
import { useRouter } from "expo-router";
import { useEffect, useState } from "react";

/* -----------------Types--------------- */
import type { AccessCodeResult } from "@features/auth/relay/applyAccessCode";
import { fetchExamDetails } from "@features/exam/queries/FetchExamDetails";
import type { ExamDetails } from "@features/exam/types";
import Box from "@memorang/ui/src/components/Box";
import Loader from "@memorang/ui/src/components/Loader";
/* -----------------Child components--------------- */
import RedeemCodeSuccessView from "./RedeemCodeSuccessView";

const getProductsMapping = (accessCodeResult: AccessCodeResult) => {
	return accessCodeResult.productData && accessCodeResult.productData.length > 1
		? accessCodeResult.productData.map((product) => {
				return {
					icon: product.icon,
					title: product.bundleName,
					id: product.bundleId,
					description:
						product.bundleType === "PRACTICE_TEST"
							? "Practice Test"
							: "Study-Pack",
					features: product.features,
					isCustomAccess: product.isCustomAccess,
					examId: product.examId,
					bundleType: product.bundleType,
					beginStartDate: product.beginStartDate,
					endStartDate: product.endStartDate,
				};
			})
		: [
				{
					icon: accessCodeResult.data.icon,
					title: accessCodeResult.data.bundleName,
					id: accessCodeResult.data.bundleId,
					description:
						accessCodeResult.data.bundleType === "STUDY_PACK"
							? "Study-Pack"
							: "Practice Test",
					features: accessCodeResult.data.features,
					isCustomAccess: accessCodeResult.data.isCustomAccess,
					examId: accessCodeResult.data.examId,
					bundleType: accessCodeResult.data.bundleType,
					beginStartDate: accessCodeResult.data.beginStartDate,
					endStartDate: accessCodeResult.data.endStartDate,
				},
			];
};

const RedeemCodeSuccessContainer = ({
	accessCodeResult,
}: { accessCodeResult: AccessCodeResult }) => {
	const { isMobile } = useDevice();
	const appName = useAppStore((store) => store.app.name);
	const supportEmail = useAppStore((store) => store.supportEmail);
	const tenantId = useAppStore((store) => store.tenant.id);
	const tenantReadableName = useAppStore((store) => store.tenant.name);

	const router = useRouter();
	const { userDetails, viewerId } = useAuthContext();
	const { setPartialChatState } = useRedeemCopilotChat();
	const [examDetails, setExamDetails] = useState<ExamDetails | null>(null);

	const [showFreeQuizDialog, setShowFreeQuizDialog] = useState(false);

	const username = userDetails?.name || "";
	const email = userDetails?.email || "";
	const examId = accessCodeResult.data.examId;

	// biome-ignore lint/correctness/useExhaustiveDependencies: Setting initial chat state
	useEffect(() => {
		setPartialChatState({
			userId: viewerId,
			username: userDetails?.name || "",
			userEmail: userDetails?.email || "",
			supportEmail,
			appName,
		});
	}, []);

	useActionTriggerContext({
		value: {
			username,
			email,
			tenantId,
			tenantReadableName,
			supportEmail,
			appName,
		},
	});

	usePurchaseVerificationActionTrigger({ tenantId, email });
	useVerifyMaskedEmailActionTrigger({ email });
	useNavigateByRouteActionTrigger({ redeemStep: "success" });
	useRedirectToContentActionTrigger();

	const handleNavigateToProduct = () => {
		if (examDetails) {
			const hasPracticeTests = accessCodeResult.productData?.find(
				(item) => item.bundleType === "PRACTICE_TEST",
			);
			const url =
				!hasPracticeTests || isMobile ? "/study-pack" : "/practice-tests";
			router.push(url);
		}
	};
	const handleCloseFreeQuizDialog = (navigate?: boolean) => {
		setShowFreeQuizDialog(false);
		if (navigate) {
			handleNavigateToProduct();
		}
	};

	useEffect(() => {
		if (examId) {
			fetchExamDetails({
				examId,
				tenantId,
			}).then((response) => setExamDetails(response));
		}
	}, [examId, tenantId]);

	if (!(accessCodeResult && examDetails)) {
		return (
			<Box height="100%">
				<Loader />
			</Box>
		);
	}

	const title = "Access Granted";
	const products = getProductsMapping(accessCodeResult);

	return (
		<RedeemCodeSuccessView
			examName={examDetails?.exam.name || ""}
			products={products}
			title={title}
			showFreeQuizDialog={showFreeQuizDialog}
			handleCloseFreeQuizDialog={handleCloseFreeQuizDialog}
			examDetails={examDetails}
		/>
	);
};

export default RedeemCodeSuccessContainer;
