import { isWeb } from "@helpers/platform";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";

/* -----------------Child components--------------- */
import VerifyEmailContainer from "@features/auth/screens/VerifyEmailContainer";

/* -----------------Third parties--------------- */
import { Stack, useLocalSearchParams, useRouter } from "expo-router";

import { getAwsExportsJson } from "@configs/awsexports";
import { events, pageViewTypes } from "@constants/tracking";
import OnboardingAppBar from "@features/auth/components/OnboardingAppBar";
import { buildType, currentAppVersion } from "@helpers/expo-extras";
import { useDevice } from "@hooks/useDevice";
import { useGlobalStore } from "@hooks/useGlobalStore";
/* -----------------Analytics--------------- */
import {
	registerUserProperties,
	trackCustomEvent,
	trackLogin,
	trackPageView,
} from "analytics";
import DocumentTitle from "components/DocumentTitle";
import { useEffect } from "react";

const VerifyRoute = () => {
	const router = useRouter();

	const { isMobile } = useDevice();
	const setLastLoggedInEmail = useGlobalStore(
		(state) => state.setLastLoggedInEmail,
	);

	const finalAwsExports = getAwsExportsJson();

	const params = useLocalSearchParams<{
		redeemCode: string;
	}>();
	const redeemCode = params?.redeemCode;
	const appId = useAppStore((store) => store.app.id);
	const tenantId = useAppStore((store) => store.tenant.id);
	const onboarding = useAppStore((store) => store.onboarding);
	const companyName = useAppStore((store) => store.companyName);
	const supportEmail = useAppStore((store) => store.supportEmail);

	useEffect(() => {
		trackPageView({ type: pageViewTypes.verifyEmail });
	}, []);

	const handleNavigation = (back?: boolean) => {
		if (back) {
			router.back();
		}
	};

	const handlePostVerifyEmail = async (
		userId: string,
		email: string,
		username: string,
		userExists?: boolean,
	) => {
		setLastLoggedInEmail(email);
		trackLogin(userId);
		registerUserProperties({
			userId,
			email,
			username,
		});
		trackCustomEvent({
			eventName: userExists ? events.userLoggedIn : events.userRegistered,
			userId: userId,
			email: email,
			tenantId: tenantId,
		});

		const showOnboarding = onboarding?.find(
			(item) => item.step === "chooseContext",
		);

		if (userExists) {
			if (redeemCode && isWeb) {
				router.replace({
					pathname: "/redeem",
					params: {
						redeemCode,
					},
				});
			} else {
				router.replace("/(protected)/(tabs)/home");
			}
		} else if (showOnboarding) {
			if (isWeb) {
				router.replace({
					pathname: "/redeem",
					params: {
						...(redeemCode && { redeemCode }),
						step: "redeem",
						showOnboarding: "true",
					},
				});
			} else {
				router.replace("/(protected)/onboarding");
			}
		} else {
			router.replace("/(protected)/(tabs)/home");
		}
	};
	return (
		<>
			<Stack.Screen
				options={{
					headerShown: true,
					header: () => {
						return (
							<OnboardingAppBar
								title="Verify your email"
								showLogo={!isMobile}
								onBackAction={() => handleNavigation(true)}
							/>
						);
					},
				}}
			/>
			<DocumentTitle title="Verify your email" />
			<VerifyEmailContainer
				supportEmail={supportEmail}
				companyName={companyName}
				awsExports={finalAwsExports}
				buildType={buildType}
				currentAppVersion={currentAppVersion!}
				appId={appId}
				tenantId={tenantId}
				handlePostVerifyEmail={handlePostVerifyEmail}
			/>
		</>
	);
};

export default VerifyRoute;
