/* -----------------Child components--------------- */
import CustomAppbar from "components/CustomAppbar";

import { appBarMeta } from "@constants/appbar";
import { pageViewTypes } from "@constants/tracking";
/* -----------------Features--------------- */
import LeaderboardContainer from "@features/dashboard/screens/LeaderboardContainer";
import { isWeb } from "@helpers/platform";
import useAnalytics from "@hooks/useAnalytics";
import { useDevice } from "@hooks/useDevice";
import DocumentTitle from "components/DocumentTitle";
const LeaderboardRoute = () => {
	const { isMobile } = useDevice();

	useAnalytics({
		type: pageViewTypes.leaderboard,
	});
	return (
		<>
			<CustomAppbar
				showCommandBar={isWeb}
				showHambugerMenu
				options={{
					headerTitle: appBarMeta.leaderboard.title,
					subtitle: isMobile ? "" : appBarMeta.leaderboard.description,
				}}
			/>
			<DocumentTitle title="Leaderboard" />
			<LeaderboardContainer />
		</>
	);
};

export default LeaderboardRoute;
