import { useAppTheme } from "@hooks/useAppTheme";
import Box from "@memorang/ui/src/components/Box";
import { Text } from "react-native-paper";

const StudySessionAppBarLeft = ({
	title,
	userName,
	numericId,
}: {
	title: string;
	userName: string;
	numericId: number;
}) => {
	const theme = useAppTheme();
	return (
		<Box flexDirection="column" gap={2}>
			<Text
				variant="titleLarge"
				style={{
					color: theme.colors.white,
				}}
			>
				{title}
			</Text>
			<Text
				variant="bodyLarge"
				style={{ textTransform: "capitalize", color: theme.colors.white }}
			>
				{`${userName || ""} • Test #${numericId}`}
			</Text>
		</Box>
	);
};

export default StudySessionAppBarLeft;
