import { Text } from "react-native-paper";
import type {
	ActionHandlerReturn,
	ActionHandlerStatus,
	PurchaseResult,
	RedirectResult,
	SupportResult,
} from "../types";
import ContentSelector from "./ContentSelector";
import ProductDetails from "./ProductDetails";
import RedirectPrompt from "./RedirectPrompt";
import SupportDetails from "./SupportDetails";

// Define available actions and their result types
export const actionComponents = {
	purchaseVerification: {
		name: "purchaseVerification",
		loadingMessage: "Verifying Purchase Details...",
		resultType: {} as PurchaseResult,
	},
	contactSupport: {
		name: "contactSupport",
		loadingMessage: "Creating Support Email...",
		resultType: {} as SupportResult,
	},
	redirectToContent: {
		name: "redirectToContent",
		loadingMessage: "Finding your content...",
		resultType: {} as RedirectResult,
	},
} as const;

export type ActionComponent = typeof actionComponents;

// Type for available actions
export type ActionName = keyof typeof actionComponents;

// Type-safe props
type ActionRendererProps<T extends ActionName> = {
	actionName: T;
	result: ActionHandlerReturn<ActionComponent[T]["resultType"]>;
	status: ActionHandlerStatus;
};

export const ActionRenderer = <T extends ActionName>({
	actionName,
	result,
	status,
}: ActionRendererProps<T>) => {
	if (status !== "complete") {
		return <Text>{actionComponents[actionName].loadingMessage}</Text>;
	}

	const { success, resultData } = result;

	if (!(success && resultData)) {
		return null;
	}

	switch (actionName) {
		case "purchaseVerification": {
			const purchaseData = resultData as PurchaseResult;
			return purchaseData.activeCodes && purchaseData.userEmail ? (
				<ProductDetails
					products={purchaseData.activeCodes}
					email={purchaseData.userEmail}
					title="Your Purchases"
				/>
			) : null;
		}

		case "contactSupport": {
			const supportData = resultData as SupportResult;
			return <SupportDetails {...supportData.details} />;
		}

		case "redirectToContent": {
			const redirectData = resultData as RedirectResult;
			return redirectData.data ? (
				<ContentSelector
					bundleType={redirectData.data.bundleType}
					examName={redirectData.data.examName}
					icon={redirectData.data.icon}
				/>
			) : (
				<RedirectPrompt accessCode={redirectData.accessCode} />
			);
		}

		default:
			return null;
	}
};
