/* -----------------Child components--------------- */
import AuthContainer from "@memorang/ui/src/components/AuthContainer";
import EmailForm from "./EmailForm";

interface Props {
	handleSubmit: (email: string) => void;
	loading?: boolean;
	emailFromUrl?: string;
	lastLoggedInEmail?: string;
}
const EmailView = ({
	handleSubmit,
	loading,
	emailFromUrl,
	lastLoggedInEmail,
}: Props) => {
	return (
		<>
			<AuthContainer>
				<EmailForm
					loading={loading}
					handleSubmitEmail={handleSubmit}
					emailFromUrl={emailFromUrl}
					lastLoggedInEmail={lastLoggedInEmail}
				/>
			</AuthContainer>
		</>
	);
};

export default EmailView;
