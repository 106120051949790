import { DateTime } from "luxon";
/* -----------------Globals--------------- */
import React, {
	createContext,
	useState,
	useEffect,
	useCallback,
	type ReactNode,
	useMemo,
} from "react";

/* -----------------Child components--------------- */
import { trackCustomEvent, updateUserProperties } from "analytics";

/* -----------------Constants--------------- */
import { events } from "@constants/tracking";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import useBundleStore from "@features/store/hooks/useBundleStore";
import fetchBundleDetails from "@features/store/relay/queries/FetchBundleDetails";
import { useAppStore } from "@hooks/useAppStore";
import {
	constructClientExamContext,
	getUserId,
	retrieveExamContextFromKV,
	storeExamContextInKV,
} from "../helpers";
import { useExams } from "../hooks/useExams";
/* -----------------Local modules--------------- */
import updateExamMeta from "../mutations/UpdateExamMetadata";
import { fetchExamDate } from "../queries/FetchExamDate";
import { fetchExams } from "../queries/FetchExams";
import type { ExamDetails } from "../types";
import { defaultExamContext } from "./constants";

export type CurrentExamContext = {
	studyPackProductId: string;
	examName: string;
	examId: string;
	examDate?: string;
	examIcon?: string;
	studyPackProductNumericId: number;
	practiceTestProductId?: string;
	practiceTestProductNumericId?: number;
	hasRedeemed?: boolean;
	hasStudyPackAccess?: boolean;
	hasPracticeTestAccess?: boolean;
};

type ContextValue = {
	currentExamContext: CurrentExamContext;
	updateCurrentExamContext: (examContext: CurrentExamContext) => void;
	updateExamDate: (examDate: Date) => void;
	handleSwitchExamContext: (examDetails: ExamDetails) => Promise<void>;
};

const initExamContext = {
	studyPackProductId: "",
	examName: "",
	examId: "",
	studyPackProductNumericId: 0,
};

const Context = createContext<ContextValue>({
	currentExamContext: initExamContext,
	updateCurrentExamContext: () => {
		//
	},
	updateExamDate: () => {
		//
	},
	handleSwitchExamContext: async () => {
		//
	},
});

const ExamContextProvider = ({ children }: { children: ReactNode }) => {
	const [currentExamContext, setCurrentExamContext] =
		useState<CurrentExamContext>(initExamContext);

	const setCurrentlySelectedBundleDetails = useBundleStore(
		(store) => store.setCurrentlySelectedBundleDetails,
	);
	const setCurrentlySelectedBundleDetailsForIAP = useBundleStore(
		(store) => store.setCurrentlySelectedBundleDetailsForIAP,
	);
	const setCurrentlySelectedBundleDetailsForPracticeTests = useBundleStore(
		(store) => store.setCurrentlySelectedBundleDetailsForPracticeTests,
	);

	const { viewerId: userId } = useAuthContext();

	const studyPackProductNumericId =
		currentExamContext?.studyPackProductNumericId;

	const practiceTestProductNumericId =
		currentExamContext?.practiceTestProductNumericId;
	const appId = useAppStore((store) => store.app.id);
	const tenantId = useAppStore((store) => store.tenant.id);

	const setExams = useExams((store) => store.setExams);
	const exams = useExams((store) => store.exams);

	useEffect(() => {
		if (studyPackProductNumericId) {
			fetchBundleDetails(studyPackProductNumericId).then((res) => {
				setCurrentlySelectedBundleDetails(res);
				setCurrentlySelectedBundleDetailsForIAP(res);
			});
		}
	}, [
		setCurrentlySelectedBundleDetails,
		setCurrentlySelectedBundleDetailsForIAP,
		studyPackProductNumericId,
	]);
	useEffect(() => {
		if (practiceTestProductNumericId) {
			fetchBundleDetails(practiceTestProductNumericId).then((res) => {
				setCurrentlySelectedBundleDetailsForPracticeTests(res);
			});
		}
	}, [
		practiceTestProductNumericId,
		setCurrentlySelectedBundleDetailsForPracticeTests,
	]);
	useEffect(() => {
		fetchExams(tenantId, appId).then((exams) => {
			setExams(exams);
		});
	}, [tenantId, appId, setExams]);

	const checkAndAddPracticeTest = useCallback(
		(data: CurrentExamContext) => {
			if (!data.practiceTestProductId) {
				const currentExam = exams.find((exam) => exam.exam.id === data.examId);
				const practiceTest = currentExam?.exam.bundles.find(
					(item) => item.bundle.bundleType === "PRACTICE_TEST",
				)?.bundle;
				return {
					...data,
					practiceTestProductId: practiceTest?.id,
					practiceTestProductNumericId: practiceTest?.numericId,
				};
			}
			return data;
		},
		[exams],
	);
	const checkAndStudyPack = useCallback(
		(data: CurrentExamContext) => {
			if (!data.studyPackProductId && data.examId) {
				const currentExam = exams.find((exam) => exam.exam.id === data.examId);
				const studyPack = currentExam?.exam.bundles.find(
					(item) => item.bundle.bundleType === "STUDY_PACK",
				)?.bundle;
				return {
					...data,
					studyPackProductId: studyPack?.id!,
					studyPackProductNumericId: studyPack?.numericId!,
				};
			}
			return data;
		},
		[exams],
	);

	const updateCurrentExamContext = useCallback(
		async (data: CurrentExamContext) => {
			const finalUserId = userId || (await getUserId());
			const finalExamContext = checkAndAddPracticeTest(data);
			const finalExamContextWithStudyPack = checkAndStudyPack(finalExamContext);
			setCurrentExamContext(finalExamContextWithStudyPack);

			if (finalUserId) {
				storeExamContextInKV(finalExamContextWithStudyPack, finalUserId);
			}
			trackCustomEvent({
				eventName: events.examContextSet,
				examName: data.examName,
				examId: data.examId,
			});
			updateUserProperties({
				mem_exam_name: data.examName,
				mem_exam_id: data.examId,
				mem_exam_date: data.examDate!,
			});
		},
		[checkAndAddPracticeTest, checkAndStudyPack, userId],
	);

	const setDefaultExamContext = useCallback(() => {
		if (userId) {
			setCurrentExamContext(defaultExamContext);
			// storeExamContextInKV(defaultExamContext, userId);
		}
	}, [userId]);

	const getExamContextWithDate = useCallback(
		async (
			cachedContext: CurrentExamContext,
			tenantId: string,
		): Promise<CurrentExamContext> => {
			const examDetails = await fetchExamDate({
				examId: cachedContext.examId,
				tenantId,
			});
			return { ...cachedContext, examDate: examDetails.metadata.examDate };
		},
		[],
	);

	const createExamContextFromPurchased = useCallback(
		(examDetails: ExamDetails): CurrentExamContext => {
			const practiceTest = examDetails.exam.bundles.find(
				(item) => item.bundle.bundleType === "PRACTICE_TEST",
			)?.bundle;
			const studyPack = examDetails.exam.bundles.find(
				(item) => item.bundle.bundleType === "STUDY_PACK",
			)?.bundle;

			return {
				studyPackProductId: studyPack?.id!,
				studyPackProductNumericId: studyPack?.numericId!,
				examName: examDetails.exam.name,
				examId: examDetails.exam.id,
				examDate: examDetails.metadata.examDate,
				examIcon: examDetails.exam.icon,
				practiceTestProductId: practiceTest?.id,
				practiceTestProductNumericId: practiceTest?.numericId,
			};
		},
		[],
	);

	const handleCachedExamContext = useCallback(
		async (cachedExamContext: CurrentExamContext) => {
			setCurrentExamContext(cachedExamContext);
			const updatedContext = await getExamContextWithDate(
				cachedExamContext,
				tenantId,
			);
			setCurrentExamContext(updatedContext);
		},
		[getExamContextWithDate, tenantId],
	);

	const syncExamContext = useCallback(async () => {
		if (!userId) {
			return;
		}

		try {
			const cachedExamContext = await retrieveExamContextFromKV(userId);

			if (cachedExamContext) {
				await handleCachedExamContext(cachedExamContext);
				return;
			}

			const latestExams = await fetchExams(tenantId, appId);
			const purchasedExam = latestExams.find((item) =>
				item.exam.bundles.some(
					(bundle) => bundle.metadata?.subscription?.status === "ACTIVE",
				),
			);

			if (!purchasedExam) {
				const cachedExamContext = await retrieveExamContextFromKV(userId);
				if (cachedExamContext) {
					await handleCachedExamContext(cachedExamContext);
				} else {
					setDefaultExamContext();
				}
				return;
			}

			const newExamContext = createExamContextFromPurchased(purchasedExam);
			await storeExamContextInKV(newExamContext, userId);
			setCurrentExamContext(newExamContext);
		} catch (error) {
			console.error("Failed to sync exam context:", error);
			setDefaultExamContext();
		}
	}, [
		appId,
		tenantId,
		userId,
		setDefaultExamContext,
		createExamContextFromPurchased,
		handleCachedExamContext,
	]);
	useEffect(() => {
		syncExamContext();
	}, [syncExamContext]);

	const updateExamDate = useCallback(
		(examDate: Date) => {
			if (examDate) {
				const formattedDate = DateTime.fromJSDate(examDate, {
					zone: "local",
				}).toFormat("MMMM dd, yyyy");

				if (currentExamContext.examId) {
					trackCustomEvent({
						eventName: events.examDateSet,
						examDate: formattedDate,
					});
					updateExamMeta({
						id: currentExamContext.examId,
						examDate: formattedDate,
					});

					updateCurrentExamContext({
						...currentExamContext,
						examDate: formattedDate,
					});
				}
			}
		},
		[currentExamContext, updateCurrentExamContext],
	);

	const handleSwitchExamContext = useCallback(
		async (examDetails: ExamDetails) => {
			const constructedExamContext = constructClientExamContext(examDetails);

			await updateCurrentExamContext(constructedExamContext);
		},
		[updateCurrentExamContext],
	);

	const value = useMemo(() => {
		return {
			currentExamContext,
			updateCurrentExamContext,
			updateExamDate,
			handleSwitchExamContext,
		};
	}, [
		currentExamContext,
		handleSwitchExamContext,
		updateCurrentExamContext,
		updateExamDate,
	]);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useExamContext = () => {
	const context = React.useContext(Context);
	if (context === undefined) {
		throw new Error("useExamContext must be used within a ExamContextProvider");
	}
	return context;
};

export { ExamContextProvider, useExamContext };
