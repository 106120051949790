/* -----------------Types--------------- */
import type { SessionChildItem } from "@memorang/types/src/session/common";

import { getNumPercenetageAnswered } from "@features/exam/helpers";
import Box from "@memorang/ui/src/components/Box";
/* -----------------Components--------------- */
import ChoiceListItem from "./ChoiceListItem";
interface Props {
	choices: SessionChildItem[];
	handleSelectChoice: (choiceId: string, crossingOut?: boolean) => void;
	selectedChoiceIds?: string[];
	correctAnswerIds?: string[];
	crossedOutChoiceIds?: string[];
	multiSelect?: boolean;
	isAnswered?: boolean;
	useCardWrapper?: boolean;
	showCrossOut?: boolean;
}

const ChoicesList = ({
	choices,
	handleSelectChoice,
	selectedChoiceIds,
	multiSelect,
	correctAnswerIds,
	crossedOutChoiceIds,
	isAnswered,
	useCardWrapper = true,
	showCrossOut,
}: Props) => {
	return (
		<Box gap={useCardWrapper ? 16 : 0}>
			{choices?.map((choice, index) => {
				const isCorrectAnswer = correctAnswerIds?.includes(choice.id);
				const status = selectedChoiceIds?.includes(choice.id)
					? "checked"
					: "unchecked";
				const isCrossedOut = crossedOutChoiceIds?.includes(choice.id);

				const percentageAnswered = getNumPercenetageAnswered(
					choices,
					choice.numFirstSelections,
					isAnswered,
				);
				const isSelected = selectedChoiceIds?.includes(choice.id);
				return (
					<ChoiceListItem
						key={choice.id}
						{...choice}
						index={index + 1}
						useCardWrapper={useCardWrapper}
						answered={isAnswered}
						multiSelect={multiSelect}
						status={status}
						isSelected={isSelected}
						showCrossOut={showCrossOut}
						isCorrectAnswer={isCorrectAnswer}
						isCrossedOut={isCrossedOut}
						percentageAnswered={percentageAnswered}
						handleSelectChoice={(crossOut) =>
							handleSelectChoice(choice.id, crossOut)
						}
					/>
				);
			})}
		</Box>
	);
};

export default ChoicesList;
