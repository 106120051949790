/**
 * @generated SignedSource<<f1258bd8dc5fdf111f9c524dac6e9f63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BundleType = "EXAM" | "PRACTICE_TEST" | "STUDY_PACK" | "%future added value";
export type FetchActiveCodesQuery$variables = {
  input: string;
  sku?: number | null;
  tenantId: string;
};
export type FetchActiveCodesQuery$data = {
  readonly getActiveCodes: ReadonlyArray<{
    readonly bundle: {
      readonly bundleType: BundleType | null;
      readonly features: ReadonlyArray<string | null> | null;
      readonly id: string;
      readonly name: string;
    };
    readonly codes: ReadonlyArray<string> | null;
  }> | null;
};
export type FetchActiveCodesQuery = {
  response: FetchActiveCodesQuery$data;
  variables: FetchActiveCodesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sku"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "sku",
        "variableName": "sku"
      },
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "CodeDetails",
    "kind": "LinkedField",
    "name": "getActiveCodes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Bundle",
        "kind": "LinkedField",
        "name": "bundle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "features",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bundleType",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "codes",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchActiveCodesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "FetchActiveCodesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "ff1427028da32fcb4cc5c7a7dc1d8459",
    "id": null,
    "metadata": {},
    "name": "FetchActiveCodesQuery",
    "operationKind": "query",
    "text": "query FetchActiveCodesQuery(\n  $tenantId: String!\n  $input: String!\n  $sku: Int\n) {\n  getActiveCodes(tenantId: $tenantId, input: $input, sku: $sku) {\n    bundle {\n      id\n      name\n      features\n      bundleType\n    }\n    codes\n  }\n}\n"
  }
};
})();

(node as any).hash = "802ef7563af4d8da36d3b2648414b331";

export default node;
