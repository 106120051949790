import { updateSessionConfig } from "@features/session/relay/UpdateSessionConfigMutation";
import { isWeb } from "@helpers/platform";
import { useEffect } from "react";
import DialogSession from "../../components/SessionDialog";
import {
	getBreakDialogConfig,
	getBreakOngoingDialogConfig,
} from "../../constants/dialog-configs";
import useSessionStore from "../../hooks/useSessionStore";
import SubtitleComponentContainer from "./SubtitleComponentContainer";
import { getBreakTimeTakenInSeconds } from "./helpers";

const BreakDialogContainer = ({
	sectionId,
	showBreakDialog,
	setShowBreakDialog,
	breakTime,
}: {
	sectionId: string;
	showBreakDialog: boolean;
	setShowBreakDialog: (showBreakDialog: boolean) => void;
	breakTime: number;
}) => {
	const toggleBreakOngoing = useSessionStore(
		(state) => state.toggleBreakOngoing,
	);

	const updateBreak = useSessionStore((state) => state.updateBreak);
	const breaks = useSessionStore((state) => state.breaks);
	const sessionId = useSessionStore((state) => state.id);

	const firstBreakStartTime = breaks?.length ? breaks[0].startTime : "";

	const updateBreakTimeElapsed = useSessionStore(
		(state) => state.updateBreakTimeElapsed,
	);

	const isBreakOngoing = useSessionStore((state) => state.isBreakOngoing);

	const toggleShowBreakDialog = () => {
		setShowBreakDialog(!showBreakDialog);
	};

	useEffect(() => {
		if (isWeb && isBreakOngoing) {
			window.onbeforeunload = () => {
				return "";
			};

			return () => {
				window.onbeforeunload = null;
			};
		}
	}, [isBreakOngoing]);

	const updateExpiredBreak = () => {
		const breakTimeTakenInSeconds =
			getBreakTimeTakenInSeconds(firstBreakStartTime);
		updateBreak({
			startTime: firstBreakStartTime,
			allotedTime: breakTime,
			status: "EXPIRED",
			totalBreakTimeTakenInSeconds: breakTimeTakenInSeconds,
		});
		updateSessionConfig(
			{
				allotedTime: breakTime,
				section: sectionId,
				status: "EXPIRED",
				startTime: firstBreakStartTime,
				totalBreakTimeTakenInSeconds: breakTimeTakenInSeconds,
			},
			sessionId,
		);
	};

	const handleClickButton = (type: string) => {
		if (type === "confirm") {
			if (isBreakOngoing) {
				toggleShowBreakDialog();
				toggleBreakOngoing(false);
				updateBreakTimeElapsed(0);
				updateExpiredBreak();
			} else {
				toggleBreakOngoing(true);
				const startTime = new Date().toISOString();
				updateBreak({
					startTime,
					allotedTime: breakTime,
					status: "STARTED",
				});
				updateSessionConfig(
					{
						allotedTime: breakTime,
						section: sectionId,
						startTime,
						status: "STARTED",
					},
					sessionId,
				);
			}
		} else {
			toggleShowBreakDialog();
			updateExpiredBreak();
		}
	};
	return (
		<>
			{showBreakDialog && (
				<DialogSession
					subtitleComponent={
						isBreakOngoing && (
							<SubtitleComponentContainer breakTime={breakTime} />
						)
					}
					customConfig={
						isBreakOngoing
							? getBreakOngoingDialogConfig()
							: getBreakDialogConfig(breakTime)
					}
					open={showBreakDialog}
					handleClickButton={handleClickButton}
					showCloseButton={false}
				/>
			)}
		</>
	);
};

export default BreakDialogContainer;
