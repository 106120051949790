/* -----------------Globals--------------- */
import { numMaxItemPerSession } from "../constants";

import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------UI--------------- */
import { Text } from "react-native-paper";

import { useAppTheme } from "@hooks/useAppTheme";
/* -----------------Helpers & Hooks--------------- */
import { formatNumber } from "@memorang/helpers/src/common";

/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";

type Props = {
	numTotalItemsFiltered: number;
	numItemSelected: number;
};

const SessionItemsAvailabilityCaption = (props: Props) => {
	const { numTotalItemsFiltered, numItemSelected } = props;

	const showWarning = numItemSelected >= numMaxItemPerSession;

	const numAvailableText = `${formatNumber(numTotalItemsFiltered)} available`;

	const warningText = `Max ${numMaxItemPerSession} items per session (${numAvailableText})`;
	const noItemsText = "No items available, try adjusting your filters";

	const getCaption = () => {
		if (numTotalItemsFiltered === 0) {
			return noItemsText;
		}
		if (showWarning) {
			return warningText;
		}
		return numAvailableText;
	};

	const caption = getCaption();

	const theme = useAppTheme();
	return (
		<Box
			flexDirection="row"
			alignItems="center"
			justifyContent="flex-end"
			gap={8}
		>
			{showWarning && (
				<MaterialCommunityIcons
					color={theme.colors.onSurfaceVariant}
					size={18}
					name="alert-outline"
				/>
			)}
			<Text
				variant="labelSmall"
				style={{
					color: theme.colors.onSurfaceVariant,
				}}
			>
				{caption}
			</Text>
		</Box>
	);
};

export default SessionItemsAvailabilityCaption;
