import type {
	SessionChildItem,
	SessionItem,
} from "@memorang/types/src/session/common";
/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import QuestionItem from "../question/Item";
import StudySessionItemGroupPassageStem from "./StudySessionItemGroupPassageStem";

interface Props {
	item: SessionItem;
}
const PassageItem = ({ item }: Props) => {
	const { parentStem, displayName } = item;
	return (
		<Box>
			<StudySessionItemGroupPassageStem
				stem={parentStem!}
				stemTitle={displayName!}
			/>
			<QuestionItem item={item as SessionChildItem} />
		</Box>
	);
};

export default PassageItem;
