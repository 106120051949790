import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { trackCustomEvent, trackPageView } from "analytics";
import { useCallback, useEffect } from "react";

const useAnalytics = (params?: Record<string, string>, runOnMount = true) => {
	const {
		currentExamContext: { examId, examDate, examName },
	} = useExamContext();

	const { email, viewerId } = useAuthContext();

	useEffect(() => {
		if (runOnMount && params && examId) {
			const commonTrackParams = {
				exam_id: examId,
				exam_name: examName,
				exam_date: examDate,
				user_id: viewerId,
				user_email: email,
			};
			if (params.type) {
				trackPageView({
					type: params.type,
					...commonTrackParams,
				});
			}
			if (params.eventName) {
				trackCustomEvent({
					eventName: params.eventName,
					...params,
					...commonTrackParams,
				});
			}
		}
	}, [examId, params, runOnMount, examName, examDate, viewerId, email]);

	const explicitCallTrackCustomEvent = useCallback(
		(params: Record<string, string | number | undefined>) => {
			if (examId) {
				trackCustomEvent({
					eventName: params.eventName,
					...params,
					exam_id: examId,
					exam_name: examName,
					exam_date: examDate,
					user_id: viewerId,
					user_email: email,
				});
			}
		},
		[examId, examName, examDate, viewerId, email],
	);

	return {
		trackCustomEvent,
		explicitCallTrackCustomEvent,
	};
};

export default useAnalytics;
