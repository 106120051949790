/* -----------------Globals--------------- */
import { ScrollView } from "react-native";

/* -----------------Helpers & Hooks--------------- */
import useSessionConfig, {
	type AdditionalParams,
} from "../hooks/useSessionConfig";

/* -----------------Types--------------- */
import type { Distribution } from "@memorang/types/src/content";
import type { LearningModes } from "../types";

/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import StickyButtonContainer from "@memorang/ui/src/components/StickyButtonContainer";
import DistributionFilterList from "../components/DistributionFilterList";
import ItemSlider from "../components/ItemSlider";
import LearningModesList from "../components/LearningModesList";
import TagFiltersList from "../components/TagFiltersList";

type Props = {
	available: Distribution;
	additionalParams?: AdditionalParams;
	handleCreateSession: (
		distribution: Distribution,
		learningMode: LearningModes,
		tags: string[],
	) => void;
	creatingSession?: boolean;
	handleShowPurchase: () => void;
};
const SessionConfig = ({
	available,
	additionalParams,
	handleCreateSession,
	creatingSession,
	handleShowPurchase,
}: Props) => {
	const {
		selectedLearningMode,
		handleSetLearningMode,
		selectedDistributionCheckboxMap,
		handleSetTagCheckboxMap,
		selectedTagCheckboxMap,
		handleDistributionCheckboxState,
		availableItems,
		numRequiredItems,
		handleSliderChange,
		finalAvailableDist,
		requiredDistribution,
		numTotalItemsFiltered,
	} = useSessionConfig(available, additionalParams || {}, creatingSession);

	const handleStartSession = () => {
		const selectedTags = selectedTagCheckboxMap
			? Object.keys(selectedTagCheckboxMap).filter(
					(tagId) => selectedTagCheckboxMap[tagId],
				)
			: [];

		const unselectedTags =
			additionalParams?.hasAccess && selectedTagCheckboxMap
				? Object.keys(selectedTagCheckboxMap || {}).filter(
						(tagId) => !selectedTagCheckboxMap[tagId],
					)
				: additionalParams?.freeTagIds;
		const finalTagIds =
			selectedTags?.length > 0 ? selectedTags : unselectedTags;
		if (!creatingSession) {
			handleCreateSession(
				requiredDistribution,
				selectedLearningMode,
				finalTagIds || [],
			);
		}
	};

	const cta = creatingSession ? "Generating session..." : "Start";
	return (
		<StickyButtonContainer
			buttonText={cta}
			icon="play-outline"
			onPress={handleStartSession}
			loading={creatingSession}
			disabled={!numRequiredItems}
			testID="start-session-button"
		>
			<ScrollView
				contentContainerStyle={{
					paddingBottom: 16,
				}}
				showsVerticalScrollIndicator={false}
			>
				<Box gap={16} flex={1}>
					{additionalParams?.numItemsMap ? (
						<LearningModesList
							selectedLearningMode={selectedLearningMode}
							disableAll={creatingSession}
							handleSetLearningMode={handleSetLearningMode}
							numItemsMap={additionalParams.numItemsMap}
						/>
					) : null}
					<ItemSlider
						label={"Session Length"}
						availableItems={availableItems}
						requiredItems={numRequiredItems}
						disabled={creatingSession}
						mode={selectedLearningMode}
						numTotalItemsFiltered={numTotalItemsFiltered}
						handleSliderChange={handleSliderChange}
						testID="session-length-slider"
					/>
					<DistributionFilterList
						distribution={finalAvailableDist}
						disableAll={creatingSession}
						selectedCheckboxMap={selectedDistributionCheckboxMap}
						handleSelectCheckbox={handleDistributionCheckboxState}
					/>
					{additionalParams?.sectionTags ? (
						<TagFiltersList
							disableAll={creatingSession}
							selectedCheckboxMap={selectedTagCheckboxMap}
							handleSelectCheckbox={(distKey, locked) => {
								if (locked) {
									// trackCustomEvent({
									//   eventName: events.lockedTagClicked,
									//   tagId: distKey,
									// });
									handleShowPurchase();
								} else {
									handleSetTagCheckboxMap(distKey);
								}
							}}
							sectionTags={additionalParams.sectionTags}
						/>
					) : null}
				</Box>
			</ScrollView>
		</StickyButtonContainer>
	);
};

export default SessionConfig;
