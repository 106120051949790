import OnboardingAppBar from "@features/auth/components/OnboardingAppBar";
import { useDevice } from "@hooks/useDevice";
import { MaterialTopTabs } from "components/MaterialTopTabs";
import { router } from "expo-router";

export default function LegalLayout() {
	const { isMobile } = useDevice();
	return (
		<>
			<OnboardingAppBar
				onBackAction={() => router.back()}
				showLogo={!isMobile}
				title="Read our rules & policies"
			/>
			<MaterialTopTabs />
		</>
	);
}
