/* -----------------UI--------------- */
import { Redirect } from "expo-router";
import { useFocusEffect } from "expo-router";
import { useCallback } from "react";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
/* -----------------Helpers & Hooks--------------- */
import { useDevice } from "@hooks/useDevice";

import { AppBottomTabs } from "@features/dashboard/layouts/AppBottomTabs";
import { AppDrawer } from "@features/dashboard/layouts/AppDrawer";
/* -----------------Types--------------- */
import { replayManager } from "@helpers/lib/sentry";
import { isWeb } from "@helpers/platform";

const DashboardLayout = () => {
	const { isMobile } = useDevice();
	const { isAuthenticated, loading } = useAuthContext();

	useFocusEffect(
		useCallback(() => {
			replayManager().stopReplay();
		}, []),
	);

	if (!(isAuthenticated || loading)) {
		return <Redirect href="/" />;
	}

	return <>{isMobile && !isWeb ? <AppBottomTabs /> : <AppDrawer />}</>;
};

export default DashboardLayout;
