/* -----------------Globals--------------- */
import { SectionList } from "react-native";

/* -----------------UI--------------- */
import { Chip, RadioButton } from "react-native-paper";

import type { SvgProps } from "react-native-svg";
import { useAppTheme } from "../../useAppTheme";
import useBreakpoints from "../../useBreakpoints";
import Box from "../Box";
/* -----------------Child components--------------- */
import { CustomListSubheader } from "../CustomListSubheader";
import EmptyState from "../EmptyView";

interface Section {
	title: string;
	data: {
		name: string;
		id: string;
	}[];
}

interface Props {
	sections: Section[];
	handleSelectItem: (itemId: string) => void;
	selectedItemId: string;
	emptySearchResultSvg?: React.FC<SvgProps>;
	handleClearSearch: () => void;
	initSelectedItemId?: string;
}
export const SearchableSectionList = ({
	sections,
	handleSelectItem,
	selectedItemId,
	emptySearchResultSvg,
	handleClearSearch,
	initSelectedItemId,
}: Props) => {
	const theme = useAppTheme();
	const { xsUp } = useBreakpoints();

	const showSectionHeader = sections.length > 1;
	return (
		<SectionList
			sections={sections}
			showsVerticalScrollIndicator={false}
			ListEmptyComponent={
				<Box paddingBottom={24}>
					<EmptyState
						title="No exams found"
						subtitle="Try adjusting your filters or search query"
						image={emptySearchResultSvg}
						cta="Clear search"
						handleAction={handleClearSearch}
					/>
				</Box>
			}
			contentContainerStyle={{
				paddingBottom: 200,
				minHeight: "100%",
			}}
			keyExtractor={(item) => item.id}
			renderItem={({ item }) => (
				<>
					<RadioButton.Item
						onPress={() => handleSelectItem(item.id)}
						label={item.name}
						mode="android"
						position="leading"
						labelStyle={{
							textAlign: "left",
							paddingLeft: 16,
						}}
						style={{
							paddingLeft: 8,
						}}
						status={selectedItemId === item.id ? "checked" : "unchecked"}
						value={selectedItemId}
					/>
					{initSelectedItemId === item.id && xsUp && (
						<Box position="absolute" right={16} top={10}>
							<Chip selected compact>
								Current Exam
							</Chip>
						</Box>
					)}
				</>
			)}
			renderSectionHeader={({ section: { title } }) =>
				showSectionHeader ? (
					<CustomListSubheader
						enableGutters={false}
						style={{
							paddingLeft: 16,
							backgroundColor: theme.colors.background,
						}}
					>
						{title}
					</CustomListSubheader>
				) : null
			}
		/>
	);
};
