import { useCopilotChat } from "@copilotkit/react-core";
import { Role, TextMessage } from "@copilotkit/runtime-client-gql";
import type { Bundle } from "@memorang/types/src/bundle";
import { Card, List } from "react-native-paper";
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../../analytics";
import { formatCase } from "../../helpers/string";
import Accordion from "./Accordion";

type Props = {
	products: { bundle: Bundle; codes?: string[] }[];
	email: string;
	title?: string;
};

const ProductDetails = ({ products, email, title = "Purchases" }: Props) => {
	const { appendMessage } = useCopilotChat();
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	const handleButtonClick = (code: string, bundle: Bundle) => {
		trackRedeemEvent(
			rcEvents.codeRedemptionAttempted,
			{
				interactionType: "manual",
				interactionElementType: "button",
				productType: bundle.bundleType,
				productId: bundle.id?.toString(),
				productName: bundle.examName,
			},
			{
				accessCode: code,
			},
		);

		appendMessage(
			new TextMessage({
				content: `Redeem code ${code}`,
				role: Role.User,
			}),
		);
	};

	const items = products.map((product) => {
		const isOnlyProduct = products.length === 1;
		const firstCode = product.codes?.[0] || "";
		const numCodes = product.codes?.length || 0;
		const accordionId = `${product.bundle.examId}-${firstCode}`;

		return (
			<Accordion
				id={accordionId}
				key={accordionId}
				code={firstCode}
				numCodes={numCodes}
				bundle={product.bundle}
				title={product.bundle.examName}
				description={formatCase(product.bundle.bundleType)}
				imageUrl={product.bundle.icon.url}
				handleButtonClick={handleButtonClick}
				initialExpanded={isOnlyProduct}
			/>
		);
	});

	return (
		<Card mode="elevated" style={{ overflow: "hidden" }}>
			<Card.Title
				title={title}
				titleVariant="titleLarge"
				subtitle={`for ${email}`}
			/>
			<List.AccordionGroup>{items}</List.AccordionGroup>
		</Card>
	);
};

export default ProductDetails;
