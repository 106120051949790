import { formatCount, formatNumber } from "@memorang/helpers/src/common";
import type { TagsByType } from "types/tag";

export const customTitleMap: Record<string, string> = {
	"Major Area": "Knowledge Levels",
	"Diagnostic Level": "Reasoning",
};

const getSectionTags = (
	numItems: number,
	tagsByType: TagsByType,
	handleShowPurchaseDialog: () => void,
	hasAccess?: boolean,
) => {
	const allTags = Object.keys(tagsByType).flatMap((key) => tagsByType[key]);

	const lockedTags = allTags.filter((item) =>
		hasAccess ? !item.locked : item.locked,
	);

	const unlockedTags = allTags.filter(({ locked }) => !locked);

	const numUnlockedTagsTopics = unlockedTags.length;
	const numLockedTagsTopics = lockedTags.length;

	const numUnlockedItems = unlockedTags.reduce((a, c) => {
		let acc = a;
		acc += c.items.length;
		return acc;
	}, 0);

	const lockedSectionItemsCount = hasAccess
		? numItems
		: numItems - numUnlockedItems;

	const sections = hasAccess
		? [
				{
					title: `${numUnlockedTagsTopics} ${formatCount(
						"topic",
						numUnlockedTagsTopics,
					)} • ${formatNumber(numUnlockedItems)} items`,
					cta: null,
					tags: unlockedTags,
					action: null,
					mode: "flat" as const,
				},
			]
		: [
				{
					title: `${numUnlockedTagsTopics} ${formatCount(
						"topic",
						numUnlockedTagsTopics,
					)} • ${formatNumber(numUnlockedItems)} items`,
					cta: "Free",
					icon: "gift-outline",
					tags: unlockedTags,
					mode: "outlined" as const,
					action: handleShowPurchaseDialog,
				},
				{
					title: `${numLockedTagsTopics} ${formatCount(
						"topic",
						lockedSectionItemsCount,
					)} • ${formatNumber(lockedSectionItemsCount)} items`,
					cta: "Upgrade",
					icon: "crown-outline",
					mode: "flat" as const,
					tags: lockedTags,
					action: handleShowPurchaseDialog,
				},
			];

	return [...sections];
};

export { getSectionTags };
