/* -----------------Globals--------------- */
import { useCallback, useEffect, useMemo, useState } from "react";

/* -----------------UI--------------- */
import Box from "@memorang/ui/src/components/Box";
import { useDevice } from "@memorang/ui/src/useDevice";

import { useExamContext } from "@features/exam/contexts/ExamContext";
/* -----------------Helpers & Hooks--------------- */
import { isIOS, isWeb } from "@helpers/platform";
import { getUserPrompt } from "../components/QASearch/helpers";
import { useAilaStore } from "../hooks/useAilaStore";
import useSessionStore from "../hooks/useSessionStore";

import { useCustomNavigation } from "@hooks/useCustomNavigation";
import type { SessionChildItem } from "@memorang/types/src/session/common";
import { useFocusEffect, useLocalSearchParams, useRouter } from "expo-router";
import { Button, FAB, Portal } from "react-native-paper";
import QAChipsWrapper from "../components/QASearch/QAChipsWrapper";
import SuggestCorrectionDialog from "../components/dialogs/SuggestCorrectionDialog";

import { useAppStore } from "@hooks/useAppStore";
/* -----------------Child components--------------- */
import PostAnswerModal from "../components/postanswer/PostAnswerModal";

/* -----------------Types--------------- */
import type { OriginalQuestionData } from "@memorang/features/src/qa-search/types/question";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { sessionMaxWidth } from "../constants";
import { getPointsEarnedStatItemValue } from "../helpers/stats";

const StudySessionFeedbackContainer = () => {
	const { index } = useLocalSearchParams<{
		index: string;
	}>();

	const [showSuggestCorrectionDialog, setShowSuggestCorrectionDialog] =
		useState(false);

	const { isMobile } = useDevice();

	const { handleSuggestCorrectionNavigation } = useCustomNavigation();

	const toggleShowSuggestCorrectionDialog = () => {
		setShowSuggestCorrectionDialog((prev) => !prev);
	};
	const currentItemIndex = index ? Number.parseInt(index) - 1 : 0;

	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);
	const currentBlockIndex = useSessionStore((state) => state.currentBlockIndex);
	const inReviewMode = useSessionStore((state) => state.inReviewMode);
	const sessionItems = useSessionStore((state) => state.sessionItems);
	const answers = useSessionStore((state) => state.answers);

	const currentSessionItems = isSummativeTest
		? sessionItems[currentBlockIndex].children
		: sessionItems;
	const itemType = currentSessionItems[currentItemIndex]?.itemType;
	const currentItem = currentSessionItems[currentItemIndex] as SessionChildItem;
	const currentItemAnswer = answers?.get(currentItem.id);

	const { bottom } = useSafeAreaInsets();
	const isAssistantVisible = useAilaStore((state) => state.showDrawer);
	const updateIsAssistantVisible = useAilaStore(
		(state) => state.updateShowDrawer,
	);
	const updateSelectedQuery = useAilaStore(
		(state) => state.updateSelectedQuery,
	);
	const aiAssistantAlias = useAppStore((store) => store.app.aiAssistant.alias);

	const router = useRouter();

	const handleAssistantFabPress = () => {
		updateIsAssistantVisible(true);
		if (!isWeb) {
			router.push(`/aila/${currentItem.id}`);
		}
	};

	useFocusEffect(
		useCallback(() => {
			if (!isWeb) {
				updateIsAssistantVisible(false);
				updateSelectedQuery("");
			}
		}, [updateIsAssistantVisible, updateSelectedQuery]),
	);

	useEffect(() => {
		if (isSummativeTest && inReviewMode) {
			updateIsAssistantVisible(true);
		}
	}, [isSummativeTest, inReviewMode, updateIsAssistantVisible]);

	const {
		currentExamContext: { examName },
	} = useExamContext();

	const renderSuggestCorrectionButton = () => {
		if (!isSummativeTest) {
			return (
				<Box alignSelf="center" paddingTop={16} paddingBottom={100}>
					<Button
						onPress={() =>
							handleSuggestCorrectionNavigation(
								toggleShowSuggestCorrectionDialog,
								currentItemIndex,
							)
						}
						icon={"flag-outline"}
					>
						Suggest Correction
					</Button>
					{showSuggestCorrectionDialog && (
						<SuggestCorrectionDialog
							visible={showSuggestCorrectionDialog}
							handleClose={toggleShowSuggestCorrectionDialog}
						/>
					)}
				</Box>
			);
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: // TODO fix me later
	const renderCommonPostAnswerModal = useMemo(() => {
		const currentAnswer = answers?.get(currentItem.id);

		const shouldRenderPostAnswerModal = isSummativeTest
			? inReviewMode
			: currentAnswer != null;

		const confidence = currentAnswer?.confidence;
		const timeTaken = currentItem.answers
			? currentItem.answers.timeTaken
			: currentAnswer?.timeTaken;
		const correct = currentItem.answers
			? currentItem.answers.isCorrect
			: currentAnswer?.correct;
		const choices = currentItem.children as SessionChildItem[];

		const pointsEarnedStatValue = getPointsEarnedStatItemValue({
			answers: currentItem.answers,
			children: currentItem.children,
		});

		const tags = currentItem?.tags;
		const correctChoices = choices?.filter(({ isCorrect }) => isCorrect);
		const data = {
			stem: currentItem.stem,
			explanation: correctChoices[0]?.explanation,
			choices,
			topics: tags?.map((tagItem) => tagItem.label),
		};
		const userPrompt = getUserPrompt(data, examName);
		const originalQuestionData: OriginalQuestionData = {
			stem: data.stem,
			explanation: data?.explanation,
			topics:
				(currentItem.tags?.map((tagItem) => tagItem.label).filter(Boolean) as
					| string[]
					| undefined) ?? [],
			choices:
				data.choices.map((choice) => ({
					text: choice.stem,
					isCorrect: choice.isCorrect,
				})) ?? [],
		};

		const AiFab = () => {
			// Render above the bottom app bar + some padding
			const bottomPosition = 80 + 24;

			return (
				<Portal>
					<FAB
						icon="chat-question-outline"
						label={`Ask ${aiAssistantAlias}`}
						style={{
							position: "absolute",
							// account for safe area inset on iOS
							bottom: bottomPosition + (isIOS ? bottom - 12 : 0),
							right: 24,
						}}
						onPress={handleAssistantFabPress}
					/>
				</Portal>
			);
		};

		const isAiLAFabVisible = !isAssistantVisible && shouldRenderPostAnswerModal;

		return (
			<>
				{isAiLAFabVisible && <AiFab />}
				{shouldRenderPostAnswerModal && (
					<PostAnswerModal
						choices={choices}
						timeTaken={`${timeTaken} secs`}
						confidence={confidence}
						showExplanation
						correct={correct}
						tags={currentItem.tags}
						currentQuestionAnswered
						currentItemIndex={currentItemIndex}
						itemId={currentItem.id}
						userPrompt={userPrompt}
						isSummativeTest={isSummativeTest}
						renderSuggestCorrectionButton={renderSuggestCorrectionButton}
						originalQuestionData={originalQuestionData}
						pointsEarnedStatValue={pointsEarnedStatValue}
					/>
				)}
				<Box margin={16}>
					{(!isSummativeTest || inReviewMode) && (
						<QAChipsWrapper
							itemId={currentItem.id}
							userPrompt={userPrompt}
							shouldRender={!!shouldRenderPostAnswerModal && isWeb}
							originalQuestionData={originalQuestionData}
						/>
					)}
				</Box>
			</>
		);
		//TODO:Need to remove all these useMemo :(
	}, [
		answers,
		currentItem,
		currentItemIndex,
		examName,
		inReviewMode,
		isSummativeTest,
		isAssistantVisible,
	]);

	const lookup: Record<string, React.ReactNode> = {
		QUESTION: renderCommonPostAnswerModal,
		PASSAGE: renderCommonPostAnswerModal,
	};

	const Component = lookup[itemType || "QUESTION"];

	if (!currentItem) {
		return null;
	}

	return (
		<Box
			maxWidth={
				isSummativeTest ? sessionMaxWidth.summative : sessionMaxWidth.formative
			}
			alignSelf={isSummativeTest ? "flex-start" : "center"}
			paddingHorizontal={isMobile ? 16 : 0}
		>
			{Component}
			{isWeb && currentItemAnswer != null && renderSuggestCorrectionButton()}
		</Box>
	);
};

export default StudySessionFeedbackContainer;
