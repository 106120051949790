/**
 * @generated SignedSource<<f7e541b843b5ca7e946e0a5856241885>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FetchContentsDistQuery$variables = {
  bundleId: string;
};
export type FetchContentsDistQuery$data = {
  readonly getMixedItemDistribution: {
    readonly contents: ReadonlyArray<{
      readonly distribution: {
        readonly high: number | null;
        readonly low: number | null;
        readonly medium: number | null;
      } | null;
      readonly id: string;
      readonly totalCount: number | null;
    } | null> | null;
    readonly freeTagIds: ReadonlyArray<string | null> | null;
    readonly tags: ReadonlyArray<{
      readonly displayType: ReadonlyArray<string | null> | null;
      readonly id: string;
      readonly items: ReadonlyArray<{
        readonly distribution: string | null;
        readonly id: string;
        readonly type: string | null;
      } | null> | null;
      readonly title: string | null;
      readonly type: string | null;
    } | null> | null;
  } | null;
};
export type FetchContentsDistQuery = {
  response: FetchContentsDistQuery$data;
  variables: FetchContentsDistQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bundleId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bundleId",
        "variableName": "bundleId"
      }
    ],
    "concreteType": "MixedItemDistribution",
    "kind": "LinkedField",
    "name": "getMixedItemDistribution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "freeTagIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MixedContent",
        "kind": "LinkedField",
        "name": "contents",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgressDistribution",
            "kind": "LinkedField",
            "name": "distribution",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "low",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "medium",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "high",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MixedItemTag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayType",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MixedItemScore",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "distribution",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchContentsDistQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchContentsDistQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b0bf4787da8b69be8c7b800f1cd8d291",
    "id": null,
    "metadata": {},
    "name": "FetchContentsDistQuery",
    "operationKind": "query",
    "text": "query FetchContentsDistQuery(\n  $bundleId: ID!\n) {\n  getMixedItemDistribution(bundleId: $bundleId) {\n    freeTagIds\n    contents {\n      id\n      totalCount\n      distribution {\n        low\n        medium\n        high\n      }\n    }\n    tags {\n      id\n      title\n      displayType\n      type\n      items {\n        id\n        distribution\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92f526c7e4bfe3554814ac26bc598c65";

export default node;
