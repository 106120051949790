import Box from "@memorang/ui/src/components/Box";
import { useRouter } from "expo-router";
import { Button, Card, Text } from "react-native-paper";

/* --------------- Analytics --------------- */
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../analytics";

type Props = {
	accessCode?: string;
};

export const RedirectPrompt = ({ accessCode }: Props) => {
	const router = useRouter();
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	const title = accessCode ? "Access your content" : "Switch Exam Context";
	const subtitle = accessCode ? `Access code: ${accessCode}` : "";

	const description = accessCode
		? "To access your content, you'll need to switch to the correct exam context. This can be done in just a few clicks."
		: "First try redeeming an access code to access your content. If you need to switch exam context, you can follow the steps below.";

	const navigationText = "Go to More > Your Exam > Switch Exam";

	const handleRedirect = () => {
		trackRedeemEvent(rcEvents.contentRedirectAttempted, {
			interactionType: "manual",
			interactionElementType: "button",
			success: true,
		});
		router.replace("/more");
	};

	return (
		<Card mode="elevated" style={{ overflow: "hidden" }}>
			<Card.Title
				title={title}
				titleVariant="titleLarge"
				titleNumberOfLines={2}
				subtitle={subtitle}
			/>
			<Card.Content>
				<Box gap={16}>
					<Text variant="bodyLarge">{description}</Text>
					<Text variant="titleMedium">{navigationText}</Text>
				</Box>
			</Card.Content>
			<Card.Actions style={{ marginVertical: 16, marginRight: 8 }}>
				<Button onPress={handleRedirect}>Go to more page</Button>
			</Card.Actions>
		</Card>
	);
};

export default RedirectPrompt;
