import { baseNextjsUrl } from "@memorang/constants/src/urls";

export const urls = {
	core: "https://memorang.com",
	dev: "http://localhost:8081",
};

export const ENDPOINT_FEEDBACK = `${baseNextjsUrl}/api/ai/feedback`;
export const ENDPOINT_EXAM_CONTEXT = `${baseNextjsUrl}/api/ai/exam-context`;
export const ENDPOINT_HINT = `${baseNextjsUrl}/api/ai/hint`;
export const ENDPOINT_GENERATE_EXPLANATION = `${baseNextjsUrl}/api/ai/assessment`;
export const ENDPOINT_GENERATE_EXPLANATION_FOR_RECORDING = `${baseNextjsUrl}/api/ai/generate-explanation`;
