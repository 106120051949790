import { commitMutation, graphql } from "react-relay";
/* -----------------Globals--------------- */
import relayEnv from "relay/relayenv";

type PaymentResult = {
	objectId: string;
	paymentKey: string;
	success: boolean;
	paymentUrl?: string;
	clientSecret: string;
};

export type StripeCheckout = {
	description: string;
	productId: string;
	planId: string;
	purchaseMode?: string;
	successUrl: string;
	failureUrl: string;
	checkoutMode?: "EMBED" | "HOSTED";
};

type Response = {
	createCheckoutSession: PaymentResult;
};

/* -------------------Query----------------------- */

const mutation = graphql`
  mutation createCheckoutMutation($payment: CreateCheckoutInput!) {
    createCheckoutSession(payment: $payment) {
      objectId
      paymentKey
      success
      paymentUrl
      clientSecret
    }
  }
`;

const createCheckoutSession = async (payment: StripeCheckout) => {
	const promise = new Promise((resolve, reject) => {
		const variables = {
			payment,
		};
		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const finalResponse = response as Response;
				const {
					createCheckoutSession: { clientSecret },
				} = finalResponse;
				resolve({
					clientSecret,
				});
			},
			onError: (error) => {
				reject(error);
			},
		});
	});
	return promise as Promise<{
		clientSecret: string;
	}>;
};

export default createCheckoutSession;
