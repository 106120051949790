import { storeKey } from "@memorang/helpers/src/storage";
import { createContext, useContext, useState } from "react";

type ThemeContextProps = {
	isDarkMode: boolean;
	toggleDarkMode: () => void;
};

export const ThemeContext = createContext<ThemeContextProps>({
	isDarkMode: false,
	toggleDarkMode: () => {
		throw new Error("toggleDarkMode is not implemented");
	},
});

const ThemeContextProvider = ({
	children,
	initIsDarkMode,
}: {
	children: React.ReactNode;
	initIsDarkMode: boolean;
}) => {
	const [isDarkMode, setIsDarkMode] = useState(initIsDarkMode);

	const toggleDarkMode = () => {
		setIsDarkMode(!isDarkMode);
		storeKey("isDarkMode", !isDarkMode);
	};

	return (
		<ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
			{children}
		</ThemeContext.Provider>
	);
};

const useThemeContext = () => {
	const context = useContext(ThemeContext);

	if (!context) {
		throw new Error(
			"useThemeContext must be used within a ThemeContextProvider",
		);
	}

	return context;
};
export { ThemeContextProvider, useThemeContext };
