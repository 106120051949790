import Box from "@memorang/ui/src/components/Box";
import LeaderboardListItem from "components/LeaderboardListItem";
import LeaderboardUser from "./LeaderboardUser";
import type { LeaderboardItemType } from "./types";

type Props = {
	leaderboards: LeaderboardItemType[];
	userLeaderboardItem?: LeaderboardItemType;
};

const LeaderboardList = (props: Props) => {
	const { leaderboards, userLeaderboardItem } = props;

	const bottomLeaderboards = leaderboards.slice(3);

	const leaders = bottomLeaderboards.slice(0, 7);
	return (
		<Box>
			{leaders.slice(0, 7).map(({ username, xp, avatar }, index) => (
				<LeaderboardListItem
					key={username}
					title={username}
					xp={xp}
					image={avatar}
					rank={index + 4}
					showDivider={index !== leaders.length - 1}
				/>
			))}
			{userLeaderboardItem && (
				<LeaderboardUser leaderboardItem={userLeaderboardItem} />
			)}
		</Box>
	);
};

export default LeaderboardList;
