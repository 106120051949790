import { cacheKeys } from "@constants/cache-keys";
import { isScreenshotMode } from "@constants/common";
import { mockedUserLeaderboard } from "@constants/mocked-data";
import { useQuery } from "@tanstack/react-query";
import fetchUserLeaderboard from "relay/user/FetchUserLeaderboard";

const useUserLeaderboard = (tenantId: string, scope: string) => {
	const { data: response } = useQuery({
		queryKey: [cacheKeys.userLeaderboard, tenantId, scope],
		queryFn: () => fetchUserLeaderboard(tenantId, scope),
		enabled: !!tenantId,
	});

	const userLeaderboardResponse = response?.leaderboardUserRecord;

	return {
		userLeaderboardResponse: isScreenshotMode
			? mockedUserLeaderboard
			: userLeaderboardResponse,
	};
};

export default useUserLeaderboard;
