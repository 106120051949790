import { useCopilotAction } from "@copilotkit/react-core";
import fetchBundleDetails from "@features/store/relay/queries/FetchBundleDetails";
import { actionComponents } from "../components/ActionRenderer";
import { REDIRECT_TO_CONTENT_INSTRUCTIONS } from "../constants";
import { useRedeemCopilotChat } from "../context";

/* --------------- Analytics --------------- */
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../analytics";

export const useRedirectToContentActionTrigger = () => {
	const {
		chatState: { accessCode, lastRedeemedResult, lastRedeemedBundleId },
	} = useRedeemCopilotChat();
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	useCopilotAction({
		name: actionComponents.redirectToContent.name,
		description: "Trigger when asked to redirect to/view/access content",
		handler: async () => {
			if (accessCode) {
				// Case 1: Full result available
				if (lastRedeemedResult?.data) {
					trackRedeemEvent(rcEvents.contentRedirectSucceeded, {
						interactionType: "auto",
						interactionElementType: "action",
						productType: lastRedeemedResult.data.bundleType,
						productId: lastRedeemedResult.data.bundleId,
						productName: lastRedeemedResult.data.bundleName,
						success: true,
					});

					return {
						success: true,
						instructions: REDIRECT_TO_CONTENT_INSTRUCTIONS,
						resultData: {
							data: lastRedeemedResult.data,
						},
					};
				}

				// Case 2: Only bundleId available
				if (lastRedeemedBundleId) {
					const bundleDetails = await fetchBundleDetails(lastRedeemedBundleId);

					if (bundleDetails?.bundle) {
						trackRedeemEvent(rcEvents.contentRedirectSucceeded, {
							interactionType: "auto",
							interactionElementType: "action",
							productType: bundleDetails.bundle.bundleType,
							productId: bundleDetails.bundle.id,
							productName: bundleDetails.bundle.name,
							success: true,
						});

						return {
							success: true,
							instructions: REDIRECT_TO_CONTENT_INSTRUCTIONS,
							resultData: {
								data: {
									examId: bundleDetails.bundle.examId,
									bundleType: bundleDetails.bundle.bundleType,
									bundleName: bundleDetails.bundle.name,
									examName: bundleDetails.bundle.examName,
									icon: bundleDetails.bundle.icon?.url,
								},
							},
						};
					}
				}

				// Case 3: BundleId or access code result not available

				trackRedeemEvent(rcEvents.contentRedirectFailed, {
					interactionType: "auto",
					interactionElementType: "action",
					errorType: "bundle_not_found",
					success: false,
				});

				return {
					success: false,
					instructions: REDIRECT_TO_CONTENT_INSTRUCTIONS,
					resultData: {
						accessCode,
					},
				};
			}

			// Case 4: User directly tried to access content, no access code provided

			trackRedeemEvent(rcEvents.contentRedirectFailed, {
				interactionType: "auto",
				interactionElementType: "action",
				errorType: "no_access_code",
				success: false,
			});

			return {
				success: false,
				instructions: REDIRECT_TO_CONTENT_INSTRUCTIONS,
			};
		},
	});
};
