/* -----------------Child components--------------- */
import { AppDrawer } from "@features/dashboard/layouts/AppDrawer";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { getShowStudyPackViews } from "@helpers/exams";
import { isWeb } from "@helpers/platform";

/* -----------------Helpers & Hooks--------------- */
import { useDevice } from "@hooks/useDevice";
import { Redirect, Slot } from "expo-router";

const StudyPackLayout = () => {
	const { isMobile } = useDevice();

	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();

	const showStudyPackViews = studyPackProductId
		? getShowStudyPackViews(studyPackProductId)
		: true;

	if (isWeb && !showStudyPackViews) {
		return <Redirect href={"/practice-tests"} />;
	}
	return <>{isMobile ? <Slot /> : <AppDrawer />}</>;
};

export default StudyPackLayout;
