import { events } from "@constants/tracking";
import { calculatePlanExpiry } from "@memorang/helpers/src/bundle";
import type { Plan } from "@memorang/types/src/bundle";
import { trackCustomEvent } from "analytics";
import createCheckoutSession, {
	type StripeCheckout,
} from "../mutations/createCheckout";

type Params = {
	selectedPlan: Plan;
	productId: string;
	redirectUrl: string;
	productName: string;
	productDescription: string;
};
export const handleCreateCheckoutSession = async ({
	selectedPlan,
	productId,
	redirectUrl,
	productName,
	productDescription,
}: Params): Promise<string> => {
	const {
		id: planId,
		amount,
		duration,
		interval,
		currency,
		name,
	} = selectedPlan;
	const handleTracking = (
		eventName: string,
		params?: Record<string, string | number>,
	) => {
		trackCustomEvent({
			eventName,
			bundleId: productId,
			bundleName: productName,
			...params,
		});
	};
	const checkoutParams: StripeCheckout = {
		productId,
		planId,
		description: productDescription,
		purchaseMode: "USER",
		successUrl: redirectUrl,
		failureUrl: redirectUrl,
		checkoutMode: "EMBED",
	};

	const trackParams = {
		plan_id: planId,
		plan_duration: `${duration} ${interval}`,
		plan_price: amount,
		plan_currency: currency,
		plan_name: name,
	};

	handleTracking(events.paymentSubmitted, trackParams);
	const result = await createCheckoutSession(checkoutParams);
	const clientSecret = result.clientSecret;
	return clientSecret;
};

export const getAccessDurationTemplate = (plan?: Plan, expiryDate?: string) => {
	if (!plan) {
		return "";
	}
	const isUntilYouPass = plan.duration === 12;

	if (isUntilYouPass) {
		return "The **until you pass** plan is a great choice in case you may need to reschedule your exam or possibly retake it in the future";
	}

	const expiryText = calculatePlanExpiry(
		plan.interval,
		plan.duration,
		expiryDate,
	);
	return `The **${plan.alias.toLowerCase()}** plan will grant you access until **${expiryText}**`;
};
