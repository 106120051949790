import type {
	MixedItemDist,
	MixedItemTag,
	PracticeTestExam,
} from "@memorang/types/src/tag";
/* -----------------Types--------------- */
import type {
	CumulativeSummaryResponse,
	FetchMasteryOvertimeResponse,
} from "./../features/dashboard/types/index";
import type { CurrentView, MasteryTagProgressData } from "./content/types";

import { isScreenshotMode } from "@constants/common";
import {
	mockedMasteryChartProgressData,
	mockedPracticeTestSummary,
} from "@constants/mocked-data";
/* -----------------Helpers & Hooks--------------- */
import { groupTagsByType } from "@helpers/content/tag";
import { formatCount, formatNumber } from "@memorang/helpers/src/common";
import { getParentMetadata } from "./content/metadata";
import { getMappedExams } from "./exams";
import { randomizeDistribution } from "./mocked-helpers";
import { isWeb } from "./platform";

type AreaChartData = {
	date: string;
	masteryCount: number;
	timeTaken?: number;
};

const studySummaryTooltip =
	"This includes all studying across web and mobile, including the practice tests and study-packs";

export type ExamCardData = {
	title: string;
	subtitle: string;
	avgScore: number;
	list: {
		title: string;
		description: string;
		status: string;
		numericId: number;
		cta: string;
		id: string;
		score: number;
	}[];
};

export type SummaryCardData = {
	title: string;
	subtitle: string;
	tooltip: string;
	data: {
		name: string;
		list: {
			icon: string;
			title: string;
			description: string;
			chipLabel: string;
			key?: string;
			tooltip?: string;
		}[];
	}[];
};

const sortByDate = (
	items: {
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		x: any;
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		y: any;
	}[],
) => items.sort((a, b) => a.x - b.x);

const setDate = (date: string, hasDate?: boolean) => {
	const dateArr = date.split("-");
	const dateObj = new Date(
		Number.parseInt(dateArr[0], 10),
		Number.parseInt(dateArr[1], 10) - 1,
		hasDate ? Number.parseInt(dateArr[2], 10) : 1,
	);
	return dateObj;
};
const transformDateAndSort = (items: AreaChartData[]) => {
	const result = [...items].map((item) => ({
		x: setDate(item.date),
		y: item.masteryCount,
	}));
	return sortByDate(result);
};

const transformDateAndAvgTime = (items: number[]) => {
	const result = [...items].map((item, index) => ({
		x: index,
		y: item,
	}));
	return sortByDate(result);
};
const getMasteryTagsProgressData = ({
	currentView,
	tags,
	numItems,
	freeTagIds = [],
	hasAccess,
}: {
	currentView: CurrentView;
	tags: MixedItemTag[];
	freeTagIds?: string[];
	hasAccess?: boolean;
	numItems: number;
}): MasteryTagProgressData[] => {
	const groupedTagsByType = groupTagsByType(
		tags,
		currentView,
		freeTagIds,
		hasAccess,
	);
	const data = Object.keys(groupedTagsByType)
		.filter((item) => {
			return groupedTagsByType[item].length > 0;
		})
		.map((key) => {
			return {
				title: key,
				tagType: key.toLowerCase(),
				tooltip: isWeb ? studySummaryTooltip : "",
				subtitle: `Based on your responses to ${formatNumber(
					numItems,
				)} items across ${formatCount(
					"topic",
					groupedTagsByType[key].length,
					true,
				)}`,

				items: groupedTagsByType[key].map((tag) => {
					return {
						title: tag.title,
						distribution: isScreenshotMode
							? randomizeDistribution(tag.distribution)
							: tag.distribution,
						rightText: `${formatCount("item", tag.items.length, true)}`,
					};
				}),
			};
		});
	return data;
};

const getProgressPageSummaryCardData = (
	response: MixedItemDist,
	numTotalItems: number,
) => {
	const { distribution } = getParentMetadata(response.contents, numTotalItems);
	const { low, medium, high } = distribution;
	const numCompletion = low + medium + high;

	if (!numCompletion) {
		return undefined;
	}
	const masteryList = [
		{
			icon: "speedometer",
			title: "Mastered",
			description: `${formatCount("item", high, true)}`,
			chipLabel: `${Math.round((high / numTotalItems) * 100)}%`,
			key: "high",
			tooltip: `You have mastered ${high} items.`,
		},
		{
			icon: "speedometer-medium",
			title: "Almost there",
			description: `${formatCount("item", medium, true)}`,
			chipLabel: `${Math.round((medium / numTotalItems) * 100)}%`,
			key: "medium",
			tooltip: `You have almost mastered ${medium} items.`,
		},
		{
			icon: "speedometer-slow",
			title: "Incorrect",
			description: `${formatCount("item", low, true)}`,
			chipLabel: `${Math.round((low / numTotalItems) * 100)}%`,
			key: "low",
			tooltip: `You have answered ${low} items incorrectly.`,
		},
	];

	const stats = [
		{
			icon: "checkbox-multiple-outline",
			title: "Completion",
			description: `${numCompletion} / ${numTotalItems} items`,
			chipLabel: `${Math.round((numCompletion / numTotalItems) * 100)}%`,
			tooltip: `You have completed ${numCompletion} items out of ${numTotalItems}.`,
		},
		// {
		//   icon: 'radar',
		//   title: 'Accuracy',
		//   description: `${numCompletion} answer attempts`,
		//   chipLabel: `${response.accuracy}%`,
		//   tooltip: `You have answered ${numCompletion} items with ${response.accuracy}% accuracy.`,
		// },
		// {
		//   icon: 'timer-outline',
		//   title: 'Speed',
		//   description: `${numCompletion} answer attempts`,
		//   chipLabel: `${response.speed} seconds`,
		//   tooltip: `You have answered ${numCompletion} items with ${response.speed} seconds per item.`,
		// },
	];

	const data: SummaryCardData = {
		title: "Summary",
		subtitle: `Based on ${numTotalItems} items including practice tests and study-packs`,
		tooltip: isWeb ? studySummaryTooltip : "",
		data: [
			{
				name: "Mastery",
				list: masteryList,
			},
			{
				name: "Quick stats",
				list: stats,
			},
		],
	};
	return data;
};
const getCumulativeProgressData = ({
	cumulativeSummaryStats: { cumulative },
}: CumulativeSummaryResponse) => {
	const {
		speed,
		accuracy,
		completion: { distCompleted, distCount, completionPercentage },
	} = cumulative[0];
	const items = [
		{
			title: "Completion",
			overline: `${distCompleted} / ${distCount} items`,
			rightText: `${completionPercentage}%`,
			icon: "text-box-check-outline",
		},
		{
			title: "Accuracy",
			overline: "Percent Correct",
			rightText: `${accuracy}%`,
			icon: "bullseye-arrow",
		},
		{
			title: "Speed",
			overline: "Avg Per Item",
			rightText: `${speed}s / item`,
			icon: "timer-outline",
		},
	];
	const data = {
		title: "Cumulative Summary",
		subtitle: `Based on ${formatNumber(distCount)} items`,
		items,
	};
	return data;
};

const getMasteryChartProgressData = (
	numItems: number,
	masteryOvertime?: FetchMasteryOvertimeResponse,
) => {
	if (!masteryOvertime) {
		return null;
	}
	const {
		masteryOverTime: { mastery },
	} = masteryOvertime;
	const numMastered = mastery.reduce((a, c) => a + c.masteryCount, 0);

	const chartData = transformDateAndSort(mastery);

	const xTickValues = chartData.map((item) => item.x);
	const yTickValues = [
		...new Set(chartData.filter((item) => item.y).map((item) => item.y)),
	];
	const data = {
		subtitle: `Based on ${formatNumber(numItems)} items`,
		title: "Items Mastered",
		stats: [
			{
				title: "Total Mastered",
				rightText: `${numMastered}`,
				icon: "check-outline",
			},
		],
		chartData: {
			data: chartData,
			name: "mastery-area",
			fillColor: "#66bb68",
			strokeColor: "#66bb68",
			xTickValues: xTickValues,
			yTickValues: yTickValues,
			itemsCount: numItems,
		},
	};
	return data;
};

const getSpeedChartProgressData = (
	numItems: number,
	mixedItemDist: MixedItemDist,
	masteryOvertime?: FetchMasteryOvertimeResponse,
) => {
	if (!masteryOvertime) {
		return null;
	}
	const {
		masteryOverTime: { speed },
	} = masteryOvertime;

	const chartData = transformDateAndAvgTime(speed);

	const filteredChartData = chartData.filter((item) => item.y);
	const avgSpeed =
		chartData.reduce((a, b) => a + b.y, 0) / filteredChartData.length;
	const {
		distribution: { high, low, medium },
	} = getParentMetadata(mixedItemDist.contents, numItems);

	const maxItemCount = Math.ceil(avgSpeed + 1);
	const badgeValue = `${Math.ceil(avgSpeed)} ${
		Math.ceil(avgSpeed) === 1 ? "sec" : "secs"
	} / item`;

	const answeredItems = high + low + medium;

	const data = {
		subtitle: `Based on ${answeredItems} items`,
		title: "Speed",
		stats: [
			{
				title: "Average Speed",
				rightText: badgeValue,
				icon: "timer-outline",
			},
		],
		chartData: {
			data: chartData,
			name: "speed-area",
			fillColor: "#d66565",
			strokeColor: "#d66565",
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			xTickValues: chartData.map<any>((item) => item.x),
			itemsCount: maxItemCount,
		},
	};
	return data;
};

const getNeedsImprovementProgressData = (mixedItemDist: MixedItemDist) => {
	const { tags, freeTagIds } = mixedItemDist;
	const groupedTagsByType = groupTagsByType(tags, "Progress", freeTagIds);
	const allTags = Object.keys(groupedTagsByType).flatMap(
		(key) => groupedTagsByType[key],
	);
	const lowScoredTags = allTags.filter(
		(item) =>
			item.distribution.low > 0 ||
			item.distribution.high > 0 ||
			item.distribution.medium > 0,
	);

	const sortedLowScoredTags = lowScoredTags?.map((tag) => ({
		...tag,
		distribution: {
			low: tag.distribution.low,
			medium: tag.distribution.medium,
			high: tag.distribution.high,
			none: tag.distribution.none,
		},
	}));

	if (sortedLowScoredTags.length) {
		const data = {
			title: "Needs Improvement",
			subtitle: "Recommended topics for you to improve on",
			icon: "snowflake",
			items: sortedLowScoredTags.map((tag) => {
				return {
					title: tag.title,
					distribution: tag.distribution,
					rightText: `${formatNumber(tag.items.length)} items`,
					id: tag.id,
				};
			}),
		};
		return data;
	}
	return null;
};

const getPracticeTestsCardsData = (exams?: PracticeTestExam[]) => {
	const filteredExams = exams?.filter(({ status }) => status === "COMPLETE");

	if (!filteredExams?.length) {
		return undefined;
	}
	const avgScore =
		getMappedExams(filteredExams).reduce((a, c) => a + c.score, 0) /
		filteredExams.length;

	const examsCardData: ExamCardData = {
		title: "Practice Tests",
		subtitle: `Based on ${formatCount(
			"completed assessment",
			filteredExams.length,
			true,
		)}`,
		avgScore: Math.round(avgScore),
		list: filteredExams?.length ? getMappedExams(filteredExams) : [],
	};
	return examsCardData;
};

export const hardcodedPracticeTestExams = (
	_examName: string,
	exams: PracticeTestExam[],
) => ({
	title: "Practice Tests",
	subtitle: `Based on ${exams.length} completed diagnostics`,
	avgScore: 85,
	list: exams.slice(0, 3).map((exam) => ({
		title: exam.title,
		description: `${exam.numItems} items • May 9, 2023 • 50:00`,
		status: "COMPLETE",
		numericId: 15277,
		cta: "View Report",
		id: "B",
		score: Math.floor(Math.random() * 11) + 90,
	})),
});
const getProgressCardsData = (
	data: MixedItemDist,
	currentView: CurrentView,
	hasAccess: boolean,
	masteryOvertime?: FetchMasteryOvertimeResponse,
	examName?: string,
) => {
	const numItems = data.contents.reduce((a, c) => a + c.totalCount, 0);
	const { distribution } = getParentMetadata(data.contents, numItems);
	const { low, medium, high } = distribution;
	const numCompletion = low + medium + high;

	const masteryTagsProgressData = getMasteryTagsProgressData({
		currentView,
		tags: data.tags,
		numItems: numCompletion,
		hasAccess,
		freeTagIds: data.freeTagIds,
	});
	const masteryChartProgressData = isScreenshotMode
		? mockedMasteryChartProgressData
		: getMasteryChartProgressData(numItems, masteryOvertime);
	const speedChartProgressData = getSpeedChartProgressData(
		numItems,
		data,
		masteryOvertime,
	);
	const needsImprovementProgressData = getNeedsImprovementProgressData(data);

	const showEmptyState = needsImprovementProgressData == null;

	const practiceTestsCardsData = isScreenshotMode
		? hardcodedPracticeTestExams(examName!, data.exams!)
		: getPracticeTestsCardsData(data.exams);
	const progressPageSummaryCardData = isScreenshotMode
		? mockedPracticeTestSummary
		: getProgressPageSummaryCardData(data, numItems);
	return {
		masteryTagsProgressData,
		masteryChartProgressData,
		speedChartProgressData,
		needsImprovementProgressData,
		showEmptyState,
		practiceTestsCardsData,
		progressPageSummaryCardData,
	};
};

export {
	getProgressCardsData,
	getCumulativeProgressData,
	getMasteryTagsProgressData,
};
