import { useAppStore } from "@hooks/useAppStore";
import {
	CustomListSubheader,
	type CustomListSubheaderProps,
} from "@memorang/ui/src/components/CustomListSubheader";

const CustomListSubheaderContainer = (props: CustomListSubheaderProps) => {
	const appFont = useAppStore((store) => store.app.font);
	return <CustomListSubheader {...props} font={appFont} />;
};

export default CustomListSubheaderContainer;
