/* -----------------UI--------------- */
import type { IconType } from "components/types/icon";

import { getMasteryLabels } from "@helpers/content/distribution";
import { getMasteryTagsProgressData } from "@helpers/progress-data";
/* -----------------Helpers & Hooks--------------- */
import { formatSecondsToUTCtime } from "@memorang/helpers/src/common";

/* -----------------Child components--------------- */
import theme from "@configs/theme";
import { isScreenshotMode } from "@constants/common";
import {
	mockedMasteryCardData,
	mockedSummaryCardData,
	mockedTagProgressData,
} from "@constants/mocked-data";
import type { SessionItemTypes } from "@memorang/types/src/session/common";
/* -----------------Types--------------- */
import type { ReportDetails } from "@memorang/types/src/session/report";

const getSummaryCardData = ({
	accuracy,
	numCorrect,
	numQuestions,
	timeElapsed,
	avgSpeed,
	reportSessionType,
	totalChoicePoints,
	numChoicePoints,
	numHints,
	score,
	isSummativeTest,
}: ReportDetails & {
	isSummativeTest?: boolean;
}) => {
	const getTitle = () => {
		switch (reportSessionType) {
			case "DIAGNOSTIC":
				return "Diagnostic Assessment Completed";
			case "PRACTICETEST":
				return "Practice Test Completed";
			case "STUDYPACK":
				return "Practice Questions Completed";
			default:
				return "";
		}
	};

	const title = getTitle();
	const summaryCardData = {
		title,
		score: Math.round(isSummativeTest ? accuracy : score),
		numItems: numQuestions,
		stats: [
			{
				overlineText: "Correct",
				icon: "checkbox-marked-circle-outline",
				value: `${numCorrect} / ${numQuestions}`,
				tooltip: "How many questions you've answered",
			},
			...(isSummativeTest
				? []
				: [
						{
							overlineText: "Accuracy",
							icon: "radar",
							value: `${Math.round(accuracy)}%`,
							tooltip: "How many questions you've answered correctly",
						},
					]),
			{
				overlineText: "Duration",
				icon: "timer-outline",
				value: formatSecondsToUTCtime(timeElapsed),
				tooltip: "How long you took to complete the test",
			},
			{
				overlineText: "AVG SPEED",
				icon: "speedometer",
				value: `${Number(avgSpeed).toFixed(1)} secs`,
				tooltip: "How long you took to answer each question",
			},
			...(numHints != null
				? [
						{
							overlineText: "Hints used",
							icon: "lightbulb-on-outline",
							value: `${numHints}`,
							tooltip: "Number of hints used in this session",
						},
					]
				: []),
			...(totalChoicePoints
				? [
						{
							overlineText: "Points Earned",
							icon: "star-outline",
							value: `${numChoicePoints} / ${totalChoicePoints}`,
							description: `You've earned ${numChoicePoints} out of ${totalChoicePoints} points`,
						},
					]
				: []),
		],
	};
	return summaryCardData;
};
const getPerformanceCardData = (response: ReportDetails) => {
	const { numQuestions, numCorrect, numIncorrect } = response;
	const performanceCardData = {
		title: "Performance",
		subtitle: `Based on ${numQuestions} items`,
		sections: [
			{
				sectionTitle: "",
				items: [
					{
						title: "Correct",
						rightText: `${numCorrect} items`,
						icon: "thumb-up-outline" as IconType,
						color: theme().colors.success.main,
					},
					{
						title: "Incorrect",
						rightText: `${numIncorrect} items`,
						icon: "thumb-down-outline" as IconType,
						color: theme().colors.error,
					},
				],
			},
		],
	};
	return performanceCardData;
};

const getMasteryCardData = ({
	distribution,
	numQuestions,
	numMarked,
	numSkipped,
	isSummativeTest,
}: {
	distribution: ReportDetails["distribution"];
	numQuestions?: number;
	numMarked?: number;
	numSkipped?: number;
	isSummativeTest?: boolean;
}) => {
	if (!distribution) {
		return null;
	}

	const { high, medium, low } = distribution;

	const masterLabels = getMasteryLabels();
	const title = isSummativeTest ? "Performance" : "Session Mastery";

	const practiceTestMasteryCardData = {
		title: "Performance",
		subtitle: `Based on ${numQuestions} items`,
		sections: [
			{
				items: [
					{
						title: "Correct",
						count: high,
						icon: "thumb-up-outline" as IconType,
						type: "correct" as const,
						color: theme().colors.progress.high,
					},

					{
						title: "Incorrect",
						type: "incorrect" as const,
						count: low,
						icon: "thumb-down-outline" as IconType,
						color: theme().colors.progress.low,
					},
					...(numMarked != null
						? [
								{
									icon: "flag-outline" as IconType,
									title: "Marked",
									count: numMarked,
									type: "marked" as const,
									color: theme().colors.secondary,
								},
							]
						: []),
					...(numSkipped != null
						? [
								{
									title: "Skipped",
									count: numSkipped,
									type: "skipped" as const,
									icon: "minus-circle-outline" as IconType,
									color: theme().colors.progress.noneBackground,
								},
							]
						: []),
				],
			},
		],
	};
	const masteryCardData = {
		title,
		subtitle: `Based on ${numQuestions} items`,
		sections: [
			{
				sectionTitle: "Correct",
				items: [
					{
						title: masterLabels.high,
						count: high,
						icon: "speedometer" as IconType,
						type: "correct" as const,
						color: theme().colors.progress.high,
					},
					{
						title: masterLabels.medium,
						count: medium,
						type: "almostcorrect" as const,
						icon: "speedometer-medium" as IconType,
						color: theme().colors.progress.medium,
					},
				],
			},
			{
				sectionTitle: "Incorrect",
				items: [
					{
						title: masterLabels.low,
						type: "incorrect" as const,
						count: low,
						icon: "speedometer-slow" as IconType,
						color: theme().colors.progress.low,
					},
				],
			},
		],
	};
	return isSummativeTest ? practiceTestMasteryCardData : masteryCardData;
};
const getReviewListItemData = ({
	distribution,
	isSummativeTest,
	numSkipped,
	numMarked,
}: {
	distribution: ReportDetails["distribution"];
	cstItemsCount?: ReportDetails["cstItemsCount"];
	numSkipped?: number;
	numMarked?: number;
	isSummativeTest?: boolean;
}): Record<
	Extract<SessionItemTypes, "QUESTION">,
	{
		items: ReturnType<typeof getMasteryCardData> | undefined;
		numIncorrect: number;
	}
> => {
	const reviewListItemsDataForQuestion = getMasteryCardData({
		distribution,
		isSummativeTest,
		numMarked,
		numSkipped,
	});

	return {
		QUESTION: {
			items: reviewListItemsDataForQuestion,
			numIncorrect: distribution.low,
		},
	};
};

const getReportData = (response: ReportDetails, isSummativeTest?: boolean) => {
	const {
		markedCount: numMarked,
		numSkipped,
		numQuestions,
		numIncorrect,
		numCorrect,
		examName,
	} = response;
	const summaryCardData = isScreenshotMode
		? mockedSummaryCardData
		: getSummaryCardData({ ...response, isSummativeTest });

	const performanceCardData = getPerformanceCardData(response);
	const masteryCardData = isScreenshotMode
		? mockedMasteryCardData
		: getMasteryCardData({
				distribution: response.distribution,
				numQuestions,
				numMarked,
				numSkipped,
				isSummativeTest,
			});
	const reviewListItemsData = response.distribution
		? getReviewListItemData({
				distribution: response.distribution,
				isSummativeTest,
				numSkipped,
				numMarked,
			})
		: null;
	const masteryTagProgressData = isScreenshotMode
		? mockedTagProgressData
		: getMasteryTagsProgressData({
				tags: response.tagDetails,
				currentView: "Progress",
				numItems: numQuestions,
			});
	return {
		summaryCardData,
		performanceCardData,
		masteryTagProgressData,
		masteryCardData,
		reviewListItemsData,
		numCorrect,
		numIncorrect,
		sessionId: response.id,
		sessionTitle: examName,
	};
};

export { getReportData };
