import { Platform } from "react-native";
import type { SESSION_STATUS } from "../../types";

const isWeb = Platform.OS === "web";

export const getButtonIconAndLabel = (
	status: SESSION_STATUS,
	showReport?: boolean,
) => {
	switch (status) {
		case "ACTIVE":
			return {
				icon: "play-pause" as const,
				label: "Resume",
				mode: "outlined" as const,
				progressIcon: "progress-clock" as const,
			};
		case "COMPLETE":
			return {
				icon: "poll" as const,
				label: showReport ? "View Report" : "",
				mode: "text" as const,
				progressIcon: "checkbox-marked-outline" as const,
			};
		case "NOT_STARTED":
			return {
				icon: "play-outline" as const,
				label: "Start",
				mode: "contained" as const,
				progressIcon: isWeb
					? ("progress-alert" as const)
					: ("progress-question" as const),
			};
		default:
			return null;
	}
};
