/* -----------------Globals--------------- */
import { useCallback, useState } from "react";

/* -----------------UI--------------- */
import Box from "@memorang/ui/src/components/Box";
import Container from "@memorang/ui/src/components/Container";
import Loader from "@memorang/ui/src/components/Loader";
import StickyButtonContainer from "@memorang/ui/src/components/StickyButtonContainer";

import { useReviewStore } from "@features/feedback/hooks/useReviewStore";
import useSessionStore from "@features/session/hooks/useSessionStore";
import { fetchSession } from "@features/session/relay/FetchSession";
import { getFilteredSessionItems } from "../helpers/review";
/* -----------------Helpers & Hooks--------------- */
import { useReport } from "../hooks/useReport";

import type {
	SessionItem,
	SessionItemTypes,
} from "@memorang/types/src/session/common";
/* -----------------Types--------------- */
import { router, useLocalSearchParams } from "expo-router";

/* -----------------Child components--------------- */

import { events } from "@constants/tracking";
import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { SessionConfigDialog } from "@features/session-config/components/SessionConfigDialog";
import StudySessionProgressBar from "@features/session/components/StudySessionProgressBar";
import { formatTimeString } from "@helpers/date";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
import { useDevice } from "@hooks/useDevice";
import { isUuid } from "@memorang/helpers/src/common";
import { trackCustomEvent } from "analytics";
import CustomAppbar from "components/CustomAppbar";
import { Appbar } from "react-native-paper";
import { ReportView } from "../components/ReportView";
import type {
	CheckboxStateMap,
	CheckboxType,
} from "../components/dialogs/ReviewAnswersDialog";

const ReportContainer = () => {
	const [showReviewDialog, setShowReviewDialog] = useState(false);
	const trackAppReviewCritieria = useReviewStore(
		(state) => state.trackAppReviewCritieria,
	);
	const [isReviewCriteriaTracked, setIsReviewCriteriaTracked] = useState(false);

	const { isMobile } = useDevice();

	const [showSessionConfigDialog, setShowSessionConfigDialog] = useState(false);

	const toggleSessionConfigDialog = () => {
		setShowSessionConfigDialog((prev) => !prev);
	};
	const { id: sessionIdOrNumericId } = useLocalSearchParams<{
		id: string;
	}>();

	const { handleSessionConfigNavigation } = useCustomNavigation();

	const [checkboxStateMap, setCheckboxStateMap] = useState<CheckboxStateMap>({
		correct: false,
		incorrect: false,
		skipped: false,
		almostcorrect: false,
		marked: false,
	});

	const handleChangeCheckboxStateMap = useCallback(
		(key: keyof CheckboxStateMap) => {
			setCheckboxStateMap((prev) => {
				return {
					...prev,
					[key]: !prev[key],
				};
			});
		},
		[],
	);
	const handleUpdateIncorrectCheckboxStateMap = useCallback(() => {
		setCheckboxStateMap((prev) => {
			return {
				...prev,
				incorrect: true,
			};
		});
	}, []);

	const [fetchingReviewItems, setFetchingReviewItems] = useState(false);
	const resetSessionStore = useSessionStore((state) => state.resetSessionStore);
	const updateSession = useSessionStore((state) => state.updateSession);
	const updateCurrentBlockIndex = useSessionStore(
		(state) => state.updateCurrentBlockIndex,
	);
	const updateInReview = useSessionStore((state) => state.updateInReview);
	const { reportDetails } = useReport();
	const [selectedItemTypeForReview, setSelectedItemTypeForReview] = useState<
		Extract<SessionItemTypes, "QUESTION"> | undefined
	>();

	const sessionId = isUuid(sessionIdOrNumericId)
		? sessionIdOrNumericId
		: undefined;
	const sessionNumericId = isUuid(sessionIdOrNumericId)
		? undefined
		: Number(sessionIdOrNumericId);

	const { viewerId: userId } = useAuthContext();

	const handleReview = async (checkboxStateMap: CheckboxStateMap) => {
		setFetchingReviewItems(true);
		const selectedKeys = Object.keys(checkboxStateMap).filter(
			(key) => checkboxStateMap[key as CheckboxType],
		) as CheckboxType[];
		const { response } = await fetchSession({
			sessionId,
			numericId: sessionNumericId,
			userId: userId!,
		});

		const filteredSessionItems = getFilteredSessionItems(
			response.sessionItems,
			selectedKeys,
			selectedItemTypeForReview,
		);

		if (isSummativeTest) {
			const finalResponse = {
				...response,
				sessionItems: [
					{
						id: "1",
						children: filteredSessionItems,
						itemType: "QUESTION" as const,
						stem: "",
					},
				],
			};
			updateCurrentBlockIndex(0);
			updateSession({
				sessionResponse: finalResponse,
				isSummativeTest: true,
				inReviewMode: true,
			});
		} else {
			updateInReview(true, filteredSessionItems as SessionItem[]);
		}
		setFetchingReviewItems(false);
		router.push({
			pathname: "/(protected)/session/[id]/review",
			params: {
				id: sessionIdOrNumericId,
				index: "1",
			},
		});
		setShowReviewDialog(false);
	};
	const handleContinue = () => {
		resetSessionStore();

		if (router.canGoBack()) {
			router.back();
		} else if (isSummativeTest) {
			router.replace("/practice-tests");
		} else {
			router.replace("/study-pack");
		}
	};

	const handleClickActionChip = (type: string) => {
		// trackCustomEvent({
		//   eventName: events.suggestedChipClicked,
		//   type,
		// });
		switch (type) {
			case "review": {
				setSelectedItemTypeForReview("QUESTION");
				setShowReviewDialog(true);
				break;
			}
			case "study": {
				resetSessionStore();
				handleSessionConfigNavigation(toggleSessionConfigDialog);
				break;
			}
			default:
				break;
		}
	};

	const handleReviewButtonClick = () => {
		trackCustomEvent({
			eventName: events.reviewAnswersClicked,
			type: "QUESTION",
		});
		setSelectedItemTypeForReview("QUESTION");
		setShowReviewDialog(true);
	};

	const handleCloseReviewDialog = () => {
		setShowReviewDialog(false);
		setCheckboxStateMap({
			correct: false,
			incorrect: false,
			skipped: false,
			almostcorrect: false,
			marked: false,
		});
	};

	const isSummativeTest = !isUuid(sessionIdOrNumericId);

	if (!isReviewCriteriaTracked && reportDetails && !isSummativeTest) {
		trackAppReviewCritieria(reportDetails.score > 60);
		setIsReviewCriteriaTracked(true);
	}

	const breadcrumbs = isSummativeTest
		? [
				{
					label: "Practice Tests",
					href: "/practice-tests",
				},
				{
					label: reportDetails?.examName!,
				},
				...(reportDetails
					? [
							{
								label: `Test #${reportDetails.numericId}`,
							},
						]
					: []),
			]
		: [];

	const creationTime = reportDetails
		? formatTimeString(reportDetails.sessionDate, !isMobile)
		: "";
	const caption = reportDetails
		? `Test #${reportDetails.numericId} ∙ ${creationTime}`
		: "";

	const handleTracking = useCallback(
		(eventName: string, params?: Record<string, string | number>) => {
			trackCustomEvent({
				eventName,
				...(params || {}),
				view: "session_report",
			});
		},
		[],
	);

	return (
		<>
			{isSummativeTest && (
				<CustomAppbar
					back
					breadcrumbs={breadcrumbs}
					options={{
						headerTitle: reportDetails?.examName,
						subtitle: caption,
						headerRight: () => null,
					}}
				/>
			)}
			<StickyButtonContainer
				buttonText="Continue"
				maxWidth="sm"
				disabled={!reportDetails}
				onPress={handleContinue}
				showButton={isMobile}
			>
				<Box gap={16} flex={1}>
					{!isSummativeTest && (
						<Appbar.Header
							style={{
								backgroundColor: "transparent",
							}}
						>
							<Container>
								<StudySessionProgressBar />
							</Container>
						</Appbar.Header>
					)}
					{reportDetails ? (
						<ReportView
							reportDetails={reportDetails}
							fetchingReviewItems={fetchingReviewItems}
							handleContinue={handleContinue}
							handleClickActionChip={handleClickActionChip}
							checkboxStateMap={checkboxStateMap}
							isSummativeTest={isSummativeTest}
							handleTracking={handleTracking}
							handleChangeCheckboxStateMap={handleChangeCheckboxStateMap}
							handleUpdateIncorrectCheckboxStateMap={
								handleUpdateIncorrectCheckboxStateMap
							}
							handleReviewButtonClick={handleReviewButtonClick}
							handleCloseReviewDialog={handleCloseReviewDialog}
							selectedItemTypeForReview={selectedItemTypeForReview}
							handleReview={handleReview}
							showReviewDialog={showReviewDialog}
						/>
					) : (
						<Container
							maxWidth="sm"
							containerStyle={{
								flexGrow: 1,
							}}
						>
							<Loader />
						</Container>
					)}
				</Box>
			</StickyButtonContainer>
			{showSessionConfigDialog ? (
				<SessionConfigDialog
					visible={showSessionConfigDialog}
					handleClose={toggleSessionConfigDialog}
				/>
			) : null}
		</>
	);
};

export default ReportContainer;
