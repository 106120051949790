/* -----------------Globals--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------UI--------------- */
import { Card } from "react-native-paper";

/* -----------------Child components--------------- */
import LeaderboardListItem from "components/LeaderboardListItem";

/* -----------------Types--------------- */
import type { LeaderboardItemType } from "./types";

type Props = {
	leaderboardItem: LeaderboardItemType;
};
const LeaderboardUser = ({ leaderboardItem }: Props) => {
	const theme = useAppTheme();
	const { rank, username, xp, avatar } = leaderboardItem;
	return (
		<Card
			style={{
				backgroundColor: theme.colors.elevation.level5,
				borderRadius: 8,
			}}
		>
			<LeaderboardListItem
				title={username}
				xp={xp}
				rank={rank!}
				image={avatar}
				textStyle={{
					...theme.typography.fonts.bold,
					color: theme.colors.onPrimaryContainer,
				}}
			/>
		</Card>
	);
};

export default LeaderboardUser;
