import Box from "@memorang/ui/src/components/Box";
/* -----------------UI--------------- */
import { Button, Text, useTheme } from "react-native-paper";

/* -----------------Child components--------------- */

type Props = {
	handleOpenSupport?: () => void;
	label: string;
	buttonText: string;
	disabled?: boolean;
};
const ContactSupport = (props: Props) => {
	const theme = useTheme();
	const { handleOpenSupport, label, buttonText, disabled } = props;
	return (
		<Box
			flexDirection="row"
			alignItems="center"
			justifyContent="center"
			paddingTop={16}
		>
			<Text>{label}</Text>
			<Button
				mode="text"
				textColor={theme.colors.secondary}
				uppercase={false}
				disabled={disabled}
				onPress={handleOpenSupport}
			>
				{buttonText}
			</Button>
		</Box>
	);
};

export default ContactSupport;
