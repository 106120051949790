/* -----------------Globals--------------- */
import { useCallback, useEffect, useState } from "react";

/* -----------------UI--------------- */
import BottomFab from "@memorang/ui/src/components/BottomFab";
import Box from "@memorang/ui/src/components/Box";
import Loader from "@memorang/ui/src/components/Loader";
import ScrollContainer from "@memorang/ui/src/components/ScrollContainer";
import useBreakpoints from "@memorang/ui/src/useBreakpoints";

import { useExamContext } from "@features/exam/contexts/ExamContext";
import useBundleStore from "@features/store/hooks/useBundleStore";
import fetchBundleDetails from "@features/store/relay/queries/FetchBundleDetails";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useQuery } from "@tanstack/react-query";
import { useFocusEffect, useRouter } from "expo-router";
import { getDashboardData, getStats } from "../helpers/dashboard-data";
import useUserLeaderboard from "../hooks/useUserLeaderboard";

/* -----------------Types--------------- */
import type { BundleDetails } from "@memorang/types/src/bundle";
import type { MixedItemDistResponse } from "@memorang/types/src/tag";

/* -----------------Child components--------------- */
import StudyPackView from "../components/StudyPackView";

import { events } from "@constants/tracking";
/* -----------------Analytics--------------- */
import { trackCustomEvent } from "analytics";

import { appBarMeta } from "@constants/appbar";
import { cacheKeys } from "@constants/cache-keys";
import { isWeb } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";

import CustomAppbar from "components/CustomAppbar";
import type { QuickActionsChipType } from "components/QuickActionChips/types";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { PurchaseDialog } from "../components/PurchaseDialog";
import { getWeakTagsIds } from "../helpers/common";
import fetchStudyPackContents from "../queries/FetchStudyPackContents";

const StudyPackContainer = ({
	showBackButton = true,
}: {
	showBackButton?: boolean;
}) => {
	const {
		currentExamContext: { studyPackProductNumericId, studyPackProductId },
	} = useExamContext();

	const { isMobile } = useDevice();
	const showStudyPackAsHome = useAppStore((store) => store.showStudyPackAsHome);

	const { data: mixedItemDistResponse } = useQuery({
		queryKey: [cacheKeys.studypack, studyPackProductId],
		queryFn: () => fetchStudyPackContents(studyPackProductId),
		enabled: !!studyPackProductId,
	});

	const setCurrentlySelectedBundleDetails = useBundleStore(
		(store) => store.setCurrentlySelectedBundleDetails,
	);
	const currentlySelectedBundleDetails = useBundleStore(
		(store) => store.currentlySelectedBundleDetails,
	);
	const setCurrentlySelectedBundleDetailsForIAP = useBundleStore(
		(store) => store.setCurrentlySelectedBundleDetailsForIAP,
	);

	useFocusEffect(
		useCallback(() => {
			if (currentlySelectedBundleDetails) {
				setCurrentlySelectedBundleDetailsForIAP(currentlySelectedBundleDetails);
			}
		}, [
			currentlySelectedBundleDetails,
			setCurrentlySelectedBundleDetailsForIAP,
		]),
	);

	useEffect(() => {
		if (studyPackProductNumericId) {
			fetchBundleDetails(studyPackProductNumericId).then((res) => {
				setCurrentlySelectedBundleDetails(res);
				setCurrentlySelectedBundleDetailsForIAP(res);
			});
		}
	}, [
		setCurrentlySelectedBundleDetails,
		setCurrentlySelectedBundleDetailsForIAP,
		studyPackProductNumericId,
	]);

	const {
		currentExamContext: { examName },
	} = useExamContext();

	const { smDown } = useBreakpoints();
	const finalHeaderTitle = smDown ? examName : appBarMeta["product-home"].title;

	const finalSubtitle = smDown ? "" : appBarMeta["product-home"].description;

	const customProps = showStudyPackAsHome
		? {
				mode: isWeb ? ("medium" as const) : ("small" as const),
				align: "flex-start" as const,
			}
		: {};

	const CustomLoader = () => {
		return (
			<Box flex={1}>
				<CustomAppbar
					back={showBackButton}
					{...customProps}
					options={{
						headerTitle: finalHeaderTitle,
						subtitle: finalSubtitle,
						...(isMobile && { headerRight: () => null }),
					}}
				/>
				<Loader />
			</Box>
		);
	};

	if (!(mixedItemDistResponse && currentlySelectedBundleDetails)) {
		return <CustomLoader />;
	}

	return (
		<>
			{mixedItemDistResponse && (
				<PreloadedView
					mixedItemDistResponse={mixedItemDistResponse}
					headerTitle={finalHeaderTitle}
					headerSubtitle={finalSubtitle}
					currentlySelectedBundleDetails={currentlySelectedBundleDetails}
					showBackButton={showBackButton}
				/>
			)}
		</>
	);
};

export default StudyPackContainer;

const PreloadedView = ({
	mixedItemDistResponse,
	currentlySelectedBundleDetails,
	headerTitle,
	headerSubtitle,
	showBackButton,
}: {
	mixedItemDistResponse: MixedItemDistResponse;
	currentlySelectedBundleDetails: BundleDetails;
	headerTitle: string;
	headerSubtitle: string | React.ReactNode;
	showBackButton?: boolean;
}) => {
	const { isMobile, isMediumWindowSize } = useDevice();
	const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);

	const theme = useAppTheme();
	const toggleShowPurchaseDialog = () => {
		setShowPurchaseDialog((prev) => !prev);
	};

	const showStudyPackAsHome = useAppStore((store) => store.showStudyPackAsHome);
	const groupByCategory = useAppStore((store) => store.groupByCategory);
	const tenantId = useAppStore((store) => store.tenant.id);

	const router = useRouter();
	const data = mixedItemDistResponse;

	const inset = useSafeAreaInsets();

	const handleNavigateToIAP = () => {
		trackCustomEvent({
			eventName: events.upgradeChipClicked,
		});
		if (isMobile || isMediumWindowSize) {
			router.push({
				pathname: "/purchase",
				params: {
					numItems,
					productType: "STUDY_PACK",
				},
			});
		} else {
			toggleShowPurchaseDialog();
		}
	};
	const {
		distribution,
		productImage,
		productTitle,
		sectionTags,
		numItems,
		masteryPercentage,
	} = getDashboardData(
		data as unknown as MixedItemDistResponse,
		currentlySelectedBundleDetails,
		handleNavigateToIAP,
		groupByCategory,
	);

	const weakTagsIds = getWeakTagsIds(data.getMixedItemDistribution);

	const handleQuickActionPress = (action: QuickActionsChipType["type"]) => {
		trackCustomEvent({
			eventName: events.quickActionClicked,
			action,
		});
		switch (action) {
			case "weak":
				router.push({
					pathname: "/(protected)/session/customize",
					params: {
						selectedTagIds: weakTagsIds,
					},
				});
				break;
			case "leaderboard":
				router.push("/(protected)/(tabs)/leaderboard");
				break;
			case "progress":
				router.push("/(protected)/(tabs)/progress");
				break;
			default:
				break;
		}
	};

	//TODO:Below hooks look ugly to get just the leaderboard work, instead the api should return the stats

	const { userLeaderboardResponse } = useUserLeaderboard(tenantId, "ALLTIME");

	const numAnswered = Object.entries(distribution).reduce(
		(acc, [key, value]) => (key !== "none" ? acc + value : acc),
		0,
	);

	const stats = getStats({
		masteryPercentage,
		numAnswered,
		xp: userLeaderboardResponse?.xp,
		isDarkMode: theme.dark,
	});

	const Wrapper = isWeb ? ScrollContainer : Box;

	const customProps = showStudyPackAsHome
		? {
				mode: isMobile ? ("small" as const) : ("medium" as const),
				align: "flex-start" as const,
			}
		: {};
	return (
		<>
			<CustomAppbar
				back={showBackButton}
				{...customProps}
				options={{
					headerTitle,
					subtitle: headerSubtitle,
					...(isMobile && { headerRight: () => null }),
				}}
				stats={stats}
			/>
			<Wrapper
				style={{
					flexGrow: 1,
					paddingHorizontal: isMobile ? 0 : 16,
				}}
			>
				<StudyPackView
					distribution={distribution}
					productTitle={productTitle}
					productImage={productImage}
					stats={stats}
					sectionTags={sectionTags}
					handleNavigateToIAP={handleNavigateToIAP}
					handleQuickActionPress={handleQuickActionPress}
				/>

				{isMobile && (
					<BottomFab
						icon={"play-outline"}
						bottom={inset.bottom}
						label="Quick Study"
						onPress={() => router.push("/(protected)/session/customize")}
					/>
				)}
			</Wrapper>

			{showPurchaseDialog ? (
				<PurchaseDialog
					visible={showPurchaseDialog}
					handleClose={toggleShowPurchaseDialog}
					productType={"STUDY_PACK"}
				/>
			) : null}
		</>
	);
};
