/**
 * @generated SignedSource<<acb1b1c58bd8211977194ad5acb164dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */

// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FetchAvatarsQuery$variables = {};
export type FetchAvatarsQuery$data = {
  readonly avatarList: ReadonlyArray<{
    readonly gender: string;
    readonly id: string;
    readonly skin: string;
    readonly url: any;
  } | null> | null;
};
export type FetchAvatarsQuery = {
  response: FetchAvatarsQuery$data;
  variables: FetchAvatarsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Avatar",
    "kind": "LinkedField",
    "name": "avatarList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gender",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "skin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchAvatarsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FetchAvatarsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "092e6eeea110810d824435453022e09a",
    "id": null,
    "metadata": {},
    "name": "FetchAvatarsQuery",
    "operationKind": "query",
    "text": "query FetchAvatarsQuery {\n  avatarList {\n    gender\n    id\n    skin\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "0adea3462a08d24a73ba6316050d32b0";

export default node;
