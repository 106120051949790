/* ----------------- Third parties --------------- */
import { Dialog } from "react-native-paper";

/* ----------------- UI --------------- */
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";

import { events } from "@constants/tracking";
import { useAppTheme } from "@hooks/useAppTheme";
import { trackCustomEvent } from "analytics";
import { useEffect } from "react";
/* ----------------- Components --------------- */
import DeleteForm from "../DeleteForm";

type Props = {
	visible: boolean;
	handleDismiss: () => void;
	handleSendFeedback: () => void;
};
const DeleteConfirmationDialog = ({
	visible,
	handleDismiss,
	handleSendFeedback,
}: Props) => {
	const theme = useAppTheme();

	useEffect(() => {
		if (visible) {
			trackCustomEvent({
				eventName: events.dialogOpened,
				type: "delete_confirmation",
			});
		}
	}, [visible]);

	const handleDialogClose = () => {
		handleDismiss();
		trackCustomEvent({
			eventName: events.dialogClosed,
			type: "delete_confirmation",
		});
	};
	return (
		<DialogWrapper
			visible={visible}
			maxWidth={"xs"}
			handleClose={handleDialogClose}
		>
			<Dialog.Icon color={theme.colors.error} icon="alert-outline" />
			<Dialog.Title
				style={{
					textAlign: "center",
				}}
			>
				Confirm delete account
			</Dialog.Title>
			<Dialog.Content>
				<DeleteForm
					handleDismiss={handleDialogClose}
					handleSendFeedback={handleSendFeedback}
				/>
			</Dialog.Content>
		</DialogWrapper>
	);
};

export default DeleteConfirmationDialog;
