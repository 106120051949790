/* -----------------Types--------------- */
import type { Confidence } from "@memorang/types/src/session/common";

import Container from "@memorang/ui/src/components/Container";
/* -----------------Child components--------------- */
import BottomNavigationSession from "./BottomNavigationSession";
import ConfidenceSubmitButtons from "./ConfidenceSubmitButtons";
import NavButtons, { type NextButtonProps } from "./NavButtons";

interface Props extends NextButtonProps {
	showConfidenceSubmitButtons?: boolean;
	onConfidenceProvided: (confidence: Confidence) => void;
	progressText?: string;
	isSummativeTest?: boolean;
	toggleHelpDialog: () => void;
	inReviewMode?: boolean;
	handleTrackConfidenceClick: (confidence: Confidence) => void;
}
const StudySessionBottomAppBar = ({
	handleNext,
	handlePrevious,
	showConfidenceSubmitButtons,
	onConfidenceProvided,
	progressText,
	isSummativeTest,
	toggleHelpDialog,
	handleTrackConfidenceClick,
	inReviewMode,
	...rest
}: Props) => {
	return (
		<BottomNavigationSession isSummativeTest={isSummativeTest}>
			<Container fullWidth={isSummativeTest} maxWidth={"sm"}>
				{showConfidenceSubmitButtons ? (
					<ConfidenceSubmitButtons
						onConfidenceProvided={onConfidenceProvided}
						handleTrackConfidenceClick={handleTrackConfidenceClick}
					/>
				) : (
					<NavButtons
						showButtonNavNext
						showButtonPrevious
						handleNext={handleNext}
						handlePrevious={handlePrevious}
						progressText={progressText}
						isSummativeTest={isSummativeTest}
						inReviewMode={inReviewMode}
						toggleHelpDialog={toggleHelpDialog}
						{...rest}
					/>
				)}
			</Container>
		</BottomNavigationSession>
	);
};

export default StudySessionBottomAppBar;
