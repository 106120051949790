/* -----------------UI--------------- */
import { Appbar } from "react-native-paper";

import StudySessionProgressBar from "../StudySessionProgressBar";
/* -----------------Child components--------------- */
import StudySessionAppBarLeft from "./StudySessionAppBarLeft";
import { StudySessionAppBarRight } from "./StudySessionAppBarRight";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { isAndroid } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
import type { QuestionVariant } from "@memorang/types/src/session/question";
/* -----------------Globals--------------- */
import Box from "@memorang/ui/src/components/Box";
import useSessionStore from "../../hooks/useSessionStore";

type Props = {
	numericId: number;
	examName?: string;
	timeRemaining?: string;
	handleClickRightCta: () => void;
	variant?: QuestionVariant;
	handleClickMenuItem: (type: "pause") => void;
	isTimerExpired?: boolean;
	showPause?: boolean;
};
const StudySessionAppBar = ({
	numericId,
	examName,
	timeRemaining,
	handleClickRightCta,
	variant,
	handleClickMenuItem,
	isTimerExpired,
	showPause,
}: Props) => {
	const { isMobile } = useDevice();
	const { userDetails } = useAuthContext();
	const theme = useAppTheme();

	const userName = userDetails?.name;
	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);
	const inReviewMode = useSessionStore((state) => state.inReviewMode);

	if (isMobile || !isSummativeTest) {
		return (
			<Appbar.Header
				mode={"small"}
				style={{
					justifyContent: "center",
					paddingTop: isAndroid ? 8 : 0,
					backgroundColor: isSummativeTest ? theme.colors.info.dark : undefined,
				}}
			>
				<Box position="relative" width={"100%"} maxWidth={800}>
					<Box flex={1}>
						<StudySessionProgressBar
							showClose
							showMenuIcon={isSummativeTest}
							timeRemaining={timeRemaining}
							handleAction={handleClickRightCta}
							handleClickMenuItem={handleClickMenuItem}
						/>
					</Box>
				</Box>
			</Appbar.Header>
		);
	}

	const icon = isSummativeTest
		? inReviewMode
			? "check-circle-outline"
			: "pause-circle-outline"
		: "stop-circle-outline";
	const cta = isSummativeTest
		? inReviewMode
			? "End Review"
			: "Pause Session"
		: "End Session";

	return (
		<Appbar.Header
			mode="medium"
			style={{
				backgroundColor: isSummativeTest ? theme.colors.info.dark : undefined,
			}}
		>
			<Box
				justifyContent="space-between"
				flexDirection="row"
				width={"100%"}
				padding={16}
				alignItems="center"
			>
				{isSummativeTest ? (
					<Box flex={1}>
						<StudySessionAppBarLeft
							userName={userName!}
							numericId={numericId}
							title={examName!}
						/>
					</Box>
				) : (
					<Box width={150} />
				)}
				<Box flex={1} maxWidth={800}>
					<StudySessionProgressBar />
				</Box>
				<Box flex={1} justifyContent="flex-end" flexDirection="row">
					<StudySessionAppBarRight
						cta={cta}
						icon={icon}
						handleAction={handleClickRightCta}
						timeRemaining={timeRemaining}
						showPause={showPause}
						isTimerExpired={isTimerExpired}
						isSummativeTest={isSummativeTest}
						isReview={inReviewMode}
						variant={variant}
					/>
				</Box>
			</Box>
		</Appbar.Header>
	);
};

export default StudySessionAppBar;
