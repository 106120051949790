/* -----------------UI--------------- */
import { Button, Caption, Text } from "react-native-paper";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { isWeb } from "@helpers/platform";
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@hooks/useDevice";
/* -----------------Components--------------- */
import Box from "@memorang/ui/src/components/Box";
import { useRouter } from "expo-router";
import { useState } from "react";
/* -----------------Hooks--------------- */
import { ChooseDateSvg } from "../../../../assets";
import OnboardingAppBar from "./OnboardingAppBar";
import FreeQuizSessionDialog from "./dialogs/FreeQuizSessionDialog";

interface Props {
	selectedExamDate?: string;
	toggleDatePicker: () => void;
}
const ChooseDateView = ({ selectedExamDate, toggleDatePicker }: Props) => {
	const { isMobile } = useDevice();

	const router = useRouter();

	const quizEnabled = useAppStore((store) => store.exam.quizEnabled);

	const [showFreeQuizDialog, setShowFreeQuizDialog] = useState(false);

	const handleCloseFreeQuizDialog = async (navigate?: boolean) => {
		setShowFreeQuizDialog(false);
		if (navigate) {
			await refreshAuth();
			router.replace("/(protected)/(tabs)/home");
		}
	};

	const handleOpenFreeQuizDialog = () => {
		setShowFreeQuizDialog(true);
	};

	const { refreshAuth } = useAuthContext();
	const {
		currentExamContext: { examName },
	} = useExamContext();
	const appFont = useAppStore((store) => store.app.font);

	const SVG = ChooseDateSvg;

	const title = "When do you plan on taking your exam:";

	const dateLabel = selectedExamDate || "Choose date";
	const caption = "You can change this later";

	const RenderTitle = () => (
		<Box gap={8} alignItems="center">
			<Text
				style={{
					textAlign: "center",
					fontSize: 22,
					lineHeight: 28,
				}}
				variant="bodyLarge"
			>
				{`${title} `}
				<Text
					style={{
						fontFamily: `${appFont}-bolditalic`,
					}}
					variant="titleLarge"
				>
					{examName}
				</Text>
			</Text>
		</Box>
	);

	const SelectDateButton = () => (
		<Button
			mode="contained"
			icon="calendar-outline"
			style={{
				marginTop: 16,
			}}
			onPress={toggleDatePicker}
		>
			{dateLabel}
		</Button>
	);

	const handleContinue = async () => {
		if (isWeb && quizEnabled) {
			handleOpenFreeQuizDialog();
		} else {
			await refreshAuth();
			router.push("/(protected)/(tabs)/home");
		}
	};
	return (
		<>
			<OnboardingAppBar
				title="Choose exam date"
				onBackAction={() => {
					router.setParams({
						step: "chooseexam",
					});
				}}
				elevated
				showProfileMenu={!isMobile}
			/>
			<Box
				flex={1}
				display="flex"
				justifyContent="center"
				alignItems="center"
				paddingHorizontal={16}
				gap={16}
			>
				<SVG
					height={180}
					width="100%"
					style={{
						// @ts-expect-error
						resizeMode: "contain",
						backgroundColor: "transparent",
					}}
				/>
				<RenderTitle />
				<SelectDateButton />
				<Button onPress={handleContinue}>Continue</Button>
				<Caption>{caption}</Caption>
			</Box>
			{showFreeQuizDialog && (
				<FreeQuizSessionDialog
					visible={showFreeQuizDialog}
					onClose={handleCloseFreeQuizDialog}
				/>
			)}
		</>
	);
};

export default ChooseDateView;
