import fetchQueryWrapper from "@helpers/relay-fetch-query-wrapper";
import type { ReportDetails } from "@memorang/types/src/session/report";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";

type Response = {
	getMixedSessionSummary: ReportDetails;
};

const query = graphql`
  query FetchReportQuery($numericId: Int, $sessionId: ID, $userId: ID) {
    getMixedSessionSummary(
      numericId: $numericId
      sessionId: $sessionId
      userId: $userId
    ) {
      id
      numHints
      questionsAccuracy {
        hotspot {
          numQuestions
          mastered
          needsWork
        }
        mcq {
          numQuestions
          mastered
          needsWork
        }
        written {
          numQuestions
          mastered
          needsWork
        }
        sata {
          numQuestions
          mastered
          needsWork
        }
        sequence {
          numQuestions
          mastered
          needsWork
        }
      }
      answered
      accuracy
      timeElapsed
      totalTime
      avgSpeed
      sessionDate
      reportSessionType
      sessionSummaryConfig
      score
      numQuestions
      numCorrect
      numIncorrect
      numSkipped
      markedCount
      numChoicePoints
      totalChoicePoints
      distribution {
        low
        medium
        high
      }
      cstMarkedCount
      isMigratedProgress
      cstItemsCount {
        numSections
        numCorrectActions
        numIncorrectActions
        numItems
      }
      numericId
      examName
      tagDetails {
        id
        title
        type
        weight
        icon
        displayType
        numCorrect
        numIncorrect
        numSkipped
        items {
          distribution
          type
        }
      }
      sessionType
    }
  }
`;

const fetchReport = async ({
	sessionId,
	userId,
	numericId,
}: {
	sessionId: string;
	userId: string;
	numericId?: number;
}): Promise<ReportDetails> => {
	const variables = {
		sessionId,
		userId,
		numericId,
	};
	const { getMixedSessionSummary } = (await fetchQueryWrapper(
		query,
		variables,
	)) as Response;
	return getMixedSessionSummary;
};

export { fetchReport };
