export const emptyStates = {
	progress: {
		title: "No Progress Data Available",
		subtitle: "As you study your progress will show up here",
		cta: "STUDY",
	},
};

export const buttonConfig: Record<
	string,
	{
		buttonMode: "contained" | "outlined" | "text" | "contained-tonal";
		buttonIcon: "play" | "play-pause" | "file-chart-outline";
		text?: string;
	}
> = {
	NOT_STARTED: {
		buttonMode: "contained",
		buttonIcon: "play",
		text: "Start",
	},
	ACTIVE: {
		buttonMode: "outlined",
		buttonIcon: "play-pause",
		text: "Resume",
	},
	COMPLETE: {
		buttonMode: "text",
		buttonIcon: "file-chart-outline",
		text: "View Report",
	},
	LEGACYREPORT: {
		buttonMode: "contained-tonal",
		buttonIcon: "file-chart-outline",
	},
	MIGRATEDREPORT: {
		buttonMode: "contained-tonal",
		buttonIcon: "file-chart-outline",
	},
};

export const statusMeta: Record<
	string,
	{
		section: string;
		text: string;
		icon?: string;
	}
> = {
	NOT_STARTED: {
		section: "Full-Length Practice Tests",
		text: "Not started",
		icon: "clipboard-check-outline",
	},
	ACTIVE: {
		section: "In progress",
		text: "In progress",
		icon: "progress-clock",
	},
	COMPLETE: {
		section: "Completed",
		text: "Completed",
	},

	LEGACYREPORT: {
		text: "Legacy Reports",
		section: "Legacy Reports",
	},
	MIGRATEDREPORT: {
		text: "Migrated Reports",
		section: "Migrated Reports",
	},
};

export const purchaseUrlMap: Record<
	number,
	{
		purchaseUrl: string;
	}
> = {
	66: {
		purchaseUrl: "https://www.therapyed.com/npte-exam/npte-study-guide/",
	},
	67: {
		purchaseUrl: "https://www.therapyed.com/pta-exam/pta-study-guide/",
	},
	68: {
		purchaseUrl: "https://www.therapyed.com/slp-praxis-exam/slp-study-guide/",
	},
	69: {
		purchaseUrl:
			"https://www.therapyed.com/nbcot-cota-exam/nbcot-cota-study-guide/",
	},
	70: {
		purchaseUrl:
			"https://www.therapyed.com/nbcots-otr-exam/nbcots-otr-study-guide/",
	},
};

export const drawerWidths = {
	collapsed: 80,
	expanded: 360,
};
