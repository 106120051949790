/* -----------------Constants--------------- */
import type {
	SessionChildItem,
	SessionItem,
} from "@memorang/types/src/session/common";

import { events } from "@constants/tracking";
import { trackCustomEvent } from "analytics";
import { useEffect } from "react";
import { useMemo } from "react";
import PassageItem from "../components/itemtypes/passage/Item";
/* -----------------Components--------------- */
import QuestionItem from "../components/itemtypes/question/Item";

interface Props {
	item: SessionItem;
	sessionId: string;
}
const StudySessionItemContainer = ({ item, sessionId }: Props) => {
	const { itemType } = item;

	const eventsObject = useMemo(() => {
		return {
			eventName: events.sessionItemViewed,
			itemId: item.id,
			sessionId: sessionId,
		};
	}, [item.id, sessionId]);

	useEffect(() => {
		trackCustomEvent(eventsObject);
	}, [eventsObject]);

	const lookup: Record<string, React.ReactNode> = {
		QUESTION: <QuestionItem item={item as SessionChildItem} />,
		PASSAGE: <PassageItem item={item} />,
	};

	const Component = lookup[itemType];

	return <>{Component}</>;
};

export default StudySessionItemContainer;
