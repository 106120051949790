export const validateCode = (code: string): string | null => {
	if (code.length === 6 && /^[0-9]+$/.test(code)) {
		return "The code you have entered is a One Time Verification Code. Please enter a valid Access Code.";
	}
	return null;
};

export const extractMaskedEmail = (text: string) => {
	const emailRegex =
		/([a-zA-Z0-9])[a-zA-Z0-9*]*@([a-zA-Z0-9])[a-zA-Z0-9*]*\.[a-zA-Z]+/;
	const match = text.match(emailRegex);
	return {
		maskedEmail: match?.[0],
		firstChar: match?.[1],
		domainFirstChar: match?.[2],
	};
};

export const isEmailMatch = (
	maskedEmail: string,
	actualEmail: string,
): boolean => {
	const { firstChar, domainFirstChar } = extractMaskedEmail(maskedEmail);
	if (!firstChar || !domainFirstChar) return false;

	const [actualLocal, actualDomain] = actualEmail.split("@");
	return (
		actualLocal.charAt(0) === firstChar &&
		actualDomain.charAt(0) === domainFirstChar
	);
};
