import { isWeb } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@hooks/useDevice";
import Box from "@memorang/ui/src/components/Box";
import SvgAvatar from "@memorang/ui/src/components/CustomAvatar/SvgAvatar";
/* -----------------Child components--------------- */
import RowStats, { type Stat } from "components/RowStats";
import { Pressable } from "react-native";
import { IconButton } from "react-native-paper";

/* -----------------Props--------------- */
type Props = {
	iconUrl: string;
	avatarSize?: number;
	stats: Stat[];
	handleAvatarClick: () => void;
};

/* -----------------Component--------------- */
const ProfileHeader = (props: Props) => {
	const { iconUrl, avatarSize = 128, stats, handleAvatarClick } = props;
	const theme = useAppTheme();
	const { isMobile } = useDevice();

	return (
		<>
			<Pressable onPress={handleAvatarClick}>
				<Box
					flexDirection={isMobile ? "column" : "row"}
					padding={16}
					justifyContent="center"
					gap={24}
					position="relative"
				>
					<Box
						width={avatarSize}
						height={avatarSize}
						position="absolute"
						zIndex={10000}
						alignSelf="center"
					>
						<IconButton
							style={{
								zIndex: 10000,
								position: "absolute",
								right: -16,
								top: isWeb ? 0 : -16,
							}}
							icon={"pencil-outline"}
							mode="contained"
						/>
					</Box>
					<Box
						width={avatarSize}
						height={avatarSize}
						borderRadius={avatarSize / 2}
						borderColor={theme.colors.secondary}
						borderWidth={3}
						overflow="hidden"
						display="flex"
						alignItems="center"
						justifyContent="center"
						alignSelf="center"
					>
						<SvgAvatar
							size={avatarSize}
							avatar={
								iconUrl ||
								"https://icon.memorang.com/avatars/avatar-pirate-beard-man-asian.svg"
							}
							style={{
								backgroundColor: theme.colors.background,
								width: avatarSize,
								height: avatarSize,
								overflow: "hidden",
								justifyContent: "center",
								alignItems: "center",
								borderRadius: avatarSize / 2,
							}}
						/>
					</Box>
				</Box>
			</Pressable>
			<RowStats stats={stats} maxWidth={"100%"} />
		</>
	);
};

export default ProfileHeader;
