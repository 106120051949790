import { useState } from "react";
import { ChatContext } from "./context";
import { type ChatState, initialChatState } from "./state";

export const ChatContextProvider = ({
	children,
}: { children?: React.ReactNode }) => {
	const [chatState, setChatState] = useState<ChatState>(initialChatState);

	const setPartialChatState = (state: Partial<ChatState>) => {
		setChatState((prevState) => ({
			...prevState,
			...state,
		}));
	};

	return (
		<ChatContext.Provider value={{ chatState, setPartialChatState }}>
			{children}
		</ChatContext.Provider>
	);
};
