/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

/* -----------------UI--------------- */
import Loader from "@memorang/ui/src/components/Loader";

import { useExamContext } from "@features/exam/contexts/ExamContext";
import { useExams } from "@features/exam/hooks/useExams";
import { fetchExams } from "@features/exam/queries/FetchExams";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";

/* -----------------Types--------------- */
import type { ExamDetails } from "@features/exam/types";

/* -----------------Child components--------------- */
import ProductsView from "../components/Store/ProductsView";

import { events } from "@constants/tracking";
import { useToast } from "@contexts/ToastContext";
import useAnalytics from "@hooks/useAnalytics";
/* -----------------Others--------------- */
import { useRouter } from "expo-router";

const StoreContainer = () => {
	const {
		handleSwitchExamContext,
		currentExamContext: { examId, examName },
	} = useExamContext();
	const appId = useAppStore((store) => store.app.id);
	const tenantId = useAppStore((store) => store.tenant.id);

	const router = useRouter();
	const groupedExams = useExams((store) => store.groupedExams);
	const setExams = useExams((store) => store.setExams);

	const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

	const [searchQuery, setSearchQuery] = useState("");

	const { showToast } = useToast();

	const currentCategoryExamSelected = groupedExams?.find((item) => {
		return item.data.find(({ exam }) => exam.id === examId);
	});
	const handleChangeSearch = (query: string) => setSearchQuery(query);

	const filteredProducts = groupedExams
		.map((section) => ({
			...section,
			data: section.data.filter((examDetails) =>
				examDetails.exam.name.toLowerCase().includes(searchQuery.toLowerCase()),
			),
		}))
		.filter(
			(section) =>
				(selectedFilters.length > 0
					? selectedFilters.includes(section.title)
					: true) &&
				(searchQuery
					? section.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
						section.data.length > 0
					: true),
		);

	const finalGroupedExams = (
		searchQuery || selectedFilters.length ? filteredProducts : groupedExams
	).map((section) => ({
		...section,
		data: section.data.sort((a, b) =>
			a.exam.id === examId ? -1 : b.exam.id === examId ? 1 : 0,
		),
	}));
	useAnalytics({
		eventName: events.storeViewed,
	});
	useEffect(() => {
		if (!groupedExams.length) {
			fetchExams(tenantId, appId).then((exams) => {
				setExams(exams);
			});
		}
	}, [tenantId, appId, setExams, groupedExams.length]);

	if (!groupedExams.length) {
		return <Loader />;
	}

	const interceptHandleSwitchExamContext = async (examDetails: ExamDetails) => {
		const message = `Switched Exam from "${examName}" to "${examDetails?.exam.name}"`;
		showToast({ message, duration: 1000 });
		await handleSwitchExamContext(examDetails);
		router.push("/home");
	};

	const filteredGroupedExams = [...finalGroupedExams]
		.sort(({ title }) => {
			return title === currentCategoryExamSelected?.title ? -1 : 1;
		})
		.filter((item) => {
			return item.data.length > 0;
		});

	const handleSelectFilter = (filter: string) => {
		if (selectedFilters.includes(filter)) {
			setSelectedFilters(selectedFilters.filter((f) => f !== filter));
		} else {
			setSelectedFilters([...selectedFilters, filter]);
		}
	};

	const filters = groupedExams.map((section) => section.title);

	return (
		<ProductsView
			products={filteredGroupedExams}
			searchQuery={searchQuery}
			selectedFilters={selectedFilters}
			handleSelectFilter={handleSelectFilter}
			filters={filters}
			handleChangeSearch={handleChangeSearch}
			handleSwitchExamContext={interceptHandleSwitchExamContext}
		/>
	);
};

export default StoreContainer;
