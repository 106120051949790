/* -----------------Globals--------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { useDevice } from "@memorang/ui/src/useDevice";
import { StyleSheet } from "react-native";

/* -----------------UI--------------- */
import { Surface } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

/* -----------------Styles--------------- */
const getStyles = () =>
	StyleSheet.create({
		surface: {
			elevation: 4,
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			zIndex: 100000,
		},
	});

/* -----------------Props--------------- */
type Props = {
	children: React.ReactNode;
	isSummativeTest?: boolean;
};
/* -----------------Component--------------- */
const BottomNavigationSession = (props: Props) => {
	const { children, isSummativeTest } = props;
	const styles = getStyles();
	const theme = useAppTheme();
	const { isMobile } = useDevice();
	const insets = useSafeAreaInsets();
	const insetBottom = Math.max(insets.bottom - 16, 0);
	const defaultHeight = 80;
	// TODO: Instead of absolute position, this should be outside of the ScrollView
	return (
		<Surface
			style={[
				styles.surface,
				{
					height: defaultHeight + insetBottom,
					display: "flex",
					flexDirection: "row",
					alignItems: isMobile ? "flex-start" : "center",
					paddingHorizontal: 16,
					paddingTop: isMobile ? 16 : 0,
					position: "absolute",
					bottom: 0,
					right: 0,
					left: 0,
					backgroundColor: isSummativeTest
						? theme.colors.info.dark
						: theme.colors.elevation.level1,
				},
			]}
		>
			{children}
		</Surface>
	);
};

export default BottomNavigationSession;
