import type { VectorDocument } from "../types";

export const getShortenedText = (text: string, wordLimit = 8) => {
	const cleanedText = text.replace(/\n/g, " ");
	const words = cleanedText.split(" ");
	if (words.length > wordLimit) {
		return `${words.slice(0, wordLimit).join(" ")}...`;
	}
	return cleanedText;
};

export const getChapterTextAndSource = ({
	metadata,
	pageContent,
}: VectorDocument) => {
	const source = metadata.SourceId;
	const chapter = metadata.chapter;
	const subChapter = metadata.subChapter;
	const cfi = metadata.cfi;
	const title = metadata.title;
	const referenceText = pageContent;

	const shortenedReferenceText = getShortenedText(referenceText);
	const url = `/content/ebook/${source}#${cfi}`;

	return {
		source,
		chapter,
		subChapter,
		title,
		cfi,
		url,
		shortenedReferenceText,
	};
};
