/* ======== Hooks ========= */
import { useAppTheme } from "@hooks/useAppTheme";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* ======== Components ========= */
import Box from "@memorang/ui/src/components/Box";
import { Text } from "react-native-paper";

type Props = {
	features: string[];
};
const FeaturesSection = ({ features }: Props) => {
	const theme = useAppTheme();
	return (
		<Box paddingHorizontal={0} gap={8}>
			{features.map((item) => (
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					key={item}
					gap={8}
				>
					<Icon size={24} color={theme.colors.progress.high} name="check" />
					<Text
						style={{
							flex: 1,
						}}
						variant="labelLarge"
					>
						{item}
					</Text>
				</Box>
			))}
		</Box>
	);
};
export default FeaturesSection;
