import { events } from "@constants/tracking";
import type {
	AccessCodeData,
	AccessCodeResult,
} from "@features/auth/relay/applyAccessCode";
import { fetchExamDetails } from "@features/exam/queries/FetchExamDetails";
import { fetchExams } from "@features/exam/queries/FetchExams";
import type { ExamDetails } from "@features/exam/types";
import { formatTimeString } from "@helpers/date";
import { isOlxExamWithinDateRange } from "@helpers/exams";
import { currentAppVersion } from "@helpers/expo-extras";
import { extractMaskedEmail, isEmailMatch } from "@helpers/string-utils";
import { getPlatform } from "@memorang/helpers/src/common";
import { trackCustomEvent } from "analytics";
import { DateTime } from "luxon";
import type { Dispatch } from "react";
import type { SetStateAction } from "react";

export const getRedeemCodeSuccessText = (accessCodeResult: AccessCodeData) => {
	const { bundleName, expiryDate, planLength } = accessCodeResult;
	const expiryText = DateTime.fromISO(expiryDate).toFormat("MMMM dd, yyyy");
	return `You now have **${planLength} access** to the **${bundleName}**! This will expire on **${expiryText}**`;
};

export const getOlxPostRedeemDialogConfig = (
	beginStartDate: string,
	endStartDate: string,
) => {
	const now = Date.now();
	const beginTime = new Date(beginStartDate).getTime();
	const endTime = new Date(endStartDate).getTime();
	let title = "Access Unlocked";
	let body = "";
	let confirmCta: string | undefined;
	let cancelCta = "OK";

	if (now < beginTime) {
		const daysUntilStart = Math.ceil((beginTime - now) / (1000 * 60 * 60 * 24));
		body = `Your access to the OLX test has been unlocked! You can start your session in ${daysUntilStart} day(s). Please check back later to begin your session from the Practice Test tab.`;
	} else if (isOlxExamWithinDateRange(beginStartDate, endStartDate)) {
		body = `Your access to the OLX test is now active! You can start your session anytime before ${formatTimeString(
			endStartDate,
		)}. Would you like to begin your session now or start it later?`;
		confirmCta = "Start Now";
		cancelCta = "Start Later";
	} else if (now > endTime) {
		title = "Access Expired";
		body = `Your access to the OLX test has expired on ${formatTimeString(
			endStartDate,
		)}. Please contact your professor or school administrator for further assistance.`;
	}

	return {
		title,
		body,
		confirmCta,
		cancelCta,
	};
};

export const handleApplyCodeErrorMessage = (
	email: string,
	e: unknown,
): { error: string; bundleId?: string } => {
	let error = "An unexpected error occurred.";
	let bundleId: string | undefined;

	if (
		e &&
		typeof e === "object" &&
		"message" in e &&
		typeof e.message === "string"
	) {
		error = e.message;
		if ("bundleId" in e && typeof e.bundleId === "string") {
			bundleId = e.bundleId;
		}
		const { maskedEmail } = extractMaskedEmail(error);
		if (maskedEmail) {
			const sameEmail = isEmailMatch(maskedEmail, email);
			error = sameEmail
				? `This code is already redeemed by ${maskedEmail}, partially matches current login - ${email}.`
				: `${error} The code has already been redeemed by a different user.`;
		}
	}

	return { error, bundleId };
};

export const getSupportDetails = (
	appName: string,
	username: string,
	email: string,
) => {
	const version = currentAppVersion;
	const platform = getPlatform();
	const subject = `[${appName}] Access Code Issue`;
	const body = `Hey ${appName} support, my username is ${username} and email is ${email}. I'm using version ${version} of the ${appName} ${platform} app. I'm having some trouble with my access code. I've included it here: [copy paste your access code here]
  
Thank you!`;

	return { subject, body, version, platform };
};

export const switchExamContext = async (
	examId: string,
	tenantId: string,
	handleSwitchExamContext: (examDetails: ExamDetails) => Promise<void>,
	appId: string,
) => {
	try {
		const examDetailsLatest = await fetchExamDetails({ tenantId, examId });

		if (examDetailsLatest) {
			// console.info("examDetailsLatest", examDetailsLatest);
			await handleSwitchExamContext(examDetailsLatest);
			return;
		}
		trackCustomEvent({
			eventName: events.settingExamContextFromExamDetailsFailed,
			examId,
			tenantId,
			examDetailsResult: JSON.stringify(examDetailsLatest),
		});

		const exams = await fetchExams(tenantId, appId);
		const examDetails = exams.find(
			(examDetails) => examDetails.exam.id === examId,
		);
		if (examDetails) {
			// console.info("examDetails", examDetails);

			await handleSwitchExamContext(examDetails);
		}
	} catch (error) {
		trackCustomEvent({
			eventName: events.postRedeemExamContextSwitchFailed,
			examId,
			tenantId,
			error: JSON.stringify(error),
		});
	}
};

export const handleAccessCodeResult = async (
	result: AccessCodeResult,
	appId: string,
	handleSwitchExamContext: (examDetails: ExamDetails) => Promise<void>,
	setOlxDates: Dispatch<
		SetStateAction<{
			beginStartDate: string;
			endStartDate: string;
			tempAccessCodeResponse?: AccessCodeResult;
		} | null>
	>,
	handleNext: (result: AccessCodeResult) => void,
	tenantId: string,
) => {
	trackCustomEvent({
		eventName: events.promoCodeRedeemed,
		examId: result.data.examId,
		examName: result.data.examName,
	});
	await switchExamContext(
		result.data.examId,
		tenantId,
		handleSwitchExamContext,
		appId,
	);
	if (result.data.beginStartDate && result.data.endStartDate) {
		setOlxDates({
			beginStartDate: result.data.beginStartDate,
			endStartDate: result.data.endStartDate,
			tempAccessCodeResponse: result,
		});
	} else {
		handleNext(result);

		// Mock Succssful Redeem Response
		// success = true;
		// const res = mockedAccessCodeResponse as AccessCodeResult;
		// handleNext(res);
	}
};
