import { groupTagsByType } from "@helpers/content/tag";
import type { Distribution } from "@memorang/types/src/content";
import type { MixedItemTag } from "@memorang/types/src/tag";

const hasStudiedOrAttempted = (distribution: Distribution) => {
	const { high, medium, low } = distribution;
	return high + medium + low > 0;
};

const calculateOpporunityScore = (accuracy: number, tagWeight: number) => {
	const score = Math.round((1 - accuracy / 100) * tagWeight);
	return score;
};
export const getTopicsTableData = (
	tags: MixedItemTag[],
	type: string,
	numericId?: number,
	examName?: string,
	// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
) => {
	const defaultDescription = `Your predicted mastery per ${type} based upon your progress so far`;

	const groupedTags = groupTagsByType(tags, "Progress", [], true);
	const tagsForType = groupedTags[`${type}`];

	const filteredTagsForType = tagsForType.filter(({ distribution }) =>
		hasStudiedOrAttempted(distribution),
	);

	const areWeightEmpty = filteredTagsForType.every(({ weight }) => !weight);

	const columns = [
		{
			key: "accuracy.score",
			title: "Accuracy",
			tooltip: "Accuracy is the percentage of items answered correctly",
		},
		{
			key: "title",
			title: type || "Topic",
			tooltip: "Name of the topic",
		},
		{
			key: "correct",
			title: "Correct",
			tooltip: "Number of items answered correctly",
		},
		{
			key: "incorrect",
			title: "Incorrect",
			tooltip: "Number of items answered incorrectly",
		},
		{
			key: "items",
			title: "Total",
			tooltip: "Total number of items",
		},
		...(areWeightEmpty
			? []
			: [
					{
						key: "exam",
						title: "Exam",
						tooltip: "Percentage of the exam that the topic represents",
					},
					{
						key: "opportunity",
						title: "Score Opportunity",
						tooltip:
							"The greater the “opportunity,” the better suited you will be by focusing your studies to strengthen your mastery of that topic to maximize your chance of passing.",
					},
				]),
	];

	const rowData = filteredTagsForType.map(
		({
			count = 0,
			distribution,
			title,
			score,
			weight,
			numCorrect = 0,
			numIncorrect = 0,
			// numSkipped,
		}) => {
			const opportunity = calculateOpporunityScore(
				score ? Number(score) : 0,
				weight || 0,
			);
			return {
				accuracy: {
					distribution,
					score: Number(score),
				},
				title,
				correct: numCorrect,
				incorrect: numIncorrect,
				items: count,
				...(areWeightEmpty
					? {}
					: {
							exam: weight,
							opportunity,
						}),
			};
		},
	);

	// Get the list of all properties in the rowData
	const rowDataKeys = columns.map((column) => column.key);

	// Create the footerData object based on rowDataKeys
	const footerDataObj: {
		[key: string]: string;
	} = {};
	for (const key of rowDataKeys) {
		if (key === "accuracy.score") {
			// Calculate the accuracy as the average of all scores
			const scoreSum = rowData.reduce(
				(sum, obj) => sum + (obj.accuracy ? obj.accuracy.score : 0),
				0,
			);
			const scoreCount = rowData.filter((obj) => obj.accuracy).length;
			footerDataObj.accuracy = `${
				scoreCount > 0 ? (scoreSum / scoreCount).toFixed(2) : 0
			}%`;
		} else if (key === "title") {
			// Set the title to "Total"
			footerDataObj.title = `${rowData.length} items`;
		} else if (key === "correct") {
			// Calculate the sum of all correct
			footerDataObj.correct = `${rowData.reduce(
				(sum, obj) => sum + obj.correct,
				0,
			)} Correct`;
		} else if (key === "incorrect") {
			// Calculate the sum of all incorrect
			footerDataObj.incorrect = `${rowData.reduce(
				(sum, obj) => sum + obj.incorrect,
				0,
			)} Incorrect`;
		} else if (key === "items") {
			// Calculate the sum of all items
			footerDataObj.items = `${rowData.reduce(
				(sum, obj) => sum + obj.items,
				0,
			)}`;
		} else if (key === "exam") {
			// Set the exam percentage to 100%
			footerDataObj.exam = `${Math.round(
				rowData.reduce((sum, obj) => sum + (obj.exam || 0), 0),
			)}%`;
		} else if (key === "opportunity") {
			// Calculate the total score opportunity
			footerDataObj.opportunity = `${Math.round(
				rowData.reduce((sum, obj) => sum + (obj.opportunity || 0), 0),
			)}%`;
		}
	}

	const sortedRows = rowData.sort((a, b) => {
		if (a.accuracy.score < b.accuracy.score) {
			return -1;
		}
		return 1;
	});
	const numTopics = tagsForType.length;
	const numItems = tagsForType.reduce((acc, { count = 0 }) => acc + count, 0);
	const subheader = `${numTopics} topics ∙ ${numItems} items`;

	const isProgressPage = window.location.pathname.includes("progress");
	const fromStudyPack = window.location.pathname.includes("study-pack");
	const breadcrumbs = [
		...(examName
			? [
					{
						label: "Practice Tests",
						href: "/practice-tests",
					},
					{
						label: examName,
					},
					{
						label: numericId ? `Test #${numericId}` : "Report",
						href: `/session/${numericId}/report/`,
					},
				]
			: []),
		...(isProgressPage
			? [
					{
						label: "Progress",
						href: "/progress",
					},
					...(fromStudyPack
						? [{ label: "Study Pack" }]
						: [
								{
									label: "Practice Tests",
								},
							]),
				]
			: []),
	];

	return {
		columns,
		rows: sortedRows,
		title: type,
		breadcrumbs,
		subheader,
		description: defaultDescription,
		footerData: footerDataObj,
	};
};
