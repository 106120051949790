import { graphql } from "react-relay";
import { commitMutation } from "react-relay";
import relayEnv from "relay/relayenv";

export type AdminResult = {
	success: boolean;
	message: string;
};

type Response = {
	resendAccessCode: AdminResult;
};

export const mutation = graphql`
  mutation ResendAccessCodesMutation($orderId: String!, $tenantId: String!) {
    resendAccessCode(orderId: $orderId, tenantId: $tenantId) {
      success
      message
    }
  }
`;

export const resendAccessCode = async ({
	orderId,
	tenantId,
}: {
	orderId: string;
	tenantId: string;
}): Promise<AdminResult> => {
	const promise = new Promise((resolve, reject) => {
		const variables = {
			orderId,
			tenantId,
		};

		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const finalResponse = response as Response;
				const { resendAccessCode } = finalResponse;

				const { success, message } = resendAccessCode;
				if (success) {
					resolve(resendAccessCode);
				} else {
					reject(message);
				}
			},
			onError: (error) => {
				reject(error);
			},
		});
	});
	return promise as Promise<AdminResult>;
};
