/* -----------------Globals--------------- */
import { Link, useNavigation, usePathname, useRouter } from "expo-router";

/* -----------------UI--------------- */
import { Appbar, type AppbarHeaderProps, Text } from "react-native-paper";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { FeedbackDialog } from "@features/feedback/components/dialogs/FeedbackDialog";
import { defaultFeedbackSubtitle } from "@features/feedback/constants";
import { isWeb } from "@helpers/platform";
/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@hooks/useAppTheme";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
import { useDevice } from "@hooks/useDevice";
import Box from "@memorang/ui/src/components/Box";
import CustomAvatar from "@memorang/ui/src/components/CustomAvatar";
import {
	type BreadcrumbType,
	CustomBreadcrumbWeb,
} from "@memorang/ui/src/components/CustomBreadcrumbWeb";
import TooltipWrapper from "@memorang/ui/src/components/TooltipWrapper";
import { useState } from "react";
import RowStats, { type Stat } from "../RowStats";
import { CommandBar } from "./CommandBar";

const TitleContent = ({
	headerTitle,
	subtitle,
	stats,
	breadcrumbs,
	renderSearchbar,
}: {
	align?: "center" | "flex-start";
	headerTitle?: string;
	subtitle?: string | React.ReactNode;
	stats?: Stat[];
	breadcrumbs?: BreadcrumbType[];
	renderSearchbar?: () => React.ReactNode;
}) => {
	const { isMobile, isMediumWindowSize } = useDevice();
	return (
		<Box gap={isMobile || isMediumWindowSize ? 8 : 16} width={"100%"}>
			{renderSearchbar?.()}
			<Box gap={4} paddingTop={isMobile ? 0 : 16}>
				{breadcrumbs?.length && !isMobile && isWeb ? (
					<CustomBreadcrumbWeb breadcrumbs={breadcrumbs} />
				) : null}
				<Box>
					{headerTitle && (
						<Text
							numberOfLines={1}
							style={{ maxWidth: isMobile ? 400 : "100%" }}
							variant={isMobile ? "titleLarge" : "headlineMedium"}
						>
							{headerTitle as string}
						</Text>
					)}
					{subtitle && <Text variant="bodyMedium">{subtitle}</Text>}
				</Box>
			</Box>
			{stats?.length && !isMobile ? (
				<RowStats stacked={false} stats={stats} />
			) : null}
		</Box>
	);
};

const CustomNavigationBar = (
	props: {
		back?: boolean;
		stats?: Stat[];
		breadcrumbs?: BreadcrumbType[];
		showHambugerMenu?: boolean;
		showCommandBar?: boolean;
		renderSearchbar?: () => React.ReactNode;
		align?: "center" | "flex-start";
		options: {
			headerTitle?: string;
			subtitle?: string | React.ReactNode;
			headerRight?: () => React.ReactNode;
			headerLeft?: () => React.ReactNode;
		};
	} & Omit<AppbarHeaderProps, "children">,
) => {
	const {
		back,
		stats,
		options: { headerTitle, subtitle, headerRight, headerLeft },
		breadcrumbs,
		renderSearchbar,
		showHambugerMenu,
		align,
		showCommandBar,
		...rest
	} = props;
	const theme = useAppTheme();

	const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
	const pathname = usePathname();

	const { handleFeedbackNavigation } = useCustomNavigation();
	const { userDetails } = useAuthContext();

	const userAvatar = userDetails?.iconUrl;

	const toggleShowFeedbackDialog = () => {
		setShowFeedbackDialog((prev) => !prev);
	};

	const { isMobile, isMediumWindowSize } = useDevice();

	// FOR SOME REASON, the medium prop medium does not work well on tablet
	const mode = isMobile ? "small" : isMediumWindowSize ? "large" : "medium";
	const finalAlign =
		align || (isMobile ? ("center" as const) : ("flex-start" as const));

	const renderHeaderRight = () => {
		if (headerRight) {
			return headerRight();
		}

		return (
			<Box flexDirection="row" paddingRight={12} alignItems="center">
				{isWeb ? (
					<TooltipWrapper title="We value your feedback. Click here to share your thoughts.">
						<Appbar.Action
							icon="comment-quote-outline"
							onPress={() => handleFeedbackNavigation(toggleShowFeedbackDialog)}
						/>
					</TooltipWrapper>
				) : (
					<Appbar.Action
						icon="comment-quote-outline"
						onPress={() => handleFeedbackNavigation(toggleShowFeedbackDialog)}
					/>
				)}
				<Appbar.Action
					icon="cog-outline"
					style={{ marginRight: 16 }}
					onPress={() => router.push("/(protected)/account/settings")}
				/>
				<Link href={"/profile"}>
					<CustomAvatar
						avatar={
							userAvatar ||
							"https://icon.memorang.com/avatars/avatar-pirate-beard-man-asian.svg"
						}
						size={40}
					/>
				</Link>
			</Box>
		);
	};

	const router = useRouter();
	const navigation = useNavigation<{
		toggleDrawer: () => void;
	}>();
	return (
		//@ts-ignore
		<Appbar.Header
			theme={theme}
			mode={mode}
			elevated
			{...rest}
			{...(isWeb
				? {
						style: {
							height: "fit-content",
						},
					}
				: {})}
		>
			{isWeb && (
				<Box
					position="absolute"
					alignItems="center"
					left={0}
					flexDirection="row"
				>
					{isMobile && showHambugerMenu && (
						<Appbar.Action
							icon="menu"
							onPress={() => navigation.toggleDrawer()}
						/>
					)}
					{showCommandBar && !isMobile && (
						<Box paddingLeft={12}>
							<CommandBar />
						</Box>
					)}
				</Box>
			)}

			{headerLeft?.()}
			{back && !headerLeft && (
				<Appbar.BackAction
					onPress={() => {
						if (router.canGoBack()) {
							router.back();
						} else {
							router.push("/(protected)/(tabs)/home");
						}
					}}
				/>
			)}
			<Appbar.Content
				title={
					<TitleContent
						align={finalAlign}
						headerTitle={headerTitle}
						subtitle={subtitle}
						stats={stats}
						breadcrumbs={breadcrumbs}
						renderSearchbar={renderSearchbar}
					/>
				}
			/>
			{renderHeaderRight()}
			{isWeb && (
				<FeedbackDialog
					visible={showFeedbackDialog}
					view={pathname}
					feedbackSubtitle={defaultFeedbackSubtitle}
					handleDismiss={toggleShowFeedbackDialog}
				/>
			)}
		</Appbar.Header>
	);
};

export default CustomNavigationBar;
