import type {
	ContextProperties,
	RedeemCopilotEventProperties,
	SensitiveProperties,
} from "../types";
import { maskSensitiveData } from "./masking";

const convertToStringRecord = (
	obj: Record<string, unknown>,
): Record<string, string> => {
	const result: Record<string, string> = {};

	for (const [key, value] of Object.entries(obj)) {
		if (value !== undefined && value !== null) {
			result[key] = value.toString();
		}
	}

	return result;
};

export const prepareTrackingProperties = (
	baseProperties: ContextProperties,
	properties: RedeemCopilotEventProperties,
	sensitiveData: SensitiveProperties,
): Record<string, string> => {
	const { success, ...otherProperties } = properties;
	const { accessCode, lastRedeemedAccessCode } = sensitiveData;

	const preparedProperties = {
		...baseProperties,
		...otherProperties,
		...(success !== undefined && { success: success.toString() }),
		...(accessCode && {
			accessCode: maskSensitiveData.accessCode(accessCode),
		}),
		...(lastRedeemedAccessCode &&
			lastRedeemedAccessCode !== accessCode && {
				lastRedeemedAccessCode: maskSensitiveData.accessCode(
					lastRedeemedAccessCode,
				),
			}),
	};

	return convertToStringRecord(preparedProperties);
};
