/* -----------------Globals--------------- */
import { ScrollView } from "react-native";
/* -----------------UI--------------- */
import { Text } from "react-native-paper";

/* -----------------Types--------------- */
import type { LeaderboardItemType } from "./types";

import FilterChips from "./FilterChips";
import LeaderboardList from "./LeaderboardList";
/* -----------------Child components--------------- */
import TopRankersList from "./TopRankersList";

type Props = {
	leaderboards: LeaderboardItemType[];
	daysToReset?: string;
	userLeaderboardItem?: LeaderboardItemType;
	topRankers: LeaderboardItemType[];
	selectedChip: string;
	handleSelectChip: (type: string) => void;
};

const LeaderboardView = (props: Props) => {
	const {
		leaderboards,
		daysToReset,
		topRankers,
		userLeaderboardItem,
		selectedChip,
		handleSelectChip,
	} = props;

	if (!leaderboards?.length) {
		return null;
	}

	const LeaderboardCaption = ({ days }: { days: string }) => (
		<Text variant="bodySmall">{` Leaderboard resets in ${days} days`}</Text>
	);

	return (
		<ScrollView
			contentContainerStyle={{
				padding: 16,
				gap: 16,
			}}
		>
			<FilterChips
				selectedChip={selectedChip}
				handleSelectChip={handleSelectChip}
			/>
			<TopRankersList topRankers={topRankers} />
			{daysToReset ? <LeaderboardCaption days={daysToReset} /> : null}
			<LeaderboardList
				userLeaderboardItem={userLeaderboardItem}
				leaderboards={leaderboards}
			/>
		</ScrollView>
	);
};

export default LeaderboardView;
