import { useExamContext } from "@features/exam/contexts/ExamContext";
import { fetchExamDetails } from "@features/exam/queries/FetchExamDetails";
import { useAppStore } from "@hooks/useAppStore";
import { useAppTheme } from "@hooks/useAppTheme";
import { AlertStandard } from "@memorang/ui/src/components/AlertStandard";
import Box from "@memorang/ui/src/components/Box";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import { useCallback, useEffect, useState } from "react";
import { Button, TextInput } from "react-native-paper";
import triggerAccessCode, {
	type AccessCodeData,
} from "../relay/applyAccessCode";

type Props = {
	onboarding?: boolean;
	redeemCode?: string;
};
const FieldAccessCode = ({ onboarding, redeemCode }: Props) => {
	const theme = useAppTheme();
	const tenantId = useAppStore((store) => store.tenant.id);
	const [textCode, setTextCode] = useState("");
	const [redeemed, setRedeemed] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const { handleSwitchExamContext } = useExamContext();
	const handlePostApplyAccessCode = useCallback(
		async (accessCodeResult: AccessCodeData) => {
			const examDetails = await fetchExamDetails({
				examId: accessCodeResult.examId,
				tenantId,
			});

			await handleSwitchExamContext(examDetails);
		},
		[handleSwitchExamContext, tenantId],
	);
	const handleApplyAccessCode = useCallback(
		async (code: string) => {
			if (code) {
				setLoading(true);
				if (code.length === 6 && code.match(/^[0-9]+$/)) {
					setErrorMessage(
						"The code you have entered is a One Time Verification Code. Please enter a valid Access Code.",
					);
					setShowError(true);
					setLoading(false);
					return;
				}
				try {
					const accessCodeResult = await triggerAccessCode(code);
					if (onboarding) {
						await handlePostApplyAccessCode(accessCodeResult.data);
					}
					setRedeemed(true);
				} catch (e) {
					// const res = {
					//   bundleId: 'd4bbf09e-f415-42fd-83f2-35ef0689a993',
					//   bundleNumericId: 79,
					//   bundleName: 'string',
					//   planLength: 'string',
					//   expiryDate: 'string',
					//   examName: 'string',
					//   bundleType: 'PRACTICE_TEST',
					// };
					// await handleSwitchExamContext(res);
					// setRedeemed(true);
					setShowError(true);
					setErrorMessage(e as string);
				} finally {
					setLoading(false);
				}
			}
		},
		[onboarding, handlePostApplyAccessCode],
	);

	useEffect(() => {
		if (redeemCode) {
			setTextCode(redeemCode);
			handleApplyAccessCode(redeemCode);
		}
	}, [handleApplyAccessCode, redeemCode]);

	if (redeemed) {
		return <AlertStandard title="Access redeemed" visible />;
	}
	return (
		<Box>
			<Box flexDirection="column" gap={16}>
				<TextInput
					placeholder="XXX-XXX-XXXXXX"
					mode="outlined"
					autoFocus
					value={textCode}
					onChangeText={(text) => {
						setTextCode(text);
						if (showError) {
							setShowError(false);
						}
					}}
					style={{
						width: "100%",
					}}
				/>
				<Button
					mode="contained"
					onPress={() => handleApplyAccessCode(textCode)}
					loading={loading}
					style={{
						minWidth: 360,
					}}
					disabled={showError || !textCode || loading || textCode.length < 6}
				>
					Redeem
				</Button>
			</Box>
			{showError && (
				<MarkdownRenderer
					text={errorMessage}
					textAlign="center"
					customStyles={{
						text: {
							color: theme.palette.error.main,
							width: 250,
						},
					}}
				/>
			)}
		</Box>
	);
};

export default FieldAccessCode;
