import Box from "@memorang/ui/src/components/Box";
import CardContainer from "@memorang/ui/src/components/CardContainer";
import Loader from "@memorang/ui/src/components/Loader";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import { Button, Card, TouchableRipple } from "react-native-paper";
import { useAppTheme } from "../useAppTheme";
import { subtitle, title } from "./constants";

type Props = {
	sessionAnalysis?: string;
	loading?: boolean;
	toggleShowCompleteReport: () => void;
};
const SessionAnalysisCard = ({
	sessionAnalysis = "",
	loading = false,
	toggleShowCompleteReport,
}: Props) => {
	const theme = useAppTheme();

	const CardContent = () => {
		const truncatedText =
			sessionAnalysis?.length > 400
				? `${sessionAnalysis?.substring(0, 330)}...`
				: sessionAnalysis;

		return (
			<Card.Content
				style={{
					paddingHorizontal: 16,
					height: 170,
					overflow: "hidden",
					paddingTop: 8,
				}}
			>
				<MarkdownRenderer text={truncatedText} variant="explanation" />
			</Card.Content>
		);
	};

	return (
		<CardContainer
			title={title}
			subtitle={subtitle}
			icon="head-lightbulb-outline"
			actions={
				<Card.Actions>
					<Button
						theme={{ colors: { primary: theme.colors.secondary } }}
						onPress={toggleShowCompleteReport}
						mode="text"
					>
						View Full Analysis
					</Button>
				</Card.Actions>
			}
		>
			<TouchableRipple onPress={toggleShowCompleteReport}>
				{loading || !sessionAnalysis ? (
					<Box paddingVertical={16} height={170} alignItems="center">
						<Loader caption="Generating... this may take a minute" />
					</Box>
				) : (
					<CardContent />
				)}
			</TouchableRipple>
		</CardContainer>
	);
};

export default SessionAnalysisCard;
