/* -----------------Globals--------------- */
import { commitMutation, graphql } from "react-relay";
import relayEnv from "relay/relayenv";

import { captureErrorToSentry } from "@helpers/sentry-logger";
/* -----------------Types--------------- */
import type { RecordMixedItemInput } from "@memorang/types/src/session/common";

interface Response {
	recordMixedItem: boolean;
}

const recordSummativeTestEvents = async (
	input: RecordMixedItemInput,
): Promise<boolean> => {
	const mutation = graphql`
    mutation RecordSummativeTestEventsMutation($request: RecordMixedItemInput) {
      recordMixedItem(request: $request)
    }
  `;

	const promise = new Promise<boolean>((resolve, reject) => {
		const variables = {
			request: input,
		};

		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const finalResponse = response as Response;
				resolve(finalResponse.recordMixedItem);
			},
			onError: (err) => {
				reject(err);
				captureErrorToSentry(
					err,
					{
						extraData: input,
					},
					"Record Summative Test Events Error",
				);
			},
		});
	});
	return promise;
};

export { recordSummativeTestEvents };
