import { validateCode } from "@helpers/string-utils";

export const maskSensitiveData = {
	accessCode: (code: string) => {
		const validationError = validateCode(code);
		if (!code || validationError) {
			return "invalid_code";
		}
		const maskLength = code.length - 3;
		return `${code.charAt(0)}${"*".repeat(maskLength)}${code.slice(-2)}`;
	},
	email: (email: string) => {
		if (!email?.includes("@")) {
			return "invalid_email";
		}
		const [local, domain] = email.split("@");
		const [domainName, ext] = domain.split(".");

		const localMaskLength = local.length - 1;
		const domainMaskLength = domainName.length - 1;

		return `${local.charAt(0)}${"*".repeat(localMaskLength)}@${domainName.charAt(0)}${"*".repeat(domainMaskLength)}.${ext}`;
	},
};
