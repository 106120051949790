import { helpDialog } from "@features/session/constants";
import type { SessionItem } from "@memorang/types/src/session/common";
import { ConfirmDialog } from "components/ConfirmDialog";
import { useRouter } from "expo-router";
import type { Dispatch, SetStateAction } from "react";

type Props = {
	helpDialogVisible: boolean;
	sessionItems: SessionItem[];
	numericId: number;
	totalTime: number;
	examName?: string;
	setHelpDialogVisible: Dispatch<SetStateAction<boolean>>;
};

const SessionHelpDialog = ({
	helpDialogVisible,
	sessionItems,
	examName,
	totalTime,
	numericId,
	setHelpDialogVisible,
}: Props) => {
	const router = useRouter();

	if (helpDialogVisible) {
		const currentSessionItems = sessionItems[0].children;
		const numSections = sessionItems.length;
		const confirmDialogConfig = helpDialog({
			sessionItems: currentSessionItems as SessionItem[],
			numSections,
			examName: examName!,
			totalTime,
			isDiagnosticTest: false,
			numTotalQuestions: sessionItems.reduce(
				(acc, item) => acc + item.children.length,
				0,
			),
		});

		return (
			<ConfirmDialog
				visible={helpDialogVisible}
				title={confirmDialogConfig.title}
				icon={confirmDialogConfig.icon}
				body={confirmDialogConfig.body}
				confirmCallback={() => {
					setHelpDialogVisible(false);
					router.replace({
						pathname: "/(protected)/session/[id]",
						params: {
							id: numericId,
							index: "1",
						},
					});
				}}
				confirmCta="Got it"
			/>
		);
	}
	return null;
};

export default SessionHelpDialog;
