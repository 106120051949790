/* -----------------Globals--------------- */

/* -----------------UI--------------- */
import { Card, Chip, type ChipProps } from "react-native-paper";

/* -----------------Types--------------- */
import type { MixedItemTag } from "types/tag";

import { SessionConfigDialog } from "@features/session-config/components/SessionConfigDialog";
import { isWeb } from "@helpers/platform";
import { useDevice } from "@hooks/useDevice";
import Box from "@memorang/ui/src/components/Box";
import { MasteryListItem } from "@memorang/ui/src/components/MasteryListItem";
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
import { useRouter } from "expo-router";
import { Fragment, useState } from "react";
/* -----------------Components--------------- */
import { SectionList } from "react-native";

export type SectionTag = {
	title: string;
	tags: MixedItemTag[];
	cta?: string | null;
	action?: unknown;
	icon?: string;
	mode: ChipProps["mode"];
};
type Props = {
	sectionTags: SectionTag[];
	handleNavigateToIAP: () => void;
};

const TagsSectionList = (props: Props) => {
	const { sectionTags, handleNavigateToIAP } = props;
	const { isMobile } = useDevice();
	const [showSessionConfigDialog, setShowSessionConfigDialog] = useState(false);
	const [selectedTagId, setSelectedTagId] = useState<string | null>(null);

	const toggleShowSessionConfigDialog = () => {
		setShowSessionConfigDialog((prev) => !prev);
	};

	const router = useRouter();
	const DATA = sectionTags.map(
		({ action, cta, title, tags: data, icon, mode }) => ({
			title,
			cta,
			action,
			data,
			icon,
			mode,
		}),
	);

	const renderSectionHeader = ({
		section: { title, cta, action, icon, mode },
	}: {
		section: {
			title: string;
			cta?: string | null;
			action?: unknown;
			icon?: string;
			mode?: ChipProps["mode"];
		};
	}) => (
		<Box
			justifyContent="space-between"
			display="flex"
			flexDirection="row"
			alignItems="center"
			paddingHorizontal={isMobile ? 16 : 0}
		>
			<CustomListSubheaderContainer enableGutters={false}>
				{title}
			</CustomListSubheaderContainer>
			{cta ? (
				<Chip
					compact
					mode={mode}
					icon={icon}
					onPress={typeof action === "function" ? () => action() : undefined}
				>
					{cta}
				</Chip>
			) : null}
		</Box>
	);

	const handleClickListItem = (selectedTagId: string, locked?: boolean) => {
		if (locked) {
			handleNavigateToIAP();
		} else if (isWeb) {
			if (isMobile) {
				router.push({
					pathname: "/session/customize",
					params: {
						selectedTagIds: [selectedTagId],
					},
				});
			} else {
				setSelectedTagId(selectedTagId);
				toggleShowSessionConfigDialog();
			}
		} else {
			router.push({
				pathname: "/session/customize",
				params: {
					selectedTagIds: [selectedTagId],
				},
			});
		}
	};

	const renderItem = ({
		item: { title, locked, distribution, id, description },
	}: {
		item: MixedItemTag;
	}) => (
		<MasteryListItem
			title={title}
			description={description}
			locked={locked}
			distribution={distribution}
			onPress={() => handleClickListItem(id, locked)}
		/>
	);

	const keyExtractor = (item: MixedItemTag, index: number) =>
		`home-sectionlist-${item.id}-${index}`;
	// Parent scrollview causes error, so remove section list and use directly

	return isMobile ? (
		<SectionList
			sections={DATA}
			renderSectionHeader={renderSectionHeader}
			keyExtractor={keyExtractor}
			renderItem={renderItem}
		/>
	) : (
		<Box gap={16} paddingTop={16} paddingBottom={24}>
			{DATA.map((section) => (
				<Box gap={8} key={section.title}>
					{renderSectionHeader({ section })}
					<Card>
						{section.data.map((item) => (
							<Fragment key={item.id}>{renderItem({ item })}</Fragment>
						))}
					</Card>
				</Box>
			))}
			{showSessionConfigDialog ? (
				<SessionConfigDialog
					visible={showSessionConfigDialog}
					handleClose={toggleShowSessionConfigDialog}
					selectedTagIds={[selectedTagId!]}
				/>
			) : null}
		</Box>
	);
};

export default TagsSectionList;
