/* -----------------Globals--------------- */
import { useState } from "react";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MUIMenu from "@mui/material/Menu";
import MUIMenuItem from "@mui/material/MenuItem";
/* -----------------UI--------------- */
import { IconButton } from "react-native-paper";

/* -----------------Types--------------- */
import type { GetMessageActions } from "../types";

type Props = {
	messageActions: GetMessageActions;
	showEmail?: boolean;
	content?: string;
	runId?: string;
};

export const MessageMenuActions = ({
	messageActions,
	showEmail,
	content,
	runId,
}: Props) => {
	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorElement);

	const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElement(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorElement(null);
	};

	return (
		<>
			{/* @ts-expect-error this is only intended for web, where it does get the proper mouse event*/}
			<IconButton icon="dots-vertical" onPress={openMenu} />
			<MUIMenu
				anchorEl={anchorElement}
				open={open}
				onClose={closeMenu}
				MenuListProps={{
					"aria-labelledby": "message-action-menu",
				}}
			>
				{messageActions(showEmail, runId).map(
					({ icon, altText, handleOnPress }) => (
						<MUIMenuItem
							key={icon}
							onClick={() => {
								handleOnPress(content);
								closeMenu();
							}}
						>
							<ListItemIcon>
								<Icon
									name={icon as React.ComponentProps<typeof Icon>["name"]}
									color="inherit"
									size={20}
								/>
							</ListItemIcon>
							<ListItemText>{altText}</ListItemText>
						</MUIMenuItem>
					),
				)}
			</MUIMenu>
		</>
	);
};
