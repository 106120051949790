import { isScreenshotMode } from "@constants/common";
import { isWeb } from "@helpers/platform";
import { formatCount, isEmptyList } from "@memorang/helpers/src/common";
import type { BundleDetails, Plan } from "@memorang/types/src/bundle";
import { DateTime } from "luxon";
import type { Item, MixedItemTag, TagsByType } from "types/tag";
import type { CurrentView } from "./types";

export const customTitleMap: Record<string, string> = {
	"Major Area": "Knowledge Levels",
	"Diagnostic Level": "Reasoning",
};

const calculateTagDist = (items: Item[]) =>
	items.reduce(
		(a, { distribution }) => {
			a[distribution] = a[distribution] ? a[distribution] + 1 : 1;
			return a;
		},
		{
			low: 0,
			none: 0,
			high: 0,
			medium: 0,
		},
	);
const getItemsCountDescription = ({ items }: MixedItemTag) => {
	const questions = items.filter((item) => item.type === "question").length;
	// const flashcards = items.filter((item) => item.type === 'flashcard').length;
	const description = [
		...(questions
			? [`${questions} ${formatCount("question", questions)}`]
			: []),
		// ...(flashcards ? [`${flashcards} flashcards`] : []),
	].join(" • ");

	return description;
};
const filterTagsByType = (tagsByType: TagsByType, currentView: CurrentView) => {
	return Object.keys(tagsByType).reduce((a: TagsByType, c) => {
		const currentTags = tagsByType[c];
		const currentTagsWithDist = currentTags.filter(
			(item: MixedItemTag) =>
				item.displayType?.includes(currentView) && item.items.length > 0,
		);
		a[c] = currentTagsWithDist;
		return a;
	}, {});
};
const groupTagsByType = (
	tags: MixedItemTag[],
	currentView: CurrentView,
	freeTagIds: string[],
	hasAccess?: boolean,
	includeCount?: boolean,
) => {
	const tagsByType = tags.reduce((acc: TagsByType, tag) => {
		const tagType = customTitleMap[tag.type] || tag.type;
		if (!acc[tagType]) {
			acc[tagType] = [];
		}

		const dist = calculateTagDist(tag.items);

		const newTag = {
			...tag,
			locked: hasAccess ? false : !freeTagIds?.includes(tag.id),
			distribution: dist,
			description: getItemsCountDescription(tag),
			count: tag.items.length,
			score: (
				(dist.high / (dist.medium + dist.low + dist.none + dist.high)) *
				100
			).toFixed(0),
			...(includeCount
				? {
						count: tag.items.length,
					}
				: {}),
		};
		acc[tagType].push(newTag);
		return acc;
	}, {});
	const filteredTagsByType = filterTagsByType(tagsByType, currentView);

	return filteredTagsByType;
};

const hasBundleAccess = (bundleDetails: BundleDetails) => {
	if (bundleDetails) {
		const {
			metadata: {
				subscription: { status, endDate },
			},
			bundle: { plans },
		} = bundleDetails;
		const isSubExpired = endDate
			? DateTime.now() > DateTime.fromISO(endDate)
			: true;
		const hasTagAccess =
			(status === "ACTIVE" && !isSubExpired) || isEmptyList(plans);
		return isScreenshotMode ? true : hasTagAccess;
	}
	return false;
};

const filterPlans = (plans: Plan[]) => {
	return plans.filter(
		(plan) =>
			(isWeb && plan.isMobile === false) ||
			(!isWeb && plan.isMobile === true) ||
			plan.isMobile == null,
	);
};
export { groupTagsByType, hasBundleAccess, calculateTagDist, filterPlans };
