import fetchQueryWrapper from "@helpers/relay-fetch-query-wrapper";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";
import type { ExamDetails } from "../types";

type Response = {
	examList: ExamDetails[];
};
const query = graphql`
  query FetchExamsQuery($tenantId: ID, $appId: ID) {
    examList(tenantId: $tenantId, appId: $appId) {
      metadata {
        examDate
      }
      id
      exam {
        id
        name
        icon
        isActive
        numericId
        category {
          id
          name
          icon
        }
        bundles {
          metadata {
            subscription {
              status
              endDate
            }
            trial {
              status
              endDate
              isOpted
            }
          }
          bundle {
            id
            purchasable
            numericId
            name
            features
            isActive
            accessType
            isMobileOnly
            bundleType
            contentsItemsCount {
              numFacts
              numQuestions
            }
            icon {
              url
            }
            plans {
              id
              duration
              interval
              amount
              alias
              isMobile
            }
          }
        }
      }
    }
  }
`;

const fetchExams = async (
	tenantId: string,
	appId: string,
): Promise<ExamDetails[]> => {
	const variables = {
		tenantId,
		appId,
	};
	const { examList } = (await fetchQueryWrapper(query, variables)) as Response;
	return examList;
};

export { fetchExams };
