/* -----------------Globals--------------- */
import { useCallback, useEffect } from "react";

/* -----------------UI--------------- */
import { Stack, useFocusEffect, useLocalSearchParams } from "expo-router";

/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";
import { useRouter } from "expo-router";

/* -----------------Child components--------------- */
import EmailContainer from "@features/auth/screens/EmailContainer";

/* -----------------Constants--------------- */
import { pageViewTypes } from "@constants/tracking";

import { getAwsExportsJson } from "@configs/awsexports";
import OnboardingAppBar from "@features/auth/components/OnboardingAppBar";
import { buildType, currentAppVersion } from "@helpers/expo-extras";
import { replayManager } from "@helpers/lib/sentry";
import { createRelativeURL } from "@helpers/url";
import { useDevice } from "@hooks/useDevice";
import { useGlobalStore } from "@hooks/useGlobalStore";
/* -----------------Analytics--------------- */
import { trackCustomEvent, trackPageView } from "analytics";
import DocumentTitle from "components/DocumentTitle";

const LoginPage = () => {
	const router = useRouter();

	useFocusEffect(
		useCallback(() => {
			replayManager().startReplay();
		}, []),
	);

	const finalAwsExports = getAwsExportsJson();
	const appId = useAppStore((store) => store.app.id);
	const tenantId = useAppStore((store) => store.tenant.id);
	const companyName = useAppStore((store) => store.companyName);
	const supportEmail = useAppStore((store) => store.supportEmail);
	const { isMobile } = useDevice();

	const lastLoggedInEmail = useGlobalStore((state) => state.lastLoggedInEmail);
	const params = useLocalSearchParams<{
		redeemCode: string;
		email: string;
	}>();
	useEffect(() => {
		trackPageView({ type: pageViewTypes.login });
	}, []);

	const handleNavigation = (back?: boolean) => {
		if (back) {
			router.back();
		} else {
			const url = createRelativeURL("/verify", params);

			router.push(url);
		}
	};
	const title = "Continue with email";

	const emailFromUrl = params?.email
		? decodeURIComponent(params?.email).replace(/ /g, "+")
		: "";

	return (
		<>
			<Stack.Screen
				options={{
					headerShown: true,
					header: () => {
						return (
							<OnboardingAppBar
								showLogo={!isMobile}
								title={title}
								onBackAction={() => router.back()}
							/>
						);
					},
				}}
			/>
			<DocumentTitle title="Login" />
			<EmailContainer
				handleNavigation={handleNavigation}
				handleTracking={trackCustomEvent}
				companyName={companyName}
				lastLoggedInEmail={lastLoggedInEmail}
				tenantId={tenantId}
				supportEmail={supportEmail}
				awsExports={finalAwsExports}
				buildType={buildType}
				currentAppVersion={currentAppVersion!}
				appId={appId}
				emailFromUrl={emailFromUrl}
				handleNavigateToDashboard={() =>
					router.replace("/(protected)/(tabs)/home")
				}
			/>
		</>
	);
};

export default LoginPage;
