/* ======== Types ========= */
import type { Distribution } from "@memorang/types/src/content";
/* ======== Components ========= */
import ContentHero from "components/ContentHero";
import type { Stat } from "components/RowStats";
import { FlatList } from "react-native";
import TagsSectionList, {
	type SectionTag,
} from "../TagsSectionList/TagsSectionList";

// import useAnalytics from '@hooks/useAnalytics';
// import { events } from '@constants/tracking';
import { useDevice } from "@hooks/useDevice";
import Box from "@memorang/ui/src/components/Box";
import Container from "@memorang/ui/src/components/Container";
import type { QuickActionsChipType } from "components/QuickActionChips/types";

interface Props {
	distribution: Distribution;
	productTitle: string;
	productImage: string;
	stats?: Stat[];
	sectionTags: SectionTag[];
	handleNavigateToIAP: () => void;
	handleQuickActionPress: (action: QuickActionsChipType["type"]) => void;
}
const StudyPackView = ({
	distribution,
	productTitle,
	productImage,
	stats,
	sectionTags,
	handleNavigateToIAP,
	handleQuickActionPress,
}: Props) => {
	const { isMobile } = useDevice();

	// useAnalytics({
	//   eventName: events.productHomeViewed,
	//   productTitle,
	//   stats: JSON.stringify(stats),
	// });

	return (
		<Box flex={1}>
			<Container>
				<FlatList
					data={[]}
					keyExtractor={(item) => item}
					renderItem={() => <></>}
					ListHeaderComponent={
						isMobile ? (
							<ContentHero
								distribution={distribution}
								title={productTitle}
								stats={stats}
								image={productImage}
								handleQuickActionPress={handleQuickActionPress}
							/>
						) : null
					}
					ListFooterComponent={
						<TagsSectionList
							handleNavigateToIAP={handleNavigateToIAP}
							sectionTags={sectionTags}
						/>
					}
				/>
			</Container>
		</Box>
	);
};
export default StudyPackView;
