/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

/* -----------------UI--------------- */
import { Dialog, Portal } from "react-native-paper";

import { isWeb } from "@helpers/platform";
/* -----------------Helpers & Hooks--------------- */
import { trackCustomEvent } from "analytics";

import SnackBarCustom from "components/CustomSnackbar";
/* -----------------Child components--------------- */
import FeedbackForm from "../FeedbackForm";

/* -----------------Third party libraries--------------- */
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";

import { events } from "@constants/tracking";
/* -----------------Constants--------------- */
import { dialogTexts } from "../../constants";

type Props = {
	visible: boolean;
	handleDismiss: () => void;
	view: string;
	feedbackSubtitle?: string;
};
export const FeedbackDialog = ({
	visible,
	handleDismiss,
	view,
	feedbackSubtitle,
}: Props) => {
	const [showSnackbar, setShowSnackBar] = useState(false);
	const config = dialogTexts.feedback;

	useEffect(() => {
		if (visible) {
			trackCustomEvent({
				eventName: events.dialogOpened,
				type: "feedback",
			});
		}
	}, [visible]);

	const handleDismissFeedback = () => {
		setShowSnackBar(true);
		handleDismiss();
		trackCustomEvent({
			eventName: events.dialogClosed,
			type: "feedback",
		});
	};

	return (
		<>
			<DialogWrapper
				visible={visible}
				handleClose={handleDismiss}
				maxWidth="xs"
			>
				<Dialog.Icon icon="comment-quote-outline" />
				<Dialog.Title
					style={{
						textAlign: "center",
					}}
				>
					{config.title}
				</Dialog.Title>
				<Dialog.Content>
					<FeedbackForm
						view={view || "profile"}
						handleDismiss={handleDismissFeedback}
						feedbackSubtitle={feedbackSubtitle}
					/>
				</Dialog.Content>
			</DialogWrapper>
			<SnackbarHOC>
				<SnackBarCustom
					message={"Thank you for your feedback!"}
					visible={showSnackbar}
					duration={2000}
					handleDismiss={() => {
						setShowSnackBar(false);
					}}
				/>
			</SnackbarHOC>
		</>
	);
};

const SnackbarHOC = ({ children }: { children: React.ReactNode }) => {
	if (isWeb) {
		return <Portal>{children}</Portal>;
	}
	return <>{children}</>;
};
