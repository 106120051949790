/* ----------------- React --------------- */
import { useState } from "react";

/* ----------------- Third parties --------------- */
import { useRouter } from "expo-router";
import { HelperText, Portal, TextInput } from "react-native-paper";

/* ----------------- Auth --------------- */
import { useAuthContext } from "@features/auth/contexts/AuthContext";

/* ----------------- Helpers --------------- */
import { signOut } from "@memorang/helpers/src/amplify/amplifyAuth";

/* ----------------- Contexts --------------- */
import { useAppStore } from "@hooks/useAppStore";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* ----------------- Constants --------------- */
import { events } from "@constants/tracking";

/* ----------------- Analytics --------------- */
import { trackCustomEvent } from "analytics";

/* ----------------- UI --------------- */
import Box from "@memorang/ui/src/components/Box";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";

/* ----------------- Components --------------- */
import DeleteFormActions from "./DeleteFormActions";

import { zodResolver } from "@hookform/resolvers/zod";
import SnackBarCustom from "components/CustomSnackbar";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
/* ----------------- Mutations --------------- */
import deleteUser from "../mutations/DeleteUser";

type Props = {
	handleSendFeedback: () => void;
	handleDismiss: () => void;
};

const emailSchema = z.object({
	email: z.string().email("Please enter a valid email address"),
});
const DeleteForm = ({ handleSendFeedback, handleDismiss }: Props) => {
	const { userDetails, viewerId: userId, refreshAuth } = useAuthContext();

	const [showSnackbar, setShowSnackBar] = useState(false);

	const router = useRouter();

	const tenantId = useAppStore((store) => store.tenant.id);

	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: zodResolver(emailSchema),
		mode: "onChange",
	});

	const email = watch("email");

	const userName = userDetails?.name!;
	const existingUserEmail = userDetails?.email!;
	const [showError, setShowError] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleLogout = async () => {
		await signOut();
		await refreshAuth();
		handleDismiss();
		router.replace("/");
	};

	const handleDelete = async () => {
		setLoading(true);
		const finalEmail = email.trim().toLowerCase();
		if (finalEmail !== existingUserEmail) {
			setShowError(true);
			setLoading(false);
			return;
		}
		if (email) {
			try {
				await deleteUser(email, tenantId);
				setShowSnackBar(true);
				handleSendFeedback();
				trackCustomEvent({
					eventName: events.userDeleted,
					userId,
					email,
					userName,
				});
				await handleLogout();
			} catch (_err) {
				setShowError(true);
				setValue("email", "");
			} finally {
				setLoading(false);
			}
		}
	};

	const handleFormAction = (shouldDelete?: boolean) => {
		if (shouldDelete) {
			handleDelete();
		} else {
			handleDismiss();
		}
	};

	const theme = useAppTheme();

	const errorMessage =
		"Sorry, that email doesn't match this account. Please try again";
	return (
		<>
			<Controller
				control={control}
				render={({ field: { onChange, onBlur, value } }) => (
					<Box>
						<TextInput
							mode="outlined"
							placeholder="e.g., dave@gmail.com"
							onChangeText={(text) => {
								onChange(text);
								setShowError(false);
							}}
							onBlur={onBlur}
							value={value}
							autoFocus
							label="Email"
							autoCapitalize="none"
							onSubmitEditing={handleSubmit(() => handleFormAction(true))}
							keyboardType="email-address"
							disabled={loading}
							style={{ width: "100%" }}
						/>
						{errors.email && typeof errors.email.message === "string" && (
							<HelperText type="error">{errors.email.message}</HelperText>
						)}
						{showError && !errors.email && (
							<MarkdownRenderer
								text={errorMessage}
								customStyles={{
									text: {
										fontSize: 12,
										color: theme.colors.error,
									},
								}}
							/>
						)}
						<DeleteFormActions
							handleAction={handleFormAction}
							primaryActionLabel="Delete"
							disablePrimaryButton={!email || loading}
							loadingPrimaryButton={loading}
						/>
					</Box>
				)}
				name="email"
				defaultValue=""
			/>

			<Portal>
				<SnackBarCustom
					message={"Account deleted successfully!"}
					visible={showSnackbar}
					duration={2000}
					handleDismiss={() => {
						setShowSnackBar(false);
					}}
				/>
			</Portal>
		</>
	);
};

export default DeleteForm;
