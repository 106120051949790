import { fetchData } from "@memorang/helpers/src/fetchData";
/* -----------------Globals--------------- */
import { graphql } from "react-relay";
import relayEnv from "../relayenv";

const getIsOlxExam = (id: string) => id.includes("OLX");

type EXAM_STATUS =
	| "ACTIVE"
	| "COMPLETE"
	| "NOT_STARTED"
	| "LEGACYREPORT"
	| "MIGRATEDREPORT";
type Response = {
	getMixedItemDistribution: {
		exams: {
			id: string;
			status: EXAM_STATUS;
			title: string;
			hasAccess: boolean;
			numericId: number;
		}[];
	};
};
const query = graphql`
  query FetchExamsCountQuery(
    $bundleId: ID!
    $dashboardAction: DashboardAction
  ) {
    getMixedItemDistribution(
      bundleId: $bundleId
      dashboardAction: $dashboardAction
    ) {
      exams {
        id
        status
        title
        hasAccess
				numericId
      }
    }
  }
`;

const fetchExamsCount = async (bundleId: string) => {
	const variables = {
		bundleId,
		dashboardAction: "GETEXAMS",
	};

	const {
		getMixedItemDistribution: { exams },
	} = (await fetchData(relayEnv, query, variables)) as Response;

	const practiceTests = exams?.filter((exam) => {
		return getIsOlxExam(exam.id) ? exam.hasAccess : exam.title.includes("Test");
	});

	const quizzes = exams
		?.filter((exam) => !exam.title.includes("Test"))
		?.slice(0, 2);

	return {
		numPracticeTests: practiceTests?.length,
		numQuiz: quizzes?.length,
		exams,
	};
};

export default fetchExamsCount;
