import { ENDPOINT_EXAM_CONTEXT } from "@constants/urls";
import { isAuthenticated } from "@memorang/helpers/src/amplify/amplifyAuth";
import type { SessionChildItem } from "@memorang/types/src/session/common";
import type { CurrentExamContext } from "../contexts/ExamContext";
import type { ExamDetails } from "../types";

const groupExamsByCategory = (
	examList: ExamDetails[],
): {
	title: string;
	icon: string;
	data: ExamDetails[];
}[] => {
	const groupedData = examList.reduce<Record<string, ExamDetails[]>>(
		(acc, cur) => {
			const category = cur.exam.category[0].name;

			if (!acc[category]) {
				acc[category] = [];
			}

			const filteredCur = {
				...cur,
				exam: {
					...cur.exam,
					bundles: cur.exam.bundles
						.slice()
						.sort((a, b) => a.bundle.numericId - b.bundle.numericId),
				},
			};

			acc[category].push(filteredCur);

			return acc;
		},
		{},
	);

	const output = Object.keys(groupedData).map((category) => ({
		title: category,
		icon: groupedData[category][0].exam.category[0].icon,
		data: groupedData[category],
	}));

	return output;
};

const constructClientExamContext = ({
	exam: { id, name, bundles, icon },
	metadata: { examDate },
}: ExamDetails) => {
	const studyPack = bundles.find(
		(item) => item.bundle.bundleType === "STUDY_PACK",
	)?.bundle;
	const practiceTest = bundles.find(
		(item) => item.bundle.bundleType === "PRACTICE_TEST",
	)?.bundle;

	return {
		examName: name,
		examId: id,
		studyPackProductNumericId: studyPack?.numericId!,
		practiceTestProductId: practiceTest?.id,
		practiceTestProductNumericId: practiceTest?.numericId,
		studyPackProductId: studyPack?.id!,
		examDate: examDate,
		examIcon: icon,
	};
};

const getNumPercenetageAnswered = (
	items: SessionChildItem[],
	currentChoiceNumFirstSelections: number,
	answered?: boolean,
) => {
	const isSomeItemWithMoreSelections = items.some(
		({ numFirstSelections }) => numFirstSelections > 10,
	);
	const showPercentAnswered = isSomeItemWithMoreSelections && answered;

	const totalfirstSelections = items.reduce(
		(a, b) => a + (b.numFirstSelections || 0),
		0,
	);

	const average = totalfirstSelections
		? (currentChoiceNumFirstSelections || 0) / totalfirstSelections
		: 0;

	const percentAnswered =
		totalfirstSelections && showPercentAnswered
			? Number.parseFloat((average * 100).toFixed(0))
			: undefined;
	return percentAnswered;
};

const storeExamContextInKV = async (
	examContext: CurrentExamContext,
	userId: string,
) => {
	try {
		await fetch(ENDPOINT_EXAM_CONTEXT, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				examContext,
				userId,
			}),
		});
	} catch (error) {
		console.error("Error:", error);
	}
};

const retrieveExamContextFromKV = async (userId: string) => {
	try {
		const response = await fetch(`${ENDPOINT_EXAM_CONTEXT}?userId=${userId}`);
		if (response.status === 200) {
			const data = await response.json();
			return data as CurrentExamContext;
		}
		return null;
	} catch (error) {
		console.error("Error:", error);
	}
};

const getUserId = async () => {
	const result = (await isAuthenticated()) as {
		attributes: {
			"custom:logicalId": string;
		};
	};
	const viewerId = result ? result.attributes["custom:logicalId"] : "";
	// console.info("getUserId", viewerId);
	return viewerId;
};
export {
	groupExamsByCategory,
	constructClientExamContext,
	getNumPercenetageAnswered,
	storeExamContextInKV,
	retrieveExamContextFromKV,
	getUserId,
};
