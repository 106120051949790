/* -----------------Globals--------------- */
import { useCallback } from "react";
import { Platform } from "react-native";

import { currentAppVersion } from "@helpers/expo-extras";
/* -----------------Helpers & Hooks--------------- */
import { trackCustomEvent } from "analytics";
import useSessionStore from "./useSessionStore";

/* -----------------Types--------------- */

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
/* -----------------Contexts--------------- */
import { useAppStore } from "@hooks/useAppStore";
import type { FeedbackParams } from "@memorang/features/src/feedback/types";

export const useQASearch = () => {
	const { viewerId, userDetails } = useAuthContext();
	const appName = useAppStore((store) => store.app.name);
	const tenantName = useAppStore((store) => store.tenant.name);
	const sessionId = useSessionStore((state) => state.id);
	const inReviewMode = useSessionStore((state) => state.inReviewMode);
	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);

	const {
		currentExamContext: {
			studyPackProductId,
			practiceTestProductId,
			examName,
			examId,
		},
	} = useExamContext();

	const handleTracking = useCallback(
		(eventName: string, data?: Record<string, string>) => {
			const viewForFormative = inReviewMode
				? "review_session_formative"
				: "session_formative";
			trackCustomEvent({
				eventName,
				...(data || {}),
				view: isSummativeTest ? "review_session_summative" : viewForFormative,
			});
		},
		[inReviewMode, isSummativeTest],
	);

	const type = practiceTestProductId ? "Practice Test" : "Study-Pack";
	const contextId = practiceTestProductId ?? studyPackProductId;

	const feedbackDialogParams: FeedbackParams = {
		userName: userDetails?.name!,
		email: userDetails?.email,
		tenantId: tenantName,
		appName,
		sessionId,
		platform: Platform.OS.toUpperCase(),
		currentAppVersion,
		view: isSummativeTest
			? "Summative Review Session"
			: "Formative Review Session",
		context: examName,
		product: `${examName} ${type}`,
		feature: "AI QA",
	};

	return {
		viewerId,
		examId,
		contextId,
		handleTracking,
		feedbackDialogParams,
		examName,
	};
};
