import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { type ActionId, type ActionImpl, KBarResults, useMatches } from "kbar";
import { Fragment, forwardRef, useMemo } from "react";

const ResultItem = forwardRef(
	(
		{
			action,
			active,
			currentRootActionId,
		}: {
			action: ActionImpl;
			active: boolean;
			currentRootActionId: ActionId;
		},
		ref: React.Ref<HTMLLIElement>,
	) => {
		const ancestors = useMemo(() => {
			if (!currentRootActionId) {
				return action.ancestors;
			}
			const index = action.ancestors.findIndex(
				(ancestor) => ancestor.id === currentRootActionId,
			);
			// +1 removes the currentRootAction; e.g.
			// if we are on the "Set theme" parent action,
			// the UI should not display "Set theme… > Dark"
			// but rather just "Dark"
			return action.ancestors.slice(index + 1);
		}, [action.ancestors, currentRootActionId]);

		const text = useMemo(
			() => (
				<Stack component="span" alignItems="center" flexDirection="row">
					{ancestors.map((ancestor) => (
						<Fragment key={ancestor.id}>
							<Typography mr={2} color="GrayText">
								{ancestor.name}
							</Typography>
							<Typography mr={2}>&rsaquo;</Typography>
						</Fragment>
					))}
					{action.name}
				</Stack>
			),
			[action.name, ancestors],
		);
		return (
			<ListItem
				sx={{
					p: 0,
				}}
				ref={ref}
				secondaryAction={action.shortcut?.map((sc) => (
					<Box
						component="kbd"
						key={sc}
						sx={(theme) => ({
							py: 0.25,
							px: 1,
							m: 0.5,
							background: theme.palette.action.selected,
							borderRadius: 1,
							fontSize: 14,
						})}
					>
						{sc}
					</Box>
				))}
			>
				<ListItemButton selected={active}>
					<ListItemAvatar style={{ minWidth: 40 }}>
						{typeof action.icon === "string" ? (
							<Avatar
								sx={{ width: 30, height: 30 }}
								variant="rounded"
								src={action.icon}
								alt={action.name}
							/>
						) : (
							<Avatar variant="rounded" sx={{ width: 30, height: 30 }}>
								{action.icon}
							</Avatar>
						)}
					</ListItemAvatar>
					<ListItemText secondary={action.subtitle}>{text}</ListItemText>
				</ListItemButton>
			</ListItem>
		);
	},
);

export default function Results() {
	const { results, rootActionId } = useMatches();

	return (
		<List disablePadding>
			<KBarResults
				items={results}
				onRender={({ item, active }) => {
					return typeof item === "string" ? (
						<ListSubheader sx={{ fontSize: 12, lineHeight: 3 }} component="div">
							{item}
						</ListSubheader>
					) : (
						<ResultItem
							action={item}
							active={active}
							currentRootActionId={rootActionId || ""}
						/>
					);
				}}
			/>
		</List>
	);
}
