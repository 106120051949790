import {
	COPILOT_CHAT_SUGGESTIONS_INSTRUCTIONS,
	COPILOT_INSTRUCTIONS,
	COPILOT_URL,
	RedeemCopilotChat as Chat,
	getAssistantTitle,
	warningMessage,
} from "@features/redeem-copilot";
import { useAppStore } from "@hooks/useAppStore";
import WarningBanner from "@memorang/ui/src/components/WarningBanner";
import RedeemCodeRouter from "../RedeemCodeRouter";

const RedeemCodeCopilotWrapper = () => {
	const tenantReadableName = useAppStore((store) => store.tenant.name);

	const assistantTitle = getAssistantTitle(tenantReadableName);

	return (
		<Chat runtimeUrl={COPILOT_URL}>
			<Chat.Popup instructions={COPILOT_INSTRUCTIONS} title={assistantTitle}>
				<WarningBanner message={warningMessage} iconSize={28} />
				<Chat.Messages
					suggestionsInstructions={COPILOT_CHAT_SUGGESTIONS_INSTRUCTIONS}
				/>
				<Chat.Textarea />
			</Chat.Popup>

			<RedeemCodeRouter />
		</Chat>
	);
};

export default RedeemCodeCopilotWrapper;
