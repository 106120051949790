/* -----------------Globals--------------- */
import { useState } from "react";

/* -----------------UI--------------- */
import { ImageBackground, type ImageStyle } from "expo-image";
import { Platform, StyleSheet, TouchableOpacity } from "react-native";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "../../useAppTheme";

/* -----------------Child components--------------- */
import { Text } from "react-native-paper";
import Box from "../Box";
import ImageViewerModal from "../ImageViewerModal";

type Props = {
	url: string;
	name?: string;
	allImages: { url: string; caption?: string }[];
	borderRadius?: number;
	initialIndex?: number;
} & (ImageStyle | undefined);

const Overlay = () => {
	const theme = useAppTheme();
	return (
		<Box
			backgroundColor={theme.colors.backdrop}
			borderRadius={10}
			style={{
				...StyleSheet.absoluteFillObject,
			}}
		/>
	);
};

export const ZoomImage = ({
	url,
	borderRadius,
	name,
	allImages,
	initialIndex,
	...imageStyle
}: Props) => {
	const [showFullScreenImage, setShowFullScreenImage] = useState(false);

	const theme = useAppTheme();
	const toggleFullScreenImage = () => {
		setShowFullScreenImage((prev) => !prev);
	};
	return (
		<TouchableOpacity
			onLongPress={toggleFullScreenImage}
			onPress={toggleFullScreenImage}
		>
			{url ? (
				<ImageBackground
					//@ts-expect-error - only cursor pointer and auto is supported in types
					style={{
						height: 200,
						width: 200,
						marginTop: 8,
						position: "relative",
						justifyContent: "flex-end",
						alignItems: "center",
						...imageStyle, // Custom styling
						...(Platform.OS === "web"
							? {
									cursor: "zoom-in",
								}
							: {}),
					}}
					imageStyle={{
						borderRadius: borderRadius || theme.roundness,
					}}
					contentFit="cover"
					contentPosition="center"
					cachePolicy="memory-disk"
					source={{ uri: `${url}` }}
				>
					<Overlay />
					{name && (
						<Text
							variant="labelMedium"
							style={{
								//always show name in white color
								color: "#fff",
								fontFamily: "OpenSans-SemiBold",
								paddingBottom: 8,
							}}
						>
							{name}
						</Text>
					)}
				</ImageBackground>
			) : null}
			<ImageViewerModal
				visible={showFullScreenImage}
				images={allImages}
				initialIndex={initialIndex}
				handleClose={() => setShowFullScreenImage(false)}
			/>
		</TouchableOpacity>
	);
};
