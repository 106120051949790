import MasonryGrid from "components/MasonryGrid";

interface Props {
	cards: React.ReactElement[];
}
const ProgressCardsContainer = ({ cards }: Props) => {
	return <MasonryGrid>{cards}</MasonryGrid>;
};

export default ProgressCardsContainer;
