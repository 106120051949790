import { useAuthContext } from "@features/auth/contexts/AuthContext";
import QAChatWrapper from "@features/session/components/QASearch/QAChatWrapper";
import { isWeb } from "@helpers/platform";
import Box from "@memorang/ui/src/components/Box";
import ModalHeader from "components/ModalHeader";
import { Redirect, Stack, usePathname } from "expo-router";

export default function Layout() {
	const { isAuthenticated, loading } = useAuthContext();

	const pathname = usePathname();

	if (!(isAuthenticated || loading) && pathname !== "/redeem") {
		return <Redirect href={"/(auth)/login"} />;
	}
	return (
		<Box flex={1} flexDirection="row" justifyContent="space-between">
			<Stack
				screenOptions={{
					headerShown: false,
					contentStyle: {
						flex: 1,
					},
				}}
			>
				<Stack.Screen
					name="purchase/index"
					options={{
						presentation: "modal",
						headerShown: true,
						header: () => <ModalHeader />,
					}}
				/>
				<Stack.Screen
					name="session/customize"
					options={{
						presentation: "modal",
						headerShown: true,
						header: () => (
							<ModalHeader
								backIcon="arrow-left"
								headerTitle="Custom Study Session"
							/>
						),
					}}
				/>
				<Stack.Screen
					name="suggestcorrection"
					options={{
						presentation: "modal",
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="profile/change-avatar"
					options={{
						presentation: "modal",
						headerShown: true,
						header: () => (
							<ModalHeader
								backIcon="arrow-left"
								headerTitle="Choose your avatar"
							/>
						),
					}}
				/>
				<Stack.Screen
					name="feedback"
					options={{
						presentation: "modal",
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="exams"
					options={{
						presentation: "modal",
						headerShown: false,
					}}
				/>
				<Stack.Screen
					name="aila/[itemId]"
					options={{
						presentation: "modal",
						headerShown: false,
					}}
				/>
			</Stack>
			{isWeb && <QAChatWrapper />}
		</Box>
	);
}
