import { PurchaseDialog } from "@features/dashboard/components/PurchaseDialog";
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";
import { useState } from "react";
/* -----------------UI--------------- */
import { IconButton, Dialog as PaperDialog } from "react-native-paper";
import SessionConfigContainer from "../containers/SessionConfigContainer";

type Props = {
	visible: boolean;
	handleClose?: () => void;
	selectedTagIds?: string[];
};
export const SessionConfigDialog = ({
	visible,
	handleClose,
	selectedTagIds,
}: Props) => {
	const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);

	const toggleShowPurchaseDialog = () => {
		setShowPurchaseDialog((prev) => !prev);
	};

	if (showPurchaseDialog) {
		return (
			<PurchaseDialog
				visible={showPurchaseDialog}
				handleClose={() => handleClose?.()}
				productType="STUDY_PACK"
			/>
		);
	}
	return (
		<DialogWrapper visible={visible} handleClose={handleClose}>
			<PaperDialog.Title
				style={{
					textAlign: "center",
				}}
			>
				Custom Study Session
			</PaperDialog.Title>
			<IconButton
				icon={"close"}
				onPress={handleClose}
				style={{
					position: "absolute",
					right: 8,
					top: 6,
				}}
			/>
			<PaperDialog.Content
				style={{
					flex: 1,
					paddingBottom: 0,
					paddingHorizontal: 16,
					minHeight: 440,
				}}
			>
				<SessionConfigContainer
					propSelectedTagIds={selectedTagIds}
					toggleShowPurchaseDialog={toggleShowPurchaseDialog}
					handleClose={handleClose}
				/>
			</PaperDialog.Content>
		</DialogWrapper>
	);
};
