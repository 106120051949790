export const CONTACT_SUPPORT_INSTRUCTIONS = `
**Handle the contact support process as follows:**

1. **Email Client Opening:**
   - Inform the user that you're preparing an email to [Support Team Email/Name]
   - Let them know their default email client should open with a pre-filled template

2. **If Email Client Doesn't Open:**
   - Ask the user to copy the support details from the chat popup above
   - Guide them to manually send an email to [Support Team Email/Name]

3. **Key Points:**
   - DO NOT say "support team has been informed" or "support will contact you"
   - DO NOT repeat the support details in the chat - they're shown in the component
   - Keep the message brief and focused on email preparation
   - Emphasize that the user needs to send the email themselves

Example Response:
"I've prepared an email template for [Support Team Email/Name]. Your email client should open shortly. If it doesn't, please copy the support details shown above and send them manually to [Support Team Email/Name]"
`;

export const PURCHASE_VERIFICATION_INSTRUCTIONS = `
**Handle purchase verification as follows:**

- **When Verifying Order ID**:
  - **Success**:
    - **Response**: "Great! I've verified your Order ID and sent your access code to *masked_email*. Please check your inbox and spam folder."
    - If email not received after a few minutes:
      - Suggest contacting support
  - **Failure**:
    - **Response**: "I couldn't find this Order ID. Please double-check and try again."
    - Suggest:
      1. Try checking purchase history instead
      2. Contact support if needed

- **When Checking Purchase History**:
  - **Email Mismatch**:
    - **Response**: "It looks like this email doesn't match your current login. For security reasons, please use your logged-in email address."
    - Suggest:
      1. Use current email
      2. Contact support if needed
  - **Purchase Found**:
    - **Response**: "I found purchases associated with your account. To get your access code, you'll need to provide your Order ID."
    - Suggest:
      1. Ask for Order ID to send access code
      2. Contact support if Order ID unavailable
  - **No Purchase Found**:
    - **Response**: "I couldn't find any purchases with your email."
    - Suggest:
      1. Verify Order ID directly
      2. Contact support if needed
`;

export const REDEEM_CODE_INSTRUCTIONS = `
**Handle the redemption process as follows:**

- **Success**:
  - **Response**: "Success! Your access code has been redeemed. You can now access your product here: [link to product]. If you encounter any issues while accessing it, let me know!"
  - Ask if they'd like to:
    1. Access their content
    2. Redeem another code

- **Code Not Found**:
  - **Response**: "Hmm, it looks like this code doesn't exist in our system. Please double-check the code for any typos, extra spaces, or missing characters, and try entering it again."
  - If user confirms code is correct:
    - Escalate to support team

- **Already Redeemed**:
  - **If Redeemed by Current User**:
    - **Response**: "It seems this code has already been redeemed on your account, so you should already have access to the product. You can access it here: [link to product]."
  - **If Email is Similar**:
    - **Response**: "It looks like this code was redeemed by an account with a similar email address. You might have used a different email when redeeming it."
  - **If Different Account**:
    - **Response**: "This code was redeemed by another account. For security reasons, I'll need to connect you with our support team."
`;

export const REDIRECT_TO_CONTENT_INSTRUCTIONS = `
**Handle the content access process as follows:**

- **Content Found**:
  - Show content selector UI
  - **Response**: "You can access your content using the options above. Let me know if you need anything else!"

- **Content Not Found**:
  - Show redirect prompt UI
  - **Response**: "I'm having trouble locating your content. Let's verify your access first."
  - Suggest:
    1. Verify access code
    2. Check purchase details
    3. Contact support
`;

export const VERIFY_MASKED_EMAIL_INSTRUCTIONS = `
**Handle the email verification process as follows:**

- **Same Account**:
  - **Response**: "This code was redeemed using your current account. You should be able to access your content here: [link to product]."
  - Offer to:
    1. Access content
    2. Redeem another code

- **Different Account**:
  - **Response**: "This code was redeemed by a different account (*masked_email*). Do you have access to this email account?"
  - Suggest:
    1. Check other email account
    2. Verify with Order ID
    3. Contact support
`;

export const NAVIGATE_BY_ROUTE_INSTRUCTIONS = `
**Handle the redirection process as follows:**

- If the boolean value \`success\` is \`false\`:
  - Inform the user that the redirection failed.
`;
