/* ----------------- React --------------- */
import { useState } from "react";

import DateTimePicker from "components/DateTimePicker";
/* ----------------- Child components --------------- */
import AccountView from "../components/AccountView";
import DeleteConfirmationDialog from "../components/dialogs/DeleteConfirmationDialog";
import DeleteFeedbackDialog from "../components/dialogs/DeleteFeedbackDialog";
import { SignoutDialog } from "../components/dialogs/SignOutDialog";

import { useThemeContext } from "@contexts/ThemeContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { useDevice } from "@hooks/useDevice";
/* ----------------- Hooks --------------- */
import useAccountSettings from "../hooks/useAccountSettings";
import { useDelete } from "../hooks/useDelete";

import { isWeb } from "@helpers/platform";
/* ----------------- UI Libraries --------------- */
import Box from "@memorang/ui/src/components/Box";
import ScrollContainer from "@memorang/ui/src/components/ScrollContainer";
import { Card } from "react-native-paper";

const AccountSettings = () => {
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [showSignOutDialog, setShowSignOutDialog] = useState(false);

	const {
		toggleShowDeleteConfirmationDialog,
		toggleShowDeleteFeedbackDialog,
		showDeleteConfirmationDialog,
		showDeleteFeedbackDialog,
		selectedReasons,
		feedback,
		handleSetFeedback,
		handleSendFeedback,
		checkboxStates,
		handleChangeCheckboxState,
	} = useDelete();

	const { toggleDarkMode } = useThemeContext();
	const toggleShowSignOutDialog = () => {
		setShowSignOutDialog((prev) => !prev);
	};
	const { currentExamContext, updateExamDate } = useExamContext();
	const toggleShowDatePicker = () => {
		setShowDatePicker((prev) => !prev);
	};

	const { getAccountSections } = useAccountSettings({
		toggleShowDatePicker,
		toggleShowSignOutDialog,
		toggleDarkMode,
		toggleShowDeleteFeedbackDialog,
	});

	const sections = getAccountSections();
	const { isMobile } = useDevice();

	const Wrapper = isMobile ? Box : Card;
	return (
		<ScrollContainer maxWidth="sm">
			<Wrapper
				style={{
					marginTop: 16,
				}}
			>
				<AccountView sections={sections} />
			</Wrapper>
			{showDatePicker ? (
				<DateTimePicker
					isVisible
					onCancel={toggleShowDatePicker}
					initialDate={currentExamContext?.examDate}
					onConfirm={(date) => {
						if (!isWeb) {
							toggleShowDatePicker();
						}
						updateExamDate(date);
					}}
				/>
			) : null}
			{showSignOutDialog && (
				<SignoutDialog
					visible={showSignOutDialog}
					handleClose={toggleShowSignOutDialog}
				/>
			)}
			{showDeleteFeedbackDialog && (
				<DeleteFeedbackDialog
					visible={showDeleteFeedbackDialog}
					handleDismiss={toggleShowDeleteFeedbackDialog}
					handleShowDeleteConfirmation={toggleShowDeleteConfirmationDialog}
					feedback={feedback}
					handleSetFeedback={handleSetFeedback}
					handleSelectOption={handleChangeCheckboxState}
					checkboxStates={checkboxStates}
					selectedReasons={selectedReasons}
				/>
			)}
			{showDeleteConfirmationDialog && (
				<DeleteConfirmationDialog
					visible={showDeleteConfirmationDialog}
					handleDismiss={toggleShowDeleteConfirmationDialog}
					handleSendFeedback={handleSendFeedback}
				/>
			)}
		</ScrollContainer>
	);
};

export default AccountSettings;
