/* -----------------Globals--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------UI--------------- */
import { Text, TextInput } from "react-native-paper";

/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";

import { useAppStore } from "@hooks/useAppStore";
/* -----------------Helpers & Hooks--------------- */
import { suggestCorrectionDialogMetadata } from "./dialogs/constants";

export const SuggestCorrectionForm = ({
	source,
	correction,
	setSource,
	setCorrection,
}: {
	source: string;
	correction: string;
	setSource: (source: string) => void;
	setCorrection: (correction: string) => void;
}) => {
	const theme = useAppTheme();
	const sessionView = useAppStore((store) => store.sessionView);

	const suggestCorrectionDescription =
		sessionView?.suggestCorrection?.description;
	const { description, maxCorrectionLength, maxSourceLength } =
		suggestCorrectionDialogMetadata;

	const finalDescription = suggestCorrectionDescription || description;
	return (
		<Box
			style={{
				gap: 16,
			}}
		>
			<Text variant="bodyMedium">{finalDescription}</Text>
			<TextInput
				mode="outlined"
				autoFocus
				style={{
					backgroundColor: theme.colors.surface,
				}}
				autoCorrect={false}
				placeholder={"Trusted, verifable source required"}
				onChangeText={(value) => setSource(value)}
				value={source}
				multiline
				numberOfLines={6}
				maxLength={maxSourceLength}
			/>
			<TextInput
				mode="outlined"
				style={{
					backgroundColor: theme.colors.surface,
				}}
				autoCorrect={false}
				placeholder={"Suggested Correction"}
				defaultValue={correction}
				onChangeText={(value) => setCorrection(value)}
				value={correction}
				multiline
				numberOfLines={6}
				maxLength={maxCorrectionLength}
			/>
		</Box>
	);
};
