// @flow

/* -----------------Globals--------------- */
import { commitMutation, graphql } from "react-relay";
import relayEnv from "relay/relayenv";
import type {
	ExternalAuthInput,
	ExternalAuthResponse,
	ExternalAuthResponseData,
} from "./types";

const mutation = graphql`
      mutation handleExternalAuthMutation($authInput: ExternalAuthInput!) {
        handleExternalAuth(authInput: $authInput) {
          status
          message
          token {
            accessToken
            idToken
            refreshToken
          }
          data{
              firstName
              lastName
              userId
              userName
              email
          }

        }
      }
    `;

const handleExternalAuth = async (
	authInput: ExternalAuthInput,
): Promise<ExternalAuthResponseData> => {
	const promise = new Promise((resolve, reject) => {
		const variables = {
			authInput,
		};
		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const {
					handleExternalAuth: externalAuthResponse,
					handleExternalAuth: { status, data },
				} = response as ExternalAuthResponse;

				if (data && status === 200) {
					resolve(externalAuthResponse);
				}
				reject(externalAuthResponse);
			},
			onError: (error) => {
				reject(error);
			},
		});
	});
	return promise as Promise<ExternalAuthResponseData>;
};

export {
	// eslint-disable-next-line import/prefer-default-export
	handleExternalAuth,
};
