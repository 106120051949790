/* -----------------Types--------------- */
import type { SessionItem } from "@memorang/types/src/session/common";

/* -----------------Container--------------- */
import StudySessionItemContainer from "../../containers/StudySessionItemContainer";

/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import { useLocalSearchParams } from "expo-router";
import { sessionMaxWidth } from "../../constants";
import useSessionStore from "../../hooks/useSessionStore";

interface Props {
	items: SessionItem[];
}

const SessionListDesktop = ({ items }: Props) => {
	const localSearchParams = useLocalSearchParams<{
		index: string;
		sessionId: string;
	}>();
	const sessionId = localSearchParams.sessionId;

	const isSummativeTest = useSessionStore((state) => state.isSummativeTest);
	const index = localSearchParams.index
		? Number.parseInt(localSearchParams.index)
		: 0;
	const currentItem = index ? items[index - 1] : items[0];
	return (
		<Box
			style={{ width: "100%" }}
			maxWidth={
				isSummativeTest ? sessionMaxWidth.summative : sessionMaxWidth.formative
			}
			paddingHorizontal={isSummativeTest ? 16 : 0}
		>
			<StudySessionItemContainer item={currentItem} sessionId={sessionId} />
		</Box>
	);
};
export default SessionListDesktop;
