import type { Distribution } from "@memorang/types/src/content";

const getRandomNumber = (max: number) => Math.floor(Math.random() * max) + 1;

const randomizeDistribution = (distribution: Distribution) => {
	for (const key in distribution) {
		distribution[key as keyof Distribution] = getRandomNumber(15);
	}
	return distribution;
};

const getRandomAvatar = (mockedAvatarUrls: string[]) => {
	const defaultAvatarUrl = "https://default-avatar-url.com/default-avatar.svg";

	const randomIndex = Math.floor(Math.random() * mockedAvatarUrls.length);
	return mockedAvatarUrls[randomIndex] || defaultAvatarUrl;
};
export { randomizeDistribution, getRandomNumber, getRandomAvatar };
