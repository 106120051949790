import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* -----------------Globals--------------- */
import { Card, Text } from "react-native-paper";

/* -----------------UI--------------- */
import Box from "@memorang/ui/src/components/Box";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

import type { ExamDetails } from "@features/exam/types";
import { useAppStore } from "@hooks/useAppStore";
/* -----------------Child components--------------- */
import {
	type Purchase,
	RedeemPostPurchase,
} from "components/RedeemPostPurchase";

type Props = {
	products: Purchase[];
	examName: string;
	title: string;
	externalPurchase?: boolean;
	examDetails?: ExamDetails;
};
export const AccessGrantedView = ({
	products,
	examName,
	title,
	externalPurchase,
	examDetails,
}: Props) => {
	const { userDetails } = useAuthContext();
	const email = userDetails?.email;
	const theme = useAppTheme();
	const appFont = useAppStore((store) => store.app.font) || "opensans";

	return (
		<Box paddingBottom={24} paddingHorizontal={16} flex={1}>
			<Card>
				<Card.Content
					style={{
						paddingHorizontal: 8,
					}}
				>
					<Box gap={16}>
						<Icon
							name="check-circle"
							size={44}
							style={{
								alignSelf: "center",
							}}
							color={theme.colors.success.main}
						/>
						<Text
							variant="titleMedium"
							style={{
								textAlign: "center",
								paddingHorizontal: 16,
								fontFamily: `${appFont}-semibold`,
							}}
						>
							{title}
						</Text>
						<RedeemPostPurchase
							purchases={products}
							redeemedProductsExamName={examName}
							redeemStatus="redeemed"
							email={email}
							externalPurchase={externalPurchase}
							examDetails={examDetails}
						/>
					</Box>
				</Card.Content>
			</Card>
		</Box>
	);
};
