import { pageViewTypes } from "@constants/tracking";
import { HomeRouteContainer } from "@features/dashboard/components/HomeRouteContainer";
import StudyPackContainer from "@features/dashboard/screens/StudyPackContainer";
import useAnalytics from "@hooks/useAnalytics";
/* -----------------Globals--------------- */
import { useAppStore } from "@hooks/useAppStore";
import DocumentTitle from "components/DocumentTitle";

/* -----------------Child components--------------- */

const StudyPackRouteContainer = () => {
	return (
		<>
			<DocumentTitle title="Study-Pack" />
			<StudyPackContainer showBackButton={false} />
		</>
	);
};
const HomeRoute = () => {
	const showStudyPackAsHome = useAppStore((store) => store.showStudyPackAsHome);

	useAnalytics({
		type: pageViewTypes.home,
	});
	if (showStudyPackAsHome) {
		return <StudyPackRouteContainer />;
	}
	return (
		<>
			<DocumentTitle title="Home" />
			<HomeRouteContainer />
		</>
	);
};

export default HomeRoute;
