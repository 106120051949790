import { events } from "@constants/tracking";
import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { dialogTexts } from "@features/feedback/constants";
import { sendFeedback } from "@features/feedback/helpers";
import { useAppStore } from "@hooks/useAppStore";
import { trackCustomEvent } from "analytics";
import { useState } from "react";

export const useDelete = () => {
	const [showDeleteFeedbackDialog, setShowDeleteFeedbackDialog] =
		useState(false);
	const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
		useState(false);
	const [checkboxStates, setCheckboxStates] = useState<
		Record<string, "checked" | "unchecked"> | undefined
	>(undefined);

	const [feedback, setFeedback] = useState("");

	const { userDetails } = useAuthContext();

	const {
		currentExamContext: { examName },
	} = useExamContext();
	const appName = useAppStore((store) => store.app.name);
	const companyName = useAppStore((store) => store.companyName);

	const userName = userDetails?.name!;
	const email = userDetails?.email!;
	const isPremiumUser = userDetails?.isPremiumUser;

	const options = dialogTexts.deletefeedback.options;
	const selectedCheckboxes = checkboxStates
		? Object.keys(checkboxStates).filter(
				(key) => checkboxStates[key] === "checked",
			)
		: [];
	const selectedReasons = selectedCheckboxes
		.map(
			(key: string) =>
				`- ${
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					(options as any)[key]
				}`,
		)
		.join("\n");
	const toggleShowDeleteFeedbackDialog = () => {
		setShowDeleteFeedbackDialog((prev) => !prev);
	};

	const toggleShowDeleteConfirmationDialog = () => {
		setShowDeleteConfirmationDialog((prev) => !prev);
	};

	const handleChangeCheckboxState = (index: string) => {
		const newSelection =
			checkboxStates?.[index] === "checked" ? "unchecked" : "checked";
		const newSelections: Record<string, "checked" | "unchecked"> = {
			...(checkboxStates || {}),
			[index]: newSelection,
		};
		setCheckboxStates(newSelections);
	};

	const handleSetFeedback = (text: string) => {
		setFeedback(text);
	};

	const handleSendFeedback = () => {
		trackCustomEvent({
			eventName: events.deleteFeedbackSent,
			source: "Delete_Dialog",
		});
		if (selectedReasons && feedback) {
			const params = {
				userName,
				email,
				appName,
				feedback: `${selectedReasons} \n\n ${feedback}`,
				type: "Deletion" as const,
				view: "settings",
				isPremiumUser,
				companyName,
				examName,
			};
			sendFeedback(params);
		}
	};

	return {
		showDeleteFeedbackDialog,
		showDeleteConfirmationDialog,
		toggleShowDeleteFeedbackDialog,
		toggleShowDeleteConfirmationDialog,
		selectedReasons,
		handleChangeCheckboxState,
		feedback,
		handleSetFeedback,
		handleSendFeedback,
		checkboxStates,
	};
};
