import { useCopilotAction } from "@copilotkit/react-core";
import {
	type ActionComponent,
	actionComponents,
} from "../components/ActionRenderer";
import { PURCHASE_VERIFICATION_INSTRUCTIONS } from "../constants";
import { extractMaskedEmail } from "../helpers/string";
import { fetchAccessCodes } from "../relay/FetchAccessCodes";
import { resendAccessCode } from "../relay/ResendAccessCodes";
import type { ActionHandlerReturn, PurchaseResult } from "../types";

/* --------------- Analytics --------------- */
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../analytics";

type Result = ActionHandlerReturn<
	ActionComponent["purchaseVerification"]["resultType"]
>;

export const usePurchaseVerificationActionTrigger = ({
	tenantId,
	email,
}: {
	tenantId: string;
	email: string;
}) => {
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();

	// Verify Order ID
	useCopilotAction({
		name: "verifyOrder",
		description: "Verify order ID to receive access code via email",
		parameters: [
			{
				name: "orderId",
				type: "string",
				description: "Order ID for verification",
			},
		],
		handler: async ({
			orderId,
		}): Promise<ActionHandlerReturn<PurchaseResult>> => {
			try {
				const { success, message } = await resendAccessCode({
					orderId,
					tenantId,
				});

				if (!success) {
					trackRedeemEvent(rcEvents.purchaseVerificationFailed, {
						interactionType: "auto",
						interactionElementType: "action",
						errorType: "order_not_found",
						success: false,
					});

					return {
						success: false,
						error: message || "Order ID not found",
						instructions: PURCHASE_VERIFICATION_INSTRUCTIONS,
						resultData: { providedEmail: email },
					};
				}

				const { maskedEmail } = extractMaskedEmail(message);

				trackRedeemEvent(rcEvents.purchaseVerificationSucceeded, {
					interactionType: "auto",
					interactionElementType: "action",
					success: true,
				});

				return {
					success: true,
					instructions: PURCHASE_VERIFICATION_INSTRUCTIONS,
					resultData: { maskedEmail, providedEmail: email },
				};
			} catch (e) {
				trackRedeemEvent(rcEvents.purchaseVerificationFailed, {
					interactionType: "auto",
					interactionElementType: "action",
					errorType: "verification_error",
					success: false,
				});

				return {
					success: false,
					error: typeof e === "string" ? e : "An error occurred",
					instructions: PURCHASE_VERIFICATION_INSTRUCTIONS,
					resultData: { providedEmail: email },
				};
			}
		},
	});

	// Check Purchase History (using fetchAccessCodes)
	useCopilotAction({
		name: actionComponents.purchaseVerification.name,
		description: "Check purchase history for your account",
		parameters: [
			{
				name: "userEmail",
				type: "string",
				description: "Email to check",
			},
		],
		handler: async ({ userEmail }): Promise<Result> => {
			if (userEmail !== email) {
				trackRedeemEvent(rcEvents.purchaseVerificationFailed, {
					interactionType: "manual",
					interactionElementType: "button",
					errorType: "email_mismatch",
					success: false,
				});

				return {
					success: false,
					error: "Email does not match current login",
					instructions: PURCHASE_VERIFICATION_INSTRUCTIONS,
					resultData: { providedEmail: email },
				};
			}

			const activeCodes = await fetchAccessCodes({
				tenantId,
				input: userEmail,
			});

			if (!activeCodes || activeCodes.length === 0) {
				trackRedeemEvent(rcEvents.purchaseVerificationFailed, {
					interactionType: "manual",
					interactionElementType: "button",
					errorType: "no_purchases",
					success: false,
				});

				return {
					success: false,
					error: "No purchases found.",
					instructions: PURCHASE_VERIFICATION_INSTRUCTIONS,
					resultData: { providedEmail: email },
				};
			}

			trackRedeemEvent(rcEvents.purchaseVerificationSucceeded, {
				interactionType: "manual",
				interactionElementType: "button",
				success: true,
			});

			return {
				success: true,
				resultData: { activeCodes, userEmail, providedEmail: email },
				instructions: PURCHASE_VERIFICATION_INSTRUCTIONS,
			};
		},
	});
};
