import { z } from "zod";
import type { ContentType, Distribution } from "../content";
import type { MixedItemTag } from "../tag";
import type { QuestionVariant } from "./question";
interface Choice {
	id: string;
	text: string;
	isCorrect?: boolean;
	explanation?: string;
	stem: string;
	numFirstSelections: number;
	testID?: string;
}

export type ReportSessionType = "DIAGNOSTIC" | "PRACTICETEST" | "STUDYPACK";
enum Confidence {
	low = 0,
	medium = 50,
	high = 100,
}
enum Variants {
	mcq = "MCQ",
	sata = "SATA",
}
export type SessionStatus = "ACTIVE" | "COMPLETE" | "PAUSED";

type SessionItemTypes =
	| "ACTION"
	| "BLOCK"
	| "CHOICE"
	| "CST"
	| "PASSAGE"
	| "QUESTION"
	| "SECTION";

interface Score {
	value: number;
}

interface PointList {
	choiceId: string;
	points?: number;
}

export interface Answers {
	numDistractors: number;
	score: number;
	selectedChoiceIds: string[];
	timeTaken: number;
	isCorrect: boolean;
	answerText: string;
	selectedaction: boolean;
	pointsList?: PointList[];
}

export const SessionConfigSchema = z.object({
	startTime: z.string().optional().nullable(),
	allotedTime: z.number().optional().nullable(),
	canSkip: z.boolean().optional().nullable(),
	section: z.string().optional().nullable(),
	exam: z.string().optional().nullable(),
	status: z.string().optional().nullable(),
	totalBreakTimeTakenInSeconds: z.number().optional().nullable(),
});

export type SessionConfig = z.infer<typeof SessionConfigSchema>;

interface BaseItem {
	id: string;
	containerId?: string;
	stem: string;
	displayName?: string;
	itemType: SessionItemTypes;
	currentScore?: Score;
	previousScore?: Score;
	numAttempts?: number;
	tags?: MixedItemTag[];
	variant?: QuestionVariant;
	sequenceOrder?: string[];
	numRequiredAnswers?: number;
	parentStem?: string;
	sessionConfig?: string;
	lastUpdated?: boolean;
}
interface SessionChildItem extends BaseItem {
	instruction?: string;
	isCorrect?: boolean;
	explanation?: string;
	feedback?: string;
	text: string;
	stem: string;
	numAttempts?: number;
	answers?: Answers;
	children?: SessionChildItem[];
	numFirstSelections: number;
	parentStem?: string;
	points?: number;
	marked?: boolean;
	highlight?: string;
	crossOut?: string[];
	maxChoiceCount?: number;
	minChoiceCount?: number;
	video?: string;
}

interface SessionItem extends BaseItem {
	children: SessionChildItem[];
	numItems?: number;
}

interface SessionResponse {
	examName?: string;
	totalTime?: number;
	sessionItems: SessionItem[];
	examBreak?: string;
	startTime?: string;
	id: string;
	sessionTime: number;
	errors?: {
		title: string;
		message: string;
	};
	reportSessionType: ReportSessionType;
	numericId: number;
	hasMore: boolean;
	status: SessionStatus;
	progressList: {
		id: string;
		progress: number;
	}[];
}
export type {
	Choice,
	SessionItem,
	SessionResponse,
	SessionChildItem,
	SessionItemTypes,
};

type StudyContentInput = {
	contentId: string;
	contentType: ContentType;
};

export type StudySessionContextType = "SAMPLE" | "SELECTION";

type SessionContextInput = {
	contents: StudyContentInput[];
	contextType: StudySessionContextType;
};

type LearningOrder = "LINEAR" | "OPTIMIZED" | "SHUFFLED";

export type EndSessionTypes = "FORMATIVE" | "SUMMATIVE";
type SessionType =
	| "FORMATIVE_SESSION"
	| "GET_MIXED_SESSION_QUESTIONS"
	| "SUMMATIVE_SESSION";

type FormativeSessionType = "COMBINED" | "MANUAL" | "SCHEDULE";
export type CreateSessionType =
	| "CST"
	| "FLASHCARD"
	| "MIXED"
	| "PRACTICE"
	| "QUIZ";
export type CreateSessionInput = {
	action: SessionType;
	distribution?: Distribution;
	createSessionType: CreateSessionType;
	tags?: string[][];
	context?: SessionContextInput;
	learningOrder?: LearningOrder;
	examId?: string;
	bundleId?: string;
	userId?: string;
};
export interface EndMixedSessionInput {
	sessionId: string;
	userId: string;
	numericId?: number;
	sessionType: EndSessionTypes;
}

export interface EndSessionResponse {
	sessionId: string;
}

export interface CSTAnswerInput {
	itemId: string;
	score: number;
	sectionId: string;
	timeTaken: number;
}

export interface AnswerState {
	answerText: string;
	confidence: number;
	numDistractors: number;
	score: number;
	selectedChoiceIds: string[];
	timeTaken: number;
}

export interface RecordMixedItemInput {
	answers?: AnswerState;
	containerId?: string;
	crossOut?: string[];
	cstAnswers?: CSTAnswerInput[];
	highlight?: string;
	itemId: string;
	itemType?: SessionItemTypes;
	marked?: boolean;
	progress?: number;
	sessionId: string;
	sessionTime: number;
	userId: string;
	variant: QuestionVariant;
}

export type Mode = "FLIP" | "MATCH" | "QUESTION" | "QUIZ" | "CST";

export interface SessionMode {
	direction: "FORWARD" | "REVERSE";
	mode: Mode;
	sessionType: FormativeSessionType;
}
export interface EventContext {
	contextId: string;
	eventId?: string;
	gameType?: string;
	isCorrect?: boolean;
	numDistractors?: number;
	scheduleId?: string;
	sessionId: string;
	time: string;
	timeFactor?: number;
	timeTaken?: number;
	timezone?: string;
}

export type Hint = {
	id: string;
	text: string;
};

export interface CommonAnswerInput {
	answerId: string;
	chosenAnswerId: string;
	confidence: number;
	correctChoiceIds?: string[];
	correctness: number;
	eventContext: EventContext;
	itemId: string;
	score: number;
	selectedChoiceIds?: string[];
	sequenceOrder?: string[];
	sessionMode?: SessionMode;
	variant?: QuestionVariant;
	points?: number;
	hints: null | Hint[];
}
export { Confidence, Variants };
