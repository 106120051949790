/* ======== Types ========= */
import type { Plan, ProductType } from "@memorang/types/src/bundle";
import { Button } from "react-native-paper";
import { purchaseDisclaimerText } from "./constants";

import { isWeb } from "@helpers/platform";
import Box from "@memorang/ui/src/components/Box";
import MarkdownRenderer from "@memorang/ui/src/components/MarkdownRenderer";
import { getAccessDurationTemplate } from "../../helpers/purchase";
/* ======== Components ========= */
import FeaturesSection from "./FeaturesSection";
import PlansSection, { type PlansSectionProps } from "./PlansSection";
import ProductIconContainer, {
	type ProductIconProps,
} from "./ProductIconContainer";
import RecommendedPlanText from "./RecommendedPlanText";

type Props = {
	features: string[];
	handleRestorePurchase: () => void;
	recommendedPlan?: Plan;
	handleTracking: (eventName: string, params?: Record<string, string>) => void;
	disabled?: boolean;
	loading?: boolean;
	handlePurchase: () => void;
	productType?: ProductType;
	hasAccess?: boolean;
	handleClickAccessHere: () => void;
	purchasable?: boolean;
	fromHomePage?: boolean;
} & PlansSectionProps &
	ProductIconProps;

const IAPModalView = ({
	disabled,
	handlePurchase,
	loading,
	sortedPlans,
	selectedPlan,
	setSelectedPlan,
	expiryDate,
	features,
	url,
	numItems,
	handleTracking,
	productType,
	handleRestorePurchase,
	selectedDate,
	recommendedPlan,
	hasAccess,
	handleClickAccessHere,
	purchasable,
	fromHomePage,
}: Props) => {
	const getCta = () => {
		if (hasAccess) {
			return "Extend Access";
		}
		switch (productType) {
			case "STUDY_PACK":
				return "Upgrade";
			default:
				return `Unlock for $${selectedPlan?.amount}`;
		}
	};
	const cta = getCta();

	const UpgradeButton = () => (
		<Button
			mode="contained"
			disabled={disabled}
			uppercase={false}
			onPress={handlePurchase}
			loading={loading}
		>
			{cta}
		</Button>
	);
	const AccessHereButton = () => (
		<Button
			disabled={disabled || loading}
			uppercase={false}
			onPress={handleClickAccessHere}
		>
			{"Access Here"}
		</Button>
	);

	const restorePurchaseButtonCta = isWeb
		? "Already purchased or have an access code?"
		: "Already Purchased?";
	const RestorePurchaseButton = () => (
		<Button mode="text" onPress={handleRestorePurchase}>
			{restorePurchaseButtonCta}
		</Button>
	);

	const Disclaimer = () => (
		<Box alignItems="center">
			<MarkdownRenderer
				text={purchaseDisclaimerText}
				variant="explanation"
				fontSize={10}
				useCustomLink={false}
			/>
		</Box>
	);

	const accessDurationText = getAccessDurationTemplate(
		selectedPlan,
		expiryDate,
	);

	// TODO: This should see whether plans are being rendered
	const showAccessDurationText = productType === "STUDY_PACK" && purchasable;
	// TODO: This doesn't seem right
	const showPlansSection = productType === "STUDY_PACK" && purchasable;
	const showUpgradeButton = purchasable;
	const showFeatures = features.length > 0;
	const showProductIcon = url;
	const showDisclaimer = purchaseDisclaimerText.length > 0 && purchasable;
	return (
		<Box paddingHorizontal={24} paddingTop={24} flex={1} gap={24}>
			{showProductIcon && (
				<ProductIconContainer
					url={url}
					numItems={numItems}
					fromHomePage={fromHomePage}
					selectedDate={selectedDate}
				/>
			)}
			{showFeatures && <FeaturesSection features={features} />}
			{showPlansSection && (
				<PlansSection
					sortedPlans={sortedPlans}
					selectedPlan={selectedPlan}
					setSelectedPlan={setSelectedPlan}
					expiryDate={expiryDate}
					handleTracking={handleTracking}
				/>
			)}
			{showAccessDurationText && (
				<MarkdownRenderer
					fontSize={14}
					textAlign="center"
					text={accessDurationText}
				/>
			)}
			{showUpgradeButton && <UpgradeButton />}
			{hasAccess && <AccessHereButton />}
			{recommendedPlan && purchasable && showAccessDurationText && (
				<RecommendedPlanText
					recommendedPlan={recommendedPlan}
					selectedDate={selectedDate}
				/>
			)}
			<RestorePurchaseButton />
			{showDisclaimer && <Disclaimer />}
		</Box>
	);
};

export default IAPModalView;
