/* -----------------UI--------------- */
import { Pressable } from "react-native";

/* -----------------Icons--------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { Text } from "react-native-paper";
import { useAppTheme } from "../../useAppTheme";
import Box from "../Box";

type BreadCrumbObject = {
	label: string;
	action?: () => void;
};

type Props = {
	breadcrumbs: BreadCrumbObject[];
};
export const CustomBreadcrumb = (props: Props) => {
	const { breadcrumbs } = props;
	const theme = useAppTheme();

	return (
		<Box flexDirection="row" alignItems="center" flexWrap="wrap">
			{breadcrumbs.map(({ label, action }, index) => (
				<Pressable
					key={label}
					onPress={() => {
						if (action) {
							action();
						}
					}}
				>
					<Box flexDirection="row" alignItems="center">
						<Text
							variant="bodyMedium"
							style={{
								color:
									index !== breadcrumbs.length - 1
										? theme.colors.onPrimaryContainer
										: theme.colors.onSurfaceVariant,
							}}
						>
							{label}
						</Text>

						<Icon
							name="chevron-right"
							size={20}
							color={theme.colors.onSurfaceVariant}
						/>
					</Box>
				</Pressable>
			))}
		</Box>
	);
};
