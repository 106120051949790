/* ----------------- React --------------- */
import { useState } from "react";

import { useExamContext } from "@features/exam/contexts/ExamContext";
import useBundleStore from "@features/store/hooks/useBundleStore";
import { useCustomNavigation } from "@hooks/useCustomNavigation";
/* ----------------- Hooks --------------- */
import { useMoreSettings } from "../hooks/useMoreSettings";

import { PurchaseDialog } from "@features/dashboard/components/PurchaseDialog";
import DateTimePicker from "components/DateTimePicker";
import { ExamsFilterDialog } from "components/ExamsFilterDialog";
/* ----------------- Components --------------- */
import MoreRouteView from "../components/MoreRouteView";

/* ----------------- UI --------------- */
import Box from "@memorang/ui/src/components/Box";
import ScrollContainer from "@memorang/ui/src/components/ScrollContainer";
import { useDevice } from "@memorang/ui/src/useDevice";
import { Card, Portal } from "react-native-paper";

import type { ProductType } from "@memorang/types/src/bundle";
/* ----------------- Types --------------- */
import type { MoreSettingsItem } from "../types";

import { ChangeAvatarDialog } from "@features/profile/dialogs/ChangeAvatarDialog";
import { formatExamDate } from "@helpers/date";
import { isWeb } from "@helpers/platform";
import { useGlobalStore } from "@hooks/useGlobalStore";
import SnackBarCustom from "components/CustomSnackbar";
/* ----------------- Helpers --------------- */
import { router } from "expo-router";

const MoreRouteContainer = () => {
	const {
		moreSettingsSections,
		showDatePicker,
		toggleShowDatePicker,
		showExamsFilterDialog,
		toggleShowExamsFilterDialog,
		purchasedProducts,
	} = useMoreSettings();

	const setShowAvatarUpdatedMessage = useGlobalStore(
		(state) => state.setShowAvatarUpdatedMessage,
	);

	const showAvatarUpdatedMessage = useGlobalStore(
		(state) => state.showAvatarUpdatedMessage,
	);

	const setCurrentlySelectedBundleDetailsForIAP = useBundleStore(
		(store) => store.setCurrentlySelectedBundleDetailsForIAP,
	);
	const [selectedProductType, setSelectedProductType] = useState<
		ProductType | undefined
	>(undefined);
	const { currentExamContext, updateExamDate } = useExamContext();

	const [showChangeAvatarDialog, setShowChangeAvatarDialog] = useState(false);
	const { handleChangeAvatarNavigation } = useCustomNavigation();

	const toggleChangeAvatarDialog = () => {
		setShowChangeAvatarDialog(!showChangeAvatarDialog);
	};

	const { handleExamsFilterNavigation } = useCustomNavigation();

	const handleNavigateToPurchaseView = (id?: string) => {
		const productItem = purchasedProducts?.find(
			(item) => item.bundle.id === id,
		);
		if (productItem) {
			setCurrentlySelectedBundleDetailsForIAP(productItem);
			if (isWeb) {
				setSelectedProductType(productItem.bundle.bundleType);
			} else {
				router.push({
					pathname: "/purchase",
					params: {
						productType: productItem.bundle.bundleType,
					},
				});
			}
		}
	};
	const handleClickMoreSettingsItem = (item: MoreSettingsItem) => {
		switch (item.type) {
			case "profile":
				router.push("/profile");
				break;
			case "change-date":
				toggleShowDatePicker();
				break;
			case "switch-exam":
				handleExamsFilterNavigation(toggleShowExamsFilterDialog);
				break;
			case "subscription":
				handleNavigateToPurchaseView(item.id);
				break;
			case "change-avatar":
				handleChangeAvatarNavigation(toggleChangeAvatarDialog);
				break;
			case "redeem":
				router.push("/redeem");
				break;
			default:
				break;
		}
	};

	const { isMobile } = useDevice();

	const Wrapper = isMobile ? Box : Card;

	const showPurchaseDialog = !!selectedProductType;

	return (
		<ScrollContainer
			style={{
				padding: isMobile ? 0 : 16,
			}}
		>
			<Wrapper>
				<MoreRouteView
					sections={moreSettingsSections}
					handleClickMoreSettingsItem={handleClickMoreSettingsItem}
				/>
			</Wrapper>
			{showDatePicker ? (
				<Portal>
					<DateTimePicker
						isVisible
						onCancel={toggleShowDatePicker}
						initialDate={
							currentExamContext.examDate
								? formatExamDate(currentExamContext?.examDate)
								: currentExamContext?.examDate
						}
						onConfirm={(date) => {
							if (!isWeb) {
								toggleShowDatePicker();
							}
							updateExamDate(date);
						}}
					/>
				</Portal>
			) : null}
			{showExamsFilterDialog && (
				<ExamsFilterDialog
					visible={showExamsFilterDialog}
					handleClose={toggleShowExamsFilterDialog}
				/>
			)}
			{showPurchaseDialog ? (
				<PurchaseDialog
					visible={showPurchaseDialog}
					handleClose={() => setSelectedProductType(undefined)}
					productType={selectedProductType}
				/>
			) : null}
			{showChangeAvatarDialog && (
				<ChangeAvatarDialog
					visible={showChangeAvatarDialog}
					handleClose={toggleChangeAvatarDialog}
				/>
			)}
			{showAvatarUpdatedMessage && (
				<Portal>
					<SnackBarCustom
						message="Avatar updated successfully"
						duration={2000}
						visible
						handleDismiss={() => {
							setShowAvatarUpdatedMessage(false);
						}}
					/>
				</Portal>
			)}
		</ScrollContainer>
	);
};

export default MoreRouteContainer;
