/**
 * @generated SignedSource<<61d1e76a0e4426f152ab6c028f260c96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReportSessionType = "DIAGNOSTIC" | "PRACTICETEST" | "STUDYPACK" | "%future added value";
export type SessionTypes = "FORMATIVE" | "SUMMATIVE" | "%future added value";
export type FetchReportQuery$variables = {
  numericId?: number | null;
  sessionId?: string | null;
  userId?: string | null;
};
export type FetchReportQuery$data = {
  readonly getMixedSessionSummary: {
    readonly accuracy: number | null;
    readonly answered: number | null;
    readonly avgSpeed: number | null;
    readonly cstItemsCount: {
      readonly numCorrectActions: number | null;
      readonly numIncorrectActions: number | null;
      readonly numItems: number | null;
      readonly numSections: number | null;
    } | null;
    readonly cstMarkedCount: number | null;
    readonly distribution: {
      readonly high: number | null;
      readonly low: number | null;
      readonly medium: number | null;
    } | null;
    readonly examName: string | null;
    readonly id: string | null;
    readonly isMigratedProgress: boolean | null;
    readonly markedCount: number | null;
    readonly numChoicePoints: number | null;
    readonly numCorrect: number | null;
    readonly numHints: number | null;
    readonly numIncorrect: number | null;
    readonly numQuestions: number | null;
    readonly numSkipped: number | null;
    readonly numericId: number | null;
    readonly questionsAccuracy: {
      readonly hotspot: {
        readonly mastered: number | null;
        readonly needsWork: number | null;
        readonly numQuestions: number | null;
      } | null;
      readonly mcq: {
        readonly mastered: number | null;
        readonly needsWork: number | null;
        readonly numQuestions: number | null;
      } | null;
      readonly sata: {
        readonly mastered: number | null;
        readonly needsWork: number | null;
        readonly numQuestions: number | null;
      } | null;
      readonly sequence: {
        readonly mastered: number | null;
        readonly needsWork: number | null;
        readonly numQuestions: number | null;
      } | null;
      readonly written: {
        readonly mastered: number | null;
        readonly needsWork: number | null;
        readonly numQuestions: number | null;
      } | null;
    } | null;
    readonly reportSessionType: ReportSessionType | null;
    readonly score: number | null;
    readonly sessionDate: any | null;
    readonly sessionSummaryConfig: ReadonlyArray<string | null> | null;
    readonly sessionType: SessionTypes | null;
    readonly tagDetails: ReadonlyArray<{
      readonly displayType: ReadonlyArray<string | null> | null;
      readonly icon: string | null;
      readonly id: string;
      readonly items: ReadonlyArray<{
        readonly distribution: string | null;
        readonly type: string | null;
      } | null> | null;
      readonly numCorrect: number | null;
      readonly numIncorrect: number | null;
      readonly numSkipped: number | null;
      readonly title: string | null;
      readonly type: string | null;
      readonly weight: number | null;
    } | null> | null;
    readonly timeElapsed: number | null;
    readonly totalChoicePoints: number | null;
    readonly totalTime: number | null;
  };
};
export type FetchReportQuery = {
  response: FetchReportQuery$data;
  variables: FetchReportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "numericId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sessionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "numericId",
    "variableName": "numericId"
  },
  {
    "kind": "Variable",
    "name": "sessionId",
    "variableName": "sessionId"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numHints",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numQuestions",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mastered",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "needsWork",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "QuestionsAccuracy",
  "kind": "LinkedField",
  "name": "questionsAccuracy",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Accuracy",
      "kind": "LinkedField",
      "name": "hotspot",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Accuracy",
      "kind": "LinkedField",
      "name": "mcq",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Accuracy",
      "kind": "LinkedField",
      "name": "written",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Accuracy",
      "kind": "LinkedField",
      "name": "sata",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Accuracy",
      "kind": "LinkedField",
      "name": "sequence",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answered",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accuracy",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeElapsed",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalTime",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgSpeed",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportSessionType",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionSummaryConfig",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numCorrect",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numIncorrect",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numSkipped",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "markedCount",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numChoicePoints",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalChoicePoints",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ProgressDistribution",
  "kind": "LinkedField",
  "name": "distribution",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "low",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "medium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "high",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cstMarkedCount",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMigratedProgress",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "CSTitemsCount",
  "kind": "LinkedField",
  "name": "cstItemsCount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numSections",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numCorrectActions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numIncorrectActions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numItems",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numericId",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "examName",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayType",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "distribution",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MixedItemSessionSummary",
        "kind": "LinkedField",
        "name": "getMixedSessionSummary",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v4/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MixedItemTag",
            "kind": "LinkedField",
            "name": "tagDetails",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MixedItemScore",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  (v33/*: any*/),
                  (v29/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v34/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FetchReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MixedItemSessionSummary",
        "kind": "LinkedField",
        "name": "getMixedSessionSummary",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v4/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MixedItemTag",
            "kind": "LinkedField",
            "name": "tagDetails",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MixedItemScore",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  (v33/*: any*/),
                  (v29/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v34/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c35ca11a8f73e2b003fe44d42b414e82",
    "id": null,
    "metadata": {},
    "name": "FetchReportQuery",
    "operationKind": "query",
    "text": "query FetchReportQuery(\n  $numericId: Int\n  $sessionId: ID\n  $userId: ID\n) {\n  getMixedSessionSummary(numericId: $numericId, sessionId: $sessionId, userId: $userId) {\n    id\n    numHints\n    questionsAccuracy {\n      hotspot {\n        numQuestions\n        mastered\n        needsWork\n      }\n      mcq {\n        numQuestions\n        mastered\n        needsWork\n      }\n      written {\n        numQuestions\n        mastered\n        needsWork\n      }\n      sata {\n        numQuestions\n        mastered\n        needsWork\n      }\n      sequence {\n        numQuestions\n        mastered\n        needsWork\n      }\n    }\n    answered\n    accuracy\n    timeElapsed\n    totalTime\n    avgSpeed\n    sessionDate\n    reportSessionType\n    sessionSummaryConfig\n    score\n    numQuestions\n    numCorrect\n    numIncorrect\n    numSkipped\n    markedCount\n    numChoicePoints\n    totalChoicePoints\n    distribution {\n      low\n      medium\n      high\n    }\n    cstMarkedCount\n    isMigratedProgress\n    cstItemsCount {\n      numSections\n      numCorrectActions\n      numIncorrectActions\n      numItems\n    }\n    numericId\n    examName\n    tagDetails {\n      id\n      title\n      type\n      weight\n      icon\n      displayType\n      numCorrect\n      numIncorrect\n      numSkipped\n      items {\n        distribution\n        type\n        id\n      }\n    }\n    sessionType\n  }\n}\n"
  }
};
})();

(node as any).hash = "0993768c41fd808d1362573e07ed5073";

export default node;
