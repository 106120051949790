import type {
	EndMixedSessionInput,
	EndSessionResponse,
} from "@memorang/types/src/session/common";
import { commitMutation, graphql } from "react-relay";
import relayEnv from "relay/relayenv";

interface Response {
	endSession: EndSessionResponse;
}

const endSession = async (
	input: EndMixedSessionInput,
): Promise<EndSessionResponse> => {
	const mutation = graphql`
    mutation EndSessionMutation($request: EndMixedSessionInput) {
      endMixedSession(request: $request) {
        sessionId
      }
    }
  `;

	const promise = new Promise<EndSessionResponse>((resolve, reject) => {
		const variables = {
			request: input,
		};

		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const finalResponse = response as Response;
				resolve(finalResponse.endSession);
			},
			onError: (err) => {
				reject(err);
			},
		});
	});
	return promise;
};

export { endSession };
