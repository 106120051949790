/* -----------------UI--------------- */
import Box from "@memorang/ui/src/components/Box";
import CustomListSubheaderContainer from "components/CustomListSubheaderContainer";
import { Checkbox, List } from "react-native-paper";

export type CheckboxState = Record<string, "checked" | "unchecked">;
type Props = {
	options: string[];
	checkboxStates?: CheckboxState;
	handleSelectOption: (index: string) => void;
};

const OptionListItem = ({
	option,
	handleSelectOption,
	index,
	selected,
}: {
	option: string;
	handleSelectOption: (index: string) => void;
	index: number;
	selected?: "checked" | "unchecked" | "indeterminate";
}) => (
	<List.Item
		title={option}
		style={{
			paddingVertical: 0,
		}}
		onPress={() => handleSelectOption(`${index}`)}
		left={() => (
			<Checkbox.Android
				key={option}
				status={selected || "unchecked"}
				onPress={() => handleSelectOption(`${index}`)}
			/>
		)}
	/>
);
const DeleteOptions = (props: Props) => {
	const { options, checkboxStates, handleSelectOption } = props;

	const subheader = "Choose Reason";

	return (
		<Box paddingVertical={16}>
			<CustomListSubheaderContainer enableGutters={false}>
				{subheader}
			</CustomListSubheaderContainer>
			{options.map((option, index) => (
				<OptionListItem
					key={option}
					option={option}
					handleSelectOption={handleSelectOption}
					index={index}
					selected={checkboxStates ? checkboxStates[`${index}`] : undefined}
				/>
			))}
		</Box>
	);
};

export default DeleteOptions;
