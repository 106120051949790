/* -----------------Globals--------------- */
import { useEffect, useRef, useState } from "react";

/* -----------------UI--------------- */
import { Appbar, Divider, Searchbar } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { formatCount } from "@memorang/helpers/src/common";

import { useRouter } from "expo-router";
import { Platform, type TextInput } from "react-native";
import type { IconSource } from "react-native-paper/lib/typescript/components/Icon";
import type { SvgProps } from "react-native-svg";
import { useAppTheme } from "../../useAppTheme";
import { useDevice } from "../../useDevice";
import Box from "../Box";
import Container from "../Container";
import { CustomListSubheader } from "../CustomListSubheader";
/* -----------------Child components--------------- */
import { Filters } from "./Filters";
import { SearchableSectionList } from "./SearchableSectionList";
import { StickyActionFooter } from "./StickyActionFooter";

type Props = {
	sectionData: {
		title: string;
		data: {
			name: string;
			id: string;
		}[];
	}[];
	handleAction: (selectedItemId: string) => void;
	actionCta: string;
	initSelectedItemId?: string;
	currentItemSelectedLabel?: string;
	searchIcon?: IconSource;
	emptySearchResultSvg?: React.FC<SvgProps>;
};
export const FilterView = ({
	sectionData,
	handleAction,
	actionCta,
	initSelectedItemId,
	currentItemSelectedLabel,
	searchIcon,
	emptySearchResultSvg,
}: Props) => {
	const { isMobile } = useDevice();
	const [searchQuery, setSearchQuery] = useState("");

	const filters = sectionData.map((section) => section.title);
	const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
	const [selectedItemId, setSelectedItemId] = useState("");

	const searchbarRef = useRef<TextInput>(null);

	const disabled = !selectedItemId;

	useEffect(() => {
		if (initSelectedItemId) {
			setSelectedItemId(initSelectedItemId);
		}
	}, [initSelectedItemId]);

	const selectedItem = sectionData
		.flatMap((section) => section.data)
		.find((item) => item.id === selectedItemId);

	const filteredSections = sectionData
		.map((section) => ({
			...section,
			data: section.data.filter((item) =>
				item.name.toLowerCase().includes(searchQuery.toLowerCase()),
			),
		}))
		.filter(
			(section) =>
				(selectedFilters.length > 0
					? selectedFilters.includes(section.title)
					: true) &&
				(searchQuery
					? section.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
						section.data.length > 0
					: true),
		);

	const numItems = filteredSections.reduce((acc, section) => {
		return acc + section.data.length;
	}, 0);

	const handleSelectFilter = (filter: string) => {
		if (selectedFilters.includes(filter)) {
			setSelectedFilters(selectedFilters.filter((f) => f !== filter));
		} else {
			setSelectedFilters([...selectedFilters, filter]);
		}
	};

	const NumItemsSubheader = () => {
		const numItemsText = `${numItems} ${formatCount("exam", numItems)}`;
		return (
			<CustomListSubheader
				style={{
					paddingBottom: -16,
				}}
				enableGutters
			>
				{numItemsText}
			</CustomListSubheader>
		);
	};
	const theme = useAppTheme();
	const router = useRouter();

	const handleClearSearch = () => {
		setSearchQuery("");
		searchbarRef.current?.focus();
	};

	const showFilters = filters.length > 1;

	return (
		<>
			<Appbar.Header
				statusBarHeight={Platform.OS === "android" ? 24 : 0}
				style={{
					borderBottomWidth: 1,
					borderBottomColor: theme.colors.divider,
				}}
			>
				<Searchbar
					style={{
						flex: 1,
						borderRadius: 0,
						backgroundColor: "transparent",
					}}
					autoFocus={!isMobile}
					ref={searchbarRef}
					onIconPress={() => router.back()}
					value={searchQuery}
					icon={searchIcon || "arrow-left"}
					placeholder="Search for exam to switch to..."
					onChangeText={(text) => setSearchQuery(text)}
				/>
			</Appbar.Header>
			<Container
				containerStyle={{
					height: "100%",
				}}
			>
				<Box gap={8} paddingVertical={16} height={"100%"} flexGrow={1}>
					{showFilters ? (
						<>
							<Box paddingHorizontal={16}>
								<Filters
									filters={filters}
									handleSelectFilter={handleSelectFilter}
									selectedFilters={selectedFilters}
								/>
							</Box>
							<Divider
								style={{
									marginTop: 8,
								}}
							/>
						</>
					) : null}

					<NumItemsSubheader />
					<SearchableSectionList
						sections={filteredSections}
						handleSelectItem={setSelectedItemId}
						selectedItemId={selectedItemId}
						initSelectedItemId={initSelectedItemId}
						emptySearchResultSvg={emptySearchResultSvg}
						handleClearSearch={handleClearSearch}
					/>
					<StickyActionFooter
						title={selectedItem?.name || ""}
						subheader={currentItemSelectedLabel || "Current"}
						cta={actionCta}
						handleAction={() => {
							handleAction(selectedItemId);
						}}
						disabled={disabled}
					/>
				</Box>
			</Container>
		</>
	);
};

export { Filters };
