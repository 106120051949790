import AvatarList from "@memorang/ui/src/components/AvatarList";
import type { Avatar } from "../types";

interface Props {
	avatars: Avatar[];
	handleSelectAvatar: (id: string) => void;
	selectedAvatarId?: string;
}
const ChangAvatarView = ({
	avatars,
	selectedAvatarId,
	handleSelectAvatar,
}: Props) => {
	return (
		<AvatarList
			data={avatars}
			handleSelectAvatar={handleSelectAvatar}
			selectedAvatarId={selectedAvatarId}
		/>
	);
};

export default ChangAvatarView;
