/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

/* -----------------UI--------------- */
import { Button, type ButtonProps, Text } from "react-native-paper";

/* -----------------Helpers & Hooks--------------- */
import { useAppTheme } from "@memorang/ui/src/useAppTheme";

/* -----------------Child components--------------- */
import Box from "@memorang/ui/src/components/Box";
import { RESEND_INTERVAL_SECONDS } from "../constants";

interface Props {
	handleResendOTP: () => void;
	resendingOtp: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const ResendButton = ({ disabled, ...rest }: ButtonProps) => {
	const [seconds, setSeconds] = useState(RESEND_INTERVAL_SECONDS);
	const [isActive, setIsActive] = useState(true);

	useEffect(() => {
		let interval: NodeJS.Timeout | null = null;

		if (isActive) {
			interval = setInterval(() => {
				setSeconds((prev) => prev - 1);
			}, 1000);
		} else if (!isActive && seconds !== 0 && interval) {
			clearInterval(interval);
		}
		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [isActive, seconds]);

	useEffect(() => {
		if (seconds === 0) {
			setIsActive(false);
		}
	}, [seconds]);

	const formatTime = (secs: number) => {
		const m = Math.floor(secs / 60);
		const s = seconds % 60;
		return `${m}:${s < 10 ? "0" : ""}${s}`;
	};

	return (
		<Button {...rest} disabled={disabled || isActive}>
			{isActive ? `Resend code in ${formatTime(seconds)}` : "Resend code"}
		</Button>
	);
};

const VerfiyEmailFooter = ({
	handleResendOTP,
	resendingOtp,
	disabled,
}: Props) => {
	const body = "Didn't receive a valid code?";
	const buttonText = "Resend";
	const theme = useAppTheme();
	return (
		<Box flexDirection="row" alignItems="center" justifyContent="center">
			<Text>{body}</Text>
			<ResendButton
				onPress={handleResendOTP}
				disabled={resendingOtp || disabled}
				loading={resendingOtp}
				mode="text"
				style={{
					marginLeft: 4,
				}}
				labelStyle={{
					marginHorizontal: 0,
				}}
				textColor={theme.colors.secondary}
			>
				{resendingOtp ? "" : buttonText}
			</ResendButton>
		</Box>
	);
};

export default VerfiyEmailFooter;
