import type { AccessCodeResult } from "@features/auth/relay/applyAccessCode";

export type ChatState = {
	assistantTitle: string;
	userId: string;
	username: string;
	userEmail: string;
	supportEmail: string;
	appName: string;
	greetingMessage: string;
	accessCode: string;
	errorMessage: string;
	openChat: boolean;
	openChatMode: "auto" | "manual";
	openChatDelay?: number;
	lastRedeemedBundleId?: string;
	lastRedeemedResult?: AccessCodeResult;
	handleOnOpenChange?: (open: boolean) => void;
};

export const initialChatState = {
	assistantTitle: "",
	userId: "",
	username: "",
	userEmail: "",
	supportEmail: "",
	appName: "",
	greetingMessage: "",
	accessCode: "",
	errorMessage: "",
	openChat: false,
	openChatMode: "manual",
	openChatDelay: 1500,
} as ChatState;

export type SetPartialChatState = (state: Partial<ChatState>) => void;

export type ChatContextType = {
	chatState: ChatState;
	setPartialChatState: SetPartialChatState;
};
