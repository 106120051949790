/* -----------------Third parties--------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* -----------------UI--------------- */
import { TouchableRipple } from "react-native-paper";

/* -----------------Third parties--------------- */
import * as Animatable from "react-native-animatable";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { isWeb } from "@helpers/platform";
import { Confidence } from "@memorang/types/src/session/common";
import Container from "@memorang/ui/src/components/Container";
import TooltipWrapper from "@memorang/ui/src/components/TooltipWrapper";
/* -----------------Components--------------- */
import type { IconType } from "components/types/icon";

/* -----------------Props--------------- */
type Props = {
	onConfidenceProvided: (confidence: Confidence) => void;
	handleTrackConfidenceClick: (confidence: Confidence) => void;
};
const confidenceIcons: Record<string, IconType> = {
	low: "emoticon-sad-outline",
	medium: "emoticon-neutral-outline",
	high: "emoticon-happy-outline",
};

const tooltipForIcons: Record<string, string> = {
	low: "I'm not sure",
	medium: "I think I know",
	high: "I definitely know",
};

const ConfidenceSubmitButtons = (props: Props) => {
	const { onConfidenceProvided, handleTrackConfidenceClick } = props;
	const theme = useAppTheme();

	const handleSubmitConfidence = (selectedConfidence: Confidence) => {
		handleTrackConfidenceClick(selectedConfidence);
		onConfidenceProvided(selectedConfidence);
	};
	return (
		<Container maxWidth="sm">
			<Animatable.View
				useNativeDriver
				style={{
					flexDirection: "row",
					gap: 16,
					alignItems: "flex-start",
					// backgroundColor: 'white',
				}}
				duration={300}
				animation="fadeIn"
				easing="ease-out"
			>
				{(Object.keys(confidenceIcons) as (keyof typeof Confidence)[]).map(
					(c) => {
						const Content = () => {
							return (
								<TouchableRipple
									key={c}
									onPress={() => handleSubmitConfidence(Confidence[c])}
									style={{
										display: "flex",
										backgroundColor: theme.colors.confidence[c],
										borderRadius: 24,
										overflow: "hidden",
										justifyContent: "center",
										alignItems: "center",
										height: 40,
										flexGrow: 1,
									}}
									testID={`confidence-button-${c}`}
								>
									<Icon name={confidenceIcons[c]} size={24} color="white" />
								</TouchableRipple>
							);
						};
						return isWeb ? (
							<TooltipWrapper
								key={c}
								title={tooltipForIcons[c]}
								style={{
									flexGrow: 1,
								}}
							>
								<Content />
							</TooltipWrapper>
						) : (
							<Content key={c} />
						);
					},
				)}
			</Animatable.View>
		</Container>
	);
};

export default ConfidenceSubmitButtons;
