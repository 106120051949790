/* -----------------Globals--------------- */
import { useState } from "react";
import { Keyboard } from "react-native";

/* -----------------Third parties--------------- */
import { API } from "@aws-amplify/api";
import type { CognitoUser } from "amazon-cognito-identity-js";

import {
	handleCustomChallenge,
	isAuthenticated,
	signInUsingEmail,
	signUpUsingEmail,
} from "@memorang/helpers/src/amplify/amplifyAuth";
import setUpAmplifyConfig from "@memorang/helpers/src/amplify/setUpAmplify";

/* -----------------Hooks--------------- */
import useCognitoAuth from "../hooks/useCognitoAuth";

import CustomSnackbar from "@memorang/ui/src/components/CustomSnackbar";
import EmailView from "../components/EmailView";
import DialogContactSupport from "../components/dialogs/DialogContactSupport";
import { whitelistedEmailRegex, whitelistedEmails } from "../constants";
/* -----------------Child components--------------- */
// import { useLinkTo } from '@react-navigation/native';
import { useAuthContext } from "../contexts/AuthContext";

type Props = {
	tenantId: string;
	appId: string;
	companyName: string;
	awsExports: Record<string, string | null>;
	buildType: string;
	currentAppVersion: string;
	handleTracking: (data: Record<string, string>) => void;
	supportEmail: string;
	handleNavigation: (back?: boolean, userExists?: boolean) => void;
	handleNavigateToDashboard: () => void;
	emailFromUrl?: string;
	lastLoggedInEmail?: string;
};
const EmailContainer = ({
	tenantId,
	appId,
	companyName,
	awsExports,
	handleTracking,
	handleNavigation,
	supportEmail,
	handleNavigateToDashboard,
	buildType,
	currentAppVersion,
	emailFromUrl,
	lastLoggedInEmail,
}: Props) => {
	const [loading, setLoading] = useState(false);
	const [showContactSupportDialog, setShowContactSupportDialog] =
		useState(false);
	const [showInvalidDomainSnackBar, setShowInvalidDomainSnackBar] =
		useState(false);

	const setAuth = useCognitoAuth((state) => state.setAuth);
	const { refreshAuth } = useAuthContext();

	const handlePostLogin = async (
		email: string,
		cognitoUser: CognitoUser,
		userExists?: boolean,
	) => {
		const isTestUser =
			whitelistedEmails.includes(email) || whitelistedEmailRegex.test(email);
		if (isTestUser && cognitoUser) {
			await handleCustomChallenge("111111", cognitoUser);
			await isAuthenticated();
			await refreshAuth();
			API.configure(setUpAmplifyConfig(awsExports, true, true));
			handleNavigateToDashboard();
		} else {
			setAuth(cognitoUser, userExists);
			handleNavigation(false);
		}
	};

	const handleSubmit = async (email: string) => {
		try {
			Keyboard.dismiss();
			setLoading(true);
			try {
				await signUpUsingEmail(email, appId, tenantId);
				const cognitoUser = await signInUsingEmail(email, appId, tenantId);
				await handlePostLogin(email, cognitoUser);
				// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			} catch (e: any) {
				const { code } = e;
				if (code === "UserLambdaValidationException") {
					setShowInvalidDomainSnackBar(true);
				}
				if (code === "UsernameExistsException") {
					const result = await signInUsingEmail(email, appId, tenantId);
					await handlePostLogin(email, result, true);
				}
			}
		} catch (_err) {
			setShowContactSupportDialog(true);
			handleTracking({
				eventName: "mem_login_attempted",
				email,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<EmailView
				handleSubmit={handleSubmit}
				loading={loading}
				emailFromUrl={emailFromUrl}
				lastLoggedInEmail={lastLoggedInEmail}
			/>
			{showContactSupportDialog && (
				<DialogContactSupport
					visible={showContactSupportDialog}
					companyName={companyName}
					supportEmail={supportEmail}
					buildType={buildType}
					currentAppVersion={currentAppVersion}
					handleDismiss={() => setShowContactSupportDialog(false)}
				/>
			)}
			{showInvalidDomainSnackBar && (
				<CustomSnackbar
					handleDismiss={() => setShowInvalidDomainSnackBar(false)}
					visible={showInvalidDomainSnackBar}
					duration={2000}
					message="You must use a permanent email address"
				/>
			)}
		</>
	);
};

export default EmailContainer;
