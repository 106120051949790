import type { CognitoUser } from "amazon-cognito-identity-js";
import { create } from "zustand";

declare module "amazon-cognito-identity-js" {
	interface CognitoUser {
		username: string;
		challengeParam?: {
			email: string;
			USERNAME: string;
		};
	}
}

type State = {
	cognitoUser?: CognitoUser;
	setAuth: (cognitoUser: CognitoUser, userExists?: boolean) => void;
	userExists?: boolean;
};
const useCognitoAuth = create<State>()((set) => ({
	cognitoUser: undefined,
	setAuth: (cognitoUser, userExists) => {
		set({
			cognitoUser,
			userExists,
		});
	},
}));

export default useCognitoAuth;
