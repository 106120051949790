/* -----------------Globals--------------- */
import { commitMutation, graphql } from "react-relay";
import relayEnv from "relay/relayenv";
import type { ModeDetails, SuggestionType } from "../types/suggest-correction";

type SuggestCorrectionResponse = {
	suggestCorrection: boolean;
};

const mutation = graphql`
  mutation SuggestCorrectionMutation(
    $itemId: ID!
    $suggestion: SuggestionInput!
    $modeDetails: ModeDetails
  ) {
    suggestCorrection(
      itemId: $itemId
      suggestion: $suggestion
      modeDetails: $modeDetails
    )
  }
`;

const suggestCorrection = async (
	itemId: string,
	suggestion: SuggestionType,
	modeDetails?: ModeDetails,
): Promise<boolean | unknown> => {
	const promise = new Promise((resolve, reject) => {
		const variables = {
			itemId,
			suggestion,
			modeDetails,
		};

		commitMutation(relayEnv, {
			mutation,
			variables,
			onCompleted: (response) => {
				const finalResponse = response as SuggestCorrectionResponse;
				if (finalResponse.suggestCorrection) {
					resolve(response);
				}
			},
			onError: (err) => {
				reject(err);
			},
		});
	});
	return promise;
};

export default suggestCorrection;
