import { useCopilotAction } from "@copilotkit/react-core";
import { type Href, useRouter } from "expo-router";
import { NAVIGATE_BY_ROUTE_INSTRUCTIONS } from "../constants";
import { useRedeemCopilotChat } from "../context";
import { getGreetingMessage } from "../helpers/assistant";
import type { ActionHandlerReturn } from "../types";

/* --------------- Analytics --------------- */
import {
	redeemCopilotEvents as rcEvents,
	useRedeemCopilotAnalytics,
} from "../analytics";

type Route = "HOME" | "STUDY_PACK" | "PRACTICE_TEST" | "REDEEM" | "MORE";

const routeMap: Record<Route, Href> = {
	HOME: "/",
	STUDY_PACK: "/study-pack",
	PRACTICE_TEST: "/practice-tests",
	REDEEM: "/redeem",
	MORE: "/more",
};

const validRoutes = Object.keys(routeMap).join(", ");

export const useNavigateByRouteActionTrigger = ({
	redeemStep,
}: { redeemStep: "redeem" | "success" }) => {
	const router = useRouter();
	const { trackRedeemEvent } = useRedeemCopilotAnalytics();
	const { setPartialChatState } = useRedeemCopilotChat();

	useCopilotAction({
		name: "navigateByRouteName",
		description: `Redirect to the specified route name. Valid route names: ${validRoutes}.`,
		parameters: [
			{
				name: "routeName",
				type: "string",
				description: `Valid route name. Valid \`routeName\` = ${validRoutes}`,
			},
		],

		handler: ({ routeName }): ActionHandlerReturn => {
			const validRouteName = routeName as Route;
			const route = routeMap[validRouteName];

			if (!route) {
				trackRedeemEvent(rcEvents.contentRedirectFailed, {
					interactionType: "auto",
					interactionElementType: "action",
					errorType: "invalid_route",
					success: false,
				});

				return {
					success: false,
					instructions: NAVIGATE_BY_ROUTE_INSTRUCTIONS,
				};
			}

			if (validRouteName === "REDEEM" && redeemStep === "success") {
				trackRedeemEvent(rcEvents.contentRedirectSucceeded, {
					interactionType: "auto",
					interactionElementType: "action",
					success: true,
				});

				setPartialChatState({
					openChat: true,
					openChatMode: "auto",
					greetingMessage:
						redeemStep === "success"
							? "Would you like to redeem another access code? I can help you with that."
							: getGreetingMessage("auto"),
				});
				router.setParams({ step: "redeem" });
			} else {
				trackRedeemEvent(rcEvents.contentRedirectSucceeded, {
					interactionType: "auto",
					interactionElementType: "action",
					success: true,
				});

				router.push(route);
			}

			return {
				success: true,
			};
		},
	});
};
