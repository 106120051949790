/* ======== UI ========= */
import { configureFonts } from "react-native-paper";

import { buildType } from "@helpers/expo-extras";
import { useAppStore } from "@hooks/useAppStore";
/* ======== Helpers ========= */
import { typography } from "@memorang/helpers/src/font";
import type { Apps } from "types/apps";
import { breakpoints, componentOverrides, fontConfig } from "./constants";
import { getCustomPalette, getMergedCustomTheme, textColors } from "./helpers";

const buildTheme = (appName: Apps, darkMode: boolean) => {
	const appFont = useAppStore.getState().app.font;
	const customTextColors = textColors(darkMode);
	const customPalette = getCustomPalette(darkMode);
	const mergedCustomTheme = getMergedCustomTheme({
		appName,
		darkMode,
	});
	const customTypography = typography(darkMode, appFont ?? "opensans");
	const fonts = configureFonts({ config: fontConfig });
	const colors = {
		...mergedCustomTheme.colors,
		text: darkMode ? "#fff" : "#000",
		white: "#fff",
		black: "#000",
		...customPalette,
	};

	return {
		...mergedCustomTheme,
		dark: darkMode,
		colors,
		palette: {
			text: {
				...customTextColors,
			},
			success: {
				main: darkMode ? "#4CAF50" : "#2E7D32",
				light: darkMode ? "#81C784" : "#2E7D32",
				dark: darkMode ? "#388E3C" : "#1B5E20",
			},
			error: {
				main: darkMode ? "#FF4D4D" : "#EB0014",
				light: darkMode ? "#FF9999" : "#EB0014",
				dark: darkMode ? "#CC0000" : "#C70011",
			},
			purple: {
				main: darkMode ? "#B964F7" : "#800080",
				light: darkMode ? "#D4A4F7" : "#800080",
				dark: darkMode ? "#9932CC" : "#4B0082",
			},
		},
		breakpoints,
		typography: customTypography,
		fonts,
		componentOverrides,
	};
};

const theme = (darkMode?: boolean) => buildTheme(buildType, darkMode!);
export default theme;
