/* ----------------- Types --------------- */
import type { HeaderSectionData } from "@features/dashboard/types";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "@hooks/useAppTheme";

/* ----------------- UI --------------- */
import Box from "@memorang/ui/src/components/Box";
import DonutChart from "@memorang/ui/src/components/DonutChart";
import { DonutChartSkeleton } from "@memorang/ui/src/components/Skeletons/DonutChartSkeleton";
import { StatsSkeleton } from "@memorang/ui/src/components/Skeletons/StatsSkeleton";
import { TitleSubtitleSkeleton } from "@memorang/ui/src/components/Skeletons/TitleSubtitleSkeleton";
import { useDevice } from "@memorang/ui/src/useDevice";
import { Card, Text } from "react-native-paper";

import { SetExamDate } from "components/ExamContextListItem";
/* ----------------- Components --------------- */
import ProductIcon from "components/ProductIcon";
import RowStats from "components/RowStats";

/* ----------------- Icons --------------- */
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useAppStore } from "@hooks/useAppStore";
import { Pressable } from "react-native";

interface Props {
	headerData?: HeaderSectionData;
	handleSwitchExam: () => void;
}

const ExamInfoSection = ({ headerData, handleSwitchExam }: Props) => {
	const { isMobile } = useDevice();

	const appFont = useAppStore((state) => state.app.font) || "opensans";
	const theme = useAppTheme();
	return (
		<Card
			style={{
				borderRadius: isMobile ? 0 : 8,
				overflow: "hidden",
			}}
		>
			<Card.Content>
				<Box
					width={"100%"}
					flexDirection={isMobile ? "column" : "row"}
					gap={16}
					justifyContent="space-between"
				>
					<Box
						alignItems="center"
						flexDirection={isMobile ? "column" : "row"}
						gap={16}
					>
						{headerData ? (
							<DonutChart
								distribution={headerData.distribution}
								radius={41}
								innerRadius={30}
							>
								{headerData.examIcon ? (
									<ProductIcon image={headerData.examIcon} size={40} />
								) : (
									<Icon
										name="file-document-outline"
										size={64}
										color={theme.colors.onSurfaceVariant}
									/>
								)}
							</DonutChart>
						) : (
							<DonutChartSkeleton
								avatarStyle={{
									marginLeft: -8,
								}}
							/>
						)}
						<Box alignItems={isMobile ? "center" : "flex-start"} gap={8}>
							{headerData ? (
								<>
									<Text
										variant="titleMedium"
										numberOfLines={1}
										style={{
											maxWidth: isMobile ? "100%" : 400,
										}}
									>
										{headerData.examName}
									</Text>
									<Pressable onPress={handleSwitchExam}>
										<Box flexDirection="row" alignItems="center" gap={8}>
											<Text
												style={{
													color: theme.colors.onSurfaceVariant,
												}}
											>
												{"Not the right exam?"}
											</Text>
											<Text
												style={{
													fontFamily: `${appFont}-semibold`,
													color: theme.colors.primary,
												}}
											>
												{"Switch"}
											</Text>
										</Box>
									</Pressable>
									<SetExamDate
										examDateLabel={headerData.examStatusText}
										textColor={theme.colors.primary}
										examDate={headerData.examDate}
									/>
								</>
							) : (
								<TitleSubtitleSkeleton />
							)}
						</Box>
					</Box>
					{headerData ? (
						<RowStats showBorder stats={headerData.headerStats} flexGrow={1} />
					) : (
						<StatsSkeleton />
					)}
				</Box>
			</Card.Content>
		</Card>
	);
};

export default ExamInfoSection;
