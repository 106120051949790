import MemorangIcons from "components/MemorangIcons";

/* ----------------- Types --------------- */
import type { SectionItems } from "../../types";

import Icon from "@expo/vector-icons/MaterialCommunityIcons";
/* ----------------- Expo --------------- */
import { useRouter } from "expo-router";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "@hooks/useAppTheme";

import { useDevice } from "@memorang/ui/src/useDevice";
/* ----------------- React Native Paper --------------- */
import { Button, Card, List } from "react-native-paper";
import { ProductsSectionSkeleton } from "../Skeletons/ProductsSectionSkeleton";

interface Props {
	items: SectionItems;
	loading?: boolean;
}
const ProductsSection = ({ items, loading }: Props) => {
	const router = useRouter();
	const theme = useAppTheme();
	const { isMobile } = useDevice();
	return (
		<Card
			style={{
				borderRadius: isMobile ? 0 : 8,
				overflow: "hidden",
			}}
		>
			{loading ? (
				<ProductsSectionSkeleton />
			) : (
				<>
					{items.map((item) => {
						if ("link" in item) {
							const { link, title, icon, subtitle, custom } = item;

							const handleNavigation = () => {
								router.push(link as never);
							};
							return (
								<List.Item
									title={title}
									key={title}
									onPress={handleNavigation}
									style={{
										width: "100%",
									}}
									descriptionStyle={{
										fontSize: 12,
									}}
									description={subtitle}
									left={(props) => (
										<List.Icon
											{...props}
											icon={() => {
												return custom ? (
													<MemorangIcons
														name={icon}
														size={24}
														color={theme.colors.onSurfaceVariant}
													/>
												) : (
													<Icon
														// biome-ignore lint/suspicious/noExplicitAny: <explanation>
														name={icon as any}
														size={24}
														color={theme.colors.onSurfaceVariant}
													/>
												);
											}}
										/>
									)}
									right={(props) => (
										<List.Icon
											{...props}
											icon={() => {
												return (
													<Button onPress={handleNavigation}>Browse</Button>
												);
											}}
										/>
									)}
								/>
							);
						}
						return null;
					})}
				</>
			)}
		</Card>
	);
};

export default ProductsSection;
