import { List, type ListSubheaderProps } from "react-native-paper";

export type CustomListSubheaderProps = ListSubheaderProps & {
	enableGutters?: boolean;
	font?: string;
};
export const CustomListSubheader = ({
	children,
	enableGutters = true,
	style,
	font = "opensans",
	...rest
}: CustomListSubheaderProps) => {
	return (
		<List.Subheader
			style={[
				{
					paddingHorizontal: enableGutters ? 16 : 0,
					fontFamily: `${font}-semibold`,
				},
				style,
			]}
			{...rest}
		>
			{children}
		</List.Subheader>
	);
};
