/* -----------------UI--------------- */
import Backdrop from "@mui/material/Backdrop";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {
	type Action,
	KBarAnimator,
	type KBarOptions,
	KBarPortal,
	KBarPositioner,
	KBarProvider,
	KBarSearch,
} from "kbar";
import { type PropsWithChildren, forwardRef, useImperativeHandle } from "react";

/* -----------------Child components--------------- */
import Results from "./Results";

const KbarInput = forwardRef((props, ref) => {
	useImperativeHandle(ref, () => ({
		// focus: () => {},
	}));

	return <KBarSearch {...props} />;
});

type CommandBarProviderProps = PropsWithChildren<{
	initialActions?: Action[];
	options?: KBarOptions;
}>;

export const CommandBarProvider = ({
	children,
	initialActions,
	options,
}: CommandBarProviderProps) => {
	return (
		<KBarProvider
			actions={initialActions}
			options={{
				disableScrollbarManagement: true,
				enableHistory: true,
				...options,
			}}
		>
			<KBarPortal>
				<Backdrop open sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}>
					<KBarPositioner>
						<KBarAnimator style={{ width: "100%", maxWidth: "600px" }}>
							<Paper elevation={1} style={{ width: "100%" }}>
								<TextField
									focused={false}
									fullWidth
									InputProps={{
										// biome-ignore lint/suspicious/noExplicitAny: <explanation>
										inputComponent: KbarInput as any,
									}}
								/>
								<Results />
							</Paper>
						</KBarAnimator>
					</KBarPositioner>
				</Backdrop>
			</KBarPortal>
			{children}
		</KBarProvider>
	);
};
