/* -----------------Globals--------------- */
import { useEffect } from "react";

import { useAppStore } from "@hooks/useAppStore";
import { useAppTheme } from "@hooks/useAppTheme";
/* -----------------Helpers & Hooks--------------- */
import { useEditor } from "@tiptap/react";
import useEditorStore from "../../hooks/useEditorStore";

/* -----------------Child components--------------- */
import { EditorContent } from "@tiptap/react";

import Highlight from "@tiptap/extension-highlight";
/* -----------------Types--------------- */
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";
import { CustomImageExtension } from "../tiptapextensions/ImageExtension";

type Props = {
	text: string;
	handleStoreHtml?: (html: string) => void;
	highlightedHtml?: string;
};

const htmlEntityReplacements: { searchTerm: string; newValue: string }[] = [
	{ searchTerm: "&gte;", newValue: "&#8805;" },
	{ searchTerm: "&lte;", newValue: "&#8804;" },
	{ searchTerm: "&gt;", newValue: "&#62;" },
	{ searchTerm: "&lt;", newValue: "&#60;" },
	{ searchTerm: "&amp;", newValue: "&" },
];

const ItemStem = ({ text, handleStoreHtml, highlightedHtml }: Props) => {
	const setEditorRef = useEditorStore((store) => store.setEditorRef);
	const theme = useAppTheme();
	const stemFontSize = useAppStore((store) => store.app.fontSizes?.stem);
	const appFont = useAppStore((store) => store.app.font) || "opensans";

	const finalStemFontSize = stemFontSize || 16;

	const editor = useEditor({
		extensions: [
			StarterKit,
			Markdown,
			CustomImageExtension,
			Highlight.configure({
				HTMLAttributes: {
					class: "highlight",
				},
			}),
		],
		content: "",
		editable: false,
		onUpdate: ({ editor: updateEditor }) => {
			if (handleStoreHtml) {
				handleStoreHtml(updateEditor.getHTML());
			}
		},
	});

	useEffect(() => {
		if (editor) {
			setEditorRef(editor);
		}
	}, [editor, setEditorRef]);

	useEffect(() => {
		if (editor) {
			let editorText = highlightedHtml || text;
			for (const { searchTerm, newValue } of htmlEntityReplacements) {
				editorText = editorText.replaceAll(searchTerm, newValue);
			}
			editor.commands.setContent(editorText);
		}
	}, [editor, highlightedHtml, text]);

	return (
		<>
			<style>
				{`
					.ProseMirror strong {
						font-family: "${appFont}-bold" !important;
						font-weight: normal;
					}
				`}
			</style>
			<EditorContent
				editor={editor}
				style={{
					color: theme.colors.text,
					fontFamily: `${appFont}-regular`,
					fontSize: finalStemFontSize,
				}}
			/>
		</>
	);
};

export default ItemStem;
