/* -----------------Third parties--------------- */
import { Button, Dialog, Text } from "react-native-paper";

/* -----------------Child components--------------- */
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";

/* -----------------Constants--------------- */
import { moreItemsAlertTemplate } from "./constants";

type Props = {
	visible: boolean;
	handleClose: () => void;
	numItemsRequested: number;
	numItemsAvailable: number;
};
/* -----------------Component--------------- */
const MoreItemsAlertDialog = ({
	visible,
	handleClose,
	numItemsAvailable,
	numItemsRequested,
}: Props) => {
	const body = moreItemsAlertTemplate({
		numItemsAvailable,
		numItemsRequested,
	});
	return (
		<DialogWrapper visible={visible} handleClose={handleClose} maxWidth={"xs"}>
			<Dialog.Icon icon={"information-outline"} />
			<Dialog.Title
				style={{
					textAlign: "center",
				}}
			>
				Additional Items
			</Dialog.Title>
			<Dialog.Content>
				<Text variant="bodyMedium">{body}</Text>
			</Dialog.Content>

			<Dialog.Actions>
				<Button
					style={{
						paddingHorizontal: 16,
					}}
					onPress={handleClose}
				>
					Got it!
				</Button>
			</Dialog.Actions>
		</DialogWrapper>
	);
};

export default MoreItemsAlertDialog;
