/* -----------------Globals--------------- */
import { useEffect, useState } from "react";

/* -----------------UI--------------- */
import { Dialog } from "react-native-paper";

import { useExamContext } from "@features/exam/contexts/ExamContext";
/* -----------------Helpers & Hooks--------------- */
import { useAppStore } from "@hooks/useAppStore";

/* -----------------Types--------------- */
import type { AccessCodeData } from "@features/auth/relay/applyAccessCode";

import { useAuthContext } from "@features/auth/contexts/AuthContext";
/* -----------------Features--------------- */
import applyAccessCode from "@features/auth/relay/applyAccessCode";
import { fetchExamDetails } from "@features/exam/queries/FetchExamDetails";
import type { ExamDetails } from "@features/exam/types";
import { useDevice } from "@hooks/useDevice";
import DialogWrapper from "@memorang/ui/src/components/DialogWrapper";
import {
	type Purchase,
	RedeemPostPurchase,
} from "components/RedeemPostPurchase";
import { useRouter } from "expo-router";

const AutoRedeemDialog = ({
	visible,
	onDismiss,
	purchases,
}: {
	visible: boolean;
	onDismiss: () => void;
	purchases: Purchase[];
}) => {
	const [localPurchases, setLocalPurchases] = useState<Purchase[]>(purchases);

	useEffect(() => {
		setLocalPurchases(purchases);
	}, [purchases]);
	const router = useRouter();
	const tenantId = useAppStore((store) => store.tenant.id);

	const { isMobile } = useDevice();
	const [redeemStatus, setRedeemStatus] = useState<
		"redeeming" | "redeemed" | undefined
	>(undefined);
	const { userDetails } = useAuthContext();
	const [examDetails, setExamDetails] = useState<ExamDetails | undefined>(
		undefined,
	);

	const email = userDetails?.email || "";
	const { handleSwitchExamContext } = useExamContext();
	const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

	const hasRedeemed = redeemStatus === "redeemed";

	useEffect(() => {
		if (purchases.length) {
			setSelectedProducts(purchases.map((p) => p.id));
		}
	}, [purchases]);
	const handleSelectProduct = (id: string) => {
		setSelectedProducts((prev) => {
			if (prev.includes(id)) {
				return prev.filter((p) => p !== id);
			}
			return [...prev, id];
		});
	};

	const handlePostApplyAccessCode = async (
		accessCodeResult: AccessCodeData,
	) => {
		const examDetails = await fetchExamDetails({
			examId: accessCodeResult.examId,
			tenantId,
		});

		setExamDetails(examDetails);
	};

	const handleAction = async () => {
		if (hasRedeemed) {
			if (examDetails) {
				await handleSwitchExamContext(examDetails);
			}
			onDismiss();
			const practiceTestProduct = purchases.find(
				(item) => item.bundleType === "PRACTICE_TEST",
			);
			const examPrepProduct = purchases.find(
				(item) => item.bundleType === "EXAM",
			);

			const url =
				(practiceTestProduct || examPrepProduct) && !isMobile
					? "/practice-tests"
					: "/(protected)/study-pack";
			router.push(url);
			return;
		}
		setRedeemStatus("redeeming");
		await Promise.all(
			selectedProducts.map(async (id) => {
				const codes = purchases.find((p) => p.id === id)?.codes;
				const code = codes?.[0];
				const result = await applyAccessCode(code!);
				await handlePostApplyAccessCode(result.data);
				setLocalPurchases((prev) => {
					const currentPurchaseIndex = prev.findIndex((p) => p.id === id);
					prev[currentPurchaseIndex!].isCustomAccess =
						result.data?.isCustomAccess;
					return [...prev];
				});
			}),
		);
		setRedeemStatus("redeemed");
	};

	const title = hasRedeemed ? "Access Granted" : "Redeem Access";
	const icon = hasRedeemed ? "check-circle" : "basket-fill";
	const redeemedProductsExamName = examDetails?.exam.name || "";

	return (
		<DialogWrapper visible={visible} handleClose={onDismiss} maxWidth="xs">
			<Dialog.Icon icon={icon} />
			<Dialog.Title
				style={{
					textAlign: "center",
				}}
			>
				{title}
			</Dialog.Title>
			<Dialog.Content
				style={{
					paddingHorizontal: 8,
				}}
			>
				<RedeemPostPurchase
					purchases={localPurchases}
					email={email}
					redeemStatus={redeemStatus}
					handleAction={handleAction}
					selectedProducts={selectedProducts}
					handleSelectProduct={handleSelectProduct}
					redeemedProductsExamName={redeemedProductsExamName}
					handleDismiss={onDismiss}
					externalPurchase
				/>
			</Dialog.Content>
		</DialogWrapper>
	);
};

export default AutoRedeemDialog;
