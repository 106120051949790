/* ----------------- Features --------------- */
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { NotesCategorySectionList } from "@features/notes/components/NotesCategorySectionList";
import { subjectCategoryMap } from "@features/notes/constants";
import fetchStudyGuides from "@features/notes/queries/FetchStudyGuides";

import Loader from "@memorang/ui/src/components/Loader";
import { useQuery } from "@tanstack/react-query";
/* ----------------- Components --------------- */
import CustomAppbar from "components/CustomAppbar";

const NotesRoute = () => {
	const {
		currentExamContext: { studyPackProductId },
	} = useExamContext();

	const { data } = useQuery({
		queryKey: ["GET_STUDY_GUIDE_TAGS", studyPackProductId],
		queryFn: async () => {
			const res = await fetchStudyGuides(
				studyPackProductId,
				"GET_STUDY_GUIDE_TAGS",
			);
			return res.fetchStudyGuideItems.items;
		},
	});

	const sections = Object.keys(subjectCategoryMap).map((part) => {
		return {
			title: part,
			data:
				data
					?.filter((item) => subjectCategoryMap[part].includes(item.title))
					.sort((a, b) => a.title.localeCompare(b.title)) || [],
		};
	});

	return (
		<>
			<CustomAppbar
				options={{
					headerTitle: "Study Guides",
				}}
			/>
			{data?.length ? (
				<NotesCategorySectionList sections={sections} isMainCategory />
			) : (
				<Loader />
			)}
		</>
	);
};

export default NotesRoute;
